import Api from "@/services/Api";

export default {
  getActivities(Active) {
    return Api().get("getactivities", {
      params: {
        active: Active
      }
    });
  },
  getXActivities() {
    return Api().get("getinterests");
  },
  getMyActivities(UserID, InterestType) {
    return Api().get("myinterests", {
      params: {
        UserID: UserID,
        InterestType: InterestType
      }
    });
  },
  getLanguages(UserID) {
    return Api().get("languages", {
      params: {
        UserID: UserID
      }
    });
  },
  put(interest) {
    return Api().put(`interests/${interest.ID}`, interest);
  }
};
