import AuthenticationService from "@/services/AuthenticationService";

export default {
  async checkToken(token, userid) {
    try {
      if (token) {
        // check if token exists for user
        const res = await AuthenticationService.existToken(token);
        if (res.data === "") {
          const save = await AuthenticationService.addToken({
            token: token,
            userid: userid
          });
          console.log(save);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  /*
  tokenExists() {
    return true
  },
  addNewToken(token) {
    try {
      console.log(token)
    } catch (error) {
      console.log(error)
    }
  }
  */
};
