<template>
  <v-dialog v-model="resetdialog" persistent max-width="500px">
    <v-card>
      <v-container>
        <v-row class="fill-height">
          <v-col cols="12">
            <v-card-title class="ma-0 pa-0">Reset Password</v-card-title>
            <v-card-text> </v-card-text>
            <v-text-field
              v-if="this.changed === false"
              v-model="username"
              placeholder="Your Existing Username"
            >
            </v-text-field>
            <v-text-field
              v-if="this.changed === false"
              v-model="password"
              :append-icon="pswshow1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.minpsw]"
              :type="pswshow1 ? 'text' : 'password'"
              placeholder="New Password"
              counter="8"
              @click:append="pswshow1 = !pswshow1"
            ></v-text-field>
            <v-text-field
              v-if="this.changed === false"
              v-model="password2"
              :error-messages="errormsg"
              :append-icon="pswshow2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="pswshow2 ? 'text' : 'password'"
              name="input-10-2"
              label="Verify New Password"
              class="input-group--focused"
              @click:append="pswshow2 = !pswshow2"
            ></v-text-field>
            <v-card-text v-if="this.changed === true">
              You can now login from this link <br />
              Your password is changed <br /><br />
              <v-btn @click="login()">
                Login
              </v-btn>
            </v-card-text>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
            v-if="this.changed === true"
            small
            color="primary"
            @click="login()"
          >
            Close
          </v-btn>
          <v-btn
            v-if="this.changed === false"
            small
            color="primary"
            @click="savePsw()"
          >
            Save
          </v-btn>
          <v-btn
            v-if="this.changed === false"
            small
            color="warning"
            flat
            @click="resetdialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";

export default {
  data() {
    return {
      changed: false,
      errormsg: "",
      resetdialog: false,
      username: "",
      password: "",
      password2: "",
      rules: {
        required: value => !!value || "Required.",
        minpsw: v => v.length >= 8 || "Min 8 characters",
        minusername: v => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match"
      },
      pswshow1: false,
      pswshow2: false
    };
  },
  mounted() {
    // this.$router.push({
    //  name: 'x12jKamnC6F'
    // })
    this.resetdialog = true;
  },
  methods: {
    async savePsw() {
      try {
        // Verify username / email
        if (this.password !== this.password2) {
          this.errormsg = "Password is not same!";
          return;
        }
        const response = await AuthenticationService.resetOldPassword({
          username: this.username,
          password: this.password
        });
        console.log(response);
        if (response.data.user[0] === 0) {
          this.errormsg = "Could not update password";
          return;
        }
        this.changed = true;
      } catch (error) {
        this.errormsg = "Could not update password";
      }
    },
    login() {
      this.resetdialog = false;
      this.$router.push({
        name: "mainwindow"
      });
    }
  }
};
</script>

<style scoped></style>
