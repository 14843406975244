<template>
  <div>
    <!-- color="lime lighten-3" -->
    <v-toolbar flat dense class="mb-5">
      <v-btn text color="blue-grey lighten-1" class="ma-3 pa-0">
        MY Groups
      </v-btn>
      <div class="black--text">
        <v-text-field
          class="ml-3 mt-4"
          ref="searchGrpNameField"
          autocomplete="new-password"
          v-model="searchGrpNameField"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-btn small icon @click="createGroup()" class="mt-1 pa-0 mr-0">
        <v-icon color="blue-grey lighten-1" size="24px"
          >mdi-plus-circle-outline</v-icon
        >
      </v-btn>
    </v-toolbar>
    <v-container dense>
      <GroupChat
        v-for="item in filteredList"
        :key="item.id"
        :item="item"
        :activityid="activeactivity"
        :userid="userid"
        :theme="theme"
        :platform="platform"
        @new-group-msg="newMessage"
        @minus-group-badge="countDownGroupBadge"
        @deleted-group="deletedGroup"
      ></GroupChat>
    </v-container>
    <!-- DIALOG GROUP CREATE width="600px" ---------------------------------------------------------->
    <v-dialog
      persistent
      v-model="groupcreatedialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="dialogwidth"
    >
      <v-card :dark="theme">
        <v-card-title>Create New Group</v-card-title>
        <v-form>
          <v-container>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  required
                  v-model="creategroupname"
                  filled
                  color="blue-grey lighten-2"
                  label="Name"
                  class="ma-0 pa-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  class="mt-n3 ma-0 pa-0"
                  dense
                  filled
                  auto-grow
                  rows="2"
                  name="input-7-1"
                  label="description"
                  v-model="description"
                >
                </v-textarea>
              </v-col>
              <!-- v-col cols="12" md="6">
                <v-text-field
                  dense
                  required
                  v-model="creategrouptitle"
                  filled
                  color="blue-grey lighten-2"
                  label="Title"
                ></v-text-field>
              </v-col -->
              <!-- v-select dense
                v-model="selectedGroupActivity"
                :items="interestlist"
                item-text="name"
                item-value="id"
                label="Activities"
                class="ma-0 pa-0"
                :rules="[rules.required]"
                ></v-select -->
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="selectedcreategroupuser"
                  :items="friendlist"
                  small-chips
                  color="blue-grey lighten-2"
                  label="Add to group"
                  item-text="User.name"
                  item-value="name"
                  multiple
                  class="ma-0 pa-0"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small-chips
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.User.avatar"></v-img>
                      </v-avatar>
                      {{ data.item.User.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.User.avatar"> </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.User.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.User.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-checkbox
                  dense
                  class="text--primary ma-1 pa-1"
                  v-model="requestjoin"
                  label="Request Permission to Add Person"
                  color="primary"
                  value="1"
                  hide-details
                ></v-checkbox>
                <v-text-field
                  v-if="requestjoin"
                  dense
                  required
                  v-model="joinmessage"
                  filled
                  color="blue-grey lighten-2"
                  label="Message to user"
                  class="ma-0 pa-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="saveGroup">
            Save
          </v-btn>
          <v-btn small color="error" @click="groupcreatedialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- V-SNACKBAR -->
    <v-snackbar
      v-model="snackbarwinmsg"
      :timeout="timeout"
      top
      color="blue-grey"
    >
      {{ bartextmsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbarwinmsg = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarwinmsgwarning"
      :timeout="timeout"
      top
      color="warning"
    >
      {{ bartextmsgwarning }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbarwinmsgwarning = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */

import SearchesService from "@/services/SearchesService";
import GroupServices from "@/services/GroupServices";
import GroupChat from "@/components/GroupChat.vue";
import * as socketio from "@/plugins/socketio";

export default {
  name: "groups",
  components: {
    GroupChat,
  },
  emits: ["update-group-badge"],
  props: ["userid", "activeactivity", "theme", "platform"],
  data() {
    return {
      joinmessage: "",
      creategrouptitle: "",
      creategroupname: "",
      description: "",
      requestjoin: false,
      itemupdgroupusers: null,
      selectedgrouplist: null,
      groupuserid: 0,
      groupid: 0,
      groupusers: [],
      selectedcreategroupuser: [],
      searchGrpNameField: "",
      groupcreatedialog: false,
      friendlist: [],
      friendgrouplist: [],
      menuMessagesF: false,
      notregtxt: "",
      snackbarwarningmsg: false,
      showhistory: false,
      GroupBadge: 0,
      grpavatar:
        "https://findhavefun.com/fhfclient01/src/assets/grpavatars/017-network.png",
      bartextmsg: "",
      snackbarwinmsg: false,
      bartextmsgwarning: "",
      snackbarwinmsgwarning: false,
      timeout: 10000,
    };
  },
  watch: {
    async activeactivity() {
      await getGroups();
    },
  },
  computed: {
    filteredList() {
      return this.friendgrouplist.filter((post) => {
        return post.Group.name
          .toLowerCase()
          .includes(this.searchGrpNameField.toLowerCase());
      });
    },
    dialogwidth() {
      if (this.$vuetify.breakpoint.name === "xs") return "90%";
      if (this.$vuetify.breakpoint.name === "sm") return "80%";
      if (this.$vuetify.breakpoint.name === "md") return "60%";
      if (this.$vuetify.breakpoint.name === "lg") return "50%";
      if (this.$vuetify.breakpoint.name === "xl") return "50%";
    },
  },
  async mounted() {
    console.log(this.theme);
    if (this.friendgrouplist.length === 0) {
      await this.getGroups();
    }
    socketio.addEventListener({
      type: "group_message",
      callback: (message) => {
        this.checknoduplicatesGroups(message);
      },
    });
  },
  methods: {
    async deletedGroup(value) {

      var index = this.friendgrouplist
          .map(function(e) {
            return e.groupid;
          })
          .indexOf(value);
      if (index >= 0) {
        this.friendgrouplist.splice(index, 1);
      }
      // await this.getGroups();
    },
    clearGroupVars() {
      this.creategrouptitle = "";
      this.creategroupname = "";
      this.requestjoin = false;
      this.selectedcreategroupuser = [];
    },
    countDownGroupBadge() {
      this.GroupBadge = this.GroupBadge - 1;
      this.updateGroupBadge();
      console.log("countDownFriendBadge");
    },
    updateGroupBadge() {
      this.$emit("update-group-badge", this.GroupBadge);
    },
    checknoduplicatesGroups(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      try {
        var index = this.friendgrouplist
          .map(function(e) {
            return e.groupid;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          if (this.friendgrouplist[index].Msg.id !== message.id) {
            this.friendgrouplist[index].message = message.message;
            this.friendgrouplist[index].messageAt = message.createdAt;
            if (this.userid !== message.idsender) {
              this.friendgrouplist[index].Group.msgbadge = 1;
              this.GroupBadge++;
              this.updateGroupBadge();
              // this.play();
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    newMessage(item) {
      console.log(item);
    },
    async getGroups() {
      try {
        // this.friendgrouplist = [];
        this.GroupBadge = 0;
        // var menu = this.menuMessagesG;
        // console.log(menu);
        //const Approved = approved
        // this.friendlist = []
        // const activityid = this.activeactivity;
        const response = await GroupServices.getGroupsMessages(
          this.userid,
          this.activeactivity
        );
        this.friendgrouplist = response.data;
        const tmp = response.data;
        console.log("getGroups");
        console.log(tmp);
        if (tmp.length === 1 && tmp[0].message === " No message yet ") {
          console.log(tmp);
          return;
        }
        var index = 0;
        while (this.friendgrouplist[index]) {
          if (
            this.friendgrouplist[0].message !== " No message yet " &&
            (this.friendgrouplist[index].Read.msgread === null ||
              this.friendgrouplist[index].Read.msgread === 0)
          ) {
            this.GroupBadge++;
          }
          index++;
        }
      } catch (error) {
        console.log(error);
      }
      // if (this.myfriendslistsheader !== "GROUPS") {
      // this.myfriendslistsheader = "GROUPS";
      // }
      // this.setTBSelectedColor();
    },
    async createGroup() {
      this.groupcreatedialog = true;
      if (this.userid === 0) {
        return;
      }
      try {
        const Approved = 1;
        this.friendlist = [];
        const activityid = this.activeactivity;
        const response = await SearchesService.getActivityFriends(
          this.userid,
          activityid,
          Approved
        );
        this.friendlist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async saveGroup() {
      try {
        const selectedgroups = this.selectedcreategroupuser;
        console.log(selectedgroups);
        // Create group
        // const selectedActivity = this.selectedGroupActivity
        const requestjoin = this.requestjoin;
        const activityid = this.activeactivity;
        const result = await GroupServices.createGroup({
          userid: this.userid,
          idactivity: activityid,
          name: this.creategroupname,
          title: this.creategroupname,
          description: this.description,
          avatar: this.grpavatar,
          requestjoin: requestjoin,
          message: this.joinmessage,
          access: 0,
          type: "users",
        });
        // Get and save checked
        var groupusers = [];
        var index = 0;
        while (this.selectedcreategroupuser[index]) {
          var usergroup = { userid: 0, groupid: 0, idactivity: 0, joined: 1 };
          usergroup.groupid = result.data.id;
          var name = this.selectedcreategroupuser[index];
          var pos = this.friendlist
            .map(function(e) {
              return e.User.name;
            })
            .indexOf(name);
          // var pos = this.friendlist.indexOf(name)
          if (this.userid === this.friendlist[pos].idfriend)
            usergroup.userid = this.friendlist[pos].iduser;
          else usergroup.userid = this.friendlist[pos].idfriend;
          usergroup.idactivity = this.activeactivity;
          if (requestjoin === "1") {
            usergroup.joined = false;
          }
          groupusers.push(usergroup);
          index = index + 1;
        }
        // Add creator also to usergroup
        // activityid = this.activeactivity
        groupusers.push({
          userid: this.userid,
          groupid: result.data.id,
          idactivity: activityid,
          joined: true,
        });
        // var credentials = { userlist: groupusers }
        const response = await GroupServices.addUsersToGroup({
          userlist: groupusers,
        });
        console.log(response);
        this.groupcreatedialog = false;
        this.clearGroupVars();
        await this.getGroups();
        this.showSnackBarMsg("Group Created");
      } catch (error) {
        console.log(error);
      }
    },
    showSnackBarMsg(msg) {
      this.bartextmsg = msg;
      this.snackbarwinmsg = true;
    },
    /*
    updateFriendList(message) {
      try {
        var index = this.friendlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          this.friendlist[index].message = message.message;
          this.friendlist[index].messageAt = message.createdAt;
        }
      } catch (error) {
        console.log(error);
      }
    } */
  },
};
</script>

<style scoped></style>
