import Vue from "vue";
import App from "./App.vue";
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from "./plugins/vuetify";
// import { Capacitor } from '@capacitor/core';
import { sync } from "vuex-router-sync";
import store from "@/store/store";
import router from "./router";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// import { ValidationProvider } from 'vee-validate';
// import VueSocketIOExt from 'vue-socket.io-extended';
import * as socketio from "@/plugins/socketio";

// Vue.use(VueSocketIOExt, socketio.iosocket);
// Vue.$socketio = socketio;
Vue.prototype.$socketio = socketio;

import VueCookies from "vue-cookies";
import VueMeta from "vue-meta";
// import VueSocketIO from 'vue-socket.io'
// import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
// import VuetifyToast from "vuetify-toast-snackbar";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";

// Vuetify's CSS styles
import "vuetify/dist/vuetify.min.css";

// import ImageUploader from 'vue-image-upload-resize'
// Vue.use(ImageUploader);

// import VueRecord from '@codekraft-studio/vue-record'
// Vue.use(VueRecord)

// import "./registerServiceWorker";

// import VueCordova from 'vue-cordova'

// var VueCookie = require('vue-cookie');

// cw 20210626
// Vue.use(vuetify)

sync(store, router);

// Vue.component('ValidationProvider', ValidationProvider);

Vue.config.productionTip = false;

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi"
});

// Vue.use(VueSocketIO, 'localhost:1339')
/*
Vue.use(VuetifyToast, {
  x: "right", 
  y: "bottom",
  color: "info",
  icon: "info",
  iconColor: "",
  classes: ["body-2"],
  timeout: 3000,
  dismissable: true,
  multiLine: false,
  vertical: false,
  queueable: false,
  showClose: false,
  closeText: "",
  closeIcon: "close",
  closeColor: "",
  slot: [],
  shorts: {
    custom: {
      color: "purple"
    }
  },
  property: "$toast"
});*/

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Vue.use(VueCookies);
Vue.$cookies.config("30d");
// Tell Vue to use the plugin
// var Vue = require('vue');
// Vue.use(VueCookie);


Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: "AIzaSyDrw5LWS3H6PzqBxFMOlBcPrTJqxGWQmdo" // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  // version: '' // Optional
});

const firebaseConfig = {
  apiKey: "AIzaSyDrw5LWS3H6PzqBxFMOlBcPrTJqxGWQmdo",
  authDomain: "findhavefun.firebaseapp.com",
  projectId: "findhavefun",
  storageBucket: "findhavefun.appspot.com",
  messagingSenderId: "555905964735",
  appId: "1:555905964735:web:927b0ff75462c24412040a",
  measurementId: "G-3DXFCV6FG7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

// Vue.use(VueGoogleMaps, {
//  load: {
//    key: 'AIzaSyDrw5LWS3H6PzqBxFMOlBcPrTJqxGWQmdo',
//    libraries: 'places,drawing', // This is required if you use the Autocomplete plugin
// (as you require)
// OR: libraries: 'places,drawing'
// OR: libraries: 'places,drawing,visualization'
// }
// })

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");

// document.addEventListener('deviceready', );
