import Api from "@/services/Api";

export default {
  createScheduledItem(credentials) {
    return Api().post("createscheduleditem", credentials);
  },
  updateScheduledItem(credentials) {
    return Api().post("updatescheduleditem", credentials);
  },
  removeScheduledItem(credentials) {
    return Api().post("removescheduleditem", credentials);
  },
  getScheduledItems(UserId) {
    return Api().get("getscheduleditems", {
      params: {
        iduser: UserId
      }
    });
  },
  getScheduledItemFriends(IdSheduledItem) {
    return Api().get("getscheduleditemfriends", {
      params: {
        idscheduleditem: IdSheduledItem
      }
    });
  },
  getScheduledItemGroups(IdSheduledItem) {
    return Api().get("getscheduleditemGroups", {
      params: {
        idscheduleditem: IdSheduledItem
      }
    });
  },
  addScheduledUsers(credentials) {
    return Api().post("addscheduledusers", credentials);
  },
  addScheduledGroups(credentials) {
    return Api().post("addscheduledgroups", credentials);
  },
  getBookings(UserId, Active) {
    return Api().get("getbookings", {
      params: {
        userid: UserId,
        active: Active
      }
    });
  },
  createBooking(credentials) {
    return Api().post("createbooking", credentials);
  },
  updateBooking(credentials) {
    return Api().post("updatebooking", credentials);
  },
  createBookingType(credentials) {
    return Api().post("createbookingtype", credentials);
  },
  updateBookingType(credentials) {
    return Api().post("updatebookingtype", credentials);
  },
  getBookingTypes(UserId, CompanyID) {
    return Api().get("getbookingtypes", {
      params: {
        userid: UserId,
        idcompany: CompanyID
      }
    });
  },
  createBookingItems(credentials) {
    return Api().post("createbookingitems", credentials);
  },
  updateBookingItem(credentials) {
    return Api().post("updatebookingitem", credentials);
  },
  createCalender(credentials) {
    return Api().post("createcalender", credentials);
  },
  // ------------------------------------------------------------------------
  getCalenderBookings(CompanyId, template, Active) {
    return Api().get("getcalenderbookings", {
      params: {
        idcompany: CompanyId,
        template: template,
        active: Active
      }
    });
  },
  getBookingHeaders(IdBookingType, CompanyId, template, active) {
    return Api().get("getbookingheaders", {
      params: {
        idbookingtype: IdBookingType,
        idcompany: CompanyId,
        template: template,
        active: active
      }
    });
  },
  getCalenderTemplates(UserId, Active) {
    return Api().get("getcalendertemplates", {
      params: {
        userid: UserId,
        active: Active
      }
    });
  },
  updateCalenderBooking(credentials) {
    return Api().post("updatecalenderbooking", credentials);
  },
  updateCalenderTemplates(credentials) {
    return Api().post("updatecalendertemplates", credentials);
  }
};
