<template>
  <div>
    <v-row dense>
      <!-- v-col cols="5">
          <v-select dense class="mt-2 ml-0"
            solo
            v-model="selectedGroups"
            :items="groupList"
            item-text="Group.name"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="Groups"
            multiple
            chips
            persistent-hint
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-checkbox
              class="ma-0 mt-4 ml-2 pa-0"
              v-model="feedfriends"
              label="Show friends"
              color="success"
              hide-details
              ></v-checkbox>
        </v-col -->
        <!-- amber lighten-2 -->
      <v-col cols="12">
        <v-btn
          small
          color="grey"
          class="ml-2 ma-2 mt-3 pa-2"
          @click="writePost()"
        >
          New post
        </v-btn>
      </v-col>
    </v-row>
    <!-- class="editor" -->
    <div class="editor">
      <v-row v-if="writeapost === true">
        <v-col cols="12">
          <tiptap-vuetify v-model="content" :extensions="extensions" :toolbar-attributes="{ color: 'grey' }">
          </tiptap-vuetify>
          <v-list dense class="mb-0">
            <v-list-item>
              <v-list-item-subtitle>
                Here you define who will se your message
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-row dense class="mt-n2 mb-n6">
            <v-col cols="3">
              <v-checkbox
                class="ma-0 mt-2 ml-2 mr-2 pa-0"
                v-model="showfriends"
                label="Friends"
                color="success"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-select
                dense
                class="mt-0 ml-0"
                v-model="selectedGroups"
                :items="groupList"
                item-text="Group.name"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Send to"
                multiple
                persistent-hint
              ></v-select>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-list dense class="mb-1 mt-2">
            <v-list-item>
              <v-list-item-subtitle>
                Here you define from who you sending your message
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-row dense class="mt-n4">
            <v-col cols="3">
              <v-checkbox
                class="ma-0 mt-2 ml-2 pa-0"
                v-model="sendfromme"
                label="From me"
                color="success"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-select
                dense
                class="mt-0"
                v-model="sendFromGroup"
                :items="groupList"
                item-text="Group.name"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Send From"
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">
              <v-btn
                small
                color="primary"
                class="mt-2 ma-3 pa-0"
                @click="sendPost()"
              >
                Send
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn small color="error" class="mt-2 ma-3" @click="cancelPost()">
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="8"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-list dense expand>
      <!-- v-model -->
      <v-list-group
        class="mb-3"
        v-for="item in postlist"
        :value="item.dialog"
        :key="item.id"
        background="#424242"
        active-class="#424242"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-avatar>
            <v-img :src="item.User.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-subtitle v-text="item.User.name">
            </v-list-item-subtitle>
            <v-list-item-subtitle
              :class="postheadcolor"
              v-text="concatGroupNames(item.Groups)"
            >
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <!-- v-list-item-action>
          <v-btn
            icon
            small
            class="ma-0 pa-0"
          >
          <v-icon size="24px">mdi-dots-vertical</v-icon>
          </v-btn>
        </v-list-item-action -->
        </template>

        <v-list-item class="ml-n14">
          <v-list-item-content>
            <!--  color="#E0E0E0" -->
            <!-- :color="postcardcolor" -->
            <v-card :dark="theme"
              flat
              raised
              rounded
              shaped
              class="mb-2 pa-0 rounded-lg"
            >
              <tiptap-vuetify
                v-if="editPost === true"
                class="mb-4 tiptap-vuetify-editor__content"
                v-model="item.post1"
                :extensions="extensions"
              >
              </tiptap-vuetify>
              <v-card-text>
                <div
                  class="tiptap-vuetify-editor__content"
                  v-html="item.post1"
                />
              </v-card-text>
            </v-card>
            <v-divider class="ml-3 mr-3"></v-divider>
            <!-- color="#03DAC6" -->
            <!-- :color="postnotecolor" -->
            <v-card :dark="theme"
              raised rounded shaped class="mb-2 pa-0 rounded-lg">
              <v-card-text>
                <v-row
                  dense
                  justify="start"
                  class="mb-3 mx-0"
                  v-for="note in item.notes"
                  :key="note.id"
                >
                  <v-col cols="1">
                    <v-avatar :size="icontabsize + 'px'" class="mr-0">
                      <img :src="note.User.avatar" :alt="note.User.name" />
                    </v-avatar>
                  </v-col>
                  <v-col cols="10">
                    <div class="ml-1 font-weight-normal text-left purple--text">
                      {{ note.note }}
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <!-- v-btn color="grey"
                      icon
                      small
                      class="ma-0 pa-0"
                    >
                      <v-icon size="24px">mdi-dots-vertical</v-icon>
                    </v-btn -->
                  </v-col>
                </v-row>
                <v-row dense v-show="item.notebox === true">
                  <!-- v-if="newusermsgPopup === true" -->
                  <v-card :dark="theme" width="100%">
                    <v-col cols="12">
                      <v-textarea
                        color="#EEEEEE"
                        clear-icon="mdi-close-circle"
                        rows="1"
                        name="input-7-2"
                        label=""
                        v-model="newsusermessage"
                        counter="500"
                      ></v-textarea>
                    </v-col>
                    <v-card-actions>
                      <v-btn
                        small
                        class="ml-2 mb-1 mt-n10"
                        icon
                        @click="addNewsNoteMessage(item)"
                        color="primary"
                      >
                        <v-icon size="24px">mdi-email-send-outline</v-icon>
                      </v-btn>
                      <v-btn
                        class="mt-n10"
                        small
                        text
                        color="warning"
                        @click="cancelNote(item)"
                      >
                        cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <!-- v-btn @clic="editPost" icon small>
                <v-icon size="24px">mdi-pencil-outline</v-icon>
              </v-btn -->
                <v-btn @click="writeNote(item)" icon small>
                  <v-icon size="24px" color="grey">mdi-note-plus-outline</v-icon>
                </v-btn>
                <!-- v-btn @click="setHide(item)" icon small>
                <v-icon size="24px">mdi-eye-off-outline</v-icon>
              </v-btn -->
                <!-- v-btn @click="setRead" icon small >
                <v-icon size="24px">mdi-read</v-icon>
              </v-btn -->
                <v-btn
                  v-if="userid === item.User.id"
                  @click="removeNewsFeed(item)"
                  icon
                  small
                >
                  <v-icon size="24px" color="grey">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <!-- v-row dense class="mt-2">
        <v-col cols="12">
          <v-row class="mb-4"
            v-for="item in postlist"
            :key="item.id">
            <v-col cols="12">
              <v-avatar color="grey"
                :size="icontabsize + 'px'"
                class="ma-0 mr-2 pa-0">
                <v-img
                :src="item.User.avatar">
                </v-img>
              </v-avatar>
              {{item.User.name}}
            </v-col>
            <v-col cols=12>
            <v-card class="overflow-y-auto"
              v-scroll.self="onScroll"
              :height="item.height"
              @click="setNewsHight(item)"
              max-height="600"
              color="#26c6da"
              dark
            >
              <tiptap-vuetify v-if="editPost === true" class="mb-4"
                v-model="item.post1"
                :extensions="extensions"
              >
              </tiptap-vuetify>

              <v-card-text>
              <div class="tiptap-vuetify-editor__content" v-html="item.post1"/>
              </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row -->
    <!-- NEWS USER MESSAGE DIALOG ---------------------------------------------------------->
    <v-dialog v-model="newusermsgPopup" persistent max-width="400px">
      <v-card>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <v-textarea
                clearable
                clear-icon="mdi-close-circle"
                rows="1"
                name="input-7-2"
                label="Add Note Here"
                v-model="newsusermessage"
                counter="500"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn small color="blue" @click="addNewsNoteMessage">
            Save
          </v-btn>
          <v-btn small color="warning" @click="newusermsgPopup = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- snackbar --------------------------------------------------->
    <v-snackbar
      v-model="snackbarpostedit"
      :timeout="timeout"
      top
      color="blue-grey"
    >
      {{ notregtext }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbarpostedit = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import NewsfeedService from "@/services/NewsfeedService";
import GroupServices from "@/services/GroupServices";
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Image,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  // specify TiptapVuetify component in "components"dar
  props: ["userid", "friends", "activityid", "facilityid", "companyid", "theme"],
  components: { TiptapVuetify },
  data: () => ({
    Dark: true,
    Light: false,
    newpostbtncolor: "grey",
    postheadcolor: "yellow--text",
    postcardcolor: "green",
    postnotecolor: "amber lighten-5",
    // postcardcolor: "#37474F",
    colorXX: "#DCEDC8",
    colorText: "yellow--text",
    textFieldColor: "grey darken-2",
    feedfriends: false,
    sendfromme: true,
    showfriends: true,
    newsusermessage: "",
    newusermsgPopup: false,
    newnoteitem: null,
    scrollInvoked: 0,
    newsBoxHight: 150,
    sendFromGroup: "",
    selectedGroups: [],
    groupList: [],
    snackbarpostedit: false,
    timeout: "10000",
    notregtext: "",
    editPost: false,
    // editorDisabled: "true",
    postlist: [],
    writeapost: false,
    // declare extensions you want to use
    extensions: [
      History,
      Blockquote,
      Link,
      Image,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    // starting editor's content
    content:
      "<h2>Din rubrik!</h2><p>Formatera din text med ovan <strong>coola </strong> symboler.</p>" +
      "<p> <strong>Tänk på att texten ska visas på mobil</strong></p>"
  }),
  watch: {
    sendfromme: function(val) {
      if (val === true) {
        this.sendFromGroup = "";
      }
    },
    sendFromGroup: function(val) {
      if (val) {
        this.sendfromme = false;
      }
    },
    showfriends: function(val) {
      if (val === true) {
        this.selectedGroups = [];
      }
    },
    selectedGroups: function(val) {
      if (val.length > 0) {
        this.showfriends = false;
      } else {
        this.showfriends = true;
      }
    }
  },
  computed: {
    /*
    parentusername: {
      get: function () {
        return this.parentusername;
      }
    }, */
    icontabsize() {
      if (this.$vuetify.breakpoint.name === "xs") return 24;
      if (this.$vuetify.breakpoint.name === "sm") return 24;
      else return 32;
      // case "xs": return "24px"
      // case "sm": return "24px"
      // case "md": return "32px"
      // case "lg": return "32px"
      // case "xl": return "32px"
    }
  },
  async mounted() {
    if (this.theme === "dark") {
      this.Dark = true;
      this.postheadcolor = "orange--text";
      this.postcardcolor = "blue-grey darken-3";
      this.newpostbtncolor = "orange";
    } else {
      this.Light = false;
      this.postheadcolor = "grey--text";
      this.postcardcolor = "green lighten-4";
      this.newpostbtncolor = "orange";
    }
    await this.getPosts();
    await this.getUserGroups();
  },
  methods: {
    setActivityId (actid) {
      this.activityid = actid;
      this.getUserGroups();
    },
    xxxsetActivityId: function(actid) {
      this.activityid = actid;
      this.getUserGroups();
    },
    onScroll() {
      this.scrollInvoked++;
    },
    setNewsHight(item) {
      if (item.height === 150) item.height = 400;
      else item.height = 150;
    },
    async getUserGroups() {
      try {
        // var grp = {id: 0, Group: {name: ' '}}
        this.groupList = [];
        const usergrp = await GroupServices.getUserGroups(
          this.userid,
          this.activityid
        );
        let index = 0;
        while(usergrp.data[index]) {
          if (usergrp.data[index].Group.requestjoin === false) {
            this.groupList.push(usergrp.data[index]);
          }
          index++;
        }
        console.log(this.groupList);
        // this.groupList = usergrp.data;
        // this.groupList.unshift(grp)
      } catch (error) {
        console.log(error);
      }
    },
    selectImage() {
      // When doing an asynchronous upload, you can set the src property to the value provided by the server (backend).
      this.$emit("select-file", {
        src: "/path/to/image.jpg",
        alt: "Uploaded image"
      });
    },
    showText(text) {
      this.notregtext = text;
      this.snackbarpostedit = true;
    },
    async getPosts() {
      try {
        // Set selected groups in a group id list
        const posts = await NewsfeedService.getNewsfeeds(
          this.userid,
          this.activityid,
          this.companyid,
          this.friends
        );
        // const cc = posts.data[1].post1;
        // this.content = cc;
        this.postlist = posts.data;
      } catch (error) {
        console.log(error);
      }
    },
    async sendPost() {
      try {
        var post = {
          userid: this.userid,
          groupid: 0,
          friends: false,
          activityid: this.activityid,
          post1: this.content
        };
        if (this.showfriends === true) {
          post.friends = true;
        }
        if (this.sendfromme === false) {
          post.groupid = this.sendFromGroup;
        }
        let usergroups = this.selectedGroups;
        let listgrp = this.groupList;
        // post.groups = groups

        let groups = [];
        let index = 0;
        while (usergroups[index]) {
          var pos = listgrp
            .map(function(e) {
              return e.id;
            })
            .indexOf(usergroups[index]);
          if (pos >= 0) {
            groups.push(listgrp[pos].groupid);
          }
          index++;
        }
        post.groups = groups;
        const ret = await NewsfeedService.addNewsfeed({
          post: post,
          groups: groups
        });
        console.log(ret);
        // this.postlist.push(ret.data)
        this.showText("Post sent");
        this.writeapost = false;
        this.getPosts();
      } catch (error) {
        console.log(error);
      }
    },
    async writePost() {
      // await this.getUserGroups();
      this.writeapost = true;
    },
    cancelPost() {
      this.writeapost = false;
      this.sendFromGroup = "";
      this.selectedGroups = [];
    },
    rowClicked(item) {
      /*
      let index = 0;
      while (this.postlist[index]) {
        if (item.dialog === false && this.postlist[index].dialog === false) {
          if (this.postlist[index].id !== item.id) {
            this.postlist[index].dialog = false;
          }
        }
        index++;
      }
      */
      console.log(item);
    },
    editPost() {
      console.log("editPost");
    },
    writeNote(item) {
      if (item.notebox === false) {
        this.newnoteitem = item;
        item.notebox = true;
      } else {
        this.newnoteitem = null;
        item.notebox = false;
      }
      // this.newusermsgPopup = true;
      console.log("writeNote");
    },
    cancelNote(item) {
      item.notebox = false;
      this.newnoteitem = null;
      this.newsusermessage = "";
      console.log("cancelNote");
    },
    setHide() {
      try {
        console.log("set hide");
      } catch (error) {
        console.log(error);
      }
    },
    setRead() {
      console.log("setRead");
    },
    async addNewsNoteMessage(item) {
      try {
        const note = await NewsfeedService.addNewsFeedNote({
          newsid: item.id,
          userid: this.userid,
          note: this.newsusermessage
        });
        const newnote = await NewsfeedService.getNewsFeedNote(note.data.id);
        item.notes.push(newnote.data);
        item.notebox = false;
        this.newnoteitem = null;
        this.newsusermessage = "";
      } catch (error) {
        console.log(error);
      }
    },
    async removeNewsFeed(item) {
      try {
        const note = await NewsfeedService.removeNewsFeed({ id: item.id });
        var pos = this.postlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(item.id);
        this.postlist.splice(pos, 1);
        console.log(note);
      } catch (error) {
        console.log(error);
      }
    },
    async removeNewsFeedNote(item) {
      try {
        const note = await NewsfeedService.removeNewsFeedNote({
          newsid: item.id
        });
        console.log(note);
      } catch (error) {
        console.log(error);
      }
    },
    concatGroupNames(list) {
      var index = 0;
      var str = "";
      while (list[index]) {
        if (str) str = str + ", " + list[index].name;
        else str = list[index].name;
        index++;
      }
      return str;
    }
  }
};
</script>

<style scoped>
.tiptap-vuetify-editor__content img {
  width: 80%;
}
.icon-color-dark {
  color: grey;
}
.icon-color-light {
  color: grey;
}
.v-input {
  font-size: 0.9em;
}
.v-text-field input {
  font-size: 0.9em;
}
.v-input .v-label {
  font-size: 0.9em;
}
</style>
