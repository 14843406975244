import Api from "@/services/Api";

export default {
  getUserCoach(email) {
    return Api().get("verifyemail", {
      params: {
        email: email
      }
    });
  },
  sendVerificationEmail(credentials) {
    return Api().post("sendverificationemail", credentials);
  },
  sendEmail(credentials) {
    return Api().post("sendemail", credentials);
  },
  resetPassword(credentials) {
    return Api().post("resetpassword", credentials);
  },
  resetPasswordEmail(credentials) {
    return Api().post("resetpasswordemail", credentials);
  }
};
