import Api from "@/services/Api";

export default {
  addUserSettings(credentials) {
    return Api().post("addusersettings", credentials);
  },
  updateUserSettings(credentials) {
    return Api().post("updateusersettings", credentials);
  },
  getUserSettings(id) {
    return Api().get("getusersettings", {
      params: {
        id: id
      }
    });
  },
  addUserActivities(credentials) {
    console.log("In Api addUserActivities");
    return Api().post("adduseractivities", credentials);
  },
  addUserActivity(credentials) {
    console.log("In Api addUserActivity");
    return Api().post("adduseractivity", credentials);
  },
  sendMessage(credentials) {
    console.log("In Api sendmessage");
    return Api().post("sendmessage", credentials);
  },
  setMessageRead(credentials) {
    console.log("In Api setmessageread");
    return Api().post("setmessageread", credentials);
  },
  sendMyMessage(credentials) {
    console.log("In Api sendmymessage");
    return Api().post("sendmymessage", credentials);
  },
  sendMyGroupMessage(credentials) {
    console.log("In Api sendmygroupmessage");
    return Api().post("sendmygroupmessage", credentials);
  },
  saveMyFriendRequest(credentials) {
    console.log("In Api savemyfriendrequest");
    return Api().post("savemyfriendrequest", credentials);
  },
  approveMyFriendRequest(credentials) {
    console.log("In Api approvemyfriendrequest");
    return Api().post("approvemyfriendrequest", credentials);
  },
  removeMyFriendRequest(credentials) {
    console.log("In Api removemyfriendrequest");
    return Api().post("removemyfriendrequest", credentials);
  },
  savemyplace(credentials) {
    console.log("In Api Service");
    return Api().post("savemyplace", credentials);
  },
  savemygoogleplace(credentials) {
    console.log("In Api savemy google place Service");
    return Api().post("savemygoogleplace", credentials);
  },
  removemyplace(credentials) {
    console.log("In Api Service");
    return Api().post("removemyplace", credentials);
  },
  updDescription(credentials) {
    console.log("In Api Service Upd Desc");
    return Api().post("upddescription", credentials);
  },
  updUser(credentials) {
    console.log("In Api Service");
    return Api().post("upduser", credentials);
  },
  updUserChatId(credentials) {
    console.log("In Api Service");
    return Api().post("upduserchatid", credentials);
  },
  updUserShowinfo(credentials) {
    console.log("In Api Service");
    return Api().post("updusershowinfo", credentials);
  },
  updMyLanguage(credentials) {
    console.log("In Api Service");
    return Api().post("updmylanguage", credentials);
  },
  getmyplaces(UserID, SearchID) {
    console.log("In Api Service");
    return Api().get("myplaces", {
      params: {
        UserID: UserID,
        SearchID: SearchID
      }
    });
  },
  getUserData(UserID, Username) {
    return Api().get("getuserdata", {
      params: {
        userid: UserID,
        username: Username
      }
    });
  },
  getmygoogleplaces(UserID, SearchID) {
    console.log("In Api Service");
    return Api().get("mygoogleplaces", {
      params: {
        UserID: UserID,
        SearchID: SearchID
      }
    });
  },
  getallmygoogleplaces(UserID) {
    console.log("In Api Service");
    return Api().get("allmygoogleplaces", {
      params: {
        UserID: UserID
      }
    });
  }
};
