import Api from "@/services/Api";

export default {
  searchListByActivity(userid, activityid) {
    return Api().get("searchlistbyactivity/", {
      params: {
        userid: userid,
        idactivity: activityid
      }
    });
  },
  searchActivityPartner(
    userid,
    activity,
    facilities,
    skills,
    place,
    name,
    gender,
    agefrom,
    ageto,
    datefromto,
    singledates
  ) {
    return Api().get("searchactivitypartner/", {
      params: {
        userid: userid,
        activity: activity,
        facilities: facilities,
        skills: skills,
        place: place,
        name: name,
        gender: gender,
        agefrom: agefrom,
        ageto: ageto,
        datefromto: datefromto,
        singledates: singledates
      }
    });
  },
  searchFriendByName(Userid, Name, Place, Gender, Agefrom, Ageto) {
    console.log(Name);
    return Api().get("searchfriendbyname/", {
      params: {
        userid: Userid,
        name: Name,
        place: Place,
        gender: Gender,
        agefrom: Agefrom,
        ageto: Ageto
      }
    });
  },
  getActivityFriends(Userid, ActivityId, Approved) {
    console.log(Userid + Approved);
    return Api().get("getactivityfriends/", {
      params: {
        userid: Userid,
        activityid: ActivityId,
        approved: Approved
      }
    });
  },
  getCompanyMembers(companyid, activityid) {
    return Api().get("getcompanymembers/", {
      params: {
        companyid: companyid,
        activityid: activityid
      }
    });
  },
  getCompanyMembersSkills(companyid, activityid) {
    return Api().get("getcompanymembersskills/", {
      params: {
        idcompany: companyid,
        idactivity: activityid
      }
    });
  },
  getFriendsAndLatestMessage(Userid, ActivityId, Approved) {
    console.log(Userid + Approved);
    return Api().get("getfriendsandlatestmessage/", {
      params: {
        userid: Userid,
        activityid: ActivityId,
        approved: Approved
      }
    });
  },
  getActivityRequests(Userid, ActivityId, Approved) {
    console.log(Userid + Approved);
    return Api().get("getactivityrequests/", {
      params: {
        userid: Userid,
        activityid: ActivityId,
        approved: Approved
      }
    });
  },
  getActivityMessages(Userid, ActivityId, Approved) {
    console.log(Userid + Approved);
    return Api().get("getactivitymessages/", {
      params: {
        userid: Userid,
        activityid: ActivityId,
        approved: Approved
      }
    });
  },
  getMessage(id) {
    console.log(id);
    return Api().get("getmessage/", {
      params: {
        id: id
      }
    });
  },
  getLatestMessages(Userid, ActivityId, Approved) {
    console.log(Userid + Approved);
    return Api().get("getlatestmessages/", {
      params: {
        userid: Userid,
        activityid: ActivityId,
        approved: Approved
      }
    });
  },
  getActivityMessagesHist(Userid, Friendid, Activity) {
    console.log(Userid);
    return Api().get("getactivitymessageshist/", {
      params: {
        userid: Userid,
        friendid: Friendid,
        activityid: Activity
      }
    });
  },
  getGroupMessagesHist(Groupid, ActivityId) {
    console.log(Groupid);
    return Api().get("getgroupmessageshist/", {
      params: {
        idgroup: Groupid,
        idactivity: ActivityId
      }
    });
  },
  getWaitingFriends(Userid, ActivityId, Approved) {
    console.log(Userid + Approved);
    return Api().get("getwaitingfriends/", {
      params: {
        userid: Userid,
        activityid: ActivityId,
        approved: Approved
      }
    });
  },
  getFriends(Userid, ActivityId) {
    console.log(Userid);
    return Api().get("getfriends/", {
      params: {
        userid: Userid,
        activityid: ActivityId
      }
    });
  },
  getmysearches(UserID) {
    console.log(UserID);
    return Api().get("mysearches/", {
      params: {
        UserID: UserID
      }
    });
  },
  getmymatches(userid, SearchID) {
    console.log(userid);
    return Api().get("mymatches/", {
      params: {
        UserID: userid,
        SearchID: SearchID
      }
    });
  },
  getmymatchesMiddle(userid, SearchID) {
    console.log(userid);
    return Api().get("mymatchesmiddle/", {
      params: {
        UserID: userid,
        SearchID: SearchID
      }
    });
  },
  getmymatchesexact(userid, SearchID) {
    console.log(userid);
    return Api().get("mymatchesexact/", {
      params: {
        UserID: userid,
        SearchID: SearchID
      }
    });
  },
  getmysearch(SearchID) {
    console.log(SearchID);
    return Api().get("mysearch", {
      params: {
        id: SearchID
      }
    });
  },
  getmysearchhowlong(SearchID) {
    console.log(SearchID);
    return Api().get("mysearchhowlong/", {
      params: {
        SearchID: SearchID
      }
    });
  },
  createmyfirstsearch(credentials) {
    console.log("In Api Service createmyfirstsearch");
    console.log(credentials);
    return Api().post("createmyfirstsearch", credentials);
  },
  savemysearch(credentials) {
    console.log("In Api Service savemysearch");
    console.log(credentials);
    return Api().post("savemysearch", credentials);
  },
  removemysearch(credentials) {
    console.log("In Api Service removemysearch");
    console.log(credentials);
    return Api().post("removemysearch", credentials);
  },
  updatemysearch(credentials) {
    console.log("In Api Service updatemysearch");
    console.log(credentials);
    return Api().post("updatemysearch", credentials);
  },
  updatemysearchname(credentials) {
    console.log("In Api Service updatemysearchname");
    console.log(credentials);
    return Api().post("updatemysearchname", credentials);
  },
  updatemysearchhowlong(credentials) {
    console.log("In Api Service updatemysearchhowlong");
    console.log(credentials);
    return Api().post("updatemysearchhowlong", credentials);
  },
  savemysearchwhattodo(credentials) {
    console.log("In Api Service savemysearchwhattodo");
    console.log(credentials);
    return Api().post("savemysearchwhattodo", credentials);
  }
};
