import Vue from "vue";
import Router from "vue-router";
import MainWindow from "@/components/MainWindow";
// import MainHeader from "@/components/MainHeader";
import AdminWindow from "@/components/AdminWindow";
import x12jKamnC6F from "@/components/x12jKamnC6F";
import Login from "@/components/Login";
import Loginpre from "@/components/Loginpre";
import LoginChildCheck from '@/components/Loginchildcheck';
import ResetPassword from "@/components/ResetPassword";
// import FriendWindow from "@/components/FriendWindow";
// import ChatWindow from "@/components/ChatWindow";
// import InfoWindow from "@/components/InfoWindow";
// import FindWindow from "@/components/FindWindow";
// import ForumWindow from "@/components/ForumWindow";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "/login", name: "login", component: Login },
    { path: "/loginpre", name: "loginpre", component: Loginpre },
    { path: "/loginchildcheck", name: "loginchildcheck", component: LoginChildCheck },
    { path: "/resetpassword", name: "resetpassword", component: ResetPassword },
    { path: "/mainwindow", name: "mainwindow", component: MainWindow },
    // { path: "/mainheader", name: "mainheader", component: MainHeader },
    // { path: "/findwindow", name: "findwindow", component: FindWindow },
    // { path: "/chatwindow", name: "chatwindow", component: ChatWindow },
    // { path: "/forumwindow", name: "forumwindow", component: ForumWindow },
    // { path: "/infowindow", name: "infowindow", component: InfoWindow },
    { path: "/adminwindow", name: "adminwindow", component: AdminWindow },
    // { path: "/friendwindow", name: "friendwindow", component: FriendWindow },
    { path: "/x12jKamnC6F", name: "x12jKamnC6F", component: x12jKamnC6F },
    // { path: "/", redirect: { name: "mainwindow" } }
    { path: "/", redirect: { name: "loginpre" } }
  ]
});
