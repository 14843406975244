import Api from "@/services/Api";

export default {
  savemyimage(data) {
    console.log("In Api Service");
    console.log(data);
    return Api().post("uploadfile", data);
  },
  savemsgimage(data) {
    console.log("In Api Service");
    console.log(data);
    return Api().post("uploadmsgfile", data);
  },
  updatemyimage(data) {
    console.log("In Api Service");
    console.log(data);
    return Api().post("updatemyimage", data);
  },
  deletemyimage(data) {
    console.log("In Api Service");
    console.log(data);
    return Api().post("deletemyimage", data);
  },
  getmyimage(UserID) {
    console.log(UserID);
    console.log("In Api Service");
    return Api().get("getmyimage", {
      params: {
        userid: UserID
      }
    });
  },
  getmsgimage(id) {
    console.log(id);
    console.log("In Api Service");
    return Api().get("getmsgimage", {
      params: {
        id: id
      }
    });
  },
  getmyimageid(id) {
    console.log(id);
    console.log("In Api Service id");
    return Api().get("getmyimageid", {
      params: {
        id: id
      }
    });
  },
  getmyimages(UserID) {
    // console.log(UserID)
    console.log("In Api Service getmyimages");
    return Api().get("getmyimages", {
      params: {
        UserID: UserID
      }
    });
  },
  savemyimages(MyImage) {
    console.log("In Api Service");
    console.log(MyImage);
    return Api().post("savemyimages", MyImage);
  },
  removemyprofileimage(user) {
    console.log("In Api Service");
    console.log(user);
    return Api().post("removemyprofileimage", user);
  }
};
