import axios from "axios";
import store from "@/store/store";

export default () => {
  return axios.create({
    baseURL: `https://findhavefun.com:1341/`,
    // baseURL: `http://localhost:1341/`,
    headers: {
      Authorization: `Bearer ${store.state.token}`
      // 'content-type": blob.type'
      // 'Content-Type': 'application/json'
      // 'Content-Type': `multipart/form-data`
    }
  });
};
