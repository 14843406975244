<template>
  <div>
    <v-row dense>
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <v-card v-if="loginready === false" :dark="theme">
          <v-card-subtitle></v-card-subtitle>
          <v-alert
            dense
            type="info"
          >
            Enter your parent username, press Check Parent Username <br/>
            If ok click Save Parent Username
          </v-alert>
          <v-card-text>
            <v-text-field
              class="ma-0"
              dense
              v-model="parentusername"
              label="Enter Parent Username"
              hide-details="auto"
            ></v-text-field>
            <div>
            {{ name }}
            {{ city }}
            </div>
            <p v-if="city"> 
              If this is your parent press Save below!
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn text small color="primary" @click="checkParentUsername">
              Check Parent Username
            </v-btn>
            <v-btn text small color="primary" @click="saveParentUsername">
              Save Parent
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-alert v-if="loginready === true"
            dense
            color="blue"
          >
            You have now updated your parent username <br/>
            and can continue with login
          </v-alert>
          <v-btn v-if="loginready === true" small color="primary" @click="gotologin">
            Login ...
          </v-btn>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";

export default {
  name: "loginchildcheck",
  components: {},
  // props: ['username', 'password'],
  data() {
    return {
      userid: 0,
      dummy: "",
      theme: false,
      parentusername: "",
      parent: null,
      parentid: 0,
      name: "",
      city: "",
      resstring: "",
      loginready: false
    };
  },
  created() {},
  async mounted() {
    if (this.$route.params.userid && this.$route.params.child) {
      this.theme = this.$route.params.theme;
      this.userid = this.$route.params.userid;
      console.log(this.userid);
      console.log(this.$route.params.userid);
    }
  },
  methods: {
    gotologin() {
      this.$router.push({
        name: "mainwindow",
          params: {
            userid: this.userid
            // username: this.username,
            // password: this.password
          },
        });
    },
    async checkParentUsername() {
      try {
        const response = await AuthenticationService.checkUsername(
          this.parentusername
        );
        // console.log(response.data);
        if (response.data) {
          this.parent = response.data;
          this.parentid = this.parent.id
          this.name = this.parent.name;
          this.city = this.parent.city;
        } else {
          this.city = "";
          this.parentid = 0;
          this.name = "parent username doesn't exist";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveParentUsername() {
      try {
        const response = await AuthenticationService.updateParentId(
          {id: this.userid, parentid: this.parentid});
        console.log(response);
        this.loginready = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
