<template>
  <v-container dense class="ma-0 pa-0">
    <!-- :dark="theme" -->
    <v-card :dark="theme" flat class="ma-0 pa-0">
      <!-- v-list dense v-if="myfriendslistsheader === 'GROUPS'">
      <v-list-group class="mb-3"
        v-for="item in friendgrouplist"
        @click="createGroupInlMessage(item)"
        v-model="item.Group.dialog"
        :key="item.id"
        :append-icon="item.Group.dialog ? '' : ''"
        no-action
      -->
      <v-list dense>
        <v-list-item class="mt-n6 mb-5 pa-0">
          <v-list-item-avatar class="mr-3">
            <v-img :src="item.Group.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content @click="openActionWindow">
            <v-list-item-title
              v-if="item.Read.msgread === 0"
              v-text="item.Group.name"
            ></v-list-item-title>
            <v-list-item-subtitle v-else v-text="item.Group.name">
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="openActions === false"
              class="green--text"
              v-text="
                item.messageAt.substr(0, 10) +
                  ' ' +
                  item.messageAt.substr(11, 5) +
                  ' - ' +
                  item.message
              "
            ></v-list-item-subtitle>
            <v-list-item-subtitle
              v-else
              class="green--text"
              v-text="
                item.messageAt.substr(0, 10) +
                  ' ' +
                  item.messageAt.substr(11, 5)
              "
            ></v-list-item-subtitle>
          </v-list-item-content>

          <!-- v-if="openActions === false && (item.Group.msgbadge === 0 || item.Read.msgread === 0)" -->

          <v-badge
            v-if="openActions === false && item.Group.msgbadge === 1"
            class="mr- pa-0"
            color="green"
            :content="item.Group.msgbadge"
            overlap
          >
            <v-icon class="ma-0 pa-0" size="24px" color="blue"
              >mdi-bell
            </v-icon>
          </v-badge>

          <v-list-item-icon v-if="openActions === true" class="mr-4 mt-3 pa-0">
            <v-btn class="mt-0" small icon @click="openRecorder()">
              <v-icon size="24px">mdi-microphone</v-icon>
            </v-btn>
          </v-list-item-icon>

          <v-list-item-icon v-if="openActions === true" class="mr-n1 mt-2 pa-0">
            <v-file-input
              dense
              color="primary"
              prepend-icon="mdi-camera"
              hide-input
              accept="image/png, image/jpeg, image/bmp"
              label=""
              @change="resizeMsgImage"
            >
            </v-file-input>
          </v-list-item-icon>

          <v-list-item-icon v-if="openActions === true" class="mr-5 mt-3 pa-0">
            <v-btn small icon @click="sendGroupMessage()" color="primary">
              <v-icon size="24px">mdi-email-send-outline</v-icon>
            </v-btn>
          </v-list-item-icon>

          <!-- v-list-item-action v-if="item.Group.dialog" -->
          <v-list-item-icon v-if="openActions === false" class="mr-5 mt-3 pa-0">
            <!-- :input-value="active" -->
            <v-btn icon small @click.stop="opengroupUpdate(item)">
              <v-icon size="24px">mdi-account-multiple-outline</v-icon>
            </v-btn>
          </v-list-item-icon>
          <!-- /v-list-item-action -->
        </v-list-item>

        <!-- textFieldColor -->
        <v-list-item v-if="openActions === true" class="ml-n16">
          <v-row
            v-if="$vuetify.breakpoint.xs"
            dense
            class="ml-4 ma-0 mb-n3 pa-0"
          >
            <v-col cols="2"></v-col>
            <v-col cols="10">
              <v-textarea
                class="mt-n3 ma-0 pa-0"
                background-color="grey"
                dense
                solo
                auto-grow
                rows="1"
                name="input-7-1"
                label="new message"
                v-model="sendgroupmessage"
              >
              </v-textarea>
            </v-col>
            <!-- v-row class="mb-8 mt-n5" dense>
                  <v-col cols="1"></v-col>
                  <v-col cols="1">
                    <v-btn class="mr-2" small icon @click="openRecorder()">
                      <v-icon size="24px">mdi-microphone</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="1">
                    <v-file-input
                      dense
                      color="primary"
                      class="mt-n1 mr-2"
                      prepend-icon="mdi-camera"
                      hide-input
                      accept="image/png, image/jpeg, image/bmp"
                      label=""
                      @change="resizeMsgImage"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      small
                      icon
                      @click="sendGroupMessage()"
                      color="primary"
                    >
                      <v-icon size="24px">mdi-email-send-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="8"></v-col>
                </v-row -->
          </v-row>
          <v-row v-else dense class="ml-2 ma-0 mb-0 pa-0">
            <v-col cols="2"></v-col>
            <v-col cols="10">
              <v-textarea
                class="ma-0 pa-0"
                background-color="grey"
                dense
                solo
                auto-grow
                rows="1"
                name="input-7-1"
                label="new message"
                v-model="sendgroupmessage"
              >
              </v-textarea>
            </v-col>
            <!-- v-col cols="1">
                  <v-btn small class="ml-0 mb-n5" icon @click="openRecorder()">
                    <v-icon size="24px">mdi-microphone</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1">
                  <v-file-input
                    class="ml-0"
                    dense
                    color="primary"
                    prepend-icon="mdi-camera"
                    hide-input
                    accept="image/png, image/jpeg, image/bmp"
                    label=""
                    @change="resizeMsgImage"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    small
                    class="ml-0 mb-n5"
                    icon
                    @click="sendGroupMessage()"
                    color="primary"
                  >
                    <v-icon size="24px">mdi-email-send-outline</v-icon>
                  </v-btn>
                </v-col -->
          </v-row>

          <!-- /v-card-actions -->
        </v-list-item>
        <v-list-item v-if="showRecorder === true && createVoiceMsg === true">
          <!-- vue-record-audio mode="press" @result="onRecord" / -->
          <RecorderWeb
            v-if="platform === 'web'"
            :platform="platform"
            audiotype="mp3"
            :show="showRecorder"
            @new-voice-msg="saveGroupVoiceMessage"
          ></RecorderWeb>
          <RecorderWeb
            v-else
            @new-voice-msg="saveGroupVoiceMessage"
            :platform="platform"
            audiotype="mp3"
            :show="showRecorder"
          >
          </RecorderWeb>
        </v-list-item>
        <v-list-item v-if="openActions === true && soundMsg.url">
          <v-row dense class="mt-0 mb-5">
            <v-col cols="1">
              <v-btn
                small
                class="ml-2 mt-n1 mr-2"
                icon
                @click="removeVoiceMessage()"
              >
                <v-icon class="mr-n3" size="24px">mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col class="ml-2" cols="10">
              <audio controls :src="soundMsg.url" />
            </v-col>
          </v-row>
        </v-list-item>
        <v-row class="mt-2 ml-3 mb-1" dense v-if="card.msgimage">
          <v-col cols="1"></v-col>
          <v-col cols="11">
            <div>
              <v-btn small class="ml-0 mt-n6 mb-5" icon @click="removeImage()">
                <v-icon size="24px">mdi-close</v-icon>
              </v-btn>
              <v-img
                :src="card.msgimage"
                class="ml-3 mb-3 mt-n5 white--text align-end"
                width="300px"
              >
                <v-card-title v-text="card.title"></v-card-title>
              </v-img>
            </div>
          </v-col>
        </v-row>
        <!-- /v-row -->
        <v-card
          v-if="openActions === true"
          flat
          class="ma-0 mt-n5 pa-0"
          max-width="800"
        >
          <v-card-text>
            <v-row
              dense
              justify="start"
              class="mb-3 mx-0"
              v-for="message in grouphistmsg"
              :key="message.time"
            >
              <v-col v-if="message.color === 'green'" cols="11" sm="11">
                <div class="font-weight-normal text-left black--text">
                  <!-- color="lime lighten-3" -->
                  <v-card
                    flat
                    class="pl-2 pt-1 pb-1 pr-2"
                  >
                    <!-- {{ message.time.toLocaleString() }} -->
                    <div :class="colorTextLeft">
                      {{ message.message }}
                    </div>
                    <v-row
                      v-if="message.voiceid > 0 && message.voiceimage"
                      class="mt-2"
                      dense
                    >
                      <div>
                        <audio controls :src="message.voiceimage" />
                      </div>
                    </v-row>
                    <v-img @click="openImage(message)"
                      v-if="message.imageid"
                      :src="message.msgimage"
                      class="mt-2 mb-2 white--text align-end"
                      width="300px"
                    >
                      <v-card-title v-text="message.msgtitle"></v-card-title>
                    </v-img>
                    <div :class="colorTextLeftPerson">
                      {{
                        message.time.substr(11, 5) +
                          " " +
                          message.time.substr(0, 10) +
                          " " +
                          message.from
                      }}
                    </div>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="1" v-if="message.color === 'green'"> </v-col>
              <v-col cols="1" v-if="message.color !== 'green'"> </v-col>
              <v-col v-if="message.color !== 'green'" cols="11" sm="11">
                <div class="font-weight-normal text-left black--text">
                  <!-- color="light-blue lighten-4" -->
                  <v-card flat class="pl-2 pt-1 pb-1 pr-2">
                    <div :class="colorTextRight">
                      {{ message.message }}
                    </div>
                    <v-row
                      v-if="message.voiceid > 0 && message.voiceimage"
                      class="mt-2"
                      dense
                    >
                      <div>
                        <audio controls :src="message.voiceimage" />
                      </div>
                    </v-row>
                    <v-img 
                      v-if="message.imageid > 0"
                      @click="openImage(message)"
                      :src="message.msgimage"
                      class="mt-2 mb-2 white--text align-end"
                      width="300px"
                    >
                      <v-card-title v-text="message.msgtitle"></v-card-title>
                    </v-img>
                    <div :class="colorTextRightPerson">
                      {{
                        message.time.substr(11, 5) +
                          " " +
                          message.time.substr(0, 10) +
                          " " +
                          message.from
                      }}
                    </div>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-list>
    </v-card>
    <!-- gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" -->
    <v-dialog persistent v-model="popupImageDialog"
      :max-width="popupdialogwidth">
      <v-card flat>
        <v-toolbar dense>
          <v-btn icon @click="closeImageDialog">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-img contain
          :width="popupimagewidth"
          :src="popupImage"
        >
        </v-img>
      </v-card>
    </v-dialog>
    <!-- DIALOG GROUP UPDATE ---------------------------------------------------------->
    <v-dialog
      persistent
      v-model="groupupdatedialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="60%"
    >
      <v-card :dark="theme">
        <v-card-title>Update Group</v-card-title>
        <v-form>
          <v-container>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  disabled
                  v-model="updgroupname"
                  filled
                  color="blue-grey lighten-2"
                  label="Name"
                  class="ma-0 pa-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  class="mt-n3 ma-0 pa-0"
                  dense
                  filled
                  auto-grow
                  rows="2"
                  name="input-7-1"
                  label="Description"
                  v-model="description"
                >
                </v-textarea>
              </v-col>
              <!-- v-col cols="12" md="6">
                <v-text-field
                  dense
                  disabled
                  v-model="updgrouptitle"
                  filled
                  color="blue-grey lighten-2"
                  label="Title"
                ></v-text-field>
              </v-col -->
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="selectedupdgroupusers"
                  :items="groupusers"
                  small-chips
                  color="blue-grey lighten-2"
                  label="Select"
                  item-text="User.name"
                  item-value="User.id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <!-- @click:close="remove(data.item)"  close -->
                  <template v-slot:selection="data">
                    <v-chip
                      small-chips
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.User.avatar"></v-img>
                      </v-avatar>
                      {{ data.item.User.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.User.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.User.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.User.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="addedupdgroupusers"
                  :items="friendlist"
                  small-chips
                  color="blue-grey lighten-2"
                  label="Add to group"
                  item-text="User.name"
                  item-value="User.id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <!--  @click:close="remove(data.item)" -->
                  <template v-slot:selection="data">
                    <v-chip
                      small-chips
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.User.avatar"></v-img>
                      </v-avatar>
                      {{ data.item.User.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.User.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.User.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.groupid"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-checkbox
                  dense
                  class="text--primary ma-1 pa-1"
                  v-model="requestjoin"
                  label="Request Permission to Add Person"
                  color="primary"
                  disabled
                  hide-details
                ></v-checkbox>
                 <v-text-field v-if="requestjoin"
                  dense
                  required
                  v-model="message"
                  filled
                  color="blue-grey lighten-2"
                  label="Message to user"
                  class="ma-0 pa-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="savegroupUpdate()">
            Update
          </v-btn>
          <v-btn small color="error" @click="resetgroupUpdate()">
            Close
          </v-btn>
          <v-btn small color="warning" @click="deleteGroup()">
            Delete
          </v-btn>
          <v-btn small color="warning" @click="leaveGroup()">
            Leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="spinnerDialog" persistent :max-width="dialogwidth">
      <v-card>
        <v-card-title>Loading File</v-card-title>
      </v-card>
    </v-dialog>
    <!-- Snackbar color=''></Snackbar -->
    <v-snackbar v-model="snackbarwarningmsg" top color="warning">
      {{ notregtext }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbarwarningmsg = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
/* eslint-disable */
import UserService from "@/services/UserService";
import SearchesService from "@/services/SearchesService";
import GroupServices from "@/services/GroupServices";
import SystemService from "@/services/SystemService";
import MyImageService from "@/services/MyImageService";
import VoiceMessageService from "@/services/VoiceMessageService";
import VoiceMessageServiceForm from "@/services/VoiceMessageServiceForm";
import resizeImage from "@/utils/resizeImage.js";
import RecorderWeb from "@/components/RecorderWeb.vue";
import * as socketio from "@/plugins/socketio";
// import accessrights from "@/utils/accessrights.js" 
// import RecorderPhone from "@/components/RecorderPhone.vue";
// import AudioPlayer from './Player';

export default {
  emits: ["new-group-msg", "minus-group-badge", "group-deleted"],
  components: {
    RecorderWeb,
    // RecorderPhone,
    // AudioPlayer
  },
  name: "GroupChat",
  props: ["item", "activityid", "userid", "theme", "platform"],
  data() {
    return {
      popupImageDialog: false,
      isCompanyGroup: false,
      hasUpdateRights: false,
      popupImage: "",
      groupItem: null,
      userGroupDataChanged: false,
      requestjoin: false,
      message: "",
      GroupBadge: 0,
      itemupdgroupusers: null,
      showRecorder: false,
      friendlist: [],
      addedupdgroupusers: [],
      spinnerDialog: false,
      fileLoading: false,
      indeterminate: false,
      darkTheme: false,
      btnTextShowMore: "More...",
      widthImage: "300px",
      colorBlockRight: "light-blue lighten-4",
      colorTextRight: "purple--text",
      colorTextRightPerson: "purple--text",
      colorBlockLeft: "amber lighten-4",
      colorTextLeft: "green--text",
      colorTextLeftPerson: "green--text",
      color1Text: "black--text",
      color2Text: "black--text",
      textFieldColorBgr: "grey lighten-2",
      color1: "lime lighten-1",
      color1Background: "",
      // color2: "orange lighten-2",
      color2: "black",
      color2Background: "",
      unsaveddata: false,
      deviceIsReady: false,
      backgroundColor: "",
      card: { msgimage: "", msgtitle: "", imageid: 0 },
      chat: true,
      sendgroupmessage: "",
      messageitem: null,
      accessrights: [],
      requestlist: [],
      grouphistmsg: [],
      grouphistmsgtmp: [],
      createText: false,
      createVoiceMsg: false,
      openActions: false,
      showMessages: false,
      overlay: false,
      msgdialog: false,
      histdialog: false,
      offsetTop: 1,
      lastmessage: null,
      msgimage: "",
      showhistory: false,
      showNew: false,
      maxImageSize: 700000,
      resizedImg: null,
      soundMsg: {
        file: null,
        url: "",
        id: 0,
        title: "",
        type: "audio/mpeg",
        size: 0,
        msg: "",
        file2: null,
      },
      snackbarwarningmsg: false,
      notregtext: "",
      selectedupdgroupusers: [],
      groupuserid: 0,
      groupid: 0,
      companyid: 0,
      updgroupname: "",
      updgrouptitle: "",
      description: "",
      groupupdatedialog: false,
      groupusers: [],
    };
  },
  watch: {
    msgdialog() {
      // if (visible) {
      // Here you would put something to happen when dialog opens up
      // window.scrollTo(0, 110);
      // const el = this.$msgcard.scrollToMe;
      // if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      //  el.scrollIntoView({behavior: 'smooth'});
      //    }
      // var container = document.querySelector(".v-card");
      // container.scrollTop = document.body.scrollHeight;
      // container.scrollTo(0, 110);
      // this.$vuetify.goTo(document.body.scrollHeight, { container: this.$refs.msgcard })
      // }
    }
    // userGroupDataChanged() {
    //  this.userGroupDataChanged = true;
    // }
  },
  computed: {
    dialogwidth() {
      if (this.$vuetify.breakpoint.name === "xs") return "90%";
      if (this.$vuetify.breakpoint.name === "sm") return "80%";
      if (this.$vuetify.breakpoint.name === "md") return "60%";
      if (this.$vuetify.breakpoint.name === "lg") return "50%";
      if (this.$vuetify.breakpoint.name === "xl") return "50%";
    },
    popupdialogwidth() {
      if (this.$vuetify.breakpoint.name === "xs") return "100%";
      if (this.$vuetify.breakpoint.name === "sm") return "90%";
      if (this.$vuetify.breakpoint.name === "md") return "60%";
      if (this.$vuetify.breakpoint.name === "lg") return "50%";
      if (this.$vuetify.breakpoint.name === "xl") return "50%";
    },
    popupimagewidth() {
      if (this.$vuetify.breakpoint.name === "xs") return "100%";
      if (this.$vuetify.breakpoint.name === "sm") return "100%";
      if (this.$vuetify.breakpoint.name === "md") return "100%";
      if (this.$vuetify.breakpoint.name === "lg") return "100%";
      if (this.$vuetify.breakpoint.name === "xl") return "100%";
    }
  },
  created() {
    if (this.theme === true) {
      this.backgroundColor = "grey";
      this.textFieldColorBgr = "grey lighten-2";
      this.darkTheme = true;
    }
    if (this.theme === false) {
      this.darkTheme = false;
      this.backgroundColor = "white";
      this.textFieldColorBgr = "white";
      this.color1Text = "black--text";
      this.color2Text = "black--text";
    }
    // this.messageitem = await this.updateHistRec(this.item.Msg);
    // console.log(this.messageitem);
  },
  async mounted() {
    console.log(this.theme);
    console.log(this.item);
    // this.messageitem = await this.updateHistRec(this.item.Msg);
    // await this.getGroupHistory();
    // this.userhistmsgtmp.push(this.messageitem);
    // console.log(this.messageitem);

    /*
    if(this.theme === true) {
      this.backgroundColor = "grey";
      this.textFieldColorBgr = "Grey";
      this.darkTheme = true;
    }
    if(this.theme === false) {
      this.darkTheme = false;
      this.backgroundColor = "white";
      this.textFieldColorBgr = "white";
      this.color1Text = "green--text";
      this.color2Text = "bluegrey--text";
    }
    */

    // document.addEventListener('deviceready' , this.onDeviceReady , false);
    /*
   if(this.theme === "dark") {
      this.backgroundColor = "grey";
    }
    if(this.theme === "light") {
      this.backgroundColor = "grey lighten-2";
    }
    */
  },
  methods: {
    minusGroupBadge() {
      this.$emit("minus-group-badge", this.item);
      this.GroupBadge--;
    },
    openGroupImage(message) {
      this.groupimagepopupdialog = true;
      this.groupmsgimage = message.msgimage;
    },
    async opengroupUpdate(item) {
      try {
        console.log(item);
        this.groupItem = item;
        item.Group.dialog = true;
        this.itemupdgroupusers = item;
        // console.log(this.selectedupdgroupusers)
        this.groupid = item.groupid;
        this.groupuserid = item.Group.userid;
        this.companyid = item.Group.idcompany;
        if (this.companyid === null) {
          this.isCompanyGroup = false;
          if (item.Group.userid === this.userid) {
            this.hasUpdateRights = true;
          }
        } else {
          this.isCompanyGroup = true;
        }
        this.description = item.Group.description;
        this.selectedupdgroupusers = [];
        this.updgroupname = item.Group.name;
        this.updgrouptitle = item.Group.title;
        this.requestjoin = item.Group.requestjoin;
        this.message = item.Group.message;
        // const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupUsers(
          item.groupid,
          this.activityid
        );
        this.groupusers = response.data;
        var index = 0;
        while (this.groupusers[index]) {
          var id = this.groupusers[index].User.id;
          this.selectedupdgroupusers.push(id);
          // var name = this.groupusers[index].User.name;
          // this.selectedupdgroupusers.push(name);
          index = index + 1;
        }
        await this.getFriends();
        this.groupupdatedialog = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getAccessRights() {
      try {
        if(!this.isCompanyGroup) {
          if (this.hasUpdateRights) return true;
          else return false;
        }
        if (this.isCompanyGroup) {
          const response = await SystemService.getAccessRights(
            this.userid,
            this.activityid,
            this.companyid
          );
          this.accessrights = response.data;
          if (this.accessrights.length > 0) {
            // Here we need to add check if more then one company
            // selectCompany ()
            this.companyid = this.accessrights[0].idcompany;
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async savegroupUpdate() {
      // get user access rights here 
      let updaterights = await this.getAccessRights();
      
      if (updaterights) {
        console.log(this.selectedupdgroupusers);
        console.log(this.addedupdgroupusers);
        this.groupupdatedialog = false;
        // var name = "";
        var id = 0;
        try {
          const usersremoved = [];
          var newgroupusers = [];
          // add old and new values
          // const selectedgroups = this.selectedupdgroupuser
          // console.log(selectedgroups)
          // go through updated selectedupdgroupuser and find those removed
          if (this.description !== this.item.Group.description || this.message !== this.item.Group.message) {
            await GroupServices.updateGroupData({id: this.item.groupid, description: this.description, message: this.message});
            this.groupItem.Group.description = this.description;
            this.groupItem.Group.message = this.message;
          }
          var index = 0;
          while (this.groupusers[index]) {
            id = this.groupusers[index].User.id;
            var pos = this.selectedupdgroupusers.indexOf(id);
            if (pos < 0) {
              // user removed add to remove list
              var usergroup = { id: 0 };
              usergroup.id = this.groupusers[index].id;
              usersremoved.push(usergroup);
            }
            index = index + 1;
          }
          if (usersremoved.length > 0) {
            console.log("users to remove");
            const res = await GroupServices.deleteUsersFromGroup({
              userlist: usersremoved,
            });
            console.log(res);
          }
          // add all new users
          index = 0;
          while (this.addedupdgroupusers[index]) {
            var newusergroup = { userid: 0, groupid: 0, idactivity: 0 };
            // usergroup.groupid = result.data.id
            id = this.addedupdgroupusers[index];
            pos = this.friendlist
              .map(function(e) {
                return e.User.id;
              })
              .indexOf(id);
            if (this.userid === this.friendlist[pos].idfriend) {
              newusergroup.userid = this.friendlist[pos].iduser;
            } else {
              newusergroup.userid = this.friendlist[pos].idfriend;
            }
            newusergroup.groupid = this.groupid;
            newusergroup.idactivity = this.activityid;
            newgroupusers.push(newusergroup);
            index = index + 1;
          }
          // Add creator also to usergroup
          // groupusers.push({userid: this.userid, groupid: result.data.id})
          // var credentials = { userlist: groupusers }
          const response = await GroupServices.addUsersToGroup({
            userlist: newgroupusers,
          });
          this.addedupdgroupusers = [];
          // this.myfriendslistsheader = "GROUPS";
          console.log(response);
          // await this.getGroups();
          this.opengroupUpdate(this.itemupdgroupusers);
        } catch (error) {
          console.log(error);
        }
      } else {
        if (this.companyid)
          this.openWarningSnackBarMsg("You don't have company update rights for groups");
        else 
          this.openWarningSnackBarMsg("Only owners/creator can update a group");
      }
    },
    async deleteGroup() {
      try {
        // Check user is owner of group
        if (this.userid === this.groupuserid) {
          // Delete Group
          const del = await GroupServices.deleteGroup({
            groupid: this.groupid,
          });
          console.log(del);
          // Delete users from group
          const res = await GroupServices.deleteUsersFromGroup({
            userlist: this.groupusers,
          });
          console.log(res);
          // Close Dialog
          this.groupupdatedialog = false;
          // Refresh list
          this.deletedGroup();
          // await this.getGroups();
        } else {
          this.openWarningSnackBarMsg("Only owners/creator can delete a group");
        }
      } catch (error) {
        this.groupupdatedialog = false;
        await this.getGroups();
        console.log(error);
      }
    },
    async leaveGroup() {
      try {
        // Check user is owner of group
        if (this.userid !== this.groupuserid) {
          // Leave Group
          var usergroupid = 0;
          var index = 0;
          while (this.groupusers[index]) {
            if (this.userid === this.groupusers[index].userid) {
              usergroupid = this.groupusers[index].id;
              break;
            }
            index = index + 1;
          }
          const res = await GroupServices.deleteUsersFromGroup({
            userlist: [{ id: usergroupid }],
          });
          console.log(res);
          // Close Dialog
          this.groupupdatedialog = false;
          // Refresh list
          await this.getGroups();
        } else {
          this.openWarningSnackBarMsg("You can't leave a group you created");
        }
      } catch (error) {
        console.log(error);
      }
    },
    resetgroupUpdate() {
      this.groupupdatedialog = false;
      // this.myfriendslistsheader = "GROUPS";
      // this.selectedupdgroupusers = [];
      // this.addedupdgroupusers = [];
    },
    saveGroupVoiceMessage(voiceMsg) {
      console.log("saveGroupVoiceMessage");
      this.soundMsg = voiceMsg;
      this.createVoiceMsg = false;
      // this.sendFriendMessage(voiceMsg);
    },
    newGroupMessage(item) {
      this.$emit("new-group-msg", item);
    },
    deletedGroup() {
      this.$emit("deleted-group", this.groupid);
    },
    removeVoiceMessage() {
      this.soundMsg.file = null;
      this.soundMsg.url = "";
      this.soundMsg.id = 0;
      this.soundMsg.title = "";
      this.soundMsg.type = "";
      this.soundMsg.size = 0;
      this.soundMsg.msg = "";
      this.soundMsg.file2 = null;
    },
    async openHistory() {
      if (this.showhistory === false) {
        this.showhistory = true;
        // this.btnTextShowMore = "Less...";
        await this.getGroupHistory();
      } else {
        this.showhistory = false;
        console.log(this.messageitem);
        // this.btnTextShowMore = "More...";
        // this.messageitem = this.grouphistmsg[0];
        // this.messageitem = this.updateHistRec(this.item.Msg);
      }
    },
    async openMicroPhone() {
      if (this.createVoiceMsg === false) {
        // document.addEventListener('deviceready' , this.onDeviceReady , false);
        this.createVoiceMsg = true;
        // this.checkPermission();
      } else {
        this.createVoiceMsg = false;
        // this.stopRecording();
      }
    },
    async sendGroupMessage() {
      if (this.sendgroupmessage === "") {
        if (this.soundMsg.msg === "" && this.soundMsg.file === null) {
          this.openWarningSnackBarMsg("Write some message!");
          return;
        }
        if (this.soundMsg.msg === "" && this.soundMsg.file) {
          this.sendgroupmessage = this.voiceTitle;
        }
        if (this.soundMsg.msg === "pause recording") {
          this.openWarningSnackBarMsg(
            "Please End Your Message By Press Stop Button"
          );
          return;
        }
      }
      if (this.sendgroupmessage) {
        if (this.soundMsg.msg === "pause recording") {
          this.openWarningSnackBarMsg(
            "Please End Your Message By Press Stop Button"
          );
          return;
        }
      }
      console.log(this.item);
      let messageitem = this.item;
      await this.saveVoiceMsg2(this.soundMsg);
      var message = {
        id: 0,
        idgroup: 0,
        idactivity: 0,
        idsender: 0,
        idreceiver: 0,
        sendername: "",
        receivername: "",
        message: "",
        imageid: 0,
        msgtitle: "",
        msgimage: null,
        voiceid: this.soundMsg.id,
        voiceimage: null,
        type: "group",
        groupavatar: "",
      };
      try {
        message.idgroup = messageitem.Group.id;
        message.idactivity = this.activityid;
        message.idreceiver = messageitem.Group.id;
        message.idsender = this.userid;
        message.sendername = this.$store.state.user.name;
        message.receivername = messageitem.Group.name;
        message.groupavatar = messageitem.Group.avatar;
        message.message = this.sendgroupmessage;
        message.imageid = this.card.imageid;
        message.msgtitle = this.card.msgtitle;
        message.type = "group";
        // this.socket.emit('new_message', message )
        const response = await UserService.sendMyGroupMessage(message);
        const newmessage = response.data.message;
        newmessage.msgimage = this.card.msgimage;
        // this.messagefrienddialog = false; denna används inte i inline versionen
        if (newmessage.voiceid > 0) {
          const res = await VoiceMessageService.getVoiceDataMessage(
            newmessage.voiceid
          );
          newmessage.voiceimage = res.data;
        }
        // ? 2021-09-17 this.messagelist.unshift(newmessage);
        // this.messagegroupdialog = false;
        this.updateGroupHistoryList(newmessage);
        this.clearCard();
        this.clearSoundMsg();
        this.sendgroupmessage = "";
        this.sendGPMessage(newmessage);
      } catch (error) {
        console.log(error.message);
        this.clearCard();
        // this.clearSoundGrpMsg();
        if (newmessage.voiceid > 0) {
          this.removeVoiceMessage();
        }
        // this.error = error.response.data.error
      }
      // try {
      //   this.sendGPMessage(newmessage)
      // } catch (error) {
      //   console.log(error)
      // }
    },
    sendGPMessage(message) {
      socketio.sendEvent({
        type: "group_message",
        data: message,
      });
    },
    fileLoad(file) {
      this.fileLoading = true;
      this.spinnerDialog = true;
      this.resizeMsgImage(file);
      this.spinnerDialog = false;
      this.fileLoading = false;
    },
    resizeMsgImage(file) {
      // START: preview original
      // you can remove this section if you don't like to preview original image
      if (!file.type.match(/image.*/)) {
        // no(new Error('Not an image'));
        return;
      }
      // 20210514 CWE
      // this.clearCard();
      const reader = new FileReader();
      reader.onload = (e) => (this.originalImg = e.target.result);
      reader.readAsDataURL(file); // convert to base64 string
      // END: preview original

      // START: preview resized
      // const resimg = resizeImage({ file: file, maxSize: 150 });
      // this.resizedImg = window.URL.createObjectURL(resimg);
      resizeImage({ file: file, maxSize: this.maxImageSize })
        .then((resizedImage) => {
          this.resizedImg = URL.createObjectURL(resizedImage);
          this.card.msgimage = this.resizedImg;
          this.saveMsgImage(resizedImage);
          // console.log("this.resizedImg " + this.resizedImg);
        })
        .catch((err) => {
          console.log(err);
        });
      // END: preview resized
    },
    clearSoundMsg() {
      // soundMsg: { file: null, url: "", id: 0, title: "", type: "audio/mpeg", size: 0, msg: "", file2: null }
      this.soundMsg.file = null;
      this.soundMsg.url = "";
      this.soundMsg.id = 0;
      this.soundMsg.title = "";
      this.soundMsg.type = "audio/mpeg";
      this.soundMsg.size = 0;
      this.soundMsg.msg = "";
      this.soundMsg.file2 = null;
    },
    openWarningSnackBarMsg(msg) {
      this.notregtext = msg;
      this.snackbarwarningmsg = true;
    },
    async saveVoiceMsg2(soundmsg) {
      try {
        const data = new FormData();
        data.append("file", soundmsg.file);

        // const headers = Object.assign(this.headers, {})
        // headers['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`

        data.append("userid", this.$store.state.user.id);
        data.append("messageid", 0);
        // data.append("name", " ");
        // data.append("name", );
        data.append("type", soundmsg.type);
        data.append("size", soundmsg.size);
        // data.append("file", this.soundMsg.file);
        // var voicefile = {userid: this.userid,
        //  file: blob, len: tempfile.size, type: tempfile.type};
        const response = await VoiceMessageServiceForm.saveVoiceDataMessage(
          data
        );
        console.log(response);
        this.soundMsg.id = response.data.id;
        console.log(response.data.id);
        // const voice = await VoiceMessageService.getVoiceMessageresponse.data.id);
        // console.log(voice.data)
      } catch (error) {
        console.log(error);
      }
    },
    clearCard() {
      this.card.msgimage = "";
      this.card.msgtitle = "";
      this.card.imageid = 0;
    },
    async removeImage() {
      console.log("removeImage");
      try {
        await MyImageService.deletemyimage({ id: this.card.imageid });
        this.card.msgimage = "";
        this.card.imageid = 0;
      } catch (error) {
        console.log(error.message);
        this.card.msgimage = "";
        this.card.imageid = 0;
      }
    },
    async saveMsgImage(file) {
      this.currentFile = file;
      // Test resize here
      try {
        // const file2 = this.resizeMsgImage(file)

        var data = new FormData();
        data.append("userid", this.$store.state.user.id);
        data.append("text", " ");
        data.append("title", this.card.msgtitle);
        data.append("imagetype", "friendmsg");
        data.append("filename", file.name);
        this.MyImageType = "friendmsg";
        data.append("file", file);
        const response = await MyImageService.savemsgimage(data);
        console.log(response);
        this.card.imageid = response.data.id;
        await this.getMsgImage(response.data.id);

        // this.imagesdialog = false
        // var avatarfile =
        //  response.data.mysavedimage.FullPath +
        //  response.data.mysavedimage.DiscFileName;
        // this.notificationtext = "Your image is saved ";
        // this.snackbar = true;
        // console.log(usrupd);
      } catch (error) {
        console.log(error);
      }
    },
    async getMsgImage(msgid) {
      try {
        const res = await MyImageService.getmsgimage(msgid);
        this.card.msgimage = res.data;
        if (res.data === "NoImage") {
          console.log("NoImage");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openActionWindow() {
      if (this.openActions === false) {
        this.openActions = true;
        this.createText = true;
        if (this.item.Read.msgread === 0) {
          this.GroupBadge = this.GroupBadge - 1;
          this.item.Read.msgread = 1;
          console.log(this.item.Read.id);
          const res = await GroupServices.updateUserGroupMessageRead({
            id: this.item.Read.id,
          });
          console.log(res);
        }
        const reshist = await this.getGroupHistory();
        console.log(reshist);
        this.minusGroupBadge();
      } else {
        this.openActions = false;
        this.createText = false;
      }
    },
    openRecorder() {
      console.log("openRecorder");
      console.log(this.platform);
      if (this.showRecorder === false) {
        this.soundMsg.file = null;
        this.showRecorder = true;
        this.createVoiceMsg = true;
      } else {
        this.soundMsg.file = null;
        this.showRecorder = false;
        this.createVoiceMsg = false;
      }
    },
    async updateGroupHistoryList(message) {
      // this is called from Friend list when
      // user expends the list and send message
      try {
        // if (this.grouphistmsg.length > 0) {
        // check if userlist needs update idsender idreceiver
        // check if right user list
        /* if ((this.grouphistmsg[0].idsender === message.idsender) &&
              (this.grouphistmsg[0].idreceiver === message.idreceiver) || 
              (this.grouphistmsg[0].idreceiver === message.idsender) &&
              (this.grouphistmsg[0].idsender === message.idreceiver)) {*/

        var rec = {
          id: 0,
          idactivity: 0,
          idsender: 0,
          idreceiver: 0,
          from: "",
          message: "",
          time: "",
          color: "",
          imageid: 0,
          msgtitle: "",
          msgimage: "",
          voiceid: 0,
          voiceimage: null,
        };
        if (message.idsender === this.userid) {
          rec.color = "deep-purple lighten-1";
        } else {
          rec.color = "green";
        }
        rec.from = message.sendername;
        rec.message = message.message;
        rec.time = message.createdAt;
        rec.id = message.id;
        rec.idactivity = message.idactivity;
        rec.idsender = message.idsender;
        rec.idreceiver = message.idreceiver;
        rec.imageid = message.imageid;
        rec.msgtitle = message.msgtitle;
        rec.msgimage = message.msgimage;
        rec.voiceid = message.voiceid;
        rec.voiceimage = message.voiceimage;
        this.grouphistmsg.unshift(rec);
      } catch (error) {
        console.log(error);
      }
    },
    async getGroupHistory() {
      // this.messagegroupdialog = true
      // const pos = this.selectedmessagerows
      // this.microPhone = true;
      let grouptmp =  [];
      try {
        this.clearSoundMsg();
        this.clearCard();
        this.item.Group.msgbadge = 0;
        this.grouphistmsg = [];
        var groupid = this.item.groupid;
        const groupmsg = await SearchesService.getGroupMessagesHist(
          groupid,
          this.activityid
        );
        const tmp = groupmsg.data;
        var index = 0;
        if (tmp.length > 0) {
          while (tmp[index]) {
            var rec = {
              id: 0,
              idgroup: 0,
              idactivity: 0,
              idsender: 0,
              idreceiver: 0,
              from: "",
              message: "",
              time: "",
              color: "",
              imageid: 0,
              msgimage: "",
              msgtitle: "",
              voiceid: 0,
              voiceimage: "",
            };
            rec.idgroup = tmp[index].idgroup;
            rec.idactivity = tmp[index].idactivity;
            rec.from = tmp[index].sendername;
            rec.message = tmp[index].message;
            rec.time = tmp[index].createdAt;
            rec.id = tmp[index].id;
            rec.idsender = tmp[index].idsender;
            rec.idreceiver = tmp[index].idreceiver;
            rec.imageid = tmp[index].imageid;
            rec.msgtitle = tmp[index].msgtitle;
            // rec.voiceimage = tmp[index].voiceimage;
            try {
              rec.voiceid = tmp[index].voiceid;
              if (rec.voiceid > 0) {
                const res = await VoiceMessageService.getVoiceDataMessage(
                  rec.voiceid
                );
                // rec.voiceimage = res.data;
                // rec.voiceimage = window.URL.createObjectURL(res.data);
                // const blob = new Blob([res.data], { type: "audio/mp3" });
                // rec.voiceimage = (window.URL ? URL : webkitURL).createObjectURL(blob);
                rec.voiceimage = res.data;
                try {
                  // rec.voiceimage = window.URL.createObjectURL(res.data.file);
                  console.log("efter rec.voiceimage");
                } catch (error) {
                  console.log(error);
                }
                // rec.voiceimage = temp;
                // const reader = new FileReader();
                // var furl = reader.readAsDataURL(res.data.file);
                // rec.voiceimage = furl;
                // rec.voiceid = res.data.id
              }
            } catch (error) {
              console.log(error);
            }
            try {
              if (rec.imageid > 0) {
                const res = await MyImageService.getmsgimage(rec.imageid);
                rec.msgimage = res.data;
              }
            } catch (error) {
              console.log(error);
            }
            if (
              rec.idsender === this.userid ||
              rec.idreceiver === this.userid
            ) {
              rec.color = "deep-purple lighten-1";
            } else {
              rec.color = "green";
            }
            grouptmp.push(rec);
            index = index + 1;
          }
        }
        this.grouphistmsg.push(...grouptmp);
      } catch (error) {
        console.log(error);
      }
      this.messageitem = this.item;
      // this.messagelist[this.selectedmessagerows]
      console.log(this.messageitem);
    },
    // 2021-09-17 Need to check FriendBadge update, removed
    // rebuild to only get friends
    async getFriends() {
      try {
        // const Approved = approved
        // console.log(approved);
        // var menu = this.menuMessagesF;
        // console.log(menu);
        // this.menuMessagesF = false
        // this.friendlist = [];
        const activityid = this.activityid;
        // const response = await SearchesService.getActivityFriends(this.userid, appr)
        const response = await SearchesService.getFriends(
          this.userid,
          activityid
        );
        this.friendlist = response.data;
        let len = this.friendlist.length;
        console.log(len);
        // console.log(this.friendlist);
      } catch (error) {
        console.log(error);
      }
    },
    async getGroups() {
      try {
        this.friendgrouplist = [];
        this.GroupBadge = 0;
        var menu = this.menuMessagesG;
        console.log(menu);
        //const Approved = approved
        // this.friendlist = []
        // const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupsMessages(
          this.userid,
          this.activityid
        );
        this.friendgrouplist = response.data;
        const tmp = response.data;
        console.log(tmp);
        var index = 0;
        while (this.friendgrouplist[index]) {
          if (
            this.friendgrouplist[index].Read.msgread === null ||
            this.friendgrouplist[index].Read.msgread === 0
          ) {
            this.GroupBadge++;
          }
          index++;
        }
      } catch (error) {
        console.log(error);
      }
      // if (this.myfriendslistsheader !== "GROUPS") {
      this.myfriendslistsheader = "GROUPS";
      // }
      // this.setTBSelectedColor();
    },
    openImage(message) {
      this.popupImageDialog = true;
      this.popupImage = message.msgimage;
    },
    closeImageDialog() {
      this.popupImageDialog = false;
      this.popupImage = "";
    }
  },
};
</script>

<style scoped>
.backgrounddark {
  background-color: grey;
  color: rgb(255, 255, 255);
}

.backgroundlight {
  background-color: #e0e0e0;
  color: rgb(0, 0, 0);
}

.app {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
</style>
