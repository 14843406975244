import Api from "@/services/Api";

export default {
  getNewsfeeds(userid, activityid, companyid) {
    return Api().get("getnewsfeeds", {
      params: {
        userid: userid,
        activityid: activityid,
        companyid: companyid
      }
    });
  },
  addNewsfeed(credentials) {
    return Api().post("addnewsfeed", credentials);
  },
  removeNewsFeed(credentials) {
    return Api().post("removenewsfeed", credentials);
  },
  addNewsFeedNote(credentials) {
    return Api().post("addnewsfeednote", credentials);
  },
  removeNewsFeedNote(credentials) {
    return Api().post("removenewsfeednote", credentials);
  },
  getNewsFeedNotes(newsid, userid) {
    return Api().get("getnewsfeednotes", {
      params: {
        newsid: newsid,
        userid: userid
      }
    });
  },
  getNewsFeedNote(id) {
    return Api().get("getnewsfeednote", {
      params: {
        id: id
      }
    });
  }
};
