<template>
  <div>
      <v-row v-if="show === true" dense>
        <v-col>
          <div class="mb-3 subtitle-2 grey--text text-left" >Pause press mic else press stop when ready</div>
        <Audio-Recorder 
          :theme="theme"
          upload-url=""
          filename="mp3Soundfile"
          format="mp3"
          :attempts="3"
          :time="2"
          headers=""
          :before-recording="recCallback"
          :pause-recording="recPausCallback"
          :after-recording="afterRecCallback"
          :select-record="recCallback"
          :before-upload="recCallback"
          :successful-upload="recCallback"
          :mic-failed="recMicFailed"
          :failed-upload="recCallback"
        >
        </Audio-Recorder>
        </v-col>
      </v-row>
      <v-row v-if="show === true" dense class="mb-0">
        <v-col>
          <!-- v-btn
              small
              class="ml-0 mt-0 mb-4"
              text
              @click="removeVoice()"
            >
              More...
            </v-btn -->
          <audio v-if="soundMsg.url" controls><source :src="soundMsg.url"></audio>
          <!-- audio-player :theme="theme" :src="mp3" v-if="!showRecorder"/ -->
          <!-- audio-player v-if="soundMsg.url"
            controls
            :src="soundMsg.url"
          ></audio-player -->
        </v-col>
      </v-row>
  </div>
</template>

<script>
import AudioRecorder from "@/components/Recorder.vue";
// import AudioPlayer from './Player';

export default {
  emits: ["new-voice-msg", "remove-voice-msg"],
  props: ['platform', 'audiotype', 'show', 'theme'],
  components: {
    AudioRecorder,
    // AudioPlayer
  },
  data() {
    return {
      iosaudioshow: false,
      recordedMsg: "/demo/example.mp3",
      mp3: "/demo/example.mp3",
      mp3Soundfile: null,
      soundMsg: {
          file: null,
          url: "",
          id: 0,
          title: "",
          type: "audio/mpeg",
          size: 0,
          msg: "",
          file2: null,
      },
      headers: {
        "X-Custom-Header": "some data"
      }
    }
  },
  mounted() {
    console.log('RecordWeb ' + this.platform);
    // console.log(this.platform);
  },
  methods: {
    newVoiceMessage() {
      this.$emit("new-voice-msg", this.soundMsg);
    },
    recCallback(msg) {
      console.log("Event: ", msg);
    },
    recMicFailed(msg) {
      console.log("mic failed ", msg);
    },
    recPausCallback(msg) {
      console.log("Event: ", msg);
      this.soundMsg.msg = msg;
      // this.soundMsg.file = msg
      // this.showRecorder = false
    },
    afterRecCallback(msg) {
      console.log("Event: ", msg);
      this.soundMsg.file = msg.blob;
      this.soundMsg.url = msg.url;
      this.soundMsg.size = msg.blob.size;
      this.soundMsg.type = msg.blob.type;
      this.soundMsg.msg = "";
      this.newVoiceMessage()
      // const reader = new FileReader();
      // const furl = reader.readAsDataURL(this.soundMsg.file);
      // var tmpfile = window.URL.createObjectURL(msg.blob);
      // this.showrecorder = false;
    }
  }
};
</script>

<style></style>
