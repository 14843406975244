import Api from "@/services/Api";

export default {
  getVoiceMessage(voiceid) {
    return Api().get("getvoicemessage/", {
      params: {
        id: voiceid
      }
    });
  },

  getVoiceDataMessage(voiceid) {
    return Api().get("getvoicedatamessage/", {
      params: {
        id: voiceid
      }
    });
  },

  saveVoiceMessage(credentials) {
    return Api().post("savevoicemessage", credentials);
  },

  saveVoiceDataMessage(credentials) {
    return Api().post("savevoicedatamessage", credentials);
  }
};
