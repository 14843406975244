<template>
  <!-- class="overflow-hidden" height="100%" margin-bottom: 20px;-->
  <!-- v-app id="inspire" :dark="selectedTheme" -->
  <!-- :dark="selectedTheme" -->
  <!-- flat :dark="selectedDark" :light="selectedLight" -->
  <v-card
    flat
    :dark="selectedTheme"
    class="overflow-y-auto"
    v-scroll.self="onScroll"
    height="100%"
  >
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <v-list-group
          v-if="this.UserSignedIn === true && this.User.coach > 0"
          :value="false"
          prepend-icon="mdi-card-account-details-outline"
        >
          <template v-slot:activator>
            <v-list-item-title>My Business</v-list-item-title>
          </template>

          <v-list-item
            v-if="this.UserSignedIn === true && this.AdminAccess === true"
            @click="checkDefaultCompany()"
          >
            <v-list-item-action>
              <v-icon>mdi-cogs</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text">
                {{ companyadmin }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="this.UserSignedIn === true && this.User.coach > 0"
            @click="companyWindow()"
          >
            <v-list-item-action>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >Create Company</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="this.UserSignedIn === true && this.User.coach > 0"
            @click="coachWindow()"
          >
            <v-list-item-action>
              <v-icon>mdi-account-cog-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >My Coach Settings</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- v-list-item
          v-if="this.UserSignedIn === true"
          @click.stop="calenderdialog = true"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Calender</v-list-item-title>
          </v-list-item-content>
        </v-list-item -->
        <v-list-item
          v-if="this.UserSignedIn === true"
          @click.stop="calenderdialognew = true"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Calender</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="this.UserSignedIn != true"
          @click="userdialog = !userdialog"
        >
          <!-- v-btn small
            @click="userdialog = !userdialog"
          >
            <span>Register</span>
            <v-icon>mdi-edit-account</v-icon>
          </v-btn -->
          <v-list-item-action>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Register</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-if="this.UserSignedIn === true"
          :value="false"
          prepend-icon="mdi-account-edit"
        >
          <template v-slot:activator>
            <v-list-item-title>My Profile Settings</v-list-item-title>
          </template>
          <v-list-item @click="manageProfile()">
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text">My Data</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- v-list-item>
            <v-list-item-action @click="manageMemberships()">
              <v-icon>mdi-account-box-multiple-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >My Memberships</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item -->
          <v-list-item @click="manageUserActivities()">
            <v-list-item-action>
              <v-icon>mdi-step-forward</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >My Activities</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="manageUserSkills()">
            <v-list-item-action>
              <v-icon>mdi-arm-flex-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >My Skills</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="manageFacilities()">
            <v-list-item-action>
              <v-icon>mdi-city-variant-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >My Facilities</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="manageImages()">
            <v-list-item-action>
              <v-icon>mdi-camera-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >My Images</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-if="this.UserSignedIn === true"
          @click="manageGroupOffers()"
        >
          <v-list-item-action>
            <v-icon>mdi-handshake-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>My Offers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-if="this.UserSignedIn === true"
          :value="false"
          prepend-icon="mdi-lifebuoy"
        >
          <template v-slot:activator>
            <v-list-item-title>Help</v-list-item-title>
          </template>
          <v-list-item @click="feedbackdialog = !feedbackdialog">
            <v-list-item-action>
              <v-icon>mdi-message-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >Send feedback</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="helpdialog = !helpdialog">
            <v-list-item-action>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text">Help</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          v-if="this.UserSignedIn === true"
          :value="false"
          prepend-icon="mdi-cog-outline"
        >
          <template v-slot:activator>
            <v-list-item-title>Settings</v-list-item-title>
          </template>
          <!-- v-list-item @click="setupdialog = !setupdialog">
            <v-list-item-action>
              <v-icon>mdi-view-module-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >Notifications</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item -->
          <v-list-item @click="managePassword()">
            <v-list-item-action>
              <v-icon>mdi-form-textbox-password</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text"
                >Password</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="manageThemes">
            <v-list-item-action>
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="green--text">Themes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item @click="aboutApp()">
          <v-list-item-action>
            <v-icon>mdi-cellphone-link</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>About App</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="privacyPolicy()">
          <v-list-item-action>
            <v-icon>mdi-file-document-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Privacy Policy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="this.userid === 12" @click="testdialog">
          <v-list-item-action>
            <v-icon>mdi-cellphone-link</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Test</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- V App Bar -----hide-on-scroll shrink-on-scroll--------------------------------------->
    <!-- scroll-target="#scrolling-techniques-2" hide-on-scroll -->
    <!-- :dark="selectedTheme" -->
    <!-- gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)" hide-on-scroll -->
    <v-app-bar
      :dark="selectedTheme"
      extension-height="50"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      src="https://findhavefun.com/images/erasmus-plus-sport.jpg"
    >
      <v-app-bar-nav-icon class="mt-3 ml-n2" @click.stop="drawer = !drawer">
        <!-- v-img size="32px" src="https://findhavefun.com/images/court1_logo.jpg"> </v-img -->
      </v-app-bar-nav-icon>
      <!-- gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)" -->
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>
      <!-- -->
      <!-- img src="https://findhavefun.com/images/COURT1_logo2.jpg" -->
      <!-- :class="$vuetify.breakpoint.xs ? headtitle-xs : headtitle-lg" -->
      <v-spacer></v-spacer>
      <v-toolbar-title
        style="width: 230px; font-size: 1.8em"
        class="ml-0 mt-2 pl-4 font-italic font-weight-bold white--text"
      >
        <span>Find Have Fun</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu
        v-model="menuB"
        :close-on-content-click="true"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="UserSignedIn === false"
            class="ml-3"
            color="blue darken-2"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ signinbutton }}
            <v-icon right>
              mdi-menu-down
            </v-icon>
          </v-btn>
          <!-- span class="white--text headline">{{ user.initials }}</span -->
          <v-btn v-else icon v-bind="attrs" v-on="on">
            <v-avatar :size="profileavatarsize">
              <v-img :src="myprofileimage"></v-img>
            </v-avatar>
            <!-- v-icon class="mr-n3 mt-10">mdi-menu-down</v-icon -->
          </v-btn>
        </template>
        <!-- v-icon color="blue darken-2" dark size="48">mdi-account-circle</v-icon 
            @click="signindialog = !signindialog" -->
        <v-list>
          <v-list-item v-if="UserSignedIn === false" @click="loginDialog">
            <v-list-item-title>Sign In</v-list-item-title>
          </v-list-item>
          <v-list-item v-else @click="SignOut()">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="UserSignedIn === true"
            @click="setactivedialog = !setactivedialog"
          >
            <v-list-item-title>Set Activity</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="UserSignedIn === false"
            @click="userdialog = !userdialog"
          >
            <v-list-item-title>Register</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <template v-slot:extension>
        <!-- next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows -->
        <!-- align-with-title fixed-tabs s -->
        <v-tabs
          fixed-tabs
          color="blue"
          class="font-weight-bold"
          v-model="menu_tabs"
          text
          centered
        >
          <v-tab
            href="#tab-find"
            style="font-size: 12px; font-weight: bold; color: white"
          >
            <v-btn small elevation="8" color="primary">
              Find
            </v-btn>
            <!-- v-icon :color="tabiconcolor" v-bind="tabmenupbtn"
                >mdi-account-search-outline</v-icon -->
          </v-tab>
          <v-tab
            href="#tab-messages"
            style="font-size: 12px; font-weight: bold; color: white"
          >
            <v-btn small elevation="8" color="primary">
              Chat
            </v-btn>
            <!-- v-icon :color="tabiconcolor" v-bind="tabmenupbtn"
                >mdi-message-processing-outline</v-icon -->
          </v-tab>
          <v-tab
            href="#tab-forum"
            style="font-size: 12px; font-weight: bold; color: white"
          >
            <v-btn small elevation="8" color="primary">
              Forum
            </v-btn>
            <!-- v-icon :color="tabiconcolor" v-bind="tabmenupbtn"
                >mdi-forum-outline</v-icon
              -->
          </v-tab>
          <v-tab
            href="#tab-main"
            style="font-size: 12px; font-weight: bold; color: white"
          >
            <v-btn small elevation="8" color="primary">
              Info
            </v-btn>
            <!-- v-icon :color="tabiconcolor" v-bind="tabmenupbtn"
                >mdi-information-outline</v-icon
              -->
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <!-- END V App Bar ---------------------------------------------------------------->
    <!-- TAB FIND ------------------------------------------------------->
    <v-tabs-items :value="menu_tabs" touchless>
      <v-tab-item value="tab-find">
        <v-card v-if="this.UserSignedIn === false" flat :dark="selectedTheme">
          <v-row dense>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-card class="ma-2">
                <!-- v-card-title class="ma-0 pa-0 text-center">Find friends</v-card-title -->
                <v-card-text class="text-h6 text-center mb-n6"
                  >Find Friends</v-card-text
                >
                <v-card-text>
                  Here you will search and find new friends to enjoy activities
                  with. Register to be able send friend requests. Now you can
                  try to search but you can't send friend requests without
                  register first.
                </v-card-text>
                <v-btn text color="blue" @click="userdialog = !userdialog"
                  >Register Your Account Here
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
          </v-row>
        </v-card>
        <!-- :dark="selectedTheme" -->
        <v-card :dark="selectedTheme" class="mt-0" flat>
          <!-- v-container fluid class="ma-0 pa-0" -->
          <v-row v-if="this.UserSignedIn === true" dense>
            <v-col cols="1" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="8">
              <v-item-group v-model="selectedSeachPart0">
                <v-row dense>
                  <!-- color="green lighten-2" -->
                  <v-toolbar flat class="mt-4">
                    <v-select
                      dense
                      class="mb-n8 mr-2"
                      v-model="selectedActivity"
                      :items="myactivitylist"
                      item-text="name"
                      item-value="id"
                      label="activities"
                      v-show="selectedSeachPart0"
                      :error-messages="errorsearchactivitymsg"
                    ></v-select>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      color="primary"
                      @click="dialogSelectGender = true"
                      class="mt-1 mr-1 pa-2"
                    >
                      Gender
                    </v-btn>
                    <v-btn
                      small
                      color="primary"
                      @click="dialogSelectAge = true"
                      class="mt-1  mr-1 pa-2"
                    >
                      Age
                    </v-btn>
                    <v-btn
                      small
                      color="primary"
                      @click="dialogSelectName = true"
                      class="mt-1  mr-1 pa-2"
                    >
                      Name
                    </v-btn>
                    <v-btn
                      small
                      color="primary"
                      @click="dialogSelectMore = true"
                      class="mt-1 pa-2"
                    >
                      More
                    </v-btn>
                  </v-toolbar>
                </v-row>
                <v-col cols="12">
                  <!-- class="mt-n5" -->
                  <v-list dense class="mt-0">
                    <v-list-group
                      class="ma-0 pa-0"
                      v-for="item in userlist"
                      @click="createFriendRequestMessage(item)"
                      v-model="item.dialog"
                      :key="item.id"
                      no-action
                      active-class="green lighten-3"
                    >
                      <template v-slot:activator>
                        <v-list-item-avatar>
                          <v-img
                            class="ma-0 mr-2 pa-0"
                            :src="item.avatar"
                          ></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle v-text="item.description">
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>

                      <v-list-item class="ml-n16">
                        <v-list-item-content>
                          <v-card
                            :dark="selectedTheme"
                            flat
                            class="ma-0 mb-0 pa-0"
                            max-width="800"
                          >
                            <v-row no-gutters>
                              <v-textarea
                                class="ma-0 pa-0"
                                dense
                                solo
                                auto-grow
                                rows="1"
                                name="input-7-1"
                                label="friend request message"
                                v-model="sendfriendrequestmessage"
                              >
                              </v-textarea>
                              <v-btn
                                small
                                class="ml-3 mt-2 mb-0 pa-0"
                                icon
                                @click="connectmsgrequest()"
                                color="primary"
                              >
                                <v-icon size="32px"
                                  >mdi-email-send-outline</v-icon
                                >
                              </v-btn>
                            </v-row>
                          </v-card>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-col>
              </v-item-group>
              <v-bottom-navigation
                horizontal
                hide-on-scroll
                fixed
                flat
                v-model="searchbottommessages"
              >
                <v-btn @click="resetSearch()">
                  <span>Reset</span>
                  <v-icon color="red">mdi-account-search-outline</v-icon>
                </v-btn>
                <v-btn @click="searchhelpdialog = !searchhelpdialog">
                  <span>Info</span>
                  <v-icon color="yellow">mdi-help-circle</v-icon>
                </v-btn>
              </v-bottom-navigation>
            </v-col>
            <v-col cols="1" xs="0" sm="0" md="2"></v-col>
          </v-row>
          <!-- /v-container -->
        </v-card>
      </v-tab-item>
      <!-- TAB MESSAGES type="padding-bottom" ------------------------------------------------------->
      <v-tab-item flat value="tab-messages">
        <v-card v-if="this.UserSignedIn === false" :dark="selectedTheme" flat>
          <v-row v-if="this.UserSignedIn === false" dense>
            <v-col cols="1" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-card class="ma-2">
                <v-card-text class="text-h6 text-center mb-n6"
                  >Messages</v-card-text
                >
                <v-card-text>
                  Here you will find and manage all your messages from friends,
                  and friends in groups. You will also be able to add groups and
                  manage groups.
                </v-card-text>
                <v-btn text color="blue" @click="userdialog = !userdialog"
                  >Register Your Account Here
                </v-btn>
                <v-btn text color="blue" @click="loginDialog"
                  >Login Here
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="1" xs="0" sm="0" md="2"></v-col>
          </v-row>
        </v-card>
        <!-- :dark="selectedTheme" -->
        <v-card v-else :dark="selectedTheme" flat>
          <v-container fluid class="mb-20 mt-0 ml-0 mr-0 pa-0">
            <v-row dense v-if="this.UserSignedIn === true" class="mt-3">
              <v-col cols="1" xs="0" sm="0" md="3"></v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <div v-if="myfriendslistsheader === 'FRIENDS'"></div>
                <div v-else-if="myfriendslistsheader === 'GROUPS'"></div>
                <div v-else-if="myfriendslistsheader === 'REQUESTS'">
                  <!-- REQUSTS ------------------------------------------------>
                  <!-- color="orange lighten-4" -->
                  <v-toolbar dense flat>
                    <v-row>
                      <v-col>
                        <v-btn text color="red" class="ma-3 pa-0">
                          REQUESTS
                        </v-btn>
                      </v-col>
                      <v-col> </v-col>
                      <v-col> </v-col>
                    </v-row>
                  </v-toolbar>
                </div>
                <div v-else-if="myfriendslistsheader === 'WAITING'">
                  <!-- WAITING ------------------------------------------------>
                  <!-- color="#FFB74D" -->
                  <v-toolbar flat dense>
                    <v-row>
                      <v-col>
                        <v-btn text color="red" class="ma-3 pa-0">
                          WAITING-MESSAGES
                        </v-btn>
                      </v-col>
                      <v-col> </v-col>
                      <v-col> </v-col>
                    </v-row>
                  </v-toolbar>
                </div>

                <v-container fluid class="ma-0 pa-0">
                  <!-- ELSE GROUPS active-class="light-blue lighten-4" ---------------------------------------------------------->

                  <v-list dense v-if="myfriendslistsheader === 'GROUPS'">
                    <Groups
                      :activeactivity="ActiveActivityID"
                      :userid="userid"
                      :GroupBadge="GroupBadge"
                      :theme="selectedTheme"
                      :platform="platform"
                      @update-group-badge="updateGroupBadge"
                    >
                    </Groups>
                  </v-list>

                  <!-- ELSE FRIENDS max-height="460px" class="overflow-y-auto" ----------------------------------------------------->
                  <v-list dense v-else-if="myfriendslistsheader === 'FRIENDS'">
                    <Friends
                      :activeactivity="ActiveActivityID"
                      :userid="userid"
                      :FriendBadge="FriendBadge"
                      :theme="selectedTheme"
                      :platform="platform"
                      @update-friend-badge="updateFriendBadge"
                    >
                    </Friends>
                  </v-list>
                  <!-- ELSE REQUESTS  -------------------->
                  <v-list dense v-else-if="myfriendslistsheader === 'REQUESTS'">
                    <v-list-item-group v-model="selectedfriend">
                      <v-list-item
                        v-for="item in userallrequests"
                        :key="item.createdAt"
                      >
                        <template
                          v-slot:default="{ active }"
                          :input-value="active"
                        >
                          <v-list-item-avatar v-if="item.type === 'friend'">
                            <v-img
                              class="ma-0 pa-0"
                              :src="item.Friends.User.avatar"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-avatar v-else>
                            <v-img
                              class="ma-0 pa-0"
                              :src="item.UserGroups.Group.avatar"
                            ></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content v-if="item.type === 'friend'">
                            <v-list-item-title
                              v-text="item.Friends.User.name"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.Friends.User.description"
                            >
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-text="item.Friends.message">
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-content v-else>
                            <v-list-item-title
                              v-text="item.UserGroups.Group.name"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.UserGroups.Group.description"
                            >
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                              v-text="item.UserGroups.Group.message"
                            >
                            </v-list-item-subtitle>

                            <!-- v-list-item-subtitle v-if="item.type === 'friend'" v-text="item.Friends.message">
                            </v-list-item-subtitle -->
                          </v-list-item-content>

                          <v-list-item-action v-if="item.type === 'friend'">
                            <v-btn
                              icon
                              small
                              color="primary"
                              :input-value="active"
                              @click="approveFriendRequest(item)"
                              class="ma-0 pa-0"
                            >
                              <v-icon size="24px">mdi-plus-circle</v-icon>
                            </v-btn>
                          </v-list-item-action>

                          <v-list-item-action v-if="item.type === 'friend'">
                            <v-btn
                              icon
                              small
                              color="error"
                              :input-value="active"
                              @click="removeFriendRequest(item)"
                              class="ma-0 pa-0"
                            >
                              <v-icon size="24px">mdi-minus-circle</v-icon>
                            </v-btn>
                          </v-list-item-action>

                          <v-list-item-action v-if="item.type === 'group'">
                            <v-btn
                              icon
                              small
                              color="primary"
                              :input-value="active"
                              @click="approveGroupRequest(item)"
                              class="ma-0 pa-0"
                            >
                              <v-icon size="24px">mdi-plus-circle</v-icon>
                            </v-btn>
                          </v-list-item-action>

                          <v-list-item-action v-if="item.type === 'group'">
                            <v-btn
                              icon
                              small
                              color="error"
                              :input-value="active"
                              @click="removeGroupRequest(item)"
                              class="ma-0 pa-0"
                            >
                              <v-icon size="24px">mdi-minus-circle</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-container>

                <v-bottom-navigation fixed flat v-model="bottommessages">
                  <v-btn @click="getFriends(true)">
                    <span>Friends</span>
                    <v-badge
                      small
                      v-if="FriendBadge > 0"
                      class="ma-0 pa-0"
                      color="blue"
                      :content="FriendBadge"
                      overlap
                    >
                      <v-icon color="light-green"
                        >mdi-account-multiple-outline</v-icon
                      >
                    </v-badge>
                    <v-icon v-else color="light-green"
                      >mdi-account-multiple-outline</v-icon
                    >
                  </v-btn>
                  <v-btn @click="getGroups()">
                    <span>Groups</span>
                    <v-badge
                      small
                      v-if="GroupBadge > 0"
                      class="ma-0 pa-0"
                      color="blue"
                      :content="GroupBadge"
                      overlap
                    >
                      <v-icon color="lime">mdi-account-group</v-icon>
                    </v-badge>
                    <v-icon v-else color="lime">mdi-account-group</v-icon>
                  </v-btn>
                  <v-btn @click="getAllRequests">
                    <span>Requests</span>
                    <v-badge
                      small
                      v-if="RequestBadge > 0"
                      class="ma-0 pa-0"
                      color="blue"
                      :content="RequestBadge"
                      overlap
                    >
                      <v-icon color="orange"
                        >mdi-account-multiple-plus-outline</v-icon
                      >
                    </v-badge>
                    <v-icon v-else color="orange"
                      >mdi-account-multiple-plus-outline</v-icon
                    >
                  </v-btn>
                  <v-btn @click="waitingRequests">
                    <span>Waiting</span>
                    <v-icon color="error"
                      >mdi-account-multiple-plus-outline</v-icon
                    >
                  </v-btn>
                </v-bottom-navigation>
              </v-col>
              <v-col cols="1" xs="0" sm="0" md="3" lg="3"></v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-forum">
        <!-- :dark="selectedTheme" -->
        <v-card v-if="this.UserSignedIn === false" :dark="selectedTheme" flat>
          <v-row v-if="this.UserSignedIn === false" dense>
            <v-col cols="1" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-card class="ma-2">
                <v-card-text class="text-h6 text-center mb-n6"
                  >Forum</v-card-text
                >
                <v-card-text>
                  Here you will manage and find posts from people with same
                  activity interests.
                </v-card-text>
                <v-btn text color="blue" @click="userdialog = !userdialog"
                  >Register Your Account Here
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="2" xs="0" sm="0" md="2" lg="2"></v-col>
          </v-row>
        </v-card>
        <v-card flat v-else :dark="selectedTheme" class="ma-0 pa-0">
          <v-row dens no-gutters>
            <v-col cols="2" xs="0" sm="0" md="2" lg="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8">
              <!-- v-row dense class="mt-2" -->
              <NewsFeeds
                ref="NewsFeeds"
                :userid="this.userid"
                friends="1"
                :activityid="this.ActiveActivityID"
                :theme="selectedTheme"
              ></NewsFeeds>
              <!-- /v-row -->
              <v-row>
                <v-col cols="12">
                  <br />
                  <v-bottom-navigation
                    fixed
                    color="grey"
                    v-model="bottomtabforum"
                    background-color="teal lighten-4"
                    shift
                  >
                    <v-btn
                      class="mt-2"
                      icon
                      href="https://www.facebook.com/Findhavefun-111220730734744"
                    >
                      <v-icon color="blue darken-3" size="32px"
                        >mdi-facebook</v-icon
                      >
                    </v-btn>
                    <v-btn
                      class="mx-4"
                      icon
                      href="https://www.instagram.com/?hl=en"
                    >
                      <v-icon color="red darken-1" size="32px"
                        >mdi-instagram</v-icon
                      >
                    </v-btn>
                    <v-btn
                      class="mx-4"
                      icon
                      href="https://twitter.com/FindHaveFun1"
                    >
                      <v-icon color="blue" size="32px">mdi-twitter</v-icon>
                    </v-btn>
                    <v-btn class="mx-4" icon>
                      <v-icon size="32px">mdi-snapchat</v-icon>
                    </v-btn>
                  </v-bottom-navigation>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" xs="0" sm="0" md="2" lg="2"></v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-main">
        <!-- :dark="selectedTheme" -->
        <v-card :dark="selectedTheme" flat>
          <v-container fluid>
            <v-row>
              <v-col cols="4" sm="4">
                <v-card flat>
                  <v-img
                    src="https://findhavefun.com/images/padelmaxres.jpg"
                    lazy-src="https://findhavefun.com/images/padelmaxres.jpg"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="300"
                    max-width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="4" sm="4">
                <v-card flat>
                  <v-img
                    src="https://findhavefun.com/images/Tennis3girls.jpg"
                    lazy-src="https://findhavefun.com/images/Tennis3girls.jpg"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="300"
                    max-width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="4" sm="4">
                <v-card flat>
                  <v-img
                    src="https://findhavefun.com/images/231410-1600x1066-cheerleading.jpg"
                    lazy-src="https://findhavefun.com/images/231410-1600x1066-cheerleading.jpg"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="300"
                    max-width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="4" sm="4">
                <v-card flat>
                  <v-img
                    src="https://findhavefun.com/images/friendsgolf.jpg"
                    lazy-src="https://findhavefun.com/images/friendsgolf.jpg"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="300"
                    max-width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="4" sm="4">
                <v-card flat>
                  <v-img
                    src="https://findhavefun.com/images/friendsyoga.jpg"
                    lazy-src="https://findhavefun.com/images/friendsyoga.jpg"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="300"
                    max-width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="4" sm="4">
                <v-card flat>
                  <v-img
                    src="https://findhavefun.com/images/friendsmountainbike.jpg"
                    lazy-src="https://findhavefun.com/images/friendsmountainbike.jpg"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="300"
                    max-width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="4" sm="4">
                <v-card flat>
                  <v-img
                    src="https://findhavefun.com/images/friendstravel.jpg"
                    lazy-src="https://findhavefun.com/images/friendstravel.jpg"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="300"
                    max-width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="4" sm="4">
                <v-card flat>
                  <v-img
                    src="https://findhavefun.com/images/friendssquash.jpg"
                    lazy-src="https://findhavefun.com/images/friendssquash.jpg"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="300"
                    max-width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <v-col cols="4" sm="4">
                <v-card flat>
                  <v-img
                    src="https://findhavefun.com/images/backpackwalking.jpg"
                    lazy-src="https://findhavefun.com/images/backpackwalking.jpg"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    max-height="300"
                    max-width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-bottom-navigation
                fixed
                color="grey"
                v-model="bottommain"
                background-color="teal lighten-4"
              >
                <v-btn
                  class="mx-4"
                  icon
                  href="https://www.facebook.com/Findhavefun-111220730734744"
                >
                  <v-icon color="blue darken-3" size="32px"
                    >mdi-facebook</v-icon
                  >
                </v-btn>
                <v-btn
                  class="mx-4"
                  icon
                  href="https://www.instagram.com/?hl=en"
                >
                  <v-icon color="red darken-1" size="32px"
                    >mdi-instagram</v-icon
                  >
                </v-btn>
                <v-btn
                  class="mx-4"
                  icon
                  href="https://twitter.com/FindHaveFun1"
                >
                  <v-icon color="blue" size="32px">mdi-twitter</v-icon>
                </v-btn>
                <v-btn class="mx-4" icon>
                  <v-icon size="32px">mdi-snapchat</v-icon>
                </v-btn>
              </v-bottom-navigation>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!-- END VTAB ITEMS ----------------------------------------------------------------->

    <!-- COACH Settings width="600px" ---------------------------------------------------------->
    <v-dialog
      :dark="selectedTheme"
      persistent
      v-model="usercoachdialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="dialogwidth"
    >
      <v-card color="white">
        <v-card-text>
          <div><br /></div>
          <div class="my-4 subtitle-1">
            Select the companies you working with or create a company from left
            menu.
          </div>
        </v-card-text>
        <v-form>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-select
                  dense
                  v-model="selectedCoachActivity"
                  :items="interestlist"
                  item-text="name"
                  item-value="id"
                  label="Activity"
                ></v-select>
                <br />
                <v-autocomplete
                  dense
                  chips
                  deletable-chips
                  small-chips
                  v-model="selectedcompanies"
                  :items="companylist"
                  color="blue-grey lighten-2"
                  label="Add Company"
                  item-text="name"
                  item-value="id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.avatar"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <br />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="saveUserCoach()">
            Save
          </v-btn>
          <v-btn small color="error" @click="cancelUserCoach()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG USER ACTIVITY CREATE ------------------------------------------------->
    <v-dialog
      persistent
      v-model="useractivitydialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="dialogwidth"
    >
      <v-card :dark="selectedTheme">
        <v-card-text>
          <div><br /></div>
          <div class="my-4 subtitle-1">
            Here you select the activities you want to be active with.
          </div>
          <p class="text--primary">
            Press the field below to get a list of activities. Close the list by
            pressing outside the list/window.
          </p>
        </v-card-text>
        <v-form>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  chips
                  deletable-chips
                  small-chips
                  v-model="selectedactivities"
                  :items="interestlist"
                  color="blue-grey lighten-2"
                  label="Add activity"
                  item-text="name"
                  item-value="id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="saveUserActivities()">
            Save
          </v-btn>
          <v-btn small color="error" @click="useractivitydialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG USER FACILITIES width="600px" ---------------------------------------------------------->
    <v-dialog
      persistent
      v-model="facilityactivitydialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="dialogwidth"
    >
      <v-card :dark="selectedTheme">
        <v-card-text>
          <div><br /></div>
          <div class="my-4 subtitle-1">
            Here you select the facilties you want to use
          </div>
          <p class="text--primary">
            Press the field below to get a list of activities. Then select
            Facilities. If you dont find your Facility then Search to find your
            Facility and it will be added to FindHaveFun
          </p>
        </v-card-text>
        <v-form>
          <v-container>
            <v-row class="mx-0">
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="selectedFacilityActivity"
                  :items="interestlist"
                  color="blue-grey lighten-2"
                  label="Select activity"
                  item-text="name"
                  item-value="id"
                  class="ma-0 pa-0"
                >
                  <!-- template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      <v-avatar dense small left>
                        <v-img :src="data.item.avatar"></v-img >
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template -->
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img small :src="data.item.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="selectedmyfacilities"
                  :items="activityfacilities"
                  chips
                  deletable-chips
                  small-chips
                  color="blue-grey lighten-2"
                  label="Select facilities"
                  item-text="name"
                  item-value="id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <!-- template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template -->
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-col cols="12">
                <!-- Test adding google search for facilities --------------------------------------- -->
                <vuetify-google-autocomplete
                  :rules="facilityRules"
                  ref="addressFacility"
                  id="map3"
                  classname="form-control"
                  placeholder="City Activity or Activity City"
                  label=""
                  v-on:placechanged="getFacilityHomeCity"
                  :country="countries"
                  :types="placetypes"
                >
                </vuetify-google-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="saveUserFacilities()">
            Save
          </v-btn>
          <v-btn small color="error" @click="facilityactivitydialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG USER MEMBERSHIPS width="600px" ---------------------------------------------------------->
    <v-dialog persistent v-model="membershipdialog" :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-card-text>
          <div><br /></div>
          <div class="my-4 subtitle-1">
            Here you select the memberships you have
          </div>
          <p class="text--primary">
            Select Company / Academy / Club
          </p>
        </v-card-text>
        <v-form>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="selectedmymemberships"
                  :items="mymembershipcompanies"
                  deletable-chips
                  small-chips
                  color="blue-grey lighten-2"
                  label="My Memberships"
                  item-text="Company.name"
                  item-value="Company.id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  dense
                  v-model="selectednewmembership"
                  :items="membershipcompanies"
                  deletable-chips
                  small-chips
                  color="blue-grey lighten-2"
                  label="Add Membership"
                  item-text="name"
                  item-value="id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="saveMemberships()">
            Save
          </v-btn>
          <v-btn small color="error" @click="membershipdialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG GROUP CREATE width="600px" ---------------------------------------------------------->
    <v-dialog
      persistent
      v-model="groupcreatedialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="dialogwidth"
    >
      <v-card :dark="selectedTheme">
        <v-form>
          <v-container>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  required
                  v-model="creategroupname"
                  filled
                  color="blue-grey lighten-2"
                  label="Name"
                  class="ma-0 pa-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  required
                  v-model="creategrouptitle"
                  filled
                  color="blue-grey lighten-2"
                  label="Title"
                ></v-text-field>
              </v-col>
              <!-- v-select dense
                v-model="selectedGroupActivity"
                :items="interestlist"
                item-text="name"
                item-value="id"
                label="Activities"
                class="ma-0 pa-0"
                :rules="[rules.required]"
                ></v-select -->
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="selectedcreategroupuser"
                  :items="friendlist"
                  small-chips
                  color="blue-grey lighten-2"
                  label="Add to group"
                  item-text="User.name"
                  item-value="name"
                  multiple
                  class="ma-0 pa-0"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small-chips
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.User.avatar"></v-img>
                      </v-avatar>
                      {{ data.item.User.name }}
                    </v-chip>
                  </template>
                  <!-- template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.User.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.User.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.User.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template -->
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="saveGroup()">
            Save
          </v-btn>
          <v-btn small color="error" @click="groupcreatedialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG GROUP DELETE CONTROL ---------------------------------------------------------->
    <v-dialog v-model="groupdeletedialog" persistent max-width="200px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-text>Delete Group, Are You Sure ? </v-card-text>
          <v-divider></v-divider>
        </v-container>
        <v-card-actions class="bt-n10 ma-0 pa-0">
          <v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="error"
            @click="deleteGroup()"
          >
            YES Delete
          </v-btn>
          <v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="blue"
            @click="groupdeletedialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG GROUP UPDATE ---------------------------------------------------------->
    <v-dialog
      persistent
      v-model="groupupdatedialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="dialogwidth"
    >
      <v-card :dark="selectedTheme">
        <v-form>
          <v-container>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  disabled
                  v-model="updgroupname"
                  filled
                  color="blue-grey lighten-2"
                  label="Name"
                  class="ma-0 pa-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  disabled
                  v-model="updgrouptitle"
                  filled
                  color="blue-grey lighten-2"
                  label="Title"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="selectedupdgroupusers"
                  :items="groupusers"
                  small-chips
                  color="blue-grey lighten-2"
                  label="Select"
                  item-text="User.name"
                  item-value="User.id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <!-- @click:close="remove(data.item)"  close -->
                  <template v-slot:selection="data">
                    <v-chip
                      small-chips
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.User.avatar"></v-img>
                      </v-avatar>
                      {{ data.item.User.name }}
                    </v-chip>
                  </template>
                  <!-- template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.User.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.User.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.User.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template -->
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="addedupdgroupusers"
                  :items="friendlist"
                  small-chips
                  color="blue-grey lighten-2"
                  label="Add to group"
                  item-text="User.name"
                  item-value="User.id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <!--  @click:close="remove(data.item)" -->
                  <template v-slot:selection="data">
                    <v-chip
                      small-chips
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.User.avatar"></v-img>
                      </v-avatar>
                      {{ data.item.User.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.User.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.User.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.groupid"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="savegroupUpdate()">
            Update
          </v-btn>
          <v-btn small color="error" @click="resetgroupUpdate()">
            Close
          </v-btn>
          <v-btn small color="warning" @click="deleteGroupControl()">
            Delete
          </v-btn>
          <v-btn small color="warning" @click="leaveGroup()">
            Leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG COMPANY REGISTER ---------------------------------------------------------->
    <v-dialog
      persistent
      v-model="companydialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="60%"
    >
      <v-card :dark="selectedTheme">
        <v-card-title>Create Company</v-card-title>
        <v-container>
          <v-row class="mx-2">
            <v-col cols="6">
              <v-text-field
                dense
                required
                v-model="companyname"
                :rules="nameRules"
                placeholder="Name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                dense
                required
                v-model="selectedcompanytype"
                :items="companytypes"
                label="company type"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                required
                v-model="companyemail"
                :rules="emailRules"
                placeholder="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                required
                v-model="companycountrycode"
                placeholder="Country Code"
                counter="2"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                required
                v-model="companyphone"
                placeholder="Phone"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                required
                v-model="companyadress"
                placeholder="Adress"
              ></v-text-field>
            </v-col>
            <v-col cols="7">
              <vuetify-google-autocomplete
                dense
                :rules="cityRules"
                ref="companyaddress"
                id="map4"
                classname="form-control"
                placeholder="Place Country"
                v-on:placechanged="getCompanyCity"
                :country="countries"
                :types="placetypes"
              >
              </vuetify-google-autocomplete>
              <!-- v-text-field dense required 
                v-model="companycity" 
                placeholder="City"></v-text-field -->
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                required
                v-model="companyzipcode"
                placeholder="Zipcode"
              ></v-text-field>
            </v-col>
            <!-- v-col cols="5">
              <v-text-field dense required 
                v-model="companystate" 
                placeholder="State"></v-text-field>
            </v-col -->
            <v-col cols="6">
              <v-text-field
                dense
                v-model="companywebsite"
                placeholder="Website complete url"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="companyavatar"
                placeholder="Avatar"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="companydescription"
                :error-messages="errorunidentified"
                placeholder="Note"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                dense
                required
                v-model="selectedCompanyActivity"
                :items="interestlist"
                item-text="name"
                item-value="id"
                label="Select default activity"
                :rules="activityRules"
                :error-messages="errorsearchactivitymsg"
              ></v-select>
            </v-col>
            <v-cols cols="6">
              <v-switch
                class="ml-3"
                dense
                v-model="companyactive"
                label="Company active "
              ></v-switch>
            </v-cols>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn small color="primary" @click="registerCompany()">Save</v-btn>
          <v-btn small color="error" @click="companyCanceled()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG REGISTER ---------------------------------------------------------->
    <v-dialog
      persistent
      v-model="userdialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="60%"
    >
      <v-card :dark="selectedTheme">
        <v-card-title>Create account</v-card-title>
        <v-container>
          <v-row dense class="mx-0">
            <v-col cols="7">
              <v-text-field
                required
                v-model="name"
                :rules="nameRules"
                counter="5"
                placeholder="Name"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                required
                v-model="username"
                :rules="usernameRules"
                placeholder="username"
                counter="8"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-0">
            <v-col cols="12">
              <v-text-field
                required
                autocomplete="off"
                v-model="email"
                :rules="emailRules"
                :error-messages="errorunidentified"
                placeholder="Email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-0">
            <v-col cols="6">
              <v-text-field
                autocomplete="new-password"
                v-model="password"
                :append-icon="pswshow1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.minpsw]"
                :type="pswshow1 ? 'text' : 'password'"
                placeholder="Password"
                counter="8"
                @click:append="pswshow1 = !pswshow1"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                autocomplete="new-password2"
                v-model="password2"
                :append-icon="pswshow2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="pswshow2 ? 'text' : 'password'"
                name="input-10-2"
                label="Verify password"
                class="input-group--focused"
                @click:append="pswshow2 = !pswshow2"
                :error-messages="errorpswmatch"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-0 mt-4">
            <v-col cols="3">
              <v-radio-group class="mt-n2" dense mandatory v-model="gender">
                <v-radio label="Woman" value="woman"></v-radio>
                <v-radio label="Man" value="man"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="5">
              <v-menu
                ref="personnumbermenu"
                v-model="personnumbermenu"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="personnumber"
                    label="Birthday date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="personnumber"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1930-01-01"
                  @change="savepersonnumber"
                  :error-messages="errorage"
                  locale="sv-se"
                ></v-date-picker>
              </v-menu>
              <!-- v-text-field dense required v-model="personnumber"
                :rules="personnumberRules"
                placeholder="Birthdate" counter="8"></v-text-field -->
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-if="agebelow15 === true"
                dense
                class="mt-5"
                v-model="parentusername"
                :rules="parentusernameRules"
                :error-messages="errorparentusername"
                label=""
                placeholder="Parent Username"
                counter="8"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col cols="3">
              <v-text-field
                dense
                required
                v-model="countrycode"
                placeholder="Country Code"
                counter="2"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                v-model="phone"
                placeholder="Phone"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                dense
                v-model="mobile"
                placeholder="Mobile"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col cols="12">
              <!-- v-text-field v-model="city" placeholder="City"></v-text-field -->
              <vuetify-google-autocomplete
                :rules="cityRules"
                ref="homeaddress"
                id="map3"
                classname="form-control"
                placeholder="Place Country"
                v-on:placechanged="getHomeCity"
                :country="countries"
                :types="placetypes"
              >
              </vuetify-google-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col cols="12">
              <v-text-field
                dense
                v-model="description"
                placeholder="Note"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col>
              <v-select
                v-model="selectedDefaultActivity"
                :items="interestlist"
                item-text="name"
                item-value="id"
                label="Select default activity"
                :rules="activityRules"
                :error-messages="errorsearchactivitymsg"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col cols="12">
              <v-switch
                class="ma-0 pa-0"
                v-model="IsCoach"
                label="If you are coach or have a company/club/academy"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col cols="12">
              <v-alert
                v-if="IsCoach === true"
                border="left"
                colored-border
                color="green"
                elevation="2"
              >
                After registered you have to set up a company or connect to a
                company you working with! A company could also be a club or
                academy in the activity nish you selected.
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-col cols="12">
              <v-checkbox
                class="ma-0 pa-0"
                v-model="gdpr"
                label="GDPR General Data Protection Regulation"
                color="success"
                value="1"
                :error-messages="errorgdpr"
                hide-details
              ></v-checkbox>
              <!-- ma-0 pa-0 -->
              <div class="grey--text ml-0 pl-0 pt-3">
                When checking this box I agree that FindHaveFun store my
                information like name, email, adress and information I’m
                entering when using FindHaveFun. I can whenever i want remove my
                information or take part of all information that is stored.
              </div>
            </v-col>
            <v-col cols="12">
              <!-- v-checkbox
                class="ma-0 pa-0"
                v-model="sharecontactinfo"
                label="Marketing and advertising"
                color="primary"
                value="1"
                hide-details
              ></v-checkbox -->
              <!-- ma-0 pa-0 -->
              <div class="text--h6">
                Marketing and Advertising
              </div>
              <div class="grey--text ml-0 pl-0 pt-3">
                Advertising is not showed to you. You have to yourself navigate
                to advertising tab and there you can filter what you want to see
                and take part of.
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn small color="primary" @click="register()">Save</v-btn>
          <v-btn small color="error" @click="registerCanceled()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG UPDATE ---------------------------------------------------------->
    <v-dialog
      persistent
      v-model="userupdatedialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="60%"
    >
      <v-card :dark="selectedTheme" class="ma-0 pa-0">
        <v-card-title>Update account</v-card-title>
        <v-container fluid>
          <v-row class="mx-2">
            <v-col cols="7">
              <v-text-field
                dense
                required
                v-model="UserUpd.name"
                :rules="nameRules"
                placeholder="Name"
                counter="5"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                class="ma-0 pa-0"
                dense
                disabled
                v-model="UserUpd.username"
                placeholder="username"
                counter="8"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                required
                v-model="UserUpd.email"
                :rules="emailRules"
                placeholder="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                disabled
                v-model="UserUpd.gender"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-menu
                ref="personnumbermenuupd"
                v-model="personnumbermenuupd"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="personnumber"
                    label="Birthday Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="personnumber"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1930-01-01"
                  @change="savepersonnumberupd"
                ></v-date-picker>
              </v-menu>
              <!-- v-text-field dense required v-model="UserUpd.personnumber"
                :rules="personnumberRules"
                placeholder="Birthdate" counter="8">
              </v-text-field -->
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-if="agebelow15 === true"
                dense
                class="mt-5"
                required
                v-model="parentusername"
                :rules="usernameRules"
                :error-messages="errorparentusername"
                label="Parent Username"
                counter="8"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                dense
                required
                v-model="UserUpd.countrycode"
                placeholder="Country Code"
                counter="2"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field dense v-model="UserUpd.phone" placeholder="Phone">
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field dense v-model="UserUpd.mobile" placeholder="Mobile">
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field disabled v-model="UserUpd.city" placeholder="City">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <vuetify-google-autocomplete
                ref="homeaddress"
                id="map3"
                classname="form-control"
                placeholder="Update Place Country Here ..."
                v-on:placechanged="getHomeCity"
                :country="countries"
                :types="placetypes"
              >
              </vuetify-google-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="UserUpd.description"
                :error-messages="errorsupdateactivitymsg"
                placeholder="Note"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selectedDefaultActivity"
                :items="myactivitylist"
                item-text="name"
                item-value="id"
                label="Select Default Activity"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-switch
                class="ma-0 pa-0"
                v-model="IsCoach"
                label="If you are coach or have a company/club/academy"
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <v-alert
                v-if="IsCoach === true"
                border="left"
                colored-border
                color="green"
                elevation="2"
              >
                After registered you have to set up a company or connect to a
                company you working with! A company could also be a club or
                academy in the activity nish you selected.
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn small color="primary" @click="update()">Save</v-btn>
          <v-btn small color="error" @click="userupdatedialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  DIALOG AFTER REGISTER INFO width="500px" --------------------------------------------------------->
    <v-dialog v-model="afterregisterdialog" :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-list-item-content>
          <v-alert
            v-if="agebelow15 === false"
            border="bottom"
            colored-border
            color="success"
            elevation="2"
          >
            WELCOME To Find Have Fun !! <br /><br />
            You can now login.<br /><br />
            If you have problems email support@findhavefun.com with <br />
            name, email adress, username and phone if you entered that<br />
            and we will have a look ! <br /><br />
          </v-alert>
          <v-alert
            v-else
            border="bottom"
            colored-border
            color="success"
            elevation="2"
          >
            WELCOME To Find Have Fun !! <br /><br />
            Because your age below 15 you have to enter a parent username. If a
            parent is not member one parent has to register so you can enter the
            parents username. You have 5 days to register a parent username.
            <br /><br />
            You can now login.<br /><br />
            If you have problems email support@findhavefun.com with <br />
            name, email adress, username and phone if you entered that<br />
            and we will have a look ! <br /><br />
          </v-alert>
          <!-- v-alert
            border="bottom"
            colored-border
            color="success"
            elevation="2"
          >
          WELCOME !! Thanks for register. <br/><br/>
          Before you can login you have to confirm your email adress.<br/>
          Check your spam box if you don't find the confirmation email.<br/><br/>
          If you have problems email support@findhavefun.com with <br/>
          name, email adress, username and phone if you entered that<br/>
          and we will have a look if you spelled your email wrong ! <br/><br/>
          </v-alert -->
        </v-list-item-content>
        <v-card-actions>
          <v-btn
            class="ma-0 mt-n6 pa-0"
            color="primary"
            @click="afterregisterdialog = false"
            >Close</v-btn
          >
          <!-- v-btn text color="error" @click="coachdialog = false">Cancel</v-btn -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG UPDATE SKILL width="400px" ------------------------------------------------------->
    <v-dialog persistent v-model="updateskilldialog" max-width="60%">
      <v-card :dark="selectedTheme">
        <v-form>
          <v-container>
            <v-row dense>
              <v-select
                v-model="popupselectedskill"
                :items="popupactivityskills"
                item-text="shortname"
                item-value="id"
                label="Select skill"
              ></v-select>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="saveUserActivitySkill()">
            OK
          </v-btn>
          <v-btn small color="error" @click="cancelActivitySkill()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG MY SKILLS width="600px" ---------------------------------------------------------->
    <v-dialog
      persistent
      v-model="userskillsdialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="dialogwidth"
    >
      <v-card :dark="selectedTheme">
        <v-card-title>Add or update skills</v-card-title>
        <v-list>
          <v-list-item
            dense
            v-for="item in useractivitylist"
            :key="item.idactivity"
          >
            <template v-slot:default="{ active }" :input-value="active">
              <v-list-item-avatar class="ma-0 pa-0">
                <v-img :src="item.avatar"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title v-text="item.index"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title v-text="item.shortname"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-icon class="ma-0 pa-0">
                  <v-btn
                    icon
                    small
                    :input-value="active"
                    @click="updateUserActivitySkill(item)"
                    class="ma-0 pa-0"
                  >
                    <v-icon size="24px">mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn small color="primary" @click="userskillsdialog = false"
            >Close</v-btn
          >
          <!-- v-btn color="error" @click="userskillsdialog = false">Cancel</v-btn -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG SIGNIN width="350" ---------------------------------------------------------->
    <v-dialog
      persistent
      :width="dlgsigninwitdth"
      class="v-dialog"
      v-model="signindialog"
    >
      <v-card :dark="selectedTheme">
        <v-card-title>Sign In</v-card-title>
        <v-row class="mx-2" dense>
          <v-col cols="12">
            <v-text-field
              dense
              v-model="username"
              placeholder="username"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              @click.native.stop
              :append-icon="pswshow1 ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages="errorloginmsg"
              v-model="password"
              :type="pswshow1 ? 'text' : 'password'"
              @click:append="pswshow1 = !pswshow1"
              placeholder="password"
            >
            </v-text-field>
          </v-col>
          <!-- v-btn justify="center" text color="blue" 
                  :href="'http://findhavefun.com/resetpswwindow'">
                  reset password ?
                </v-btn -->
          <!-- v-select
                    v-model="selectedActivity"
                    :items="interestlist"
                    item-text="name"
                    item-value="id"
                    label="Activities"
                    :rules="activityRules"
                    :error-messages="errorsearchactivitymsg"
                  ></v-select -->
          <v-col cols="12">
            <v-checkbox
              dense
              class="text--primary ma-1 pa-1"
              v-model="cookie"
              label="Remember my login"
              color="indigo"
              value="1"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn small color="primary" @click="login()">Sign In</v-btn>
          <v-btn
            small
            ref="signinclick"
            color="grey"
            @click="resetpassworddialog()"
            >Reset Password</v-btn
          >
          <v-btn
            small
            ref="signinclick"
            color="error"
            @click="signindialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG RESET PASSWORD SEND EMAIL width="400px" ---------------------------------------------------------->
    <v-dialog v-model="resetsendemaildialog" :max-width="dlgsigninwitdth">
      <v-card :dark="selectedTheme">
        <v-card-title>Enter your email</v-card-title>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-col cols="10">
                <v-text-field
                  dense
                  required
                  v-model="resetemail"
                  :rules="emailRules"
                  placeholder="Email"
                >
                </v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12">
              <v-card-text v-if="resetsent === true">
                <v-alert border="bottom" color="success" dark>
                  Check your inbox for password reset link
                </v-alert>
                <!-- div>
                  <h5>Check your inbox for password reset link</h5>
                </div -->
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn
            small
            v-if="resetsent === false"
            color="primary"
            @click="resetSendEmail()"
            >Send</v-btn
          >
          <v-btn small color="error" @click="closeresetemaildialog()"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- HELP BUTTON ------------------------------------------------------------>
    <v-btn
      class="mb-n2"
      small
      bottom
      color="yellow darken-1"
      fab
      fixed
      right
      @click="helpdialog = !helpdialog"
    >
      <v-icon>mdi-help</v-icon>
    </v-btn>
    <!-- DIALOG SEARCH width="800px" ---------------------------------------------------------->
    <v-dialog v-model="searchdialog" :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-card-title>Help areas </v-card-title>
        <v-container>
          <v-row dense> </v-row>
          <v-row dense> </v-row>
          <v-row dense> </v-row>
        </v-container>
        <v-card-actions>
          <v-btn color="error" @click="searchdialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG HELP width="500px" ---------------------------------------------------------->
    <v-dialog v-model="helpdialog" :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-list-item-content>
          <v-card-text>
            <div>
              <h2>Info för testare</h2>
              <br />
              <h3>Användare</h3>
              Registrera dig och välj huvudaktivitet <br />
              Efter registrering logga in och registrera dina anläggningar och
              skills <br />
              Ladda upp en profilbild, ca 250 * 250 px <br />
              Sök kompisar och anslut, skapa grupper och anslut dina kompisar
              <br /><br />
              <h3>Coach/Tränare</h3>
              Registrera dig och markera att du är coach i slutet av
              registreringsformuläret<br />
              Välj din default aktivitet (tennis, padel eller annan) på
              registrerings formuläret<br />
              Logga in och anslut som coach för det företag du arbetar med i
              vänster meny <br />
              Anslut till de anläggningar du arbetar på och registrera dina
              skills<br /><br />
              <h3>Företag/Klubb/Akademi/Anläggning</h3>
              Först registrerar dig som användare och markererar coach <br />
              Sen registrerar du ditt företag och då kan du logga in på företags
              <br />
              admin sidan <br />
              Dina tränare och personal som ska ha tillgång registrerar sig som
              coach <br />
              och ansluter till ditt företag. När de anslutit kan de ge dem
              behörighet. <br />
              De som ej är tränare får status övriga <br />
            </div>
          </v-card-text>
        </v-list-item-content>
        <v-card-actions>
          <v-btn small color="primary" @click="helpdialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG PRIVACY -->
    <v-dialog
      v-model="privacydialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="60%"
    >
      <v-card :dark="selectedTheme">
        <v-list-item-content>
          <v-card-text>
            <div>
              <h2>Who we are</h2>
              <p><!-- /wp:heading --><!-- wp:paragraph --></p>
              <p>Our website address is: https://findhavefun.com</p>
              <h2>
                <strong
                  >What personal data we collect and why we collect it</strong
                >
              </h2>
              <p>
                The app stores data about persons including children, clubs,
                organisations, teams with two purpose
              </p>
              <p>
                1) Helping individuals to find activity friends or partners.
                Like for example you want to find padel players in your area or
                when you out traveling in some area you visit. To help you find
                persons with right skill, age, gender, facilities you playing at
                and city we save that type of data. When register you have to
                add data about your age, name, gender and place. Other data like
                Facilities and skills is optional but if you don&#39;t for
                example enter you skills if appropriate people can not find you
                if they want to find&nbsp;someone playing at a certain
                level.&nbsp;
              </p>
              <p>
                2) Helping team, organisations, academies manage their groups,
                schemas, dates, planned activities and overall helping with
                communicate with their members. For example kids/juniors playing
                padel have a schema and a program. The trainers can easily
                communicate with the members updating information about the
                schema and training program. Parents can take part och their
                kids activities and easily follow activities.&nbsp;
              </p>
              <p>
                When register you have to check GDPR and if you want to receive
                information regarding your active activity.&nbsp;
              </p>
              <p>
                If younger the 16 you have to enter your parents userid.&nbsp;
                If that is not&nbsp;available you can register with an template
                value but you have to enter your parents userid within a week
                else you will not be able to continue using the app until you
                have a parent user id.
              </p>
              <p><!-- /wp:heading --><!-- wp:heading --></p>
              <h3>Media</h3>
              <p><!-- /wp:heading --><!-- wp:paragraph --></p>
              <p>
                You will be able to upload images to your posts that is saved.
                This posts and images are connected to your id.&nbsp;
              </p>
              <p>Voice messages will also be available in coming releases.</p>
              <p><!-- /wp:paragraph --><!-- wp:heading --></p>
              <p><!-- /wp:heading --><!-- wp:heading --></p>
              <h3>Cookies</h3>
              <p><!-- /wp:heading --><!-- wp:paragraph --></p>
              <p>
                A cookie is saved for managing your login data so you don&#39;t
                have to enter it every time you restart the app. The cookie is
                valid for 30 days.
              </p>
              <p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
              <p><!-- /wp:paragraph --><!-- wp:heading --></p>
              <h3>Embedded content from other websites</h3>
              <p><!-- /wp:heading --><!-- wp:paragraph --></p>
              <p>
                Articles on this site may include embedded content (e.g. videos,
                images, articles, rss-feeds, etc.). Embedded content from other
                websites behaves in the exact same way as if the visitor has
                visited the other website.
              </p>
              <p><!-- /wp:paragraph --><!-- wp:paragraph --></p>
              <p><!-- /wp:paragraph --><!-- wp:heading --></p>
              <p><!-- /wp:heading --><!-- wp:heading --></p>
              <h2>Who we share your data with</h2>
              <p>
                If you check that you want to receive data regarding your
                activities you can get information and offers. You can check or
                uncheck that.
              </p>
              <p>Your data is not shared to anyone outside the app.&nbsp;</p>
              <h2>Analytics</h2>
              <p>No analytics are saved or performed.</p>
              <p><!-- /wp:heading --><!-- wp:heading --></p>
              <h2>How long we retain your data</h2>
              <p><!-- /wp:heading --><!-- wp:paragraph --></p>
              <p>Data is saved as long as you are an active member.&nbsp;</p>
              <h2>What rights you have over your data</h2>
              <p><!-- /wp:heading --><!-- wp:paragraph --></p>
              <p>
                If you have an account on this site you can request to receive
                an exported file of the personal data we hold about you,
                including any data you have provided to us. You can also request
                that we erase any personal data we hold about you. This does not
                include any data we are obliged to keep for administrative,
                legal, or security purposes.
              </p>
              <p><!-- /wp:paragraph --><!-- wp:heading --></p>
              <h2>Where we send your data</h2>
              <p><!-- /wp:heading --><!-- wp:paragraph --></p>
              <p>
                Visitor comments may be checked through an automated spam
                detection service.
              </p>
              <h2>Marketing and Advertising</h2>
              <p><!-- /wp:heading --><!-- wp:paragraph --></p>
              <p>
                You will never receive any advertising, you have to your self
                navigate to a tab where marketing info is available. There you
                can filter to see what you are interested of. Exception is if
                you are member of a team/organisation they can give you offers
                that corresponds to your membership.
              </p>
              <h2>Changes of the privacy policy</h2>
              <p>
                We will update and change our privacy policy when the app is
                updated and effects the policy. Updates of this policy are
                posted through the Services and the latest version of the Terms
                of Service and this policy is always available through the
                Services.
              </p>
              <p><!-- /wp:paragraph --><!-- wp:heading --></p>
              <h2>Your contact information</h2>
              <p><!-- /wp:heading --><!-- wp:heading --></p>
              <p>Reach out to us at admin@findhavefun.com</p>
            </div>
          </v-card-text>
        </v-list-item-content>
        <v-card-actions>
          <v-btn small color="primary" @click="privacydialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG FEEDBACK max-width="500px" ---------------------------------------------------------->
    <v-dialog v-model="feedbackdialog" persistent :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-text>
            <v-text-field
              v-model="feedbacktitle"
              :rules="[rules.required]"
              placeholder="Title"
              counter="15"
            ></v-text-field>
            <v-textarea
              clearable
              clear-icon="mdi-close-circle"
              rows="5"
              name="input-7-2"
              label=" Enter your feedback here"
              v-model="feedbacktext"
              counter="5"
            ></v-textarea>
          </v-card-text>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="createFeedback()">
            Send
          </v-btn>
          <v-btn small color="error" @click="feedbackdialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG SET ACTIVITY max-width="200px" ---------------------------------------------------------->
    <v-dialog v-model="setactivedialog" persistent :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-text>Set Active Activity</v-card-text>
          <v-divider></v-divider>
          <v-select
            dense
            v-model="selectedActiveActivity"
            :items="myactivitylist"
            color="blue-grey lighten-2"
            item-text="name"
            item-value="id"
            class="ma-0 pa-0"
          >
          </v-select>
        </v-container>
        <v-card-actions class="bt-n10 ma-0 pa-0">
          <v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="blue"
            @click="setActiveActivity()"
          >
            OK
          </v-btn>
          <v-btn
            small
            class="ml-2 mb-2"
            color="error"
            @click="setactivedialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG SET Theme ---------------------------------------------------------->
    <v-dialog v-model="themesDialog" persistent max-width="200px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-text>Select theme</v-card-text>
          <v-divider></v-divider>
          <v-switch
            v-model="selectedTheme"
            label="Activate dark theme"
          ></v-switch>
          <!-- v-select
            dense
            v-model="selectedTheme"
            :items="themesList"
            color="blue-grey lighten-2"
            item-text="name"
            item-value="id"
            class="ma-0 pa-0"
          >
          </v-select -->
        </v-container>
        <v-card-actions class="bt-n10 ma-0 pa-0">
          <v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="blue"
            @click="setUserSettings()"
          >
            OK
          </v-btn>
          <!-- v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="blue"
            @click="themesDialog = false"
          >
            Cancel
          </v-btn -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG SET Company ---------------------------------------------------------->
    <v-dialog v-model="setcompanydialog" persistent max-width="200px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-text>Set Active Company</v-card-text>
          <v-divider></v-divider>
          <v-select
            dense
            required
            v-model="selectedActiveCompany"
            :items="accessrights"
            color="blue-grey lighten-2"
            item-text="companyname"
            item-value="idcompany"
            class="ma-0 pa-0"
            :rules="[(v) => !!v || 'Select Company']"
          >
          </v-select>
        </v-container>
        <v-card-actions class="bt-n10 ma-0 pa-0">
          <v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="primary"
            @click="setActiveCompany()"
          >
            OK
          </v-btn>
          <v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="error"
            @click="setcompanydialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG GENDER ---------------------------------------------------------->
    <v-dialog v-model="dialogSelectGender" persistent max-width="300px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-subtitle>Select gender for search <br /></v-card-subtitle>
          <v-divider></v-divider>
          <v-select
            class="ma-0 pa-0"
            v-model="selectedGender"
            dense
            multiple
            :items="genderitems"
            :error-messages="errorsearchgendermsg"
          ></v-select>
        </v-container>
        <v-card-actions>
          <v-btn
            small
            class="ma-0 pa-0"
            color="primary"
            @click="setGenderSearch()"
          >
            OK
          </v-btn>
          <v-btn
            small
            class="ml-2"
            color="error"
            @click="dialogSelectGender = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG AGE ---------------------------------------------------------->
    <v-dialog v-model="dialogSelectAge" persistent max-width="250px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-subtitle>Search Age From To</v-card-subtitle>
          <v-divider></v-divider>
          <v-col>
            <v-text-field
              v-show="selectedSeachPart0"
              v-model="selectedAgefrom"
              class="ma-0 pa-0"
              dense
              label="Age from"
              single-line
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-show="selectedSeachPart0"
              class="ma-0 mt-n2 pa-0"
              v-model="selectedAgeto"
              dense
              label="Age to"
              single-line
            ></v-text-field>
          </v-col>
        </v-container>
        <v-card-actions class="ma-0 pa-0">
          <v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="primary"
            @click="setAgeSearch()"
          >
            OK
          </v-btn>
          <v-btn
            small
            class="ml-2 mb-2"
            color="error"
            @click="dialogSelectAge = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG NAME ---------------------------------------------------------->
    <v-dialog v-model="dialogSelectName" persistent max-width="250px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-subtitle>Search with name</v-card-subtitle>
          <v-divider></v-divider>
          <v-text-field
            dense
            v-model="searchnamefield"
            label="Name"
          ></v-text-field>
        </v-container>
        <v-card-actions class="ma-0 pa-0">
          <v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="primary"
            @click="setNameSearch()"
          >
            OK
          </v-btn>
          <v-btn
            small
            class="ml-2 mb-2"
            color="error"
            @click="dialogSelectName = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG MORE ---------------------------------------------------------->
    <v-dialog v-model="dialogSelectMore" persistent max-width="400px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-subtitle
            >Search Skills, Facilities, Place, Dates <br
          /></v-card-subtitle>
          <v-divider></v-divider>
          <v-col cols="12">
            <v-select
              dense
              class="ma-0 pa-0"
              v-model="selectedSkill"
              :items="activityskills"
              item-text="shortname"
              item-value="id"
              multiple
              chips
              deletable-chips
              small-chips
              v-show="selectedSeachPart0"
              label="Skills"
            >
            </v-select>
            <v-select
              dense
              class="ma-0 pa-0"
              v-model="selectedActivityFacilities"
              :items="activityfacilities"
              item-text="name"
              item-value="id"
              multiple
              chips
              deletable-chips
              small-chips
              v-show="selectedSeachPart0"
              :error-messages="errorsearchfacilitymsg"
              label="Facilities"
            >
            </v-select>
            <vuetify-google-autocomplete
              ref="searchaddress"
              id="map2"
              classname="form-control"
              placeholder="Place/City Country"
              v-on:placechanged="getAddressData"
              :country="countries"
              v-show="selectedSeachPart0"
              :error-messages="errorsearchplacemsg"
              :types="placetypes"
            >
            </vuetify-google-autocomplete>
            <!---- 
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="100px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="ma-0 pa-0"
                  v-model="datefromto"
                  label="Date from - to"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  range
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="datefromto"
                @input="menu2 = false"
                no-title
              >
              </v-date-picker>
            </v-menu>
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="100px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="ma-0 pa-0"
                  multiple
                  v-model="singledates"
                  label="Dates"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singledates"
                multiple
                no-title
                scrollable
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
            ---->
          </v-col>
        </v-container>
        <v-card-actions class="ma-0 pa-0">
          <v-btn
            small
            class="ma-0 ml-2 mb-2 pa-0"
            color="primary"
            @click="setMoreSearch()"
          >
            OK
          </v-btn>
          <v-btn
            small
            class="ml-2 mb-2"
            color="error"
            @click="dialogSelectMore = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG MY OFFERS -------------------------------------------------------- -->
    <v-dialog v-model="groupoffersdialog" persistent :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-toolbar dense flat>
            <v-btn text color="green" class="ma-0 mr-2 pa-0">
              Offers
            </v-btn>
            <v-spacer></v-spacer>
            <v-autocomplete
              v-model="autofillrequestsmessage"
              :items="groupofferslist"
              item-text="name"
              class="mt-5 pa-0"
              flat
              dense
              solo
              label="filter"
            ></v-autocomplete>
          </v-toolbar>
          <v-list dense>
            <v-list-group
              v-for="item in groupofferslist"
              v-model="item.dialog"
              :key="item.id"
              no-action
              active-class="light-blue lighten-4"
            >
              <template v-slot:activator>
                <!-- v-list-item-icon>
                <v-icon v-if="item.icon" color="pink">mdi-star</v-icon>
              </v-list-item-icon -->

                <v-list-item-avatar :input-value="active">
                  <v-img class="ma-0 pa-0" :src="item.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    class="green--text"
                    v-text="item.title"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-icon class="ma-0 pa-0">
                    <v-btn
                      icon
                      small
                      :input-value="active"
                      class="ma-0 pa-0"
                      @click="acceptOffer(item)"
                    >
                      <v-icon size="24px">mdi-plus-circle</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item-action>
              </template>
            </v-list-group>
          </v-list>
        </v-container>
        <v-card-actions class="bt-n10 ma-0 pa-0">
          <v-btn
            class="ml-2 mb-2"
            small
            color="primary"
            @click="groupoffersdialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG MESSAGEGROUP ---------------------------------------------------------->
    <v-dialog v-model="messagegroupdialog" persistent max-width="400px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-text>
            <v-textarea
              clearable
              clear-icon="mdi-close-circle"
              rows="1"
              name="input-7-2"
              label=" Enter your message here"
              v-model="sendgroupusermessage"
              :rules="[rules.required]"
              counter="5"
            ></v-textarea>
          </v-card-text>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" text @click="sendGroupMessage()">
            Send
          </v-btn>
          <v-btn small color="error" text @click="messagegroupdialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG SEARCHHELP max-width="600px" ---------------------------------------------------------->
    <v-dialog v-model="searchhelpdialog" persistent :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-text>
            Search for new activity friends. Select Activity and search or
            search for friends based on place/city/country or you search for
            friends based on a facility. Depending on the activity you add
            skills if activity has skills.
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-btn small color="primary" text @click="searchhelpdialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG PASSWORD max-width="400px" ---------------------------------------------------------->
    <v-dialog v-model="passwordDialog" persistent :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-title>Change password</v-card-title>
          <v-row dense>
            <v-col></v-col>
            <v-col cols="10">
              <v-text-field
                v-model="password"
                :append-icon="pswshow1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.minpsw]"
                :type="pswshow1 ? 'text' : 'password'"
                @click:append="pswshow1 = !pswshow1"
              ></v-text-field>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row dense>
            <v-col></v-col>
            <v-col cols="10">
              <v-text-field
                v-model="newpassword"
                :append-icon="pswshow1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.minpsw]"
                :type="pswshow1 ? 'text' : 'password'"
                placeholder="New password"
                counter="8"
                @click:append="pswshow1 = !pswshow1"
              ></v-text-field>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row dense>
            <v-col></v-col>
            <v-col cols="10">
              <v-text-field
                v-model="newpassword2"
                :append-icon="pswshow2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="pswshow2 ? 'text' : 'password'"
                name="input-10-2"
                label="Verify new password"
                class="input-group--focused"
                @click:append="pswshow2 = !pswshow2"
                :error-messages="errorpswmatch"
              >
              </v-text-field>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn small color="primary" @click="sendPassword()">
            OK
          </v-btn>
          <v-btn small color="error" @click="passwordDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG IMAGES max-width="400px" ---------------------------------------------------------->
    <v-dialog v-model="imagesdialog" persistent :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-img max-height="150" max-width="150" :src="myprofileimage"></v-img>
          <v-card-text>
            My Avatar
          </v-card-text>
          <v-file-input
            dense
            accept="image/png, image/jpeg, image/bmp"
            label="Upload your avatar"
            @change="saveImage"
          >
          </v-file-input>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- v-btn small color="primary">
            Save
          </v-btn -->
          <v-btn small color="error" @click="imagesdialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG SETUP max-width="400px" ---------------------------------------------------------->
    <v-dialog v-model="setupdialog" persistent :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-title>Setup and Config </v-card-title>
          <v-row dense>
            <v-col cols="6">
              <v-checkbox
                v-model="soundon"
                label="Sound On"
                color="indigo"
                value="1"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="notifications"
                label="Receive Notifications"
                color="indigo"
                value="1"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="soundfile"
                placeholder="Title"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" text @click="updateSettings()">
            Save
          </v-btn>
          <v-btn small color="error" text @click="setupdialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG IMAGE SHOW -->
    <v-dialog
      persistent
      v-model="groupimagepopupdialog"
      :max-width="dialogwidth"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card :dark="selectedTheme">
        <v-img :src="groupmsgimage"></v-img>
        <v-card-actions>
          <v-btn text @click="groupimagepopupdialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG APP DOWNLOAD  max-width="400px" ------------------------------------------------ -->
    <v-dialog v-model="appdownloaddialog" persistent :max-width="dialogwidth">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-title>About App</v-card-title>
          <v-card-subtitle class="mt-0"
            >Download App from Google / Appstore below</v-card-subtitle
          >
          <v-card-text>
            <v-btn
              small
              class="mx-4 mb-2 mt-2"
              color="primary"
              href="https://play.google.com/store/apps/details?id=com.findhavefun.app"
            >
              Android Google Play Version
              {{ this.systeminfo[0].androidversion }}
            </v-btn>
            <v-btn
              small
              class="mx-4 mb-2"
              color="primary"
              href="https://apps.apple.com/se/app/findhavefun/id1545852685"
            >
              IPhone AppStore Version {{ this.systeminfo[0].iosversion }}
            </v-btn>
            <v-btn small v-if="this.platform === 'web'" class="mx-4" text>
              Web Version {{ this.systeminfo[0].webbversion }}
            </v-btn>
            <v-btn
              v-else
              small
              class="mx-4"
              text
              href="https://findhavefun.com"
            >
              Web Version {{ this.systeminfo[0].webbversion }}
            </v-btn>
          </v-card-text>
          <v-card-subtitle class="mt-n3">
            Description
          </v-card-subtitle>
          <v-card-text>{{ this.systeminfo[0].description }} </v-card-text>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="appdownloaddialog = false" small color="primary">
            Close
          </v-btn>
          <!-- v-btn small color="error" text>
            IPhone Version 0.6
          </v-btn -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG USER BELOW 15 --------------------------------------------------------------------->
    <v-dialog persistent v-model="adduserparentdialog" width="300px">
      <v-card :dark="selectedTheme">
        <v-card-title>Parent Username</v-card-title>
        <v-card-text>
          Because you are below age 15 you have to enter a parent username. If
          you don't have that now, you have 5 days to register a parent
          username.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="adduserparentdialog = false"
            >Ok I understand</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SNACKBAR ------------------------------------------------------------------------------------>
    <v-snackbar v-model="snackbar" :timeout="timeout" top color="blue-grey">
      {{ notificationtext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!--  SNACKBAR ------------------------------------------------------------------------------------>
    <v-snackbar v-model="snackbarregmsg" top color="red">
      {{ notregtext }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbarregmsg = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbarwarningmsg" top color="warning">
      {{ notregtext }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbarwarningmsg = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- DIALOG CALENDER ---------------------------------------------------------->
    <Calender
      :visible="calenderdialog"
      @close="calenderdialog = false"
    ></Calender>
    <!-- DIALOG CALENDER ---------------------------------------------------------->
    <CalenderNew
      :visible="calenderdialognew"
      :iduser="userid"
      :ActiveActivityID="ActiveActivityID"
      :selectedTheme="selectedTheme"
      @close="calenderdialognew = false"
    ></CalenderNew>
  </v-card>
</template>

<script>
/* eslint-disable */
// import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
// import { ValidationProvider } from 'vee-validate';
import AuthenticationService from "@/services/AuthenticationService";
import SearchesService from "@/services/SearchesService";
import UserService from "@/services/UserService";
// import io from 'socket.io-client'
// import networkConnection from '@/plugins/socketconf'
// import * as socketio from "@/plugins/socketio";
// import triggerPushNotification from "@/plugins/webnotifications";
// import MessageService from '@/chatservices/MessagesService'
import VoiceMessageService from "@/services/VoiceMessageService";
import VoiceMessageServiceForm from "@/services/VoiceMessageServiceForm";
import GroupServices from "@/services/GroupServices";
import ActivityService from "@/services/ActivityService";
import SystemService from "@/services/SystemService";
import MyImageService from "@/services/MyImageService";
import MailService from "@/services/MailService";
import Calender from "@/components/Calender.vue";
import CalenderNew from "@/components/CalenderNew.vue";
import CalenderService from "@/services/CalenderService";
// import PostEditor from "@/components/PostEditor.vue";
import NewsFeeds from "@/components/NewsFeeds.vue";
// import FriendsChat from "@/components/FriendsChat.vue";
import Friends from "@/components/Friends.vue";
import Groups from "@/components/Groups.vue";
import Tokens from "@/utils/tokens";
// import { Capacitor, Plugins } from "@capacitor/core";
// import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
// import { CapacitorCli } from "@capacitor/cli";
// import { RecordingData, GenericResponse } from "capacitor-voice-recorder";
// const { LocalNotifications } = Plugins;
// const { PushNotifications } = Plugins;
import { LocalNotifications } from "@capacitor/local-notifications";
// import { PushNotifications } = "@capacitor/push-notifications";
import { PushNotifications } from "@capacitor/push-notifications";
// const { VoiceRecorder } = Plugins;
// const { VoiceRecorder } = "capacitor-voice-recorder";
import "firebase/messaging";
// import CordovaMediaCapture from "cordova-plugin-media-capture";
import resizeImage from "@/utils/resizeImage.js";
import RecorderWeb from "@/components/RecorderWeb.vue";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
// const { SecureStoragePlugin } = Plugins;

// import VolumeControl from '../../../vue-audio-recorder/src/components/volume-control.vue';
// import Recorder from "@/components/Recorder.vue";
// import AudioRecorder from 'vue-audio-recorder'
// import AdminWindow from '@/components/AdminWindow'
// import VueCookies from 'vue-cookies'
// import UserGroupService from '@/chatservices/UserGroupService'
// import LoginChatService from '@/chatservices/LoginChatService'
// import { Howl } from "howler";
import blobUtil from "blob-util";
// import Recorder from '../../../20210519-fhfclient01/src/components/Recorder.vue';
// import {Howl, Howler} from 'howler';
// require("@/assets/sound/bell-koenig.mp3")
// Howler

const FormData = require("form-data");
// const { NativeAudio } = Plugins;
// const safeAreaInsets = require("safe-area-insets");

export default {
  components: {
    RecorderWeb,
    blobUtil,
    // Recorder,
    Calender,
    CalenderNew,
    // PostEditor,
    // FriendsChat,
    Friends,
    Groups,
    NewsFeeds,
    // Recorder,
    // VoiceRecorder
    // VolumeControl
    // AudioRecorder
    // VuetifyAudio: () => import('vuetify-audio')
  },
  name: "MainWindow",
  emits: ["selected-theme"],
  props: {
    source: String,
  },
  data() {
    // const CREATE_GROUP = "CREATE GROUP",
    //  MESSAGES = "MESSAGES",
    //  FRIENDS = "FRIENDS",
    //  REQUESTS = "REQUEST",
    //  WAITING = "WAITING",
    //  addedupdgroupusers = "GROUPS"
    return {
      adduserparentdialog: false,
      tabbtncolor: "green",
      tabiconcolor: "yellow",
      btnsize: false,
      groupimagepopupdialog: false,
      groupmsgimage: null,
      textFieldColor: "grey lighten-2",
      userSettings: { id: 0, userid: 0, dark: false },
      // theme: 'light',
      audioCtx: null,
      iosaudioshow: false,
      mediaRecorder: null,
      scrollInvoked: 0,
      newsusermessage: "",
      audioRef: null,
      platform: "web",
      native: "",
      privacydialog: false,
      microPhone: true,
      voiceTitle: "🎧 🎧 🎧",
      maxImageSize: 700,
      bitrate: 128,
      hasImage: true,
      resizedImg: null,
      originalImg: null,
      mp3Soundfile: null,
      soundfile: "",
      soundMsg: {
        file: null,
        url: "",
        id: 0,
        title: "",
        type: "audio/mpeg",
        size: 0,
        msg: "",
        file2: null,
      },
      soundGrpMsg: {
        file: null,
        url: "",
        id: 0,
        title: "",
        type: "audio/mpeg",
        size: 0,
        msg: "",
        file2: null,
      },
      createGroupVoiceMsg: false,
      showRecorder: false,
      card: { msgimage: null, msgtitle: "", imageid: 0 },
      cardList: [{ msgimage: null, msgtitle: "", imageid: 0 }],
      // grpcard: { msgimage: null, msgtitle: "", imageid: 0 },
      selectedfrienditem: null,
      friendListPopup: false,
      systeminfo: [
        {
          webbversion: "0",
          androidversion: "0",
          iosversion: "0",
          description: "",
          existing: "",
          coming: "",
          issues: "",
          active: 0,
          imagesize: 0,
          bitrate: 128,
          createdAt: "",
          updatedAt: "",
        },
      ],
      // weekdays: ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],
      month: [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Maj",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      friendSearch: "",
      // friendSortBy: "name",
      friendKeys: ["Name", "Message Date"],
      filter: {},
      colortbselected: "primary",
      colortbunselected: "grey",
      colortbmsg: "grey",
      colortbgrp: "grey",
      colortbfrn: "grey",
      colortbreq: "grey",
      membershipcompanies: [],
      mymembershipcompanies: [],
      membershipdialog: false,
      companyadmin: "Company Admin",
      GroupBadge: 0,
      FriendBadge: 0,
      MessageBadge: 0,
      RequestBadge: 0,
      appdownloaddialog: false,
      snackbar: false,
      snackbarregmsg: false,
      snackbarwarningmsg: false,
      notificationtext: "",
      notregtext: "",
      timeout: 10000,
      // groupmsgbadge: 0,
      howlerSound: null,
      // For all dialogs
      dialogSaved: false,
      // Company
      companyid: 0,
      companydata: null,
      companydialog: false,
      companyname: "",
      companytypes: ["coach", "academy", "club", "facility", "other"],
      selectedcompanytype: "",
      companyemail: "",
      companycountrycode: "46",
      companyphone: "",
      companyadress: "",
      companycity: "",
      companycountry: "",
      companyzipcode: "",
      companystate: "",
      companywebsite: "",
      companydescription: "",
      companyavatar: "",
      selectedCompanyActivity: "",
      companyactive: true,
      // calender items
      IsCoach: false,
      accessrights: [],
      AdminAccess: false,
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      calenderlist: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      // bell-koenig.mp3
      // group vars
      // file: 'http://findhavefun.com/sound/bell-koenig.mp3',
      // icontabsize: "32px",
      metaInfo: {
        title: "Find Have Fun",
        titleTemplate: "%s | November 2020",
        meta: [
          { charset: "utf-8" },
          { name: "description", content: "Find Activity Friends" },
          {
            name: "viewport",
            content: "width=device-width, initial-  scale=1",
          },
        ],
        htmlAttrs: {
          lang: "en",
          amp: true,
        },
      },
      bgrcolormediabar: "teal lighten-4",
      bgrcolormsgbar: "teal lighten-4",
      cookie: "1",
      soundon: "0",
      soundfile: "",
      defaultsoundfile: "https://findhavefun.com/sound/doorbell.mp3",
      notifications: "",
      // cookie: null,
      addfriendmessage: "",
      myprofileimage: null,
      searchbottommessages: "",
      bottommessages: "",
      bottommessagesF: "",
      bottommain: "",
      bottomtabforum: "",
      errorsupdateactivitymsg: "",
      errorsearchactivitymsg: "",
      errorsearchfacilitymsg: "",
      errorsearchgendermsg: "",
      errorsearchplacemsg: "",
      errorusername: "",
      erroremail: "",
      errorgender: "",
      errorpersonnumber: "",
      errorparentusername: "",
      errorage: "",
      errorcity: "",
      errorloginmsg: "",
      errorunidentified: "",
      errorgdpr: "",
      errorpswmatch: "",
      selectedSeachPart0: true,
      absolute: null,
      usermessagewindow: false,
      grpavatar:
        "https://findhavefun.com/fhfclient01/src/assets/grpavatars/017-network.png",
      audio: null,
      idactivity: 0,
      searchnamefield: "",
      updgroupname: "",
      updgrouptitle: "",
      creategroupname: "",
      creategrouptitle: "",
      // autoUpdate: true,
      groupfriends: [],
      isUpdating: false,
      groupname: "",
      grouptitle: "",
      // end group
      User: null,
      UserUpd: "",
      // autofillsearchfriend: null,
      // autofillmessage: null,
      // autofillgroupmessage: null,
      autofillfriendsmessage: null,
      // filterFriends: null,
      autofillrequestsmessage: null,
      // autofillwaitingmessage: null,
      // chatAuthorization: "",
      // socket: io(networkConnection),
      messageitem: null,
      messagerequestitem: null,
      deletemessageitem: null,
      myfriendslistsheader: "FRIENDS",
      selectedusermessage: "",
      sendfriendusermessage: "",
      sendfriendrequestmessage: "",
      sendgroupusermessage: "",
      sendusermessage: "",
      feedbacktext: "",
      feedbacktitle: "",
      // navmodel: false,
      // calvalue: null,
      newusermsgPopup: false,
      setupdialog: false,
      userskillsdialog: false,
      friendRequest: false,
      imagesdialog: false,
      helpdialog: false,
      messagegroupdialog: false,
      feedbackdialog: false,
      passwordDialog: false,
      themesDialog: false,
      themesList: ["dark", "light"],
      selectedTheme: false,
      selectedDark: "dark",
      selectedLight: "light",
      calenderdialog: false,
      calenderdialognew: false,
      countrycode: "46",
      phone: "",
      phonehome: "",
      phonemobile: "",
      mobile: "",
      groupid: 0,
      userid: 0,
      searchOk: false,
      searchCoachOk: false,
      getFriendsOk: false,
      getWaitingFriendsOk: false,
      menuFriendList: "",
      menuA: "",
      menuB: "",
      menuMessagesF: false,
      menuMessagesG: false,
      menuMessagesC: "",
      menuMessagesR: "",
      menuMessagesW: "",
      activityButton: "activity",
      addressData: null,
      addressHome: null,
      addressCompany: null,
      placeResultData: null,
      placeResultHome: null,
      placeResultCompany: null,
      id: "",
      idHome: "",
      idHomeCompany: "",
      idcompanyAddress: "",
      searchaddress: "",
      // homeaddress: "",
      addressFacility: "",
      companyaddress: "",
      currentPlace: null,
      geoplaces: [],
      datesearchoption: false,
      skillssearchoption: false,
      selectedFacility: "",
      popupselectedskill: "",
      selectedSkill: "",
      selectedAgefrom: "",
      selectedAgeto: "",
      selectedAgeToNo: 18,
      ageto: [
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        ,
        25,
        26,
        ,
        27,
        28,
        29,
        30,
      ],
      selectedGender: ["man", "woman"],
      singleselect: true,
      selecteduseractivities: [],
      selectedactivities: [],
      selectedActivityFacilities: [],
      selecteduser: [],
      selectedfriend: [],
      selectedmessagerows: [],
      selectedgroupuser: [],
      selectedcreategroupuser: [],
      selectedupdgroupusers: [],
      itemupdgroupusers: null,
      addedupdgroupusers: [],
      selectedgrouplist: null,
      selectedcompanies: [],
      menu2: "",
      menu3: "",
      pswshow1: false,
      pswshow2: false,
      datefromtoswitch: false,
      datesingleswitch: false,
      signinbutton: "Sign In",
      UserSignedIn: false,
      datefromto: "",
      singledates: "",
      country: "",
      state: "",
      avatar: "",
      showprofileinfo: 0,
      sharecontactinfo: 0,
      gdpr: 0,
      gender: "",
      username: "",
      email: "",
      name: "",
      displayname: "",
      personnumber: "",
      personnumbermenu: false,
      personnumbermenuupd: false,
      personnumberref: false,
      newpassword: "",
      newpassword2: "",
      password: "",
      password2: "",
      city: "",
      description: "",
      signin: false,
      coachDialog: false,
      setcompanydialog: false,
      setactivedialog: false,
      groupdeletedialog: false,
      searchhelpdialog: false,
      userdialog: false,
      parentusername: "",
      agebelow15: false,
      agebelow9: false,
      agebelow15days: 0,
      afterregisterdialog: false,
      updateskilldialog: false,
      usersearchdialog: false,
      useractivitydialog: false,
      facilityactivitydialog: false,
      groupoffersdialog: false,
      dialogSelectGender: false,
      dialogSelectAge: false,
      dialogSelectName: false,
      dialogSelectMore: false,
      userupdatedialog: false,
      groupcreatedialog: false,
      groupupdatedialog: false,
      usercoachdialog: false,
      coachdialog: false,
      searchdialog: false,
      signindialog: false,
      resetsendemaildialog: false,
      resetemail: "",
      resetsent: false,
      searchinmain: true,
      drawer: null,
      mycompanylist: [],
      coachActivityCompanies: [],
      defaultCoachActivityCompany: "",
      selectedActiveCompany: 0,
      selectedCoachActivity: "activity",
      selectedmyfacilities: [],
      selectedmymemberships: [],
      selectednewmembership: [],
      selectedGroupActivity: "activity",
      selectedFacilityActivity: "activity",
      selectedActivity: "activity",
      selectedDefaultActivity: "",
      selectedActiveActivity: "",
      ActiveActivityID: 0,
      DefaultActivityID: 0,
      searchPlace: "",
      menu_tabs: "tab-main",
      userskillsheaders: [
        {
          text: "Activity",
          align: "start",
          sortable: false,
          value: "activity",
        },
        { text: "Skill", value: "skill" },
        { text: "My Note", value: "mynote" },
      ],
      tabs: [
        { tab: "Main", content: "main" },
        { tab: "Activity", content: "activity" },
      ],
      placetypes: [{ text: "cities" }],
      countries: "se",
      mySearch: [],
      skills: [
        "1 - Beginner",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10 - Advanced International level",
      ],
      facilityitems: ["No Choice", "Court1 Nacka", "Padel22 Värmdö"],
      agemin: 0,
      agemax: 20,
      ageslider: 20,
      useractivityskills: [],
      messagehistmsg: [],
      userhistmsg: [],
      grouphistmsg: [],
      activityfacilities: [],
      activityfacilitiesorg: [],
      // facilitylist: [],
      useractivitylist: [],
      activityskills: [],
      popupactivityskills: [],
      interestlist: [],
      companylist: [],
      usercoachlist: [],
      searchinterestlist: [],
      myactivitylist: [],
      userskillslist: [],
      userlist: [],
      friendlist: [],
      friendlistOrg: [],
      requestlist: [],
      usergrouprequest: [],
      userallrequests: [],
      groupofferslist: [],
      messagelist: [],
      friendgrouplist: [],
      groupusers: [],
      genderitems: ["man", "woman"],
      activities: [
        { value: "activity", text: "Activity" },
        { value: "padel", text: "Padel" },
        { value: "tennis", text: "Tennis" },
        { value: "outdoor", text: "Outdoor" },
      ],
      rmenuitems: [{ title: "SignIn" }, { title: "Register" }],
      items: [
        { icon: "mdi-contacts", text: "Friends", action: "" },
        { icon: "mdi-calendar", text: "Calender", action: "" },
        {
          icon: "mdi-account-edit",
          text: "My Profile",
          action: "manageProfile()",
        },
        { icon: "mdi-message", text: "Send feedback", action: "" },
        { icon: "mdi-help-circle", text: "Help", action: "" },
        { icon: "mdi-cellphone-link", text: "Download App", action: "" },
        { icon: "mdi-cog", text: "Settings", action: "" },
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length >= 5 || "Name has to be 5 or more characters",
      ],
      usernameRules: [
        (v) => !!v || "Username is required",
        (v) => v.length >= 8 || "Username has to be 8 or more characters",
      ],
      parentusernameRules: [
        (v) => !!v || "Parent username is required when age < 16",
        (v) => v.length >= 8 || "Username has to be 8 or more characters",
      ],
      personnumberRules: [
        (v) => !!v || "Birthdate is required",
        (v) => v.length === 8 || "Birtdate must be 8 number YYYYMMDD",
      ],
      cityRules: [
        (v) => !!v || "Place is required",
        (v) => v.length >= 10 || "Place City/Country is required",
      ],
      facilityRules: [
        (v) => !!v || "Facility is required",
        (v) => v.length >= 10 || "Facility is required",
      ],
      activityRules: [(v) => !!v || "Activity is required"],
      rules: {
        required: (value) => !!value || "Required.",
        minpsw: (v) => v.length >= 8 || "Min 8 characters",
        minusername: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match",
      },
      messagestst: [
        {
          from: "You",
          message: `Sure, I'll see you later.`,
          time: "10:42am",
          color: "deep-purple lighten-1",
        },
        {
          from: "John Doe",
          message: "Yeah, sure. Does 1:00pm work?",
          time: "10:37am",
          color: "green",
        },
        {
          from: "You",
          message: "Did you still want to grab lunch today?",
          time: "9:47am",
          color: "deep-purple lighten-1",
        },
      ],
      languages: [{ shortname: "EN" }, { shortname: "SE" }],
      localeShort: "SE",
      icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
      padless: false,
      variant: "default",
      recentMessages: [],
      joinedChatSocket: false,
      my_media: null,
      notChannel: null,
      token: "",
      groupuserid: 0,
      windowHight: {
        y: 0,
      },
      /*
      Date.locale = {
        se: {
          month_names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      */
    }; // return
  },
  watch: {
    userdialog() {
      if (this.userdialog === true) {
        this.openWarningSnackBarMsg(
          "You have to be 9 year or older to register"
        );
      }
    },
    /*
    agebelow15() {
      if(this.agebelow15 === true) {
        this.errorparentuser = "Enter parent username";
      }
    },
    */
    /*
    parentusername: function (vals) {
      try {
        console.log(this.parentusername);
        console.log(str);
      } catch (error) {
        console.log(error)
      }
    },
    */
    /*
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    }, */
    selectedActivity() {
      console.log(this.selectedActivity);
      this.getActivitySkills();
      this.getActivityFacilities();
      this.errorsearchactivitymsg = "";
      this.initUserlist();
    },
    selectedFacilityActivity() {
      // this.getActivities()
      this.getUserActivityFacilities();
      this.getUserFacilities();
    },
    selectedCoachActivity() {
      // this.getActivities()
      this.getCoachCompanies();
    },
    personnumbermenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      if (val === false) {
        this.checkIfChild();
      }
    },
    personnumbermenuupd(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      if (val === false) {
        this.checkIfChild();
      }
    },
    autofillfriendsmessage(val) {
      // val && val !== this.autofillfriendsmessage &&
      this.friendSearchSelections(val);
    },
  },
  computed: {
    tabmenupbtn() {
      const size = { xs: "medium", sm: "medium", lg: "medium", xl: "large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    dlgsigninwitdth() {
      if (this.$vuetify.breakpoint.name === "xs") return "80%";
      if (this.$vuetify.breakpoint.name === "sm") return "60%";
      if (this.$vuetify.breakpoint.name === "md") return "40%";
      if (this.$vuetify.breakpoint.name === "lg") return "30%";
      if (this.$vuetify.breakpoint.name === "xl") return "30%";
    },
    dialogwidth() {
      if (this.$vuetify.breakpoint.name === "xs") return "100%";
      if (this.$vuetify.breakpoint.name === "sm") return "80%";
      if (this.$vuetify.breakpoint.name === "md") return "60%";
      if (this.$vuetify.breakpoint.name === "lg") return "50%";
      if (this.$vuetify.breakpoint.name === "xl") return "50%";
    },
    tabmenustyle() {
      if (this.$vuetify.breakpoint.name === "xs") return "font-size: 0.8em";
      if (this.$vuetify.breakpoint.name === "sm") return "font-size: 0.8em";
      if (this.$vuetify.breakpoint.name === "md") return "font-size: 0.9em";
      if (this.$vuetify.breakpoint.name === "lg") return "font-size: 1.1em";
      if (this.$vuetify.breakpoint.name === "xl") return "font-size: 1.2em";
    },
    profileavatarsize() {
      if (this.$vuetify.breakpoint.name === "xs") return 48;
      if (this.$vuetify.breakpoint.name === "sm") return 48;
      if (this.$vuetify.breakpoint.name === "md") return 48;
      if (this.$vuetify.breakpoint.name === "lg") return 48;
      if (this.$vuetify.breakpoint.name === "xl") return 48;
    },
    icontabsize() {
      if (this.$vuetify.breakpoint.name === "xs") return 24;
      if (this.$vuetify.breakpoint.name === "sm") return 24;
      if (this.$vuetify.breakpoint.name === "md") return 32;
      if (this.$vuetify.breakpoint.name === "lg") return 32;
      if (this.$vuetify.breakpoint.name === "xl") return 32;
    },
      vbtnsize() {
      if (this.$vuetify.breakpoint.name === "xs") return "small";
      if (this.$vuetify.breakpoint.name === "sm") return "small";
      if (this.$vuetify.breakpoint.name === "md") return "";
      if (this.$vuetify.breakpoint.name === "lg") return "";
      if (this.$vuetify.breakpoint.name === "xl") return "";
      // case "xs": return "24px"
      // case "sm": return "24px"
      // case "md": return "32px"
      // case "lg": return "32px"
      // case "xl": return "32px"
    },
    filteredKeys() {
      return this.friendKeys.filter((key) => key !== "Name");
    },
  },
  created() {
    // this.onResize();
    // this.audio = document.getElementById('audio')
    // this.howlerSound = new Howl({
    // src: ['@/assets/sound/bell-koenig.mp3']
    // src: ['https://findhavefun.com/sound/bell-koenig.mp3']
    // src: ['http://localhost:8080/bell-koenig.mp3']
    // })
  },
  async mounted() {
    // triggerPushNotification()
    // this.initWebPush ();
    console.log("mounted");
    this.getPlatform();
    this.initPush();
    // console.log(Capacitor.platform);
    // this.platform = Capacitor.platform;
    if (this.$route.params.userid > 0) {
      // this.initPush();
      this.username = this.$route.params.username;
      this.password = this.$route.params.password;
      await this.getActivities();
      this.menu_tabs = "tab-main";
      // this.menu_tabs = this.$route.params.main_tab;
      // this.myfriendslistsheader = this.$route.params.listsheader
      await this.loginInit();
      // this.ActiveActivityID = this.$route.params.activity;
      this.selectedActiveActivity = this.ActiveActivityID;
      this.setActiveActivity();
    } else if (this.$route.params.username && this.$route.params.password) {
      // this.initPush();
      this.username = this.$route.params.username;
      this.password = this.$route.params.password;
      await this.getActivities();
      this.menu_tabs = "tab-main";
      // this.menu_tabs = this.$route.params.main_tab;
      // this.myfriendslistsheader = this.$route.params.listsheader
      // await this.login();
      this.$router.push({ name: "loginpre" });
      this.ActiveActivityID = this.$route.params.activity;
      this.selectedActiveActivity = this.ActiveActivityID;
      this.setActiveActivity();
    } else {
      // this.initPush();
      // await this.notOnInit();
      // const user = this.$cookies.get("user");
      // if (!this.$store.state.user || user) {
      await this.getActivities();
      this.menu_tabs = "tab-main";
      // this.username = "carweb99"
      // this.password = "zztop199"
      // this.$router.push({ name: "login" });
      // this.signindialog = true;
      await this.login();
      // }
      await this.getActivities();
      // this.initVoiceRecorder();
    }
    // this.preloadSound();
    // this.playNative();
  },
  methods: {
    updateFriendBadge(friendsbadge) {
      console.log("updateFriendBadge " + friendsbadge);
      this.FriendBadge = friendsbadge;
    },
    updateGroupBadge(groupbadge) {
      console.log("updateGroupBadge " + groupbadge);
      this.GroupBadge = groupbadge;
    },
    getPlatform() {
      if (Capacitor.isNativePlatform()) {
        this.platform = "native";
      } else {
        this.platform = "web";
      }
      this.native = Capacitor.getPlatform();
      console.log(this.native);
      // var info = await App.getInfo();
      // var state = await App.getState();
      // const rec = await App.getInfo();
      console.log(this.platform);
    },
    onRecord(data) {
      console.log("The blob data:", data);
      console.log("Downloadable audio", window.URL.createObjectURL(data));
      soundMsg.file = data;
    },
    recCallback(msg) {
      console.log("Event: ", msg);
    },
    recMicFailed(msg) {
      console.log("mic failed ", msg);
    },
    recPausCallback(msg) {
      console.log("Event: ", msg);
      this.soundMsg.msg = msg;
      // this.soundMsg.file = msg
      // this.showRecorder = false
    },
    recGrpPausCallback(msg) {
      console.log("Event: ", msg);
      this.soundGrpMsg.msg = msg;
      // this.soundMsg.file = msg
      // this.showRecorder = false
    },
    afterRecCallback(msg) {
      console.log("Event: ", msg);
      this.soundMsg.file = msg.blob;
      this.soundMsg.url = msg.url;
      this.soundMsg.size = msg.blob.size;
      this.soundMsg.type = msg.blob.type;
      this.soundMsg.msg = "";
      // const reader = new FileReader();
      // const furl = reader.readAsDataURL(this.soundMsg.file);
      // var tmpfile = window.URL.createObjectURL(msg.blob);
      this.showRecorder = false;
    },
    afterGrpRecCallback(msg) {
      console.log("Event: ", msg);
      this.soundGrpMsg.file = msg.blob;
      this.soundGrpMsg.url = msg.url;
      this.soundGrpMsg.size = msg.blob.size;
      this.soundGrpMsg.type = msg.blob.type;
      this.soundGrpMsg.msg = "";
      // const reader = new FileReader();
      // const furl = reader.readAsDataURL(this.soundMsg.file);
      // var tmpfile = window.URL.createObjectURL(msg.blob);
      this.showRecorder = false;
    },
    checkIfChild() {
      // var today = new Date();
      var time = this.personnumber;
      var birthDate = new Date(time);
      var ageDifMs = Date.now() - birthDate.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      var age = Math.abs(ageDate.getUTCFullYear() - 1970);
      if (age < 15) {
        this.agebelow15 = true;
      } else {
        this.agebelow15 = false;
      }
    },
    checkIfBelow9() {
      // var today = new Date();
      var time = this.personnumber;
      var birthDate = new Date(time);
      var ageDifMs = Date.now() - birthDate.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      var age = Math.abs(ageDate.getUTCFullYear() - 1970);
      if (age < 9) {
        this.agebelow9 = true;
      } else {
        this.agebelow9 = false;
      }
    },
    checkDaysFromRegistered(date) {
      // var today = new Date();
      if (this.username.substr(0, 10) === "kidsfhftst") {
        return true;
      }
      var time = date.substr(0, 10);
      var createDate = new Date(time);
      var daysDifMs = Date.now() - createDate.getTime();
      var daysMs = new Date(daysDifMs); // miliseconds from epoch
      var days = Math.abs(daysMs.getUTCFullYear() - 1970);
      if (days >= this.agebelow15days) {
        return true;
      } else {
        return false;
      }
    },
    friendSearchSelections(v) {
      this.friendlist
        .map(function(e) {
          return e.id;
        })
        .indexOf(v);
    },
    initWebPush() {
      if (this.platform === "web") {
        this.registerWebPush();
      }
    },
    registerWebPush() {
      console.log("RegisterWebPush");
    },
    initPush() {
      console.log("");
      if (Capacitor.isNativePlatform()) {
        this.registerPush();
      }
    },
    registerPush() {
      console.log("registerPush()");
      try {
        PushNotifications.requestPermissions().then((permission) => {
          console.log("PushNotifications.requestPermissions()");
          // console.log(permission);
          if (permission.receive === "granted") {
            // console.log("permission granted");
            PushNotifications.register();
          } else {
            console.log("No Permission");
          }
        });
        // "use strict";
        PushNotifications.addListener("registration", (token) => {
          this.token = token.value;
          console.log(this.token);
          Tokens.checkToken(this.token);
          /*
          const tkn = token.value;
          if (tkn.value === undefined) {
            this.token = token.value;
            console.log(tkn);
          } else {
            this.token = tkn.value;
          }
          */
          // this.token = token.value; // JSON.stringify(token);
          // alert('Push registration success, token: ' + this.token);
        });

        /*
        PushNotifications.addListener('registration',
          (token: PushNotificationToken) => {
            alert('Push registration success, token: ' + token.value);
          }
        ); */

        PushNotifications.addListener("registrationError", (error) => {
          // alert('Error on registration: ' + JSON.stringify(error));
        });

        /*
        PushNotifications.addListener('registrationError',
          (error: any) => {
            alert('Error on registration: ' + JSON.stringify(error));
          }
        ) */

        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            // this.sendNotifications()
            // var message = {message: notication.body, sendername: notification.title}
            // this.notScheduleBasics (message)
            // alert('Push received: ' + JSON.stringify(notification));
          }
        );

        /*
        PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotification) => {
            alert('Push received: ' + JSON.stringify(notification));
          }
        ) */

        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification) => {
            // alert('Push action performed: ' + JSON.stringify(notification));
          }
        );

        // Method called when tapping on a notification
        /*
        PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          alert('Push action performed: ' + JSON.stringify(notification));
        }
        ) */
      } catch (error) {
        console.log(error.message);
      }
    },
    async notScheduleBasics(message) {
      await LocalNotifications.schedule({
        notifications: [
          {
            title: message.sendername,
            body: message.message,
            id: 1,
            sound: "doorbell.mp3",
            // channel: "notchannel",
            extra: {
              data: "any object",
            },
          },
        ],
      });
    },
    async notOnInit() {
      await LocalNotifications.requestPermissions();
      /*
      const notChannel = {
        id: 'notchannel',
        name: 'channel',
        importance: 5,
        sound: 'doorbell.mp3',
        visibility: 1
      }
      LocalNotifications.createChannel(notChannel)
      */
    },
    async sendNotifications(message) {
      const notifs = await LocalNotifications.schedule({
        notifications: [
          {
            title: "FindHaveFune",
            body: message,
            id: 1,
            schedule: { at: new Date(Date.now() + 1000 * 5) },
            // sound: "doorbell.mp3",
            attachments: null,
            actionTypeId: "",
            extra: null,
          },
        ],
      });
      console.log(notifs);
    },
    async testdialog() {
      try {
        const groupids = await this.getUserGroupsIds();
        if (groupids.length > 0) {
          this.$socketio.sendEvent({
            type: "registergroups",
            data: groupids,
          });
        }
        this.$socketio.sendEvent({
          type: "register",
          data: { userid: this.userid },
        });
      } catch (error) {
        console.log(error);
      }
    },
    async resetSendEmail() {
      try {
        const email = await MailService.resetPasswordEmail({
          emailto: this.resetemail,
        });
        console.log(email);
        this.resetsent = true;
      } catch (error) {
        console.log(error);
      }
    },
    resetpassworddialog() {
      this.signindialog = false;
      this.resetsendemaildialog = true;
    },
    closeresetemaildialog() {
      this.resetsent = false;
      this.signindialog = false;
      this.resetsendemaildialog = false;
      this.resetemail = "";
    },
    savepersonnumber(date) {
      this.$refs.personnumbermenu.save(date);
      this.checkIfBelow9();
      if (this.agebelow9) {
        this.openSnackBarMsg("You have to be 9 year or older to register");
        this.userdialog = false;
        this.registerCanceled();
      } else {
        this.checkIfChild();
        if (this.agebelow15) {
          this.adduserparentdialog = true;
        }
      }
    },
    savepersonnumberupd(date) {
      this.$refs.personnumbermenuupd.save(date);
    },
    play() {
      console.log("play");
      try {
        if (this.soundon === "1") {
          // const isSoundEffectAvailable = Plugins.isPluginAvailable('SoundEffect');
          // if (isSoundEffectAvailable) {
          // Plugins.SoundEffect.play({ id: 'mySound' })
          // }
          // this.howlerSound.play();
        }
      } catch (error) {
        console.log(error);
      }
    },
    remove(item) {
      const index = this.groupfriends.indexOf(item.name);
      if (index >= 0) this.groupfriends.splice(index, 1);
    },
    sendIOMessage(message) {
      this.$socketio.sendEvent({
        type: "new_message",
        data: message,
      });
    },
    sendGPMessage(message) {
      this.$socketio.sendEvent({
        type: "group_message",
        data: message,
      });
    },
    async findcoach() {
      this.notificationtext = "Comming soon";
      this.snackbar = true;
    },
    async connectmsg() {
      console.log(this.messagerequestitem);
      var connectlist = [];
      var index = 0;
      const selectedActivityTmp = this.selectedActivity;
      this.addfriendmessage = "";
      try {
        if (this.selecteduser === null) {
          return;
        }
        if (this.userid === 0) {
          return;
        }
        console.log(this.selecteduser);
        while (this.selecteduser[index] >= 0) {
          var friendconn = {
            iduser: 0,
            idfriend: 0,
            idactivity: 0,
            approved: 0,
            chat: 0,
            message: "",
            connectmsg: "",
          };
          friendconn.iduser = this.userid;
          var pos = this.selecteduser[index];
          friendconn.idfriend = this.userlist[pos].id;
          friendconn.idactivity = this.selectedActivity;
          // let nr = this.selectedActivity
          // let searchinfo = this.interestlist[nr] + ' ' + this.selectedSkill
          friendconn.message = this.selectedrequestmessage;
          connectlist.push(friendconn);
          index = index + 1;
        }
        var credentials = { list: [], request: true };
        credentials.list = connectlist;
        const response = await UserService.saveMyFriendRequest(credentials);
        if (response.data.response.localeCompare("OK") != 0) {
          this.addfriendmessage = response.data.response;
        } else {
          this.addfriendmessage = "Friend Request sent";
          this.notificationtext = "Friend Request sent";
          this.snackbar = true;
          this.resetSearch();
          this.selectedActivity = selectedActivityTmp;
        }
        console.log(response.data);
        // this.reset ()
      } catch (error) {
        console.log(error);
      }
    },
    async connectmsgrequest() {
      // This is for single message to request
      console.log(this.messagerequestitem);
      const selectedActivityTmp = this.selectedActivity;
      this.addfriendmessage = "";
      var connectlist = [];
      try {
        if (this.messagerequestitem === null) {
          return;
        }
        if (this.userid === 0) {
          return;
        }
        console.log(this.messagerequestitem);

        var friendconn = {
          iduser: 0,
          idfriend: 0,
          idactivity: 0,
          approved: 0,
          chat: 0,
          message: "",
          connectMsg: "",
          type: "user",
        };
        friendconn.iduser = this.userid;
        friendconn.idfriend = this.messagerequestitem.id;
        friendconn.idactivity = this.selectedActivity;
        friendconn.connectMsg = this.sendfriendrequestmessage;
        friendconn.message = this.sendfriendrequestmessage;
        connectlist.push(friendconn);
        var credentials = { list: [], request: true };
        credentials.list = connectlist;
        const response = await UserService.saveMyFriendRequest(credentials);
        if (response.data.response.localeCompare("OK") != 0) {
          this.addfriendmessage = response.data.response;
          this.notificationtext =
            "Friend Request exist ! This shouldn't happen";
          this.snackbar = true;
        } else {
          this.addfriendmessage = "Friend Request sent";
          this.notificationtext = "Friend Request sent";
          this.snackbar = true;
        }
        console.log(response.data);
        var pos = this.userlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(this.messagerequestitem.id);
        this.userlist.splice(pos, 1);
        this.resetSearch();
        this.selectedActivity = selectedActivityTmp;
      } catch (error) {
        if (response.data.response) {
          this.addfriendmessage = response.data.response;
        }
        console.log(error);
        this.resetSearch();
      }
    },
    async connect() {
      var connectlist = [];
      var index = 0;
      try {
        if (this.selecteduser === null) {
          return;
        }
        if (this.userid === 0) {
          return;
        }
        console.log(this.selecteduser);
        while (this.selecteduser[index]) {
          var friendconn = {
            iduser: 0,
            idfriend: 0,
            approved: false,
            chat: false,
          };
          friendconn.iduser = this.userid;
          friendconn.idfriend = this.selecteduser[index].id;
          connectlist.push(friendconn);
          index = index + 1;
        }
        const response = await UserService.saveMyFriendRequest(connectlist);
        console.log(response);
      } catch (error) {
        this.error = error.response.data.error;
      }
    },
    async approveFriendRequest(item) {
      console.log(item);
      var friendconn = { iduser: 0, idfriend: 0 };
      try {
        friendconn.iduser = item.Friends.iduser;
        friendconn.idfriend = item.Friends.idfriend;
        const response = await UserService.approveMyFriendRequest(friendconn);
        // this.getFriendsRequests(1);
        await this.getAllRequests();
        this.RequestBadge--;
        this.notificationtext = "You approved friend request";
        this.snackbar = true;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    async removeFriendRequest(item) {
      console.log(item);
      var friendconn = { id: 0 };
      try {
        // friendconn.id = item.Friends.id;
        const response = await UserService.removeMyFriendRequest({
          id: item.Friends.id,
        });
        // this.getFriendsRequests(1);
        await this.getAllRequests();
        console.log(response);
        this.RequestBadge--;
      } catch (error) {
        this.error = error.response.data.error;
      }
    },
    async approveGroupRequest(item) {
      console.log(item);
      try {
        const response = await GroupServices.updateUserGroup({
          id: item.UserGroups.id,
          joined: true,
        });
        await this.getAllRequests();
        this.RequestBadge--;
        this.notificationtext = "You approved group request";
        this.snackbar = true;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    async removeGroupRequest(item) {
      try {
        const response = await GroupServices.deleteUserGroup({
          id: item.UserGroups.id,
        });
        this.getAllRequests();
        this.RequestBadge--;
        this.notificationtext = "You removed usergroup request";
        this.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData(addressData, placeResultData, id) {
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      this.searchPlace = this.placeResultData.formatted_address;
      this.city = this.searchPlace;
      this.id = id;
    },
    /**
     * When the location found
     * @param {Object} addressHome Data of the found location
     * @param {Object} placeResultHome PlaceResult object
     * @param {String} idHome Input container ID
     */
    getHomeCity(addressHome, placeResultHome, idHome) {
      this.addressHome = addressHome;
      this.placeResultHome = placeResultHome;
      this.idHome = idHome;
      this.city = this.placeResultHome.formatted_address;
      this.country = addressHome.country;
      this.state = addressHome.administrative_area_level_1;
    },
    /**
     * When the location found
     * @param {Object} addressFacility Data of the found location
     * @param {Object} placeResultFacility PlaceResult object
     * @param {String} idFacilityHome Input container ID
     */
    getFacilityHomeCity(addressFacility, placeResultFacility, idFacilityHome) {
      this.addressFacility = addressFacility;
      this.placeResultFacility = placeResultFacility;
      this.idFacilityHome = idFacilityHome;
      this.facilityCity = this.placeResultFacility.formatted_address;
      this.facilityCountry = addressFacility.country;
      this.facilityState = addressFacility.administrative_area_level_1;
      this.addFacility();
    },
    /**
     * When the location found
     * @param {Object} addressCompany Data of the found location
     * @param {Object} placeResultCompany PlaceResult object
     * @param {String} idHomeCompany Input container ID
     */
    getCompanyCity(addressCompany, placeResultCompany, idHomeCompany) {
      this.addressCompany = addressCompany;
      this.placeResultCompany = placeResultCompany;
      this.idHomeCompany = idHomeCompany;
      this.companycity = this.placeResultCompany.formatted_address;
      this.companycountry = addressCompany.country;
      this.companystate = addressCompany.administrative_area_level_1;
    },
    async initUserlist() {
      try {
        // const response = await SearchesService.searchListByActivity(this.userid, this.selectedActivity);
        await this.search();
        // this.userlist = response.data;
      } catch (error) {
        console.log(response);
      }
    },
    async search() {
      try {
        this.errorsearchfacilitymsg = "";
        this.errorsearchgender = "";
        this.searchdialog = false;
        this.menuA = false;
        let activity = this.selectedActivity;
        let facilities = this.selectedActivityFacilities;
        let skills = this.selectedSkill;
        let gender = this.selectedGender;
        let agefrom = this.selectedAgefrom;
        let ageto = this.selectedAgeto;
        let datefromto = this.datefromto;
        let singledates = this.singledates;
        let name = this.searchnamefield;
        let place = this.city;

        if (activity) {
          console.log(activity);
          if (activity === "activity") {
            this.errorsearchactivitymsg = "Activity needed";
            return;
          }
          this.errorsearchactivitymsg = "";
        } else {
          this.errorsearchactivitymsg = "Activity needed";
          return;
        }

        if (facilities.length > 0) {
          console.log(facilities);
        } else {
          facilities = "";
        }

        if (skills) {
          console.log(skills);
        }

        if (place) {
          console.log(place);
        }

        if (name) {
          console.log(name);
        }

        if (gender.length > 0) {
          console.log(gender);
          this.errorsearchgendermsg = "";
        } else {
          this.errorsearchgendermsg = "Select man or woman or both";
        }

        if (agefrom) {
          console.log(agefrom);
        }

        if (ageto) {
          console.log(ageto);
        }

        if (datefromto) {
          console.log(datefromto);
        }

        if (singledates) {
          console.log(singledates);
        }

        this.userlist = [];

        // Check namefield first
        if (this.searchnamefield) {
          this.userlist = (
            await SearchesService.searchFriendByName(
              this.userid,
              name,
              place,
              gender,
              agefrom,
              ageto
            )
          ).data;
          if (this.userlist.length > 0) this.searchOk = true;
          else this.searchOk = false;
          return;
        }

        if (this.userlist.length > 0) {
          return;
        }
        /*
        if (facilities.length === 0 && place === "") {
          this.errorsearchfacilitymsg =
            "Use facility or Place together with Activity"
          return;
        }*/

        const users = await SearchesService.searchActivityPartner(
          this.userid,
          activity,
          facilities,
          skills,
          place,
          name,
          gender,
          agefrom,
          ageto,
          datefromto,
          singledates
        );
        this.userlist = users.data;
      } catch (error) {
        console.log(error);
        this.searchOk = false;
      }
      console.log(this.userlist);
      if (this.userlist.length > 0) this.searchOk = true;
      else this.searchOk = false;
    },
    async searchCoach() {
      this.searchdialog = false;
      this.menuA = false;
      const gender = "";
      const activity = this.selectedActivity;
      const facilities = this.selectedActivityFacilities;
      const place = this.city;

      this.userlist = [];
      try {
        const coaches = await SearchesService.searchActivityPartner(
          this.userid,
          activity,
          facilities,
          place,
          gender
        );
        this.userlist = coaches.data;
        // this.userlist = this.mySearch
      } catch (error) {
        this.error = error.response.data.error;
        this.searchOk = false;
      }
      console.log(this.userlist);
      if (this.userlist.length > 0) {
        this.searchOk = true;
      } else {
        this.searchOk = false;
      }
    },
    async searchFriendByName(Userid, Name) {
      try {
        // const Approved = approved
        console.log(Name);
        this.userlist = [];
        const response = await SearchesService.searchFriendByName(Userid, Name);
        this.userlist = response.data;
      } catch (error) {
        console.log(error);
      }
      console.log(this.userlist);
    },
    async getGroups() {
      try {
        this.myfriendslistsheader = "GROUPS";
        this.friendgrouplist = [];
        this.GroupBadge = 0;
        var menu = this.menuMessagesG;
        console.log(menu);
        //const Approved = approved
        // this.friendlist = []
        const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupsMessages(
          this.userid,
          activityid
        );
        this.friendgrouplist = response.data;
        const tmp = response.data;
        console.log(tmp);
        var index = 0;
        while (this.friendgrouplist[index]) {
          if (
            this.friendgrouplist[0].message !== " No message yet " &&
            (this.friendgrouplist[index].Read.msgread === null ||
              this.friendgrouplist[index].Read.msgread === 0)
          ) {
            this.GroupBadge++;
          }
          index++;
        }
      } catch (error) {
        console.log(error);
      }
      // if (this.myfriendslistsheader !== "GROUPS") {
      this.myfriendslistsheader = "GROUPS";
      // }
      // this.setTBSelectedColor();
    },
    async getFriends(approved) {
      try {
        // const Approved = approved
        this.FriendBadge = 0;
        console.log(approved);
        var menu = this.menuMessagesF;
        console.log(menu);
        // this.menuMessagesF = false
        const appr = 1;
        // this.friendlist = [];
        const activityid = this.ActiveActivityID;
        // const response = await SearchesService.getActivityFriends(this.userid, appr)
        const response = await SearchesService.getFriendsAndLatestMessage(
          this.userid,
          activityid,
          appr
        );
        this.friendlist = response.data;

        var index = 0;
        while (this.friendlist[index]) {
          if (
            this.friendlist[index].Msg.receiverread === 0 &&
            this.friendlist[index].Msg.id > 0
          ) {
            this.FriendBadge++;
          }
          index++;
        }
        let len = this.friendlist.length;
        console.log(len);
        // console.log(this.friendlist);
      } catch (error) {
        console.log(error);
      }
      // console.log(this.friendlist);
      if (this.myfriendslistsheader !== "FRIENDS") {
        this.myfriendslistsheader = "FRIENDS";
        // this.setTBSelectedColor()
      }
    },
    async getFriendsRequests(approved) {
      if (this.userid === 0) return;
      try {
        // this.myfriendslistsheader = "REQUESTS";
        const Approved = approved;
        this.requestlist = [];
        const activityid = this.ActiveActivityID;
        const response = await SearchesService.getActivityRequests(
          this.userid,
          activityid,
          Approved
        );
        this.requestlist = response.data;
        this.RequestBadge = this.requestlist.length;
        // this.myfriendslistsheader = "REQUESTS";
      } catch (error) {
        console.log(error);
      }
      this.myfriendslistsheader = "REQUESTS";
      // this.setTBSelectedColor()
      console.log(this.requestlist);
    },
    async getUserGroupRequests() {
      try {
        const Joined = false;
        this.usergrouprequest = [];
        const response = await GroupServices.getUserGroupRequests(
          this.userid,
          this.ActiveActivityID,
          Joined
        );
        this.usergrouprequest = response.data;
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getAllRequests() {
      const approved = 0;
      await this.getFriendsRequests(approved);
      await this.getUserGroupRequests();
      this.userallrequests = [];
      let post = { type: "", Friends: null, UserGroups: null, createdAt: "" };
      let index = 0;
      while (this.usergrouprequest[index]) {
        post.Friends = null;
        post.type = "group";
        post.UserGroups = this.usergrouprequest[index];
        post.createdAt = this.usergrouprequest[index].createdAt;
        this.userallrequests.push(post);
        index++;
      }
      index = 0;
      while (this.requestlist[index]) {
        post.Friends = this.requestlist[index];
        post.type = "friend";
        post.UserGroups = null;
        post.createdAt = this.requestlist[index].createdAt;
        this.userallrequests.push(post);
        index++;
      }
      this.myfriendslistsheader = "REQUESTS";
    },
    async manageGroupOffers() {
      try {
        this.groupoffersdialog = true;
        this.groupofferslist = [];
        /*
        const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupOffers(
          this.userid,
          activityid
        );
        this.groupofferslist = response.data;
        */
      } catch (error) {
        console.log(error);
      }
    },
    acceptOffer() {
      console.log("acceptOffer");
    },
    async getGroupOffers() {
      if (this.userid === 0) return;
      try {
        this.groupoffersdialog = true;
        this.myfriendslistsheader = "REQUESTS";
        this.requestlist = [];
        const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupOffers(
          this.userid,
          activityid
        );
        this.requestlist = response.data;
        this.myfriendslistsheader = "REQUESTS";
      } catch (error) {
        console.log(error);
      }
      this.myfriendslistsheader = "REQUESTS";
      console.log(this.list);
    },
    async waitingRequests() {
      if (this.userid === 0) return;
      try {
        this.friendRequest = false;
        const Approved = false;
        this.friendlist = [];
        const activityid = this.ActiveActivityID;
        const response = await SearchesService.getWaitingFriends(
          this.userid,
          activityid,
          Approved
        );
        this.friendlist = response.data;
        // this.userlist = this.mySearch
        this.myfriendslistsheader = "WAITING";
      } catch (error) {
        console.log(error);
      }
    },
    reset() {
      console.log("Before reset");
      this.addfriendmessage = "";
      this.searchnamefield = "";
      this.password = "";
      this.newpassword = "";
      this.userid = 0;
      this.username = "";
      this.name = "";
      this.User = null;
      this.UserUpd = null;
      this.displayname = "";
      this.datefromto = "";
      this.singledates = "";
      this.selectedAgefrom = "";
      this.selectedAgeto = "";
      this.selectedGender = ["man", "woman"];
      this.currentPlace = null;
      this.addressData = null;
      this.placeResultData = null;
      this.searchOk = false;
      this.selectedActivityFacilities = [];
      this.selectedSkill = [];
      this.errorsearchfacilitymsg = "";
      this.errorsearchactivitymsg = "";
      this.errorsearchgendermsg = "";
      this.errorloginmsg = "";
    },
    async resetSearch() {
      console.log("Before reset");
      this.datefromto = "";
      this.singledates = "";
      // this.$refs.searchaddress.clear();
      this.searchnamefield = "";
      this.searchPlace = "";
      this.place = "";
      // this.userlist = [];
      // this.friendlist = []
      this.activityfacilities = [];
      // this.interestlist = []
      this.selectedActivityFacilities = [];
      this.selectedGender = ["man", "woman"];
      this.selectedSkill = "";
      this.selectedAgefrom = "";
      this.selectedAgeto = "";
      this.currentPlace = null;
      this.addressData = null;
      this.placeResultData = null;
      this.selectedusermessage = "";
      this.addfriendmessage = "";
      this.searchOk = false;
      this.selecteduser = [];
      this.messagerequestitem = null;
      this.sendfriendrequestmessage = "";
      if (this.selectedActivity === this.ActiveActivityID) {
        await this.search();
      }
      this.selectedActivity = this.ActiveActivityID;
    },
    friendsreset() {
      this.friendlist = [];
      this.selectedfriend = [];
      this.friendRequest = false;
    },
    loginRoute() {
      this.$router.push({
        name: "loginpre",
        // params: {
        //  showlogin: true
        // }
      });
    },
    loginRouteOtherUser() {
      this.$router.push({
        name: "login",
        // params: {
        //  showlogin: true
        // }
      });
    },
    loginDialog() {
      this.signindialog = true;
    },
    async login() {
      let response = null;
      this.signindialog = false;
      // let user = null;
      // let saveLogin = "1"; //this.cookie;
      try {
        console.log("Before login call");
        var username = this.username;
        console.log(username + this.password);

        // if (this.cookie === "1") {

        if (!this.username) {
          var userkey = await this.getUserData();
          // userkey = window.$cookies.get("user");
          // console.log('cookies get user ' + userkey);
          // let userkey = await getUserDataIndexDB();
          if (userkey) {
            this.username = userkey.username;
            this.password = userkey.password;
          }
        }
        // }
        if (this.showLoginWindow === true) {
          this.showLoginWindow = false;
          return;
        }
        response = await AuthenticationService.login({
          username: this.username,
          password: this.password,
        });
        /* turn off for the moment , send email checks instead 
        if (response.data.user.verified === false || response.data.user.verified === 0) {
          this.errorloginmsg = "You have to confirm your email adress, check your emails"
          MailService.sendVerificationEmail({emailto: response.data.user.email})
          return
        } */
        // this.playMP3 ()

        this.personnumber = response.data.user.personnumber;
        this.checkIfChild();
        // agebelow15days
        if (this.agebelow15 === true && response.data.user.parentid === 0) {
          this.getSystemInfo();
          const check = this.checkDaysFromRegistered(
            response.data.user.createdAt
          );
          if (check) {
            this.$router.push({
              name: "loginchildcheck",
              params: {
                userid: response.data.user.id,
                username: response.data.user.username,
                child: true,
              },
            });
          }
        }

        console.log("After login call");
        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.user);
        this.userid = response.data.user.id;

        // Tokens.checkToken(this.token, this.userid);

        if (this.cookie === "1") {
          try {
            // userkey = window.$cookies.get("user");
            // console.log('cookie.get user ' + userkey);
            // let userkey = await getUserDataIndexDB();
            userkey = await this.getUserData();
            if (userkey.username === "") {
              /* user = {
                id: this.userid,
                username: this.username,
                password: this.password,
              }; */
              // await saveUserDataIndexDB(this.username, this.password);
              // let ret = window.$cookies.set('bearer', response.data.token);
              // console.log('this.$cookies.set response.data.token ' + response.data.token + ' ret === ' + ret)
              // const ret = window.$cookies.set("user", user);
              // console.log('this.$cookies.set ret ===' + ret);
              await this.setUserData(this.username, this.password);
            } else {
              /* user = {
                id: this.userid,
                username: this.username,
                password: this.password,
              }; */
              if (userkey.username === this.username) {
                if (userkey.password !== this.password) {
                  // this.$cookies.set('bearer', response.data.token);
                  // this.$cookies.set("user", user);
                  await this.setUserData(this.username, this.password);
                }
              }
              if (userkey.username !== this.username) {
                // this.$cookies.set('bearer', response.data.token);
                // this.$cookies.set("user", user);
                await this.setUserData(this.username, this.password);
              }
            }
          } catch (error) {
            console.log(error);
            this.openWarningSnackBarMsg(
              "An error when saving your login data, you can continue. But if it happens again, send a feed back note"
            );
          }
        } else {
          await this.setUserData("uuu", "ppp");
        }

        this.signindialog = false;
        this.loginInit();
      } catch (error) {
        console.log(error);
        this.error = error;
        this.errorloginmsg = "Wrong Username or Password";
        if (this.username === "uuu") {
          this.username = "";
          this.password = "";
        }
        this.signindialog = true;
        this.UserSignedIn = false;
        return;
      }
    },
    async setUserData(username, password) {
      try {
        await SecureStoragePlugin.set({ key: "user", value: username })
          .then((success) => console.log(success))
          .catch((error) => {
            console.log(error);
          });
        await SecureStoragePlugin.set({ key: "password", value: password })
          .then((success) => console.log(success))
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("error in setUserData");
      }
    },
    async getUserData() {
      let ret = { username: "", password: "" };
      await SecureStoragePlugin.get({ key: "user" })
        .then((value) => {
          console.log(value);
          ret.username = value.value;
        })
        .catch((error) => {
          console.log("Item with specified key does not exist." + error);
        });
      await SecureStoragePlugin.get({ key: "password" })
        .then((value) => {
          console.log(value);
          ret.password = value.value;
        })
        .catch((error) => {
          console.log("Item with specified key does not exist." + error);
        });
      return ret;
    },
    async loginInit() {
      var user = null;
      try {
        // this.$store.dispatch("setToken", response.data.token);
        // this.$store.dispatch("setUser", response.data.user);

        this.userid = this.$store.state.user.id;
        // var userId = this.userid;
        this.username = this.$store.state.user.username;
        this.name = this.$store.state.user.name;
        this.User = this.$store.state.user;
        this.UserUpd = this.User;
        this.personnumber = this.$store.state.user.personnumber;
        this.displayname = this.username;
        this.ActiveActivityID = this.$store.state.user.defaultactivity;
        this.DefaultActivityID = this.$store.state.user.defaultactivity;
        this.selectedDefaultActivity = this.$store.state.user.defaultactivity;
        this.selectedActiveActivity = this.$store.state.user.defaultactivity;
        // this.selectedTheme = response.data.user.theme;
        // var activeactivity = this.ActiveActivityID;
        // this.token = response.data.user.token
        Tokens.checkToken(this.token, this.userid);

        this.UserSignedIn = true;
        if (this.$store.state.user.coach === 1) {
          this.IsCoach = true;
        } else {
          this.IsCoach = false;
        }
        var soundon = this.$store.state.user.sound;
        this.soundon = soundon.toString();
        this.soundfile = this.$store.state.user.soundfile;
        var notifications = this.$store.state.user.notifications;
        this.notifications = notifications.toString();

        await this.getSystemInfo();

        // this.setPlayer();
        // this.play();
        // this.searchPlace = response.data.user
        // await this.getFriends (true)
        await this.getActivities();
        // await this.getActivityFacilities ()
        await this.initUserActivities();
        // await this.getFriendsRequests(0);
        // await this.getGroups();
        await this.getFriends(true);
        // await this.getGroups();
        // sawait this.getMessages(true);
        await this.getAllActivitySkills();

        console.log("After getMessage in login");

        this.$socketio.keepAlive({
          type: "srvping",
          callback: (message) => {
            this.$socketio.sendEvent({
              type: "clipong",
              data: message,
            });
          },
        });

        this.$socketio.sendEvent({
          type: "register",
          data: { userid: this.userid, token: this.token },
        });

        const groupids = await this.getUserGroupsIds();
        if (groupids.length > 0) {
          this.$socketio.sendEvent({
            type: "registergroups",
            data: groupids,
          });
        }

        this.$socketio.addEventListener({
          type: "connect",
          callback: () => {
            this.onConnect();
            // console.log(this.$socketio.id + message)
          },
        });

        this.$socketio.addEventListener({
          type: "disconnect",
          callback: () => {
            this.$socketio.socketOff();
          },
        });

        this.$socketio.addEventListener({
          type: "new_message",
          callback: (message) => {
            this.checknoduplicates(message);
          },
        });
        /*
        this.$socketio.addEventListener({
          type: "group_message",
          callback: (message) => {
            this.checknoduplicatesGroups(message);
          },
        });
        */

        this.signin = true;
        this.menu_tabs = "tab-messages";
        // this.audio.play()
        await this.getUserSettings();
        await this.getMyProfileImages();
        // await this.getBookings();
        await this.getAccessRights(false);
        // this.play ()
        // this.afterregisterdialog = true
        // this.platform = Capacitor.platform;
        this.myfriendslistsheader = "FRIENDS";
      } catch (error) {
        this.error = error;
        this.errorloginmsg = "Wrong Username or Password";
        this.UserSignedIn = false;
        return;
      }
    },
    async onConnect() {
      this.$socketio.keepAlive({
        type: "srvping",
        callback: (message) => {
          this.$socketio.sendEvent({
            type: "clipong",
            data: message,
          });
        },
      });

      this.$socketio.sendEvent({
        type: "register",
        data: { userid: this.userid, token: this.token },
      });

      const groupids = await this.getUserGroupsIds();
      if (groupids.length > 0) {
        this.$socketio.sendEvent({
          type: "registergroups",
          data: groupids,
        });
      }

      this.$socketio.addEventListener({
        type: "disconnect",
        callback: (message) => {
          this.$socketio.socketOff();
        },
      });
      /*
      this.$socketio.addEventListener({
        type: "new_message",
        callback: (message) => {
          this.checknoduplicates(message);
        },
      });
      */
      /*
      this.$socketio.addEventListener({
        type: "group_message",
        callback: (message) => {
          this.checknoduplicatesGroups(message);
        },
      });
      */
    },
    async SignOut() {
      this.signinbutton = "Sign In";
      this.UserSignedIn = false;
      this.errorloginmsg = "";
      this.password = "";
      this.newpassword = "";
      this.userid = 0;
      this.username = "";
      this.name = "";
      this.email = "";
      this.personnumber = "";
      this.parentusername = "";
      this.IsCoach = false;
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.selectedTheme = false;
      // this.User = null;
      // this.UserUpd = null;
      // this.menu_tabs = "tab-main"
      // this.reset()
    },
    async setActiveActivity() {
      // this.setactivedialog = false;
      const act = this.selectedActiveActivity;
      if (act > 0) {
        this.ActiveActivityID = act;
      }
      try {
        // this.$refs.NewsFeed.setActivityId(this.ActiveActivityID);
      } catch (err) {
        console.log(err);
      }
      // this.selectedActivity = act;
      // this.selectedActiveActivity = [2]
      await this.initUserActivities();
      await this.getFriends();
      await this.getAccessRights(true);
      // await this.getAllActivitySkills ()
      // await this.getActivitySkills ()
      // await this.getActivityFacilities ()
      const groupids = await this.getUserGroupsIds();
      if (groupids.length > 0) {
        this.$socketio.sendEvent({
          type: "registergroups",
          data: groupids,
        });
      }
      console.log(act);
      this.setactivedialog = false;
    },
    async register() {
      var response = null;
      var parentid = 0;
      try {
        console.log("before Authentication");
        var iscoach = 0;
        this.error = "";
        this.errorname = "";
        this.errorusername = "";
        this.errorpersonnumber = "";
        this.errorparentusername = "";
        this.errorcity = "";
        this.errorgender = "";
        this.erroremail = "";
        this.errorunidentified = "";
        this.errorgdpr = "";
        if (this.password !== this.password2) {
          this.errorpswmatch = "Password missmatch !";
          return;
        }
        const below13 = this.checkIfBelow9();
        if (below13) {
          return;
        }
        if (this.name === "") {
          this.errorname = "Name missing";
          return;
        }
        if (this.username === "") {
          this.errorusername = "User name missing";
          this.openSnackBarMsg("User name missing");
          return;
        }
        if (this.city === "") {
          this.errorcity = "Place missing";
          return;
        }
        if (this.gender === "") {
          this.errorgender = "Gender missing";
          return;
        }
        if (this.personnumber === "") {
          this.errorpersonnumber = "Birthdate missing";
          return;
        }
        if (this.selectedDefaultActivity === "") {
          this.errorsearchactivitymsg =
            "You have to select an default activity";
          return;
        }
        if (this.gdpr === 0) {
          this.errorgdpr = "You have to check GDPR";
          return;
        }
        if (this.IsCoach === true) {
          iscoach = 1;
        }

        let useravatar = "";
        try {
          if (this.gender === "woman") {
            useravatar = "https://findhavefun.com/images/fhf/001-girl-1.png";
          } else {
            useravatar = "https://findhavefun.com/images/fhf/007-boy-1.png";
          }
        } catch (error) {
          console.log(error);
        }

        if (this.agebelow15 === true) {
          // if (this.parentusername === "12345678" || this.parentusername === "") {
          //  parentid = 0;
          //  this.parentusername = "12345678";
          // } else {
          if (this.parentusername) {
            const parent = await UserService.getUserData(
              0,
              this.parentusername
            );
            console.log(parent);
            if (parent.data === "") {
              this.errorparentusername = "Parent User Name doesn't exist";
              return;
            }
            parentid = parent.data.id;
          }
        }

        response = await AuthenticationService.register({
          username: this.username,
          parentusername: this.parentusername,
          email: this.email,
          password: this.password,
          name: this.name,
          personnumber: this.personnumber,
          countrycode: this.countrycode,
          phone: this.phone,
          mobile: this.mobile,
          country: this.country,
          state: this.state,
          city: this.city,
          gender: this.gender,
          description: this.description,
          sharecontactinfo: this.sharecontactinfo,
          showprofileinfo: this.showprofileinfo,
          testuser: 0,
          verified: 0,
          defaultactivity: this.selectedDefaultActivity,
          gdpr: this.gdpr,
          coach: iscoach,
          parentid: parentid,
          // dialog: 0,
          sound: 1,
          notifications: 0,
          soundfile: this.defaultsoundfile,
          avatar: useravatar,
          friendable: 1,
        });
        console.log(response);
        if (response.data.status === 400) {
          var str = response.data.error.fields[0];
          if (str.localeCompare("username") === 0) {
            this.errorusername = "User name exist";
            // this.errorusername = response.data.error.errors[0].message
            // this.errorunidentified = response.data.error.errors[0].message
            this.errorunidentified = "User name exist";
            this.openSnackBarMsg("User name exist");
            return;
          }
          if (str.localeCompare("email") === 0) {
            this.erroremail = "Email adress exist";
            // response.data.error.errors[0].message
            this.errorunidentified = "Email adress exist";
            this.openSnackBarMsg("Email adress exist");
            // response.data.error.errors[0].message
            return;
          }
          this.errorunidentified = "Error when register !";
          return;
        }
        if (this.gdpr === 0) {
          this.errorgdpr = "You have to check GDPR";
          return;
        }

        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.user);
        this.userid = response.data.user.id;
        this.username = "";
        this.email = "";
        this.name = "";
        this.personnumber = "";
        this.password = "";
        this.password2 = "";
        this.city = "";
        this.description = "";
        // this.selectedactivities = [this.selectedDefaultActivity]
        const defaultActivity = this.selectedDefaultActivity;
        response = await UserService.addUserActivity({
          iduser: this.userid,
          idactivity: defaultActivity,
        });
        // this.saveUserActivities ()
        // this.selectedactivities = []
        // this.UserSignedIn = true
        // const update = await AuthenticationService.update({
        //  id: this.userid,
        //  parentuser: this.parentusername
        // })
        // console.log(update)
        this.userdialog = false;
        this.afterregisterdialog = true;
      } catch (error) {
        console.log("Error" + this.error);
        this.error = response.data.error;
        console.log(this.error);
      }
    },
    openSnackBarMsg(msg) {
      this.notregtext = msg;
      this.snackbarregmsg = true;
    },
    openWarningSnackBarMsg(msg) {
      this.notregtext = msg;
      this.snackbarwarningmsg = true;
    },
    updateCanceled() {
      this.personnumbermenu = false;
    },
    registerCanceled() {
      this.error = "";
      this.errorname = "";
      this.errorusername = "";
      this.errorpersonnumber = "";
      this.errorcity = "";
      this.errorgender = "";
      this.erroremail = "";
      this.errorunidentified = "";
      this.pswnotmatcherror = "";
      this.errorgdpr = "";
      this.username = "";
      this.email = "";
      this.password = "";
      this.name = "";
      this.personnumber = "";
      this.countrycode = "";
      this.phone = "";
      this.mobile = "";
      this.country = "";
      this.state = "";
      this.city = "";
      this.gender = "";
      this.description = "";
      this.sharecontactinfo = 0;
      this.showprofileinfo = 0;
      this.gdpr = 0;
      this.avatar = "";
      this.userdialog = false;
      this.personnumbermenu = false;
    },
    checknoduplicates(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      return;
      var messageBadge = 0;
      try {
        var index = this.friendlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          this.friendlist[index].message = message.message;
          this.friendlist[index].messageAt = message.createdAt;
          if (this.userid === message.idreceiver) {
            // if (this.friendlist[index].User.msgbadge === 0) {
            // }
            this.friendmsgbadge++;
            this.FriendBadge++;
            this.friendlist[index].User.msgbadge++;
            // this.play();
            this.playNative();
          }
          // this.sound.play()
        }
      } catch (error) {
        console.log(error);
      }
    },
    checknoduplicatesGroups(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      try {
        return;
        var index = this.friendgrouplist
          .map(function(e) {
            return e.groupid;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          this.friendgrouplist[index].message = message.message;
          this.friendgrouplist[index].messageAt = message.createdAt;
          if (this.userid !== message.idsender) {
            this.friendgrouplist[index].Group.msgbadge++;
            this.GroupBadge++;
            this.play();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async update() {
      try {
        this.errorsupdateactivitymsg = "";
        console.log("before Authentication");
        this.error = "";
        /*
        if ()
          UserUpd.name
          UserUpd.email
          UserUpd.personnumber
          UserUpd.countrycode
          UserUpd.phonehome
          UserUpd.phonemobile
          UserUpd.city this.city
        */
        const defact = this.selectedDefaultActivity;
        const pnr = this.personnumber;
        if (this.city === "") {
          // City not change save what we have
          this.city = this.UserUpd.city;
          this.country = this.UserUpd.country;
          this.state = this.UserUpd.state;
        }
        if (pnr === "") {
          this.errorsupdateactivitymsg = "Please add birtdate";
        }
        if (this.IsCoach === true) {
          this.UserUpd.coach = 1;
        } else {
          this.UserUpd.coach = 0;
        }
        const response = await AuthenticationService.update({
          id: this.userid,
          email: this.UserUpd.email,
          name: this.UserUpd.name,
          personnumber: pnr,
          countrycode: this.UserUpd.countrycode,
          phone: this.UserUpd.phone,
          mobile: this.UserUpd.mobile,
          country: this.country,
          state: this.state,
          city: this.city,
          description: this.UserUpd.description,
          showProfileInfo: 0,
          defaultactivity: defact,
          coach: this.UserUpd.coach,
        });
        console.log(response);
        if (defact !== this.ActiveActivityID) {
          this.selectedActiveActivity = defact;
          this.setActiveActivity();
        }
        // this.$store.dispatch("setToken", response.data.token)
        // this.$store.dispatch("setUser", response.data.user)
        // this.email = ""
        // this.name = ""
        // this.personnumber = ""
        // this.city = ""
        // this.description = ""
        // this.UserSignedIn = true
        this.userupdatedialog = false;
        this.notificationtext = "Your profile is updated";
        this.snackbar = true;
      } catch (error) {
        console.log("Error" + this.error);
        this.error = error.response.data.error;
        console.log(this.error);
      }
    },
    async updateSettings() {
      try {
        // this.errorsupdateactivitymsg = ""
        console.log("in updateSetting");
        this.error = "";
        this.UserUpd.soundon = Number(this.soundon);
        this.UserUpd.soundfile = this.soundfile;
        if (this.notifications === "1") this.UserUpd.notifications = 1;
        else {
          this.notifications = 0;
        }

        const response = await AuthenticationService.updateSettings({
          id: this.userid,
          soundon: this.soundon,
          soundfile: this.soundfile,
          notifications: this.notifications,
        });
        console.log(response);
        // this.$store.user.soundon = this.UserUpd.soundon
        // this.$store.user.soundfile = this.UserUpd.soundfile
        // this.$store.user.notifications = this.UserUpd.notifications
        this.setupdialog = false;
        this.themesDialog = false;
      } catch (error) {
        console.log("Error" + this.error);
      }
    },
    async setUserSettings() {
      // this.errorsupdateactivitymsg = ""
      console.log("in setUserSetting " + this.selectedTheme);

      if (this.userSettings.id === 0) {
        await this.addUserSettings();
      }
      if (this.userSettings.id === this.userid) {
        await this.updateUserSettings();
      }
      this.themesDialog = false;
    },
    async updateToken() {
      try {
        // this.errorsupdateactivitymsg = ""
        console.log("in updateToken");
        this.error = "";
        const response = await AuthenticationService.updateToken({
          id: this.userid,
          token: this.token,
        });
        console.log(response);
      } catch (error) {
        console.log("Error" + this.error);
      }
    },
    showGroupUsers(item) {
      console.log(item);
    },
    async manageProfile() {
      this.checkIfChild();
      if (this.agebelow15 === true) {
        const parent = await UserService.getUserData(this.User.parentid, "");
        console.log(parent);
        if (parent.data) {
          this.parentusername = parent.data.username;
        }
      }
      this.userupdatedialog = true;
    },
    managePassword() {
      this.passwordDialog = true;
    },
    manageThemes() {
      this.themesDialog = true;
    },
    async sendPassword() {
      try {
        console.log("before Authentication");
        console.log(this.newpassword);
        if (this.newpassword !== this.newpassword2) {
          this.errorpswmatch = "Password missmatch !";
          return;
        }
        const response = await AuthenticationService.updatePassword({
          id: this.userid,
          password: this.newpassword,
        });
        this.User.password = response.data.user.password;
        // this.$store.dispatch("setUser", response.data.user)
        this.passwordDialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    async opengroupUpdate(item) {
      try {
        console.log(item);
        item.Group.dialog = true;
        this.itemupdgroupusers = item;
        // console.log(this.selectedupdgroupusers)
        this.groupid = item.groupid;
        this.groupuserid = item.Group.userid;
        this.selectedupdgroupusers = [];
        this.updgroupname = item.Group.name;
        this.updgrouptitle = item.Group.title;
        this.groupupdatedialog = true;
        const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupUsers(
          item.groupid,
          activityid
        );
        this.groupusers = response.data;
        var index = 0;
        while (this.groupusers[index]) {
          var id = this.groupusers[index].User.id;
          this.selectedupdgroupusers.push(id);
          // var name = this.groupusers[index].User.name;
          // this.selectedupdgroupusers.push(name);
          index = index + 1;
        }
        this.getFriends(true);
      } catch (error) {
        console.log(error);
      }
    },
    async savegroupUpdate() {
      if (this.userid === this.groupuserid) {
        console.log(this.selectedupdgroupusers);
        console.log(this.addedupdgroupusers);
        this.groupupdatedialog = false;
        var name = "";
        var id = 0;
        try {
          const usersremoved = [];
          var newgroupusers = [];
          // add old and new values
          // const selectedgroups = this.selectedupdgroupuser
          // console.log(selectedgroups)
          // go through updated selectedupdgroupuser and find those removed
          var index = 0;
          while (this.groupusers[index]) {
            id = this.groupusers[index].User.id;
            var pos = this.selectedupdgroupusers.indexOf(id);
            if (pos < 0) {
              // user removed add to remove list
              var usergroup = { id: 0 };
              usergroup.id = this.groupusers[index].id;
              usersremoved.push(usergroup);
            }
            index = index + 1;
          }
          if (usersremoved.length > 0) {
            console.log("users to remove");
            const res = await GroupServices.deleteUsersFromGroup({
              userlist: usersremoved,
            });
            console.log(res);
          }
          // add all new users
          index = 0;
          while (this.addedupdgroupusers[index]) {
            var newusergroup = { userid: 0, groupid: 0, idactivity: 0 };
            // usergroup.groupid = result.data.id
            id = this.addedupdgroupusers[index];
            pos = this.friendlist
              .map(function(e) {
                return e.User.id;
              })
              .indexOf(id);
            if (this.userid === this.friendlist[pos].idfriend) {
              newusergroup.userid = this.friendlist[pos].iduser;
            } else {
              newusergroup.userid = this.friendlist[pos].idfriend;
            }
            newusergroup.groupid = this.groupid;
            newusergroup.idactivity = this.ActiveActivityID;
            newgroupusers.push(newusergroup);
            index = index + 1;
          }
          // Add creator also to usergroup
          // groupusers.push({userid: this.userid, groupid: result.data.id})
          // var credentials = { userlist: groupusers }
          const response = await GroupServices.addUsersToGroup({
            userlist: newgroupusers,
          });
          this.addedupdgroupusers = [];
          this.myfriendslistsheader = "GROUPS";
          console.log(response);
          // await this.getGroups();
          this.opengroupUpdate(this.itemupdgroupusers);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.notificationtext = "Only owners/creator can update a group";
        this.snackbar = true;
      }
    },
    deleteGroupControl() {
      this.groupdeletedialog = true;
    },
    resetgroupUpdate() {
      this.groupupdatedialog = false;
      this.myfriendslistsheader = "GROUPS";
      // this.selectedupdgroupusers = [];
      // this.addedupdgroupusers = [];
    },
    async manageUserSkills() {
      console.log("manage Skills");
      this.userskillsdialog = true;
      this.useractivitylist = [];
      var tempact = [];
      var tempski = [];
      try {
        const response = await SystemService.getUserActivities(this.userid);
        if (response.data.length > 0) {
          // this.useractivitylist = response.data
          tempact = response.data;
        }
        const skill = await SystemService.getUserSkills(this.userid);
        tempski = skill.data;
        // this.userskillslist = skill.data

        var index = 0;
        while (tempact[index]) {
          var rec = {
            idactivity: 0,
            idskills: 0,
            name: "",
            index: 0,
            shortname: "",
            avatar: "",
          };
          rec.idactivity = tempact[index].idactivity;
          rec.name = tempact[index].Activity.name;
          rec.avatar = tempact[index].Activity.avatar;
          var index2 = 0;
          while (tempski[index2]) {
            if (tempact[index].idactivity === tempski[index2].idactivity) {
              rec.idskills = tempski[index2].id;
              rec.shortname = tempski[index2].shortname;
              rec.index = tempski[index2].index;
            }
            index2 = index2 + 1;
          }
          this.useractivitylist.push(rec);
          index = index + 1;
        }
        this.userskillsdialog = true;
        // console.log(response)
      } catch (error) {
        console.log(error);
      }
    },
    saveSkills() {
      console.log("save skills");
      this.userskillsdialog = false;
      this.useractivitylist = [];
    },
    async getActivities() {
      try {
        if (this.interestlist.length === 0) {
          const interests = await ActivityService.getActivities(1);
          this.interestlist = interests.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async setDefaultActivities() {
      try {
        let index = 0;
        if (this.interestlist.length > 0) {
          while (this.interestlist[index]) {
            console.log(this.interestlist[index]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getActivitySkills() {
      try {
        var pos = this.selectedActivity;
        pos = pos - 1;
        const obj = this.interestlist[pos];
        const activityskills = await SystemService.getActivitySkills(obj.id);
        this.activityskills = activityskills.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getAllActivitySkills() {
      try {
        const allactivityskills = await SystemService.getAllActivitySkills(
          this.userid
        );
        this.useractivityskills = allactivityskills.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getActivityFacilities() {
      try {
        var pos = this.selectedActivity;
        pos = pos - 1;
        const obj = this.interestlist[pos];
        const active = 1;
        const place = "";
        const activityfacilities = await SystemService.getActivityFacilities(
          obj.id,
          active,
          place
        );
        this.activityfacilities = activityfacilities.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getUserActivityFacilities() {
      try {
        var pos = this.selectedFacilityActivity;
        pos = pos - 1;
        const obj = this.interestlist[pos];
        const active = 1;
        const place = "";
        const activityfacilities = await SystemService.getActivityFacilities(
          obj.id,
          active,
          place
        );
        this.activityfacilities = activityfacilities.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getUserFacilities() {
      try {
        // Get User Facilities if exists else get all for an activity
        var pos = this.selectedFacilityActivity;
        // const obj = this.interestlist[pos]
        // Check if activity facilities exists
        const userfacact = await SystemService.getUserFacilities(
          this.userid,
          pos
        );
        if (userfacact.data.length > 0) {
          //
          // ['id', 'idactivity', 'name', 'active', 'createdAt']
          // var rec = {id: 0, iduserfacility: 0, idactivity: 0, name: "", createdAt: ""}
          var index = 0;
          // this.activityfacilities = []
          this.selectedmyfacilities = [];
          const tmplist = userfacact.data;
          while (tmplist[index]) {
            // rec.id = userfacact.data[index].Facilities.id
            // rec.iduserfacility = userfacact.data[index].id
            // rec.idactivity = userfacact.data[index].idactivity
            // rec.name = userfacact.data[index].Facilities.name
            // this.activityfacilities.push(rec)
            this.selectedmyfacilities.push(tmplist[index].idfacility);
            index = index + 1;
          }
        } else {
          // If not get from scratch
          const active = 1;
          const place = "";
          const facact = await SystemService.getActivityFacilities(
            pos,
            active,
            place
          );
          this.activityfacilities = facact.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveMemberships() {
      try {
        // selected membership
        var list = [];
        var index = 0;
        while (this.selectedmymemberships[index]) {
          var membership = {
            iduser: 0,
            idactivity: 0,
            paid: 0,
            active: 0,
            idcompany: 0,
          };
          membership.iduser = this.userid;
          membership.idactivity = this.ActiveActivityID;
          membership.idcompany = selectedmymemberships[index];
          list.push(membership);
          index = index + 1;
        }
        const res = await SystemService.createUserMemberships({ list: list });
        console.log(res);
        this.selectedmymemberships = [];
        this.membershipdialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    async addFacility() {
      try {
        // check if Facility exists
        const fac = await SystemService.existFacility(
          this.addressFacility.place_id
        );
        // create facility if not in db
        console.log(fac);
        if (fac.data === "NO") {
          const idactivity = this.selectedFacilityActivity;
          const formattedaddress = this.placeResultFacility.formatted_address;
          const name = this.addressFacility.name;
          const adress =
            this.addressFacility.route +
            " " +
            this.addressFacility.street_number;
          // const city = this.placeResultFacility.address_components[3].long_name
          var str = this.placeResultFacility.formatted_address;
          var city = "";
          var pos = str.lastIndexOf(",");
          var index = pos - 1;
          while (index > 0) {
            var ch = str.substr(index, 1);
            if (this.isNumeric(ch)) {
              break;
            }
            index = index - 1;
          }
          if (index > 0) {
            index = index + 2;
            city = str.substring(index, pos);
          } else {
            city = "";
          }
          const country = this.addressFacility.country;
          // const countryshortname = this.placeResultFacility.address_components[5].short_name
          const countryshortname = "";
          const zipcode = this.addressFacility.postal_code;
          const state = this.addressFacility.administrative_area_level_1;
          const phone = this.placeResultFacility.international_phone_number;
          const website = this.placeResultFacility.website;
          const placeid = this.addressFacility.place_id;
          const email = "";
          const iduser = this.userid;
          const latitude = this.addressFacility.latitude;
          const longitude = this.addressFacility.longitude;
          const businesstatus = this.placeResultFacility.business_status;
          const url = this.placeResultFacility.url;

          const response = await SystemService.createFacility({
            idactivity: idactivity,
            formattedaddress: formattedaddress,
            name: name,
            adress: adress,
            city: city,
            country: country,
            countryshortname: countryshortname,
            zipcode: zipcode,
            state: state,
            phone: phone,
            website: website,
            placeid: placeid,
            email: "",
            iduser: iduser,
            latitude: latitude,
            longitude: longitude,
            businesstatus: businesstatus,
            url: url,
            active: 1,
          });
          console.log(response);
          var newfac = {
            id: response.data.id,
            idactivity: response.data.idactivity,
            name: response.data.name,
            idcompany: 0,
            formattedaddress: response.data.formattedaddress,
          };
          this.activityfacilities.push(newfac);
          this.selectedmyfacilities.push(newfac.id);
          this.$refs.addressFacility.clear();
          // add new facility to
          // this.activityfacilities
          // add new facility to selected
        }
        // update selected list with new user facility
      } catch (error) {
        console.log(error);
      }
    },
    async saveUserFacilities() {
      try {
        // selected facilities
        var list = [];
        var activity = this.selectedFacilityActivity;
        console.log(activity);
        var len = this.selectedmyfacilities.length;
        var len2 = this.activityfacilities.length;
        console.log(len2);
        var index = 0;
        while (this.selectedmyfacilities[index]) {
          var fac = { iduser: 0, idactivity: 0, idfacility: 0, idcompany: 0 };
          var facid = this.selectedmyfacilities[index];
          var pos = 0;
          while (this.activityfacilities[pos]) {
            if (this.activityfacilities[pos].id === facid) {
              fac.idcompany = this.activityfacilities[pos].idcompany;
              break;
            }
            pos = pos + 1;
          }
          fac.iduser = this.userid;
          fac.idactivity = activity;
          fac.idfacility = facid;
          list.push(fac);
          console.log(fac);
          // console.log(obj)
          len = this.selectedmyfacilities.length;
          console.log(len);
          index = index + 1;
        }
        const res = await SystemService.createUserFacilities({ list: list });
        console.log(res);

        this.selectedmyfacilities = [];
        this.selectedFacilityActivity = "";
        this.facilityactivitydialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    async manageUserActivities() {
      console.log("manage UserActivities");
      this.useractivitylist = [];
      this.useractivitydialog = true;
      this.selectedactivities = [];
      if (this.userid === 0) return;
      try {
        // get activities
        this.getActivities();
        // get user activities
        const response = await SystemService.getUserActivities(this.userid);
        if (response.data.length > 0) {
          this.useractivitylist = response.data;
          // get activity in useractivity list add to selected
          var index = 0;
          while (this.useractivitylist[index]) {
            var pos = this.interestlist
              .map(function(e) {
                return e.name;
              })
              .indexOf(this.useractivitylist[index].Activity.name);
            this.selectedactivities.push(this.interestlist[pos]);
            index = index + 1;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async initUserActivities() {
      console.log("manage UserActivities");
      // this.useractivitylist = []
      // this.selectedactivities = []
      this.myactivitylist = [];
      if (this.userid === 0) return;
      try {
        // get activities
        if (this.interestlist.length === 0) {
          this.getActivities();
        }
        var index = 0;
        var pos = 0;
        // get user activities
        const response = await SystemService.getUserActivities(this.userid);
        if (response.data.length > 0) {
          const list = response.data;
          // get activity in useractivity list add to selected
          index = 0;
          while (list[index]) {
            pos = this.interestlist
              .map(function(e) {
                return e.name;
              })
              .indexOf(list[index].Activity.name);
            if (this.interestlist[pos].id === this.ActiveActivityID) {
              // this.searchinterestlist[0] = this.interestlist[pos]
              this.selectedActivity = this.ActiveActivityID;
            }
            this.myactivitylist.push(this.interestlist[pos]);
            index = index + 1;
          }
        } else {
          pos = this.interestlist
            .map(function(e) {
              return e.id;
            })
            .indexOf(this.User.defaultactivity);
          if (pos >= 0) {
            this.myactivitylist.push(this.interestlist[pos]);
            this.searchinterestlist[0] = this.interestlist[pos];
          }
          // this.selectedActiveActivity = this.interestlist[pos].id
          // this.selectedDefaultActivity = this.interestlist[pos].id
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveUserActivities() {
      console.log("save UserActivities");
      try {
        // get selected to list
        var addlist = [];
        var index = 0;
        while (this.selectedactivities[index]) {
          var id = this.selectedactivities[index];
          var pos = this.interestlist
            .map(function(e) {
              return e.id;
            })
            .indexOf(id);
          console.log(this.interestlist[pos]);
          addlist.push(this.interestlist[pos]);
          index = index + 1;
        }
        const response = await UserService.addUserActivities({
          userid: this.userid,
          list: addlist,
        });
        console.log(response);
        this.selectedactivities = [];
        this.useractivitydialog = false;
        await this.initUserActivities();
        await this.getActivitySkills();
        await this.getActivityFacilities();
        this.notificationtext = "Your activities is updated";
        this.snackbar = true;
      } catch (error) {
        console.log(error);
        this.useractivitydialog = false;
      }
    },
    async updateUserActivitySkill(item) {
      this.updateskilldialog = true;
      try {
        this.idactivity = item.idactivity;
        const activityskills = await SystemService.getActivitySkills(
          this.idactivity
        );
        this.popupactivityskills = activityskills.data;
      } catch (error) {
        console.log(error);
      }
    },
    async saveUserActivitySkill() {
      try {
        // const skill = this.popupselectedskill;
        const idskills = this.popupselectedskill;
        if (idskills === "") {
          this.popupactivityskills = [];
          this.updateskilldialog = false;
          return;
        }
        // console.log(skill + idskills);
        const response = await SystemService.updateUserSkill({
          iduser: this.userid,
          idskills: idskills,
          idactivity: this.idactivity,
        });
        console.log(response);
        this.popupactivityskills = [];
        this.updateskilldialog = false;
        this.userskillsdialog = false;
        this.manageUserSkills();
        this.idactivity = 0;
        // this.notificationtext = "Your activities is updated";
        // this.snackbar = true;
      } catch (error) {
        console.log(error);
      }
    },
    cancelActivitySkill() {
      this.popupactivityskills = [];
      this.updateskilldialog = false;
    },
    manageFacilities() {
      this.facilityactivitydialog = true;
    },
    async manageMemberships() {
      this.membershipdialog = true;
      const response = await SystemService.getUserMemberships(
        this.userid,
        this.ActiveActivityID
      );
      this.mymembershipcompanies = response.data;
      const companies = await SystemService.getCompanies(this.ActiveActivityID);
      this.membershipcompanies = companies.data;
    },
    async getUserGroupsIds() {
      try {
        const activityid = this.ActiveActivityID;
        const groupids = await GroupServices.getUserGroupsIds(
          this.userid,
          activityid
        );
        const grps = groupids.data;
        return grps;
      } catch (error) {
        console.log(error);
      }
    },
    openUserMessageWindow(item) {
      console.log(item);
      this.usermessagewindow = true;
    },
    async createFeedback() {
      try {
        const response = await SystemService.createFeedback({
          iduser: this.userid,
          title: this.feedbacktitle,
          text: this.feedbacktext,
        });
        console.log(response);
        this.feedbackdialog = false;
        this.feedbacktitle = "";
        this.feedbacktext = "";
      } catch (error) {
        console.log(error);
      }
    },
    manageImages() {
      console.log("My Images");
      this.imagesdialog = true;
    },
    cancelImage() {
      console.log("Cancel Images");
      this.imagesdialog = false;
    },
    async saveImage(file) {
      this.currentFile = file;
      var data = new FormData();
      data.append("userid", this.$store.state.user.id);
      data.append("text", " ");
      data.append("imagetype", "profile");
      data.append("filename", file.name);
      this.MyImageType = "profile";
      data.append("file", file);
      const response = await MyImageService.savemyimage(data);
      console.log(response);
      await this.getMyProfileImages();
      // this.imagesdialog = false
      var avatarfile =
        response.data.mysavedimage.FullPath +
        response.data.mysavedimage.DiscFileName;
      const usrupd = await AuthenticationService.update({
        id: this.userid,
        avatar: avatarfile,
      });
      this.notificationtext = "Your image is saved ";
      this.snackbar = true;
      console.log(usrupd);
    },
    async saveVoiceMsg() {
      if (this.soundMsg.file === null) {
        return;
      }
      try {
        // var tempfile = this.soundMsg.file;
        // var test = window.URL.createObjectURL(this.soundMsg.file);
        // const blob2 = new Blob([this.soundMsg.url], { type: 'audio/mpeg' });
        // let blob = await fetch(this.soundMsg.url).then(r => r.blob());
        var voicefile = {
          userid: this.userid,
          file: this.soundMsg.file,
          len: this.soundMsg.size,
          type: this.soundMsg.type,
        };
        const response = await VoiceMessageService.saveVoiceMessage(voicefile);
        // console.log(response);
        var msg = response.data;
        this.soundMsg.id = response.data.id;
        // const voicemsg = await this.VoiceMessageService.getVoiceMessage(response.data.id);
        // this.imagesdialog = false
        // var avatarfile =
        //  response.data.mysavedimage.FullPath +
        //  response.data.mysavedimage.DiscFileName;
        // this.notificationtext = "Your image is saved ";
        // this.snackbar = true;
        // console.log(usrupd);
      } catch (error) {
        console.log(error);
      }
    },
    async saveMsgImage(file) {
      this.currentFile = file;
      // Test resize here
      try {
        // const file2 = this.resizeMsgImage(file)

        var data = new FormData();
        data.append("userid", this.$store.state.user.id);
        data.append("text", " ");
        data.append("imagetype", "friendmsg");
        data.append("filename", file.name);
        this.MyImageType = "friendmsg";
        data.append("file", file);
        const response = await MyImageService.savemsgimage(data);
        console.log(response);
        this.card.imageid = response.data.id;
        await this.getMsgImage(response.data.id);

        // this.imagesdialog = false
        // var avatarfile =
        //  response.data.mysavedimage.FullPath +
        //  response.data.mysavedimage.DiscFileName;
        // this.notificationtext = "Your image is saved ";
        // this.snackbar = true;
        // console.log(usrupd);
      } catch (error) {
        console.log(error);
      }
    },
    async getMsgImage(msgid) {
      try {
        const res = await MyImageService.getmsgimage(msgid);
        this.card.msgimage = res.data;
        if (res.data === "NoImage") {
          console.log("NoImage");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getMyProfileImages() {
      try {
        const res = await MyImageService.getmyimage(this.userid);
        this.myprofileimage = res.data;
        if (res.data === "NoImage") {
          if (this.User.gender === "woman") {
            this.myprofileimage =
              "https://findhavefun.com/images/fhf/001-girl-1.png";
          }
          if (this.User.gender === "man") {
            this.myprofileimage =
              "https://findhavefun.com/images/fhf/007-boy-1.png";
          }
          if (this.User.gender === "both") {
            this.myprofileimage =
              "https://findhavefun.com/images/fhf/007-boy-1.png";
          }
          // this.myprofileimage = this.User.avatar
        }
      } catch (error) {
        if (this.User.gender === "woman") {
          this.myprofileimage =
            "https://findhavefun.com/images/fhf/001-girl-1.png";
        }
        if (this.User.gender === "man") {
          this.myprofileimage =
            "https://findhavefun.com/images/fhf/007-boy-1.png";
        }
        if (this.User.gender === "both") {
          this.myprofileimage =
            "https://findhavefun.com/images/fhf/007-boy-1.png";
        }
        console.log(error);
      }
    },
    async getAccessRights(showSelectCompany) {
      try {
        const idcompany = 0;
        const response = await SystemService.getAccessRights(
          this.userid,
          this.ActiveActivityID,
          idcompany
        );
        this.accessrights = response.data;
        if (this.accessrights.length > 0) {
          // Here we need to add check if more then one company
          // selectCompany ()
          this.companyid = this.accessrights[0].idcompany;
          this.AdminAccess = true;
        } else {
          this.AdminAccess = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async coachWindow() {
      try {
        this.usercoachdialog = true;
        /*
        const response = await SystemService.getCompanies(
          this.ActiveActivityID
        );
        this.companylist = response.data;
        const coaches = await SystemService.getUserCoach(
          this.userid,
          this.ActiveActivityID
        );
        this.usercoachlist = coaches.data;
        if (this.usercoachlist.length > 0) {
          var index = 0;
          this.selectedcompanies = [];
          while (this.usercoachlist[index]) {
            this.selectedcompanies.push(this.usercoachlist[index].idcompany);
            index = index + 1;
          }
        }
        */
      } catch (error) {
        console.log(error);
      }
    },
    async getCoachCompanies() {
      try {
        // this.usercoachdialog = true;
        const response = await SystemService.getCompanies(
          this.selectedCoachActivity
        );
        this.companylist = response.data;
        const coaches = await SystemService.getUserCoach(
          this.userid,
          this.selectedCoachActivity
        );
        this.usercoachlist = coaches.data;
        if (this.usercoachlist.length > 0) {
          var index = 0;
          this.selectedcompanies = [];
          while (this.usercoachlist[index]) {
            this.selectedcompanies.push(this.usercoachlist[index].idcompany);
            var pos = this.companylist
              .map(function(e) {
                return e.id;
              })
              .indexOf(this.usercoachlist[index].idcompany);
            this.coachActivityCompanies.push(this.companylist[pos]);
            index = index + 1;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    cancelUserCoach() {
      this.usercoachlist = [];
      this.selectedcompanies = [];
      this.usercoachdialog = false;
    },
    async saveUserCoach() {
      try {
        // this.usercoachdialog = true
        var usercoach = {
          iduser: this.userid,
          idactivity: this.selectedCoachActivity,
          idcompany: 0,
          admin: 0,
          defaultcompany: 0,
        };
        var index = 0;
        while (
          this.selectedcompanies &&
          this.selectedcompanies.length > 0 &&
          this.selectedcompanies[index]
        ) {
          usercoach.idcompany = this.selectedcompanies[index];
          // Check if this exists before trying to add
          if (this.usercoachlist.length > 0) {
            console.log(this.usercoachlist);
            var index2 = 0;
            var exists = 0;
            while (this.usercoachlist[index2]) {
              if (
                this.usercoachlist[index2].idcompany === usercoach.idcompany
              ) {
                // var exists = this.usercoachlist.map(function(e) {return e.idcompany;}).indexOf(usercoach.idcompany);
                // var exists = this.usercoachlist.indexOf(usercoach.idcompany)
                exists = 1;
                break;
              }
              index2++;
            }
            if (exists === 0) {
              const response = await SystemService.saveUserCoach(usercoach);
              console.log(response);
            }
          } else {
            const response = await SystemService.saveUserCoach(usercoach);
            console.log(response);
          }
          index = index + 1;
        }
        index = 0;
        // var del = null
        while (this.usercoachlist && this.usercoachlist[index]) {
          var user = this.usercoachlist[index];
          // var tst = this.usercoachlist[index].id
          console.log(user);
          if (this.selectedcompanies && this.selectedcompanies.length > 0) {
            var pos = this.selectedcompanies.indexOf(user.idcompany);
            if (pos < 0) {
              // removed
              const del = await SystemService.removeUserCoach({ id: user.id });
              console.log(del);
            }
          } else {
            console.log("del removeUserCoach");
            const del2 = await SystemService.removeUserCoach({ id: user.id });
            console.log(del2);
          }
          index = index + 1;
        }
        if (this.defaultCoachActivityCompany) {
        }
        // this.usercoachlist = coaches.data
        this.getAccessRights(false);
        this.usercoachdialog = false;
        this.usercoachlist = [];
        this.selectedcompanies = [];
        this.selectedCoachActivity = "";
      } catch (error) {
        console.log(error);
      }
    },
    companyWindow() {
      this.companydialog = true;
    },
    companyCanceled() {
      try {
        this.companydialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    async registerCompany() {
      try {
        var active = 0;
        if (this.companyactive === true) {
          active = 1;
        }
        var company = {
          iduser: this.userid,
          active: active,
          country: this.companycountry,
          name: this.companyname,
          type: this.selectedcompanytype,
          email: this.companyemail,
          ext: this.companycountrycode,
          phone: this.companyphone,
          adress: this.companyadress,
          city: this.companycity,
          zipcode: this.companyzipcode,
          state: this.companystate,
          website: this.companywebsite,
          description: this.companydescription,
          avatar: this.companyavatar,
          idactivity: this.selectedCompanyActivity,
        };
        const response = await SystemService.createCompany(company);
        this.companydata = response.data;
        console.log(response);
        var usercoach = {
          iduser: this.userid,
          idactivity: this.ActiveActivityID,
          idcompany: response.data.id,
          admin: 5,
        };
        const coach = await SystemService.saveUserCoach(usercoach);
        console.log(coach);
        90;
        const activityid = this.ActiveActivityID;
        const result = await GroupServices.createGroup({
          userid: this.userid,
          idactivity: activityid,
          name: "allmembers",
          title: "All " + this.companyname + "Members",
          avatar: this.grpavatar,
          access: 0,
          type: "allmembers",
        });

        this.getAccessRights(false);
        this.companydialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    /*
    saveSetup () {
      try {
        // convert strings to numbers
        var sound = Number(this.soundon)
        var soundfile = Number(this.soundfile)
        var notifications = Number(this.notifications)
        var settings = {id: this.userid, sound: sound, soundfile: soundfile, notifications: notifications}
        const response = AuthenticationService.updateSettings(settings)
        this.setupdialog = false
      } catch (error) {
        console.log(error)
      }
    }, 
    */
    // --------------- Calender func
    async getBookings() {
      try {
        const active = 1;
        const calender = await CalenderService.getBookings(this.userid, active);
        this.calenderlist = calender.data;
      } catch (error) {
        console.log(error);
      }
    },
    openCalender() {
      this.calenderdialog = true;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];
      console.log(start);
      console.log(end);
      /*
      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)
      let allDay
      let firstTimestamp
      let first
      let secondTimestamp
      let second
      */

      /*
      for (let i = 0; i < eventCount; i++) {
        allDay = this.rnd(0, 3) === 0
        firstTimestamp = this.rnd(min.getTime(), max.getTime())
        first = new Date(firstTimestamp - (firstTimestamp % 900000))
        secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        second = new Date(first.getTime() + secondTimestamp)
      }*/
      let index = 0;
      while (this.calenderlist[index]) {
        events.push({
          name: this.calenderlist[index].Booking.name,
          start:
            this.calenderlist[index].datefrom +
            " " +
            this.calenderlist[index].timefrom,
          end:
            this.calenderlist[index].dateto +
            " " +
            this.calenderlist[index].timeto,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          // timed: 2
        });
        index = index + 1;
      }

      this.events = events;
    },
    /*
    updateRange ({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        })
      }

      this.events = events
    },
    */
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    adminWindow() {
      this.$router.push({
        name: "adminwindow",
        params: {
          activecompany: this.companyid,
          activeactivity: this.ActiveActivityID,
          token: this.token,
          password: this.password,
          main_tab: this.menu_tabs,
          selectedTheme: this.selectedTheme,
          // listsheader: this.myfriendslistsheader
        },
      });
      // this.checkDefaultCompany()
    },
    checkDefaultCompany() {
      try {
        // check if more then one company
        var accesslist = this.accessrights;
        if (this.accessrights.length > 1) {
          this.setcompanydialog = true;
        } else if (this.accessrights.length === 1) {
          this.adminWindow();
        }
      } catch (error) {
        console.log(error);
      }
    },
    setActiveCompany() {
      if (this.selectedActiveCompany) {
        var activeCompany = this.selectedActiveCompany;
        this.$store.dispatch("activeCompany", activeCompany);
        this.companyid = activeCompany;
        this.setcompanydialog = false;
        this.adminWindow();
      }
    },
    isNumeric(value) {
      return /^-?\d+$/.test(value);
    },
    async setGenderSearch() {
      console.log("setGenderSearch()");
      await this.search();
      this.dialogSelectGender = false;
    },
    async setAgeSearch() {
      console.log("setAgeSearch()");
      await this.search();
      // this.userlist = response.data;
      this.dialogSelectAge = false;
    },
    async setNameSearch() {
      console.log("setNameSearch()");
      await this.search();
      // this.userlist = response.data;
      this.dialogSelectName = false;
    },
    async setMoreSearch() {
      console.log("setMoreSearch()");
      await this.search();
      // this.userlist = response.data;
      this.dialogSelectMore = false;
    },
    getMonthStr(str) {
      // 2021-02-24 14:22:37.915 +00:00 localeShort
      // v-text="item.createdAt.substring(5, 10) + ' '
      // + item.createdAt.substring(11, 16) + ' - ' + item.message"
      var month = "";
      var shortname = "";
      // if (len > 0) {
      month = str.substring(start, len);
      const formatter = new Intl.DateTimeFormat("se", { month: "short" });
      return formatter.format(new Date(str));
      // }
    },
    getTimeStr(str, len) {
      // 2021-02-24 14:22:37.915 +00:00
    },
    async removeCoachCompany(item) {
      try {
        const index = this.selectedcompanies.indexOf(item.id);
        if (index >= 0) this.selectedcompanies.splice(index, 1);
        /* 
        const index2 = this.usercoachlist.indexOf(item.id)
        this.usercoachlist.splice(index2, 1);
        const id = this.usercoachlist[index2].id;
        const del = await SystemService.removeUserCoach({id: user.id});
        console.log(del)
        */
      } catch (error) {
        console.log(error);
      }
    },
    async removeActivity(item) {
      try {
        // v-model="selectedactivities"
        // :items="interestlist"
        console.log(this.selectedactivities);
        // const index = this.selectedactivities.indexOf(item.id)
        var index = 0;
        while (this.selectedactivities[index]) {
          if (this.selectedactivities[index].id === item.id) {
            this.selectedactivities.splice(index, 1);
            break;
          }
          index++;
        }
        /*
        const index = this.selectedactivities.map(function(e) {
              return e.id;
            })
            .indexOf(item.id);
        */
        // if (index >= 0)
        //  this.selectedactivities.splice(index, 1)
      } catch (error) {
        console.log(error);
      }
    },
    async aboutApp() {
      try {
        // const response = await SystemService.getSysteminfo();
        // this.systeminfo = response.data;
        // this.maxImageSize = this.systeminfo[0].imagesize;
        // this.bitrate = this.systeminfo[0].bitrate;
        // console.log(this.systeminfo);
        this.appdownloaddialog = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getSystemInfo() {
      try {
        const response = await SystemService.getSysteminfo();
        this.systeminfo = response.data;
        this.maxImageSize = this.systeminfo[0].imagesize;
        this.agebelow15days = this.systeminfo[0].agebelow15days;
        // this.bitrate = this.systeminfo[0].bitrate;
        console.log(this.systeminfo);
        // this.appdownloaddialog = true;
      } catch (error) {
        console.log(error);
      }
    },
    createFriendRequestMessage(item) {
      this.messagerequestitem = item;
      // this.selecteduser = item
    },
    async removeFriend() {
      var friendconn = { id: 0 };
      try {
        friendconn.id = this.selectedfrienditem.id;
        const response = await UserService.removeMyFriendRequest(friendconn);
        console.log(response);
        this.friendListPopup = false;
        var pos = this.friendlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(friendconn.id);
        if (pos >= 0) {
          this.friendlist.splice(pos, 1);
        }
        this.notificationtext = "Friend removed";
        this.snackbar = true;
      } catch (err) {
        console.log(err);
      }
      // .v-list-group {
      // border-bottom: 1px solid rgb(233, 228, 228)
      // }
    },
    openRecorder() {
      console.log("openRecorder");
      console.log(this.platform);
      if (this.showRecorder === false) {
        this.soundGrpMsg.file = null;
        this.showRecorder = true;
        this.createGroupVoiceMsg = true;
      } else {
        this.soundGrpMsg.file = null;
        this.showRecorder = false;
        this.createGroupVoiceMsg = false;
      }
    },
    removeVoiceMessage() {
      console.log("removeVoiceMessage");
      // this.clearGroupMessage();
      this.showRecorder = false;
      this.createGroupVoiceMsg = false;
    },
    async removeImage() {
      console.log("removeImage");
      try {
        await MyImageService.deletemyimage({ id: this.card.imageid });
        this.card.msgimage = null;
        this.card.imageid = 0;
      } catch (error) {
        console.log(error.message);
        this.card.msgimage = null;
        this.card.imageid = 0;
      }
    },
    resizeMsgImage(file) {
      // START: preview original
      // you can remove this section if you don't like to preview original image
      if (!file.type.match(/image.*/)) {
        // no(new Error('Not an image'));
        return;
      }
      // 20210514 CWE
      // this.clearCard();
      const reader = new FileReader();
      reader.onload = (e) => (this.originalImg = e.target.result);
      reader.readAsDataURL(file); // convert to base64 string
      // END: preview original

      // START: preview resized
      // const resimg = resizeImage({ file: file, maxSize: 150 });
      // this.resizedImg = window.URL.createObjectURL(resimg);
      resizeImage({ file: file, maxSize: this.maxImageSize })
        .then((resizedImage) => {
          this.resizedImg = URL.createObjectURL(resizedImage);
          this.card.msgimage = this.resizedImg;
          this.saveMsgImage(resizedImage);
          console.log("this.resizedImg " + this.resizedImg);
        })
        .catch((err) => {
          console.error(err);
        });
      // END: preview resized
    },
    async setImage(file) {
      this.hasImage = true;
      // this.image = file
      this.currentFile = file;
      var data = new FormData();
      data.append("userid", this.$store.state.user.id);
      data.append("text", " ");
      data.append("imagetype", "friendmsg");
      data.append("filename", file.name);
      this.MyImageType = "friendmsg";
      data.append("file", file);
      const response = await MyImageService.savemsgimage(data);
      console.log(response);
      this.card.imageid = response.data.id;
      await this.getMsgImage(response.data.id);
    },
    privacyPolicy() {
      this.privacydialog = true;
    },
    onScroll() {
      this.scrollInvoked++;
    },
    clearCard() {
      this.card.msgimage = null;
      this.card.msgtitle = "";
      this.card.imageid = 0;
    },
    clearSoundMsg() {
      // soundMsg: { file: null, url: "", id: 0, title: "", type: "audio/mpeg", size: 0, msg: "", file2: null }
      this.soundMsg.file = null;
      this.soundMsg.url = "";
      this.soundMsg.id = 0;
      this.soundMsg.title = "";
      this.soundMsg.type = "audio/mpeg";
      this.soundMsg.size = 0;
      this.soundMsg.msg = "";
      this.soundMsg.file2 = null;
    },
    clearSoundGrpMsg() {
      // soundMsg: { file: null, url: "", id: 0, title: "", type: "audio/mpeg", size: 0, msg: "", file2: null }
      this.soundMsg.file = null;
      this.soundMsg.url = "";
      this.soundMsg.id = 0;
      this.soundMsg.title = "";
      this.soundMsg.type = "audio/mpeg";
      this.soundMsg.size = 0;
      this.soundMsg.msg = "";
      this.soundMsg.file2 = null;
    },
    initIOSPlayer() {
      const recorder = document.getElementById("friendrecorder");
      const player = document.getElementById("fiendplayer");

      recorder.addEventListener("change", function(e) {
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        // Do something with the audio file.
        player.src = url;
      });
    },
    async startRecordVoice() {
      try {
        console.log("startRecordVoice");
      } catch (error) {
        console.log(error);
      }
    },
    stopRecord() {
      // startRecord.disabled = false;
      // stopRecord.disabled=true;
      this.mediaRecorder.stop();
      this.iosaudioshow = true;
    },
    async getUserSettings() {
      try {
        console.log("getUserSettings");
        const res = await UserService.getUserSettings(this.userid);
        console.log(res);
        if (res.data !== "") {
          this.userSettings.id = res.data.id;
          this.userSettings.dark = res.data.dark;
          // this.selectedTheme = res.data.theme;
          if (res.data.dark === false) {
            this.selectedTheme = false;
            this.textFieldColor = "grey lighten-2";
          } else {
            this.textFieldColor = "grey lighten-2";
            this.selectedTheme = true;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async addUserSettings() {
      try {
        let dark = 0;
        if (this.selectedTheme === true) {
          this.selectedDark = "dark";
          this.selectedLight = "";
          dark = 1;
        } else {
          this.selectedDark = "light";
          this.selectedLight = "light";
        }
        const res = await UserService.addUserSettings({
          id: this.userid,
          dark: dark,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async updateUserSettings() {
      try {
        console.log(this.selectedTheme);
        /*
        if (this.selectedTheme === true) {
          $vuetify.theme.dark = true;
        } else {
          $vuetify.theme.dark = false;
        }*/
        let dark = 0;
        if (this.selectedTheme) {
          dark = 1;
        }
        const res = await UserService.updateUserSettings({
          id: this.userid,
          dark: dark,
        });
      } catch (err) {
        console.log(err);
      }
    },
    openGroupImage(message) {
      this.groupimagepopupdialog = true;
      this.groupmsgimage = message.msgimage;
    },
  },
};
</script>

<style>
.headtitle-xs {
  font-size: 1.3em;
}

.headtitle-lg {
  font-size: 2em;
}

.background-text-dark {
  background-color: grey;
  color: rgb(255, 255, 255);
}
.background-text-light {
  background-color: #e0e0e0;
  color: rgb(0, 0, 0);
}
.v-dialog {
  position: absolute;
  bottom: 10;
  right: 10;
}
.v-data-table td {
  border-bottom: none !important;
}
.v-card {
  display: flex !important;
  flex-direction: column;
}
.v-list-scroll {
  height: 50%;
  overflow-y: scroll;
}
.scroll {
  height: 500px;
  overflow-y: scroll;
}

.v-input {
  font-size: 0.9em;
}
.v-text-field input {
  font-size: 0.9em;
}
.v-input .v-label {
  font-size: 0.9em;
}
</style>
