<template>
  <div class="text-center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import SystemService from "@/services/SystemService";
import UserService from "@/services/UserService";
// import "firebase/auth";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
// import "capacitor-secure-storage-plugin";
import "@codetrix-studio/capacitor-google-auth";
// import { Plugins } from "@capacitor/core";
// const { Storage } = Plugins;
// import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
// import "@codetrix-studio/capacitor-google-auth";
// const { GoogleAuth } = Plugins;
// const { Storage } = Plugins;
// import { Storage } from '@capacitor/storage';
// import { saveUserDataIndexDB, getUserDataIndexDB } from '@/utils/mydbtools';
// import { setName, getKeyValue, removeKey } from '@/storage/storage';
// const { SecureStoragePlugin } = Plugins;

export default {
  name: "Login",
  components: {},
  // props: ['username', 'password'],
  data() {
    return {
      dummy: "",
      personnumber: "",
      systeminfo: null,
      maxImageSize: 0,
      agebelow15days: 0,
      userSettings: { id: 0, userid: 0, dark: false },
      selectedTheme: false,
      textFieldColor: ""
    };
  },
  created() {},
  async mounted() {
    // await GoogleAuth.init();
    // if (this.$route.params.showlogin === true) {
    //  this.showLoginWindow = true;
    // }
    this.login();
  },
  methods: {
    async login() {
      try {
        var userkey = await this.getUserData();
        // userkey = window.$cookies.get("user");
        // console.log('cookies get user ' + userkey);
        // let userkey = await getUserDataIndexDB();
        if (userkey) {
          this.username = userkey.username;
          this.password = userkey.password;
        }

        const response = await AuthenticationService.login({
          username: this.username,
          password: this.password,
        });
        /* turn off for the moment , send email checks instead 
        if (response.data.user.verified === false || response.data.user.verified === 0) {
          this.errorloginmsg = "You have to confirm your email adress, check your emails"
          MailService.sendVerificationEmail({emailto: response.data.user.email})
          return
        } */
        // this.playMP3 ()

        // check age and if parent userid is added
        this.personnumber = response.data.user.personnumber;
        if (this.checkIfChild()) {
          this.getSystemInfo();
          const check = this.checkDaysFromRegistered(
            response.data.user.createdAt
          );
          if (check) {
            this.$router.push({
              name: "loginchildcheck",
              params: {
                userid: "response.data.user.id",
                username: "response.data.user.username",
                child: true,
              },
            });
          }
        }

        console.log("After login call");
        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.user);
        this.userid = response.data.user.id;

        this.getUserSettings();

        //  userid: this.userid,
        //   username: this.username,
        //   activecompany: this.companyid,
        //   activeactivity: this.ActiveActivityID,
        //   token: this.token,
        //   password: this.password,
        //   main_tab: this.menu_tabs,
        //   selectedTheme: this.selectedTheme,

        this.$router.push({
          name: "mainwindow",
          params: {
            userid: response.data.user.id,
            username: response.data.user.username,
            password: response.data.user.password,
            activecompany: this.companyid,
            activeactivity: response.data.user.defaultactivity,
            token: response.data.token,
            selectedTheme: this.selectedTheme,
          },
        });
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: "mainwindow",
          params: {
            userid: "",
            username: "",
            password: "",
          },
        });
      }
    },
    async setUserData(username, password) {
      try {
        await SecureStoragePlugin.set({ key: "user", value: username })
          .then((success) => console.log(success))
          .catch((error) => {
            console.log(error);
          });
        await SecureStoragePlugin.set({ key: "password", value: password })
          .then((success) => console.log(success))
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("error in setUserData");
      }
    },
    async getUserData() {
      let ret = { username: "", password: "" };
      await SecureStoragePlugin.get({ key: "user" })
        .then((value) => {
          console.log(value);
          ret.username = value.value;
        })
        .catch((error) => {
          console.log("Item with specified key does not exist." + error);
        });
      await SecureStoragePlugin.get({ key: "password" })
        .then((value) => {
          console.log(value);
          ret.password = value.value;
        })
        .catch((error) => {
          console.log("Item with specified key does not exist." + error);
        });
      return ret;
    },
    checkIfChild() {
      // var today = new Date();
      var time = this.personnumber;
      var birthDate = new Date(time);
      var ageDifMs = Date.now() - birthDate.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      var age = Math.abs(ageDate.getUTCFullYear() - 1970);
      if (age < 15) {
        return true;
      } else {
        return false;
      }
    },
    async getSystemInfo() {
      try {
        const response = await SystemService.getSysteminfo();
        this.systeminfo = response.data;
        this.maxImageSize = this.systeminfo[0].imagesize;
        this.agebelow15days = this.systeminfo[0].agebelow15days;
        // this.bitrate = this.systeminfo[0].bitrate;
        console.log(this.systeminfo);
        // this.appdownloaddialog = true;
      } catch (error) {
        console.log(error);
      }
    },
    checkDaysFromRegistered(date) {
      // var today = new Date();
      if (this.username.substr(0, 10) === "kidsfhftst") {
        return true;
      }
      var time = date.substr(0, 10);
      var createDate = new Date(time);
      var daysDifMs = Date.now() - createDate.getTime();
      var daysMs = new Date(daysDifMs); // miliseconds from epoch
      var days = Math.abs(daysMs.getUTCFullYear() - 1970);
      if (days >= this.agebelow15days) {
        return true;
      } else {
        return false;
      }
    },
     async getUserSettings() {
      try {
        console.log("getUserSettings");
        const res = await UserService.getUserSettings(this.userid);
        console.log(res);
        if (res.data !== "") {
          this.userSettings.id = res.data.id;
          this.userSettings.dark = res.data.dark;
          // this.selectedTheme = res.data.theme;
          if (res.data.dark === false) {
            this.selectedTheme = false;
            this.textFieldColor = "grey lighten-2";
          } else {
            this.textFieldColor = "grey lighten-2";
            this.selectedTheme = true;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
