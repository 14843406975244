<template>
  <v-container dense class="ma-0 pa-0">
    <!-- :dark="theme" -->
    <v-card :dark="theme" flat class="ma-0 pa-0">
      <v-list-item dense class="ma-1 mb-3 pa-0">
        <v-list-item-avatar class="mr-3">
          <v-img :src="item.User.avatar" alt=""></v-img>
        </v-list-item-avatar>
        <v-list-item-content @click="openActionWindow">
          <v-list-item-title>{{ item.User.name }}</v-list-item-title>
          <v-list-item-title v-if="openActions === false" class="grey--text">
            {{
              item.messageAt.substr(5, 5) +
                " " +
                item.messageAt.substr(11, 5) +
                " " +
                item.message
            }}
          </v-list-item-title>
        </v-list-item-content>
        <div>
          <v-btn
            v-show="openActions === true"
            class="ml-n6 mr-2 mb-n1"
            @click="openMicroPhone"
            icon
            small
          >
            <v-icon size="24px">mdi-microphone</v-icon>
          </v-btn>
        </div>
        <div>
          <v-file-input
            v-show="openActions === true"
            class="ml-2 mb-0 mr-3"
            dense
            color="primary"
            prepend-icon="mdi-camera"
            hide-input
            accept="image/png, image/jpeg, image/bmp"
            label=""
            @change="resizeMsgImage"
          ></v-file-input>
        </div>
        <!-- div>
          <v-btn
            v-show="openActions === true"
            small
            class="ml-0 mb-n1"
            icon
            @click="sendFriendMessage()"
            color="primary"
          >
            <v-icon size="30px">mdi-email-send-outline</v-icon>
          </v-btn>
        </div -->
        <div v-if="openActions === false && item.Msg.msgbadge === 1">
          <v-badge
            class="ma-0 mr-5 pa-0"
            color="green"
            :content="item.Msg.msgbadge"
            overlap
          >
            <v-icon class="ma-0 pa-0" size="24px" color="blue"
              >mdi-bell
            </v-icon>
          </v-badge>
        </div>
        <div v-if="openActions === false && messageitem.msgimage">
          <v-img
            class="mr-2 rounded-sm"
            :src="messageitem.msgimage"
            width="40px"
            alt=""
          ></v-img>
        </div>
        <div v-if="openActions === false && !messageitem.msgimage">
          <v-avatar class="ml-2"></v-avatar>
        </div>
        <!-- v-row
          align="center"
          justify="end"
        >
      </v-row -->
        <!-- v-card-text>
        <v-img
            :src="messageitem.msgimage"
            class="mt-2 mb-2 white--text align-end"
            width="40px"
          >
        </v-img>
      </v-card-text -->
        <!-- :background-color="theme === 'dark' ? 'Grey' : 'grey lighten-2'" :background-color="textFieldColor" -->
        <!-- :background-color="textFieldColorBgr" -->
        <!-- :class="{backgrounddark: theme, backgroundlight: theme === false}" -->
      </v-list-item>
      <v-list-item v-show="openActions === true" dense class="ma-0 pa-0">
        <v-row dense class="ma-0 mb-0 pa-0">
          <v-col cols="10" class="ml-3">
            <v-textarea
              background-color="grey"
              class="ma-0 pa-0"
              dense
              solo
              auto-grow
              rows="1"
              name="input-7-1"
              label="new message"
              v-model="sendfriendusermessage"
            >
            </v-textarea>
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              class="ml-2 mb-n2"
              icon
              @click="sendFriendMessage()"
              color="primary"
            >
              <v-icon size="40px">mdi-email-send-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item
        v-show="openActions === true && createVoiceMsg === true"
        dense
      >
        <RecorderWeb
          v-if="platform === 'web'"
          :platform="platform"
          audiotype="mp3"
          :show="openActions"
          @new-voice-msg="saveVoiceMessage"
        ></RecorderWeb>
        <RecorderWeb
          v-else
          @new-voice-msg="saveVoiceMessage"
          :platform="platform"
          audiotype="mp3"
          :show="openActions"
        >
        </RecorderWeb>
      </v-list-item>
      <v-list-item class="mb-3" v-if="openActions === true && soundMsg.url">
        <v-row dense class="mb-0">
          <v-col cols="1">
            <v-btn
              small
              class="ml-n3 mt-n1 mr-2"
              text
              @click="removeVoiceMessage()"
            >
              <v-icon class="mt-n4" size="24px">mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9">
            <audio controls><source :src="soundMsg.url" /></audio>
            <!-- audio-player :theme="theme" :src="mp3" v-if="!showRecorder"/ -->
            <!-- audio-player v-if="soundMsg.url"
            controls
            :src="soundMsg.url"
          ></audio-player -->
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item v-if="openActions === true && card.msgimage" dense>
        <v-row v-if="card.imageid > 0">
          <v-col cols="12">
            <div>
              <v-btn small class="ml-0 mt-0 mb-4" icon @click="removeImage()">
                <v-icon size="24px">mdi-close</v-icon>
              </v-btn>
              <v-img
                :src="card.msgimage"
                class="mb-5 mt-n5 ml-3 white--text align-end"
                width="300px"
              >
                <v-card-title v-text="card.msgtitle"></v-card-title>
              </v-img>
              <v-text-field
                class="ma-0 mt-2 mb-10 ml-3 pa-0"
                solo
                dense
                v-model="card.msgtitle"
                label="On Image Text"
                hide-details="auto"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-list-item>
    </v-card>
    <!-- v-divider class="ma-0"></v-divider -->
    <!-- :dark="heme" -->
    <v-card
      v-if="openActions === true"
      :dark="theme"
      flat
      class="mt-n5 ma-0 pa-0"
    >
      <v-card-text>
        <v-container v-if="showhistory === true" class="ma-0 pa-0">
          <v-row
            v-for="message in userhistmsg"
            :key="message.time"
            dense
            class="mt-0 ml-0 mr-0 mb-3 mx-0"
          >
            <!-- div class="ma-n5 pa-n5" v-if="index > 0" -->
            <v-col v-if="message.color === 'green'" cols="1"></v-col>
            <v-col v-if="message.color === 'green'" cols="11">
              <div class="font-weight-normal text-left black--text">
                <!-- :color="color1" -->

                <!-- color="light-blue lighten-4" -->
                <v-card flat :dark="theme" class="mt-2 pl-2 pt-1 pb-1">
                  <v-row dense>
                    <v-col>
                      <div :class="colorTextLeft">
                        {{ message.message }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="message.voiceid > 0">
                    <div>
                      <audio controls>
                        <source :src="message.voiceimage" />
                      </audio>
                      <!-- audio-player
                    controls
                    :src="message.voiceimage"
                  ></audio-player -->
                    </div>
                  </v-row>
                  <v-row dense>
                    <v-col v-if="message.imageid > 0 && message.msgimage">
                      <v-img
                        @click="openFriendImage(message)"
                        :src="message.msgimage"
                        class="mt-2 mb-2 white--text align-end"
                        :width="widthImage"
                      >
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col :class="colorTextLeftPerson" class="pl-1">
                      {{
                        message.time.substr(0, 10) +
                          " " +
                          message.time.substr(11, 5) +
                          " " +
                          message.from
                      }}
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-col>
            <v-col v-if="message.color !== 'green'" cols="11">
              <div class="font-weight-normal text-left" :class="color2Text">
                <v-row dense v-if="message.voiceid > 0">
                  <div>
                    <audio controls><source :src="message.voiceimage" /></audio>
                    <!-- audio-player
                    controls
                    :src="message.voiceimage"
                  ></audio-player -->
                  </div>
                </v-row>
                <!-- color="amber lighten-4" -->
                <v-card flat :dark="theme" class="mt-2 pl-2 pt-1 pb-1">
                  <v-row dense>
                    <v-col>
                      <div :class="colorTextRight">
                        {{ message.message }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col v-if="message.imageid > 0 && message.msgimage">
                      <v-img
                        @click="openFriendImage(message)"
                        :src="message.msgimage"
                        class="mt-2 mb-2 white--text align-end"
                        :width="widthImage"
                      >
                        <v-card-title :v-text="message.msgtitle"></v-card-title>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col :class="colorTextRightPerson" class="pl-1">
                      {{
                        message.time.substr(0, 10) +
                          " " +
                          message.time.substr(11, 5) +
                          " " +
                          message.from
                      }}
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-col>
            <v-col v-if="message.color !== 'green'" cols="1"> </v-col>
            <!-- /div -->
          </v-row>
        </v-container>
      </v-card-text>
      <!-- v-divider class="mb-3 ma-0"></v-divider -->
    </v-card>
    <v-dialog v-model="spinnerDialog" persistent :max-width="100">
      <v-card>
        <v-card-title>Loading File</v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="popupImageDialog"
      :max-width="popupdialogwidth"
    >
      <v-card flat>
        <v-toolbar dense>
          <v-btn icon @click="closeImageDialog">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-img contain :width="popupimagewidth" :src="popupImage"> </v-img>
      </v-card>
    </v-dialog>
    <!-- Overlay ------------------------------------------------------------------------->
    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :value="xxuserpopupdialog"
    >
      <v-app-bar fixed dense flat class="mb-0 pb-0">
        <v-btn icon @click="userpopupdialog = !userpopupdialog">
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn test @click="scrollToEnd2">
          Scroll bottom
        </v-btn>
        <div class="mr-3">
          {{ item.User.name }}
        </div>
        <v-avatar size="32">
          <v-img :src="item.User.avatar"></v-img>
        </v-avatar>
      </v-app-bar>
      <v-container class="mt-0 mb-50" dense>
        <div id="scrollDialog" style="overflow-y: auto;" class="scrollable">
          <v-row
            dense
            v-for="(message, index) in userhistmsg"
            :key="index"
            class="font-weight-normal black--text"
          >
            <!-- div class="font-weight-normal text-left black--text" -->
            <!-- :color="color1" -->

            <!-- color="light-blue lighten-4" -->
            <v-col v-if="message.color === 'green'" offset="3" cols="9">
              <v-row dense>
                <v-col :class="colorTextLeft" class="text-left">
                  {{ message.message }}
                </v-col>
              </v-row>
              <v-row dense v-if="message.voiceid > 0">
                <v-col>
                  <div>
                    <audio controls>
                      <source :src="message.voiceimage" />
                    </audio>
                    <!-- audio-player
                    controls
                    :src="message.voiceimage"
                  ></audio-player -->
                  </div>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col v-if="message.imageid > 0 && message.msgimage">
                  <v-img
                    @click="openFriendImage(message)"
                    :src="message.msgimage"
                    class="mt-2 mb-2 white--text align-end"
                    :width="widthImage"
                  >
                  </v-img>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col :class="colorTextLeftPerson" class="text-left">
                  {{
                    message.time.substr(0, 10) +
                      " " +
                      message.time.substr(11, 5) +
                      " " +
                      message.from
                  }}
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="message.color !== 'green'" cols="9">
              <div class="font-weight-normal text-left" :class="color2Text">
                <v-row dense v-if="message.voiceid > 0">
                  <v-col>
                    <div>
                      <audio controls>
                        <source :src="message.voiceimage" />
                      </audio>
                      <!-- audio-player
                    controls
                    :src="message.voiceimage"
                  ></audio-player -->
                    </div>
                  </v-col>
                </v-row>
                <!-- color="amber lighten-4" -->
                <!-- v-card flat :dark="theme" class="mt-2 pl-2 pt-1 pb-1" -->
                <v-row dense>
                  <v-col>
                    <div :class="colorTextRight">
                      {{ message.message }}
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col v-if="message.imageid > 0 && message.msgimage">
                    <v-img
                      @click="openFriendImage(message)"
                      :src="message.msgimage"
                      class="mt-2 mb-2 white--text align-end"
                      :width="widthImage"
                    >
                      <v-card-title :v-text="message.msgtitle"></v-card-title>
                    </v-img>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col :class="colorTextRightPerson" class="pl-1 text-left">
                    {{
                      message.time.substr(0, 10) +
                        " " +
                        message.time.substr(11, 5) +
                        " " +
                        message.from
                    }}
                  </v-col>
                </v-row>
                <!-- /v-card -->
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-container v-if="openActionsXs === true" dense class="mb-50">
        <v-row dense v-if="createVoiceMsgXs === true" class="mb-5">
          <RecorderWeb
            v-if="platform === 'web'"
            :platform="platform"
            audiotype="mp3"
            :show="openActionsXs"
            @new-voice-msg="saveVoiceMessage"
          ></RecorderWeb>
        </v-row>
        <v-row
          dense
          v-if="openActionsXs === true && soundMsg.url"
          class="mt-5 mb-10"
        >
          <v-row dense>
            <v-col cols="1">
              <v-btn small class="ma-0" icon @click="removeVoiceMessage()">
                <v-icon class="mt-0" size="24px">mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="10">
              <audio controls><source :src="soundMsg.url" /></audio>
              <!-- audio-player
                    controls
                    :src="message.voiceimage"
                  ></audio-player -->
            </v-col>
          </v-row>
        </v-row>
        <v-row
          v-if="openActionsXs === true && card.msgimage"
          dense
          class="mt-n10"
        >
          <v-row v-if="card.imageid > 0">
            <v-col cols="12">
              <div>
                <v-btn small class="ml-0 mt-0 mb-4" icon @click="removeImage()">
                  <v-icon size="24px">mdi-close</v-icon>
                </v-btn>
                <v-img
                  :src="card.msgimage"
                  class="mb-5 mt-n5 ml-3 white--text align-end"
                  width="300px"
                >
                  <v-card-title v-text="card.msgtitle"></v-card-title>
                </v-img>
                <v-text-field
                  class="ma-0 mt-2 mb-10 ml-3 pa-0"
                  solo
                  dense
                  v-model="card.msgtitle"
                  label="On Image Text"
                  hide-details="auto"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
      <v-footer fixed class="mb-n3">
        <v-container class="ma-0 pa-0">
          <v-row class="mt-2 mb-0" no-gutters>
            <div>
              <v-btn
                class="ml-1 mr-2 mb-n3"
                @click="openMicroPhoneXs"
                icon
                color="primary"
                small
              >
                <v-icon size="24px">mdi-microphone</v-icon>
              </v-btn>
            </div>
            <div>
              <v-file-input
                class="ml-1 mr-1"
                dense
                color="primary"
                prepend-icon="mdi-camera"
                hide-input
                accept="image/png, image/jpeg, image/bmp"
                label=""
                @change="resizeMsgImage"
              ></v-file-input>
            </div>
            <v-textarea
              background-color="grey"
              class="ml-3 mr-5"
              dense
              solo
              auto-grow
              rows="1"
              name="input-7-1"
              label="new message"
              v-model="sendfriendusermessage"
            >
            </v-textarea>
            <v-btn
              small
              class="ma-0 mt-1 mr-3"
              icon
              color="primary"
              @click="sendFriendMessage()"
            >
              <v-icon size="38px">mdi-email-send-outline</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-footer>
    </v-overlay>
    <!-- DIALOG USER POPUP -- :height="scrobbleContainer.scrollHeight - 200" -------------------------------------------------------->
    <v-dialog
      persistent
      scrollable
      v-model="xxuserpopupdialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="60%"
    >
      <v-card :dark="theme">
        <v-app-bar fixed dense flat>
          <v-btn icon @click="closePopUpDialog">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn test @click="scrollToEnd">
            Scroll bottom
          </v-btn>
          <div class="mr-3">
            {{ item.User.name }}
          </div>
          <v-avatar size="32" class="mr-0">
            <v-img :src="item.User.avatar"></v-img>
          </v-avatar>
        </v-app-bar>
        <v-card-text
          id="scrollDialog"
          style="overflow-y: auto;"
          class="ma-0 mb-12"
        >
          <v-row
            dense
            v-for="(message, index) in userhistmsg"
            :key="index"
            class="font-weight-normal black--text"
            :class="`index-${index}`"
            :id="`${index}`"
          >
            <!-- div class="font-weight-normal text-left black--text" -->
            <!-- :color="color1" -->

            <!-- color="light-blue lighten-4" -->
            <v-col v-if="message.color === 'green'" offset="3" cols="9">
              <v-card :dark="theme" class="mt-2 pl-2 pt-1 pb-1">
                <v-row dense>
                  <v-col :class="colorTextLeft" class="text-left">
                    {{ message.message }}
                  </v-col>
                </v-row>
                <v-row dense v-if="message.voiceid > 0">
                  <v-col>
                    <div>
                      <audio controls>
                        <source :src="message.voiceimage" />
                      </audio>
                      <!-- audio-player
                    controls
                    :src="message.voiceimage"
                  ></audio-player -->
                    </div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col v-if="message.imageid > 0 && message.msgimage">
                    <v-img
                      @click="openFriendImage(message)"
                      :src="message.msgimage"
                      class="mt-2 mb-2 white--text align-end"
                      :width="widthImage"
                    >
                    </v-img>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col :class="colorTextLeftPerson" class="text-left">
                    {{
                      message.time.substr(0, 10) +
                        " " +
                        message.time.substr(11, 5) +
                        " " +
                        message.from
                    }}
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col v-if="message.color !== 'green'" cols="9">
              <v-card :dark="theme" class="mt-2 pl-2 pt-1 pb-1">
                <div class="font-weight-normal text-left" :class="color2Text">
                  <v-row dense v-if="message.voiceid > 0">
                    <div>
                      <audio controls>
                        <source :src="message.voiceimage" />
                      </audio>
                      <!-- audio-player
                    controls
                    :src="message.voiceimage"
                  ></audio-player -->
                    </div>
                  </v-row>
                  <!-- color="amber lighten-4" -->
                  <!-- v-card flat :dark="theme" class="mt-2 pl-2 pt-1 pb-1" -->
                  <v-row dense>
                    <v-col>
                      <div :class="colorTextRight">
                        {{ message.message }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col v-if="message.imageid > 0 && message.msgimage">
                      <v-img
                        @click="openFriendImage(message)"
                        :src="message.msgimage"
                        class="mt-2 mb-2 white--text align-end"
                        :width="widthImage"
                      >
                        <v-card-title :v-text="message.msgtitle"></v-card-title>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col :class="colorTextRightPerson" class="pl-1">
                      {{
                        message.time.substr(0, 10) +
                          " " +
                          message.time.substr(11, 5) +
                          " " +
                          message.from
                      }}
                    </v-col>
                  </v-row>
                  <!-- /v-card -->
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-row
          dense
          v-if="openActions === true && createVoiceMsg === true"
          class="mb-5"
        >
          <RecorderWeb
            v-if="platform === 'web'"
            :platform="platform"
            audiotype="mp3"
            :show="openActions"
            @new-voice-msg="saveVoiceMessage"
          ></RecorderWeb>
        </v-row>
        <v-row
          dense
          v-if="openActions === true && soundMsg.url"
          class="mt-5 mb-10"
        >
          <v-row dense>
            <v-col cols="1">
              <v-btn small class="ma-0" icon @click="removeVoiceMessage()">
                <v-icon class="mt-0" size="24px">mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="10">
              <audio controls><source :src="soundMsg.url" /></audio>
              <!-- audio-player
                    controls
                    :src="message.voiceimage"
                  ></audio-player -->
            </v-col>
          </v-row>
        </v-row>
        <v-row
          v-if="openActions === true && card.msgimage"
          dense
          class="mt-n10"
        >
          <v-row v-if="card.imageid > 0">
            <v-col cols="12">
              <div>
                <v-btn small class="ml-0 mt-0 mb-4" icon @click="removeImage()">
                  <v-icon size="24px">mdi-close</v-icon>
                </v-btn>
                <v-img
                  :src="card.msgimage"
                  class="mb-5 mt-n5 ml-3 white--text align-end"
                  width="300px"
                >
                  <v-card-title v-text="card.msgtitle"></v-card-title>
                </v-img>
                <v-text-field
                  class="ma-0 mt-2 mb-10 ml-3 pa-0"
                  solo
                  dense
                  v-model="card.msgtitle"
                  label="On Image Text"
                  hide-details="auto"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-row>
        <v-card-actions ref="card-actions">
          <!-- v-btn
            class="bottom"
            text
            color="primary"
            small
          >
            <v-icon size="24px">mdi-microphone</v-icon>
          </v-btn -->
          <!-- v-container class="ma-0 pa-0">
            <v-row class="mt-2 mb-0" no-gutters>
              <div>
                <v-btn
                  class="ml-1 mr-2 mb-n3"
                  @click="@click="openMicroPhone"
                  icon
                  color="primary"
                  small
                >
                  <v-icon size="24px">mdi-microphone</v-icon>
                </v-btn>
              </div>
              <div>
                <v-file-input
                  class="ml-1 mr-1"
                  dense
                  color="primary"
                  prepend-icon="mdi-camera"
                  hide-input
                  accept="image/png, image/jpeg, image/bmp"
                  label=""
                  @change="resizeMsgImage"
                ></v-file-input>
              </div>
              <v-textarea
                background-color="grey"
                class="ml-3 mr-5"
                dense
                solo
                auto-grow
                rows="1"
                name="input-7-1"
                label="new message"
              >
              </v-textarea>
              <v-btn
                small
                class="ma-0 mt-1 mr-3"
                icon
                color="primary"
                @click="sendFriendMessage()"
              >
                <v-icon size="38px">mdi-email-send-outline</v-icon>
              </v-btn>
            </v-row>
          </v-container -->
        </v-card-actions>
        <v-footer fixed class="mb-n3">
          <v-container class="ma-0 pa-0">
            <v-row class="mt-2 mb-0" no-gutters>
              <div>
                <v-btn
                  class="ml-1 mr-2 mb-n3"
                  @click="openMicroPhone"
                  icon
                  color="primary"
                  small
                >
                  <v-icon size="24px">mdi-microphone</v-icon>
                </v-btn>
              </div>
              <div>
                <v-file-input
                  class="ml-1 mr-1"
                  dense
                  color="primary"
                  prepend-icon="mdi-camera"
                  hide-input
                  accept="image/png, image/jpeg, image/bmp"
                  label=""
                  @change="resizeMsgImage"
                ></v-file-input>
              </div>
              <v-textarea
                background-color="grey"
                class="ml-3 mr-5"
                dense
                solo
                auto-grow
                rows="1"
                name="input-7-1"
                label="new message"
              >
              </v-textarea>
              <v-btn
                small
                class="ma-0 mt-1 mr-3"
                icon
                color="primary"
                @click="sendFriendMessage()"
              >
                <v-icon size="38px">mdi-email-send-outline</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-footer>
      </v-card>
    </v-dialog>
    <!-- Snackbar color=''></Snackbar -->
    <v-snackbar v-model="snackbarwarningmsg" top color="warning">
      {{ notregtext }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbarwarningmsg = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
/* eslint-disable */
import UserService from "@/services/UserService";
import SearchesService from "@/services/SearchesService";
import MyImageService from "@/services/MyImageService";
import VoiceMessageService from "@/services/VoiceMessageService";
import VoiceMessageServiceForm from "@/services/VoiceMessageServiceForm";
import resizeImage from "@/utils/resizeImage.js";
import RecorderWeb from "@/components/RecorderWeb.vue";
import * as socketio from "@/plugins/socketio";
// import RecorderPhone from "@/components/RecorderPhone.vue";
// import AudioPlayer from './Player';

export default {
  emits: ["new-friend-msg", "minus-friend-badge"],
  components: {
    RecorderWeb,
    // RecorderPhone,
    // AudioPlayer
  },
  name: "FriendsChat",
  props: ["item", "activityid", "userid", "theme", "platform"],
  data() {
    return {
      absolute: false,
      opacity: 1,
      overlay: false,
      userpopupdialog: false,
      xxuserpopupdialog: false,
      popupImage: "",
      popupImageDialog: false,
      FriendBadge: 0,
      localMsgBadge: 0,
      spinnerDialog: false,
      fileLoading: false,
      indeterminate: false,
      darkTheme: false,
      btnTextShowMore: "More...",
      widthImage: "300px",
      // colorBlockRight: "light-blue lighten-4",
      // colorTextRight: "purple--text",
      // colorBlockLeft: "amber lighten-4",
      // colorTextLeft: "green--text",
      colorBlockRight: "light-blue lighten-4",
      colorTextRight: "purple--text",
      colorTextRightPerson: "purple--text",
      colorBlockLeft: "amber lighten-4",
      colorTextLeft: "green--text",
      colorTextLeftPerson: "green--text",
      color1Text: "purple--text",
      color2Text: "green--text",
      textFieldColorBgr: "grey lighten-2",
      color1: "lime lighten-1",
      color1Background: "",
      // color2: "orange lighten-2",
      color2: "black",
      color2Background: "",
      unsaveddata: false,
      deviceIsReady: false,
      backgroundColor: "",
      card: { msgimage: "", msgtitle: "", imageid: 0 },
      chat: true,
      sendfriendusermessage: "",
      messageitem: null,
      requestlist: [],
      userhistmsg: [],
      userhistmsgtmp: [],
      createText: false,
      createVoiceMsg: false,
      openActions: false,
      createTextXs: false,
      createVoiceMsgXs: false,
      openActionsXs: false,
      showMessages: false,
      overlay: false,
      msgdialog: false,
      histdialog: false,
      offsetTop: 1,
      lastmessage: null,
      msgimage: "",
      showhistory: false,
      showNew: false,
      maxImageSize: 700000,
      resizedImg: null,
      soundMsg: {
        file: null,
        url: "",
        id: 0,
        title: "",
        type: "audio/mpeg",
        size: 0,
        msg: "",
        file2: null,
      },
      snackbarwarningmsg: false,
      notregtext: "",
      duration: 300,
      offset: 0,
      easing: 0,
      scrobbleContainer: "",
    };
  },
  watch: {
    /*
    dialogVisible(userpopupdialog) {
      if (userpopupdialog) {
        this.scrollToEnd();
        console.log("Dialog was opened!");
      } else {
        console.log("Dialog was closed!");
      }
    }, */
    msgdialog() {
      // if (visible) {
      // Here you would put something to happen when dialog opens up
      // window.scrollTo(0, 110);
      // const el = this.$msgcard.scrollToMe;
      // if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      //  el.scrollIntoView({behavior: 'smooth'});
      //    }
      // var container = document.querySelector(".v-card");
      // container.scrollTop = document.body.scrollHeight;
      // container.scrollTo(0, 110);
      // this.$vuetify.goTo(document.body.scrollHeight, { container: this.$refs.msgcard })
      // }
    },
  },
  created() {
    if (this.theme === true) {
      this.backgroundColor = "grey";
      this.textFieldColorBgr = "grey lighten-2";
      this.darkTheme = true;
    }
    if (this.theme === false) {
      this.darkTheme = false;
      this.backgroundColor = "white";
      this.textFieldColorBgr = "white";
      this.color1Text = "black--text";
      this.color2Text = "black--text";
    }
    // this.messageitem = await this.updateHistRec(this.item.Msg);
    // console.log(this.messageitem);
  },
  computed: {
    determinate() {
      if (this.indeterminate === true) {
        return { indeterminate: true };
      } else {
        return { indeterminate: false };
      }
    },
    popupdialogwidth() {
      if (this.$vuetify.breakpoint.name === "xs") return "100%";
      if (this.$vuetify.breakpoint.name === "sm") return "90%";
      if (this.$vuetify.breakpoint.name === "md") return "60%";
      if (this.$vuetify.breakpoint.name === "lg") return "50%";
      if (this.$vuetify.breakpoint.name === "xl") return "50%";
    },
    popupimagewidth() {
      if (this.$vuetify.breakpoint.name === "xs") return "100%";
      if (this.$vuetify.breakpoint.name === "sm") return "100%";
      if (this.$vuetify.breakpoint.name === "md") return "100%";
      if (this.$vuetify.breakpoint.name === "lg") return "100%";
      if (this.$vuetify.breakpoint.name === "xl") return "100%";
    },
  },
  async mounted() {
    console.log(this.theme);
    console.log(this.item);
    this.messageitem = await this.updateHistRec(this.item.Msg);
    // this.userhistmsgtmp.push(this.messageitem);
    console.log(this.messageitem);

    /*
    if(this.theme === true) {
      this.backgroundColor = "grey";
      this.textFieldColorBgr = "Grey";
      this.darkTheme = true;
    }
    if(this.theme === false) {
      this.darkTheme = false;
      this.backgroundColor = "white";
      this.textFieldColorBgr = "white";
      this.color1Text = "green--text";
      this.color2Text = "bluegrey--text";
    }
    */

    // document.addEventListener('deviceready' , this.onDeviceReady , false);
    /*
   if(this.theme === "dark") {
      this.backgroundColor = "grey";
    }
    if(this.theme === "light") {
      this.backgroundColor = "grey lighten-2";
    }
    */
  },
  methods: {
    scrollToEnd() {
      this.$nextTick().then(() => {
        // let myElement = document.getElementById("scrollDialog");
        // let topPos = myElement.scrollHeight;
        // document.getElementById('scrollDialog').scrollTop = topPos;

        // let scrollToElement = document.getElementById("scrollDialog")
        // scrollToElement.scrollIntoView(false);
        // scrollToElement.scrollTop = scrollToElement.scrollHeight;
        let pos = this.userhistmsg.length;
        if (pos > 0) {
          pos = pos - 1;
        }
        // let gotoStr = ".index-" + pos.toString();
        let gotoStr = "#" + pos.toString();
        let selector = document.querySelector(gotoStr);
        let options = { duration: 2000 };
        console.log(selector);
        this.$vuetify.goTo(gotoStr, options);
      });
    },
    scrollToEnd2() {
      this.$nextTick().then(() => {
        // let myElement = document.getElementById("scrollDialog");
        // let topPos = myElement.scrollHeight;
        // document.getElementById('scrollDialog').scrollTop = topPos;

        let scrollToElement = document.getElementById("scrollDialog");
        // scrollToElement.scrollIntoView(false);
        scrollToElement.scrollTop = scrollToElement.scrollHeight;
        /// let pos = this.userhistmsg.length;
        /// if (pos > 0) {
        ///  pos = pos - 1;
        /// }
        // let gotoStr = ".index-" + pos.toString();
        /// let gotoStr = "#" + pos.toString();
        // let selector = document.querySelector(gotoStr);
        /// let options = { duration: 2000 };
        // console.log(selector);
        /// this.$vuetify.goTo(gotoStr, options);
      });
      /*
      {
            duration: 200,
            offset: 0,
            easing: "easeInOutCubic",
            container: "div.v-card__text"
        }
      */
      // const pos = this.userhistmsg.length - 1;
      // const el = this.$el.getElementsByClassName('#31')[0];
      // if (el) {
      //  el.scrollIntoView({behavior: 'smooth'});
      // }
      // let height = container.scrollHeight;
      // this.$vuetify.goTo(9999, { container: ".v-card__text" });
      // this.$el.scrollTop = this.$el.lastElementChild.offsetTop;
      // let container = document.getElementById("scrollDialog");
      // scrollToElementght;
      // container.scrollIntoView(false);
      // document.getElementsByClassName("v-dialog--active")[0].scrollTop = height;
      // this.scrobbleContainer = document.getElementById("scrollDialog");
      // container.scrollTop = container.scrollHeight;
      // this.scrobbleContainer.scrollHeight;
      // let container = this.$el.querySelector("scrollDialog");
      // let height = container.scrollHeight;
      // container.scrollTop = height;
      // this.option.offset = height;
      // let wrapper = this.$refs['card'].el.querySelector('div.v-card__text')
      // this.$vuetify.goTo(9999, { container: 'div.v-card__text' })
      // this.$vuetify.goTo($refs.actions, { container: $refs.card });
      // this.$vuetify.goTo("#31");
    },
    closePopUpDialog() {
      // this.$vuetify.goTo(0, { container: "div.v-card__text" });
      this.openActions = false;
      this.createText = false;
      this.showhistory = false;
      this.userpopupdialog = false;
    },
    saveVoiceMessage(voiceMsg) {
      console.log("saveVoiceMessage");
      this.soundMsg = voiceMsg;
      this.createVoiceMsg = false;
      // this.sendFriendMessage(voiceMsg);
    },
    newFriendMessage() {
      this.$emit("new-friend-msg");
    },
    minusFriendBadge() {
      this.$emit("minus-friend-badge", this.item);
      this.FriendBadge--;
    },
    removeVoiceMessage() {
      this.soundMsg.file = null;
      this.soundMsg.url = "";
      this.soundMsg.id = 0;
      this.soundMsg.title = "";
      this.soundMsg.type = "";
      this.soundMsg.size = 0;
      this.soundMsg.msg = "";
      this.soundMsg.file2 = null;
    },
    async openHistory() {
      if (this.showhistory === false) {
        this.showhistory = true;
        this.btnTextShowMore = "Less...";
        this.getFriendHistory();
        let index = 0;
        while (this.userhistmsg[index]) {
          if (this.userhistmsg[index].receiverread === 0) {
            this.userhistmsg[index].receiverread = 1;
            const read = await UserService.setMessageRead({
              id: this.userhistmsg[index].id,
            });
            console.log(read);
          }
          index++;
        }
      } else {
        this.showhistory = false;
        console.log(this.messageitem);
        this.btnTextShowMore = "More...";
        this.messageitem = this.userhistmsg[0];
        // this.messageitem = this.updateHistRec(this.item.Msg);
      }
    },
    async openMicroPhone() {
      if (this.createVoiceMsg === false) {
        // document.addEventListener('deviceready' , this.onDeviceReady , false);
        this.createVoiceMsg = true;
        this.openActions = true;
        this.removeVoiceMessage();
        // this.checkPermission();
      } else {
        this.createVoiceMsg = false;
        // this.openActions = false;
        // this.soundMsg.url = "";
        // this.stopRecording();
      }
    },
    async openMicroPhoneXs() {
      if (this.createVoiceMsgXs === false) {
        // document.addEventListener('deviceready' , this.onDeviceReady , false);
        this.createVoiceMsgXs = true;
        this.openActionsXs = true;
        this.removeVoiceMessage();
        // this.checkPermission();
      } else {
        this.createVoiceMsgXs = false;
        this.openActionsXs = false;
        // this.soundMsg.url = "";
        // this.stopRecording();
      }
    },
    async sendFriendMessage() {
      // this.messageitem = this.item;
      if (this.sendfriendusermessage === "") {
        if (this.soundMsg.msg === "" && this.soundMsg.file === null) {
          this.openWarningSnackBarMsg("Write some message!");
          return;
        }
        // if (this.soundMsg.msg === "" && this.soundMsg.file) {
        //  this.sendfriendusermessage = this.voiceTitle;
        // }
        if (this.soundMsg.msg === "pause recording") {
          this.openWarningSnackBarMsg(
            "Please End Your Message By Press Stop Button"
          );
          return;
        }
      }
      if (this.sendfriendusermessage) {
        if (this.soundMsg.msg === "pause recording") {
          this.openWarningSnackBarMsg(
            "Please End Your Message By Press Stop Button"
          );
          return;
        }
      }
      if (this.soundMsg.url) {
        await this.saveVoiceMsg2(this.soundMsg);
      }
      // console.log(this.messageitem);
      var message = {
        idgroup: 0,
        idactivity: 0,
        idsender: 0,
        idreceiver: 0,
        sendername: "",
        receivername: "",
        message: "",
        imageid: 0,
        msgtitle: "",
        msgimage: "",
        voiceid: this.soundMsg.id,
        voiceimage: null,
        type: "single",
      };
      // var response = null
      var newmessage = null;
      try {
        message.idgroup = this.item.id;
        if (this.item.idfriend === this.userid) {
          message.idreceiver = this.item.iduser;
        } else {
          message.idreceiver = this.item.idfriend;
        }
        message.idactivity = this.activityid;
        message.idsender = this.userid;
        message.sendername = this.$store.state.user.name;
        message.receivername = this.item.User.name;
        message.message = this.sendfriendusermessage;
        message.imageid = this.card.imageid;
        message.msgtitle = this.card.msgtitle;
        // message.msgimage = this.card.msgimage;
        // this.socket.emit('new_message', message )
        // this.sendIOMessage(message)
        const response = await UserService.sendMyMessage(message);
        newmessage = response.data.message;
        newmessage.msgimage = this.card.msgimage;
        // this.messagefrienddialog = false; denna används inte i inline versionen
        if (newmessage.voiceid > 0) {
          newmessage.voiceimage = this.soundMsg.url;
        }
        this.sendfriendusermessage = "";
        // this.messagelist.unshift(newmessage);
        // this.updateFriendList(newmessage);
        this.updateFriendHistoryList(newmessage);
        if (this.showhistory === false) {
          // this.item.Msg = newmessage;
          // this.messageitem = newmessage;
          this.messageitem = await this.updateHistRec(newmessage);
        }
        this.newFriendMessage(newmessage);
        this.clearCard();
        this.clearSoundMsg();
        // this.soundMsg.file = null;
        // this.soundMsg.id = 0;
        newmessage.voiceimage = "";
      } catch (error) {
        console.log(error.message);
        this.clearCard();
        this.clearSoundMsg();
        // this.soundMsg.file = null;
        // this.soundMsg.id = 0;
        newmessage.voiceimage = "";
        // this.error = error.response.data.error
      }
      try {
        this.sendIOMessage(newmessage);
      } catch (error) {
        console.log(error);
      }
    },
    updateFriendList(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      try {
        // Add to friendlist message field also
        var index = this.friendlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          this.friendlist[index].message = message.message;
          this.friendlist[index].messageAt = message.createdAt;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getFriendHistory() {
      // this is called from Friend list when
      // user expends the list and send message
      let usertmp = [];
      try {
        console.log(this.activityid);
        // this.clearCard();
        // this.clearSoundMsg();

        // this.userhistmsg = [];

        if (this.userhistmsg.length > 0) {
          return;
        }

        var item = this.item;
        // this.userhistmsg = [];
        var friendid = 0;
        if (item.idfriend === this.userid) {
          friendid = item.iduser;
        } else {
          friendid = item.idfriend;
        }
        console.log(this.activityid);
        // const activityid = this.activityid;
        const usermsg = await SearchesService.getActivityMessagesHist(
          this.userid,
          friendid,
          this.activityid,
          true
        );
        const tmp = usermsg.data;
        var index = 0;
        if (tmp.length > 0) {
          while (tmp[index]) {
            var rec = {
              id: 0,
              idgroup: 0,
              idactivity: 0,
              idsender: 0,
              idreceiver: 0,
              receiverread: 0,
              from: "",
              message: "",
              time: "",
              color: "",
              imageid: 0,
              msgimage: "",
              msgtitle: "",
              voiceid: 0,
              voiceimage: "",
            };
            if (tmp[index].idsender === this.userid) {
              rec.color = "green";
            } else {
              rec.color = "blue";
            }
            rec.from = tmp[index].sendername;
            rec.message = tmp[index].message;
            rec.time = tmp[index].createdAt;
            rec.id = tmp[index].id;
            rec.idgroup = tmp[index].idgroup;
            rec.idsender = tmp[index].idsender;
            rec.idreceiver = tmp[index].idreceiver;
            rec.receiverread = tmp[index].receiverread;
            rec.idactivity = tmp[index].idactivity;
            rec.imageid = tmp[index].imageid;
            rec.msgtitle = tmp[index].msgtitle;
            // rec.voiceimage = tmp[index].voiceimage;
            try {
              rec.voiceid = tmp[index].voiceid;
              if (rec.voiceid > 0) {
                const res = await VoiceMessageService.getVoiceDataMessage(
                  rec.voiceid
                );
                // rec.voiceimage = res.data;
                // rec.voiceimage = window.URL.createObjectURL(res.data);
                // const blob = new Blob([res.data], { type: "audio/mp3" });
                // rec.voiceimage = (window.URL ? URL : webkitURL).createObjectURL(blob);
                // rec.voiceimage = res.data;
                console.log(rec);
                try {
                  rec.voiceimage = res.data;
                  // window.URL.createObjectURL(res.data.file);
                  if (rec.voiceid === 42) console.log(rec.voiceimage);
                  console.log("efter rec.voiceimage");
                } catch (error) {
                  console.log(error);
                }
                // rec.voiceimage = temp;
                // const reader = new FileReader();
                // var furl = reader.readAsDataURL(res.data.file);
                // rec.voiceimage = furl;
                // rec.voiceid = res.data.id
              }
            } catch (error) {
              console.log(error);
            }
            try {
              if (rec.imageid > 0) {
                const res = await MyImageService.getmsgimage(rec.imageid);
                rec.msgimage = res.data;
              }
            } catch (error) {
              console.log(error);
            }
            usertmp.unshift(rec);
            index = index + 1;
          }

          // let size = this.$vuetify.breakpoint.name;
          // size = "xs";

          /*
          if (size === "xs") {
            usertmp.sort(function(a, b) {
              const idA = a.time;
              const idB = b.time;
              let comparison = 0;
              if (idA < idB) {
                comparison = -1;
              } else if (idA > idB) {
                comparison = 1;
              }
              return comparison;
            });
          } else {
          */
          usertmp.sort(function(a, b) {
            const idA = a.time;
            const idB = b.time;
            let comparison = 0;
            if (idA > idB) {
              comparison = -1;
            } else if (idA < idB) {
              comparison = 1;
            }
            return comparison;
          });
        }
        // this.userhistmsg = usertmp;
        this.userhistmsg.push(...usertmp);
        if (tmp.length > 0 && item.Msg.receiverread === 0) {
          item.Msg.receiverread = 1;
          const read = await UserService.setMessageRead({ id: item.Msg.id });
          console.log(read);
        }
      } catch (error) {
        console.log(error);
      }
      // this.messageitem = item;
      // this.messagelist[this.selectedmessagerows]
      // console.log(this.messageitem);
    },
    fileLoad(file) {
      this.fileLoading = true;
      this.spinnerDialog = true;
      this.resizeMsgImage(file);
      this.spinnerDialog = false;
      this.fileLoading = false;
    },
    resizeMsgImage(file) {
      // START: preview original
      // you can remove this section if you don't like to preview original image
      if (!file.type.match(/image.*/)) {
        // no(new Error('Not an image'));
        return;
      }
      // 20210514 CWE
      // this.clearCard();
      const reader = new FileReader();
      reader.onload = (e) => (this.originalImg = e.target.result);
      reader.readAsDataURL(file); // convert to base64 string
      // END: preview original

      // START: preview resized
      // const resimg = resizeImage({ file: file, maxSize: 150 });
      // this.resizedImg = window.URL.createObjectURL(resimg);
      resizeImage({ file: file, maxSize: this.maxImageSize })
        .then((resizedImage) => {
          this.resizedImg = URL.createObjectURL(resizedImage);
          this.card.msgimage = this.resizedImg;
          this.saveMsgImage(resizedImage);
          // console.log("this.resizedImg " + this.resizedImg);
        })
        .catch((err) => {
          console.log(err);
        });
      // END: preview resized
    },
    clearSoundMsg() {
      // soundMsg: { file: null, url: "", id: 0, title: "", type: "audio/mpeg", size: 0, msg: "", file2: null }
      this.soundMsg.file = null;
      this.soundMsg.url = "";
      this.soundMsg.id = 0;
      this.soundMsg.title = "";
      this.soundMsg.type = "audio/mpeg";
      this.soundMsg.size = 0;
      this.soundMsg.msg = "";
      this.soundMsg.file2 = null;
    },
    openWarningSnackBarMsg(msg) {
      this.notregtext = msg;
      this.snackbarwarningmsg = true;
    },
    async saveVoiceMsg2(soundmsg) {
      try {
        const data = new FormData();
        data.append("file", soundmsg.file);

        // const headers = Object.assign(this.headers, {})
        // headers['Content-Type'] = `multipart/form-data; boundary=${data._boundary}`

        data.append("userid", this.$store.state.user.id);
        data.append("messageid", 0);
        // data.append("name", " ");
        // data.append("name", );
        data.append("type", soundmsg.type);
        data.append("size", soundmsg.size);
        // data.append("file", this.soundMsg.file);
        // var voicefile = {userid: this.userid,
        //  file: blob, len: tempfile.size, type: tempfile.type};
        const response = await VoiceMessageServiceForm.saveVoiceDataMessage(
          data
        );
        console.log(response);
        this.soundMsg.id = response.data.id;
        console.log(response.data.id);
        // const voice = await VoiceMessageService.getVoiceMessageresponse.data.id);
        // console.log(voice.data)
      } catch (error) {
        console.log(error);
      }
    },
    clearCard() {
      this.card.msgimage = "";
      this.card.msgtitle = "";
      this.card.imageid = 0;
    },
    sendIOMessage(message) {
      // this.$socket.client.emit('new_message', message);
      socketio.sendEvent({
        type: "new_message",
        data: message,
      });
    },
    async updateFriendHistoryList(message) {
      // this is called from Friend list when
      // user expends the list and send message
      try {
        // if userlist needs update idsender idreceiver
        // check if right user list
        var rec = {
          id: 0,
          idgroup: 0,
          idactivity: 0,
          idsender: 0,
          idreceiver: 0,
          from: "",
          message: "",
          imageid: 0,
          msgtitle: "",
          msgimage: "",
          time: "",
          color: "",
          voiceid: 0,
          voiceimage: null,
        };
        if (message.idsender === this.userid) {
          rec.color = "green";
        } else {
          rec.color = "deep-purple lighten-1";
        }
        rec.from = message.sendername;
        rec.message = message.message;
        rec.time = message.createdAt;
        rec.id = message.id;
        rec.idgroup = message.idgroup;
        rec.idsender = message.idsender;
        rec.idreceiver = message.idreceiver;
        rec.idactivity = message.idactivity;
        rec.imageid = message.imageid;
        rec.msgtitle = message.msgtitle;
        if (message.msgimage) {
          rec.msgimage = message.msgimage;
        }
        rec.voiceid = message.voiceid;
        rec.voiceimage = message.voiceimage;
        this.userhistmsg.unshift(rec);
      } catch (error) {
        console.log(error);
      }
    },
    async updateHistRec(message) {
      // this is called from Friend list when
      // user expends the list and send message
      try {
        // check if userlist needs update idsender idreceiver
        // check if right user list
        var rec = {
          id: 0,
          idgroup: 0,
          idactivity: 0,
          idsender: 0,
          idreceiver: 0,
          from: "",
          message: "",
          imageid: 0,
          msgtitle: "",
          msgimage: "",
          time: "",
          color: "",
          voiceid: 0,
          voiceimage: null,
        };
        if (message.idsender === this.userid) {
          rec.color = "green";
        } else {
          rec.color = "deep-purple lighten-1";
        }
        rec.from = message.sendername;
        rec.message = message.message;
        rec.time = message.createdAt;
        rec.id = message.id;
        rec.idgroup = message.idgroup;
        rec.idsender = message.idsender;
        rec.idreceiver = message.idreceiver;
        rec.idactivity = message.idactivity;
        rec.imageid = message.imageid;
        rec.msgtitle = message.msgtitle;
        try {
          if (rec.imageid > 0) {
            const res = await MyImageService.getmsgimage(rec.imageid);
            rec.msgimage = res.data;
          }
        } catch (error) {
          console.log(error);
        }
        // rec.msgimage = message.msgimage;
        rec.voiceid = message.voiceid;
        try {
          if (rec.voiceid > 0) {
            const res = await VoiceMessageService.getVoiceDataMessage(
              rec.voiceid
            );
            // rec.voiceimage = res.data;
            // rec.voiceimage = window.URL.createObjectURL(res.data);
            // const blob = new Blob([res.data], { type: "audio/mp3" });
            // rec.voiceimage = (window.URL ? URL : webkitURL).createObjectURL(blob);
            // rec.voiceimage = res.data;
            try {
              rec.voiceimage = res.data;
              // console.log("efter rec.voiceimage");
            } catch (error) {
              console.log(error);
            }
            // rec.voiceimage = temp;
            // const reader = new FileReader();
            // var furl = reader.readAsDataURL(res.data.file);
            // rec.voiceimage = furl;
            // rec.voiceid = res.data.id
          }
        } catch (error) {
          console.log(error);
        }
        return rec;
      } catch (error) {
        console.log(error);
      }
    },
    async removeImage() {
      console.log("removeImage");
      try {
        await MyImageService.deletemyimage({ id: this.card.imageid });
        this.card.msgimage = "";
        this.card.imageid = 0;
      } catch (error) {
        console.log(error.message);
        this.card.msgimage = "";
        this.card.imageid = 0;
      }
    },
    async saveMsgImage(file) {
      this.currentFile = file;
      // Test resize here
      try {
        // const file2 = this.resizeMsgImage(file)

        var data = new FormData();
        data.append("userid", this.$store.state.user.id);
        data.append("text", " ");
        data.append("title", this.card.msgtitle);
        data.append("imagetype", "friendmsg");
        data.append("filename", file.name);
        this.MyImageType = "friendmsg";
        data.append("file", file);
        const response = await MyImageService.savemsgimage(data);
        console.log(response);
        this.card.imageid = response.data.id;
        await this.getMsgImage(response.data.id);

        // this.imagesdialog = false
        // var avatarfile =
        //  response.data.mysavedimage.FullPath +
        //  response.data.mysavedimage.DiscFileName;
        // this.notificationtext = "Your image is saved ";
        // this.snackbar = true;
        // console.log(usrupd);
      } catch (error) {
        console.log(error);
      }
    },
    async getMsgImage(msgid) {
      try {
        const res = await MyImageService.getmsgimage(msgid);
        this.card.msgimage = res.data;
        if (res.data === "NoImage") {
          console.log("NoImage");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openActionWindow() {
      // if (this.$vuetify.breakpoint.xs) {
      /*
      let test = true;
      if (test) {
        if (this.openActions === false) {
          this.$router.push({
            name: "friendwindow",
            params: {
              item: this.item,
              activityid: this.activityid,
              userid: this.userid,
              theme: this.theme,
              platform: this.platform,
            },
          });
          this.userpopupdialog = true;
          await this.getFriendHistory();
          this.scrollToEnd2();
        } else {
          this.openActions = false;
          this.createText = false;
          this.showhistory = false;
        }
        return;
      } 
      */

      if (this.openActions === false) {
        this.openActions = true;
        this.showhistory = true;
        this.createText = true;
        if (this.item.Msg.receiverread === 0 || this.item.Msg.msgbadge === 1) {
          this.item.Msg.receiverread = 1;
          const read = await UserService.setMessageRead({
            id: this.item.Msg.id,
          });
          this.item.Msg.msgbadge = 0;
          // this.localMsgBadge
          this.minusFriendBadge();
          console.log(read);
        }
        this.getFriendHistory();
        console.log("receiverread");
      } else {
        this.openActions = false;
        this.createText = false;
        this.showhistory = false;
      }
    },
    openFriendImage(message) {
      this.popupImageDialog = true;
      this.popupImage = message.msgimage;
    },
    closeImageDialog() {
      this.popupImageDialog = false;
      this.popupImage = "";
    },
  },
};
</script>

<!-- height: calc(100vh - 20px); -->

<style scoped>
.bottom {
  margin-top: 0px;
}

.v_main {
  margin-bottom: 50px;
}

.scrollable {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh);
}

.imgmargin {
  margin-right: 10px;
}

.backgrounddark {
  background-color: grey;
  color: rgb(255, 255, 255);
}

.backgroundlight {
  background-color: #e0e0e0;
  color: rgb(0, 0, 0);
}

.app {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
</style>
