<template>
  <!-- class="overflow-hidden" -->
  <!-- v-card flat height="100%" style="margin-bottom: 20px;" -->
  <v-card :dark="selectedTheme" height="100%" flat>
    <!-- v-app id="fhfadmin" -->

    <!-- V App Bar ------------- :dark="selectedTheme" :clipped-left="$vuetify.breakpoint.lgAndUp" ------------------------------------------------------->
    <v-app-bar
      extended
      extension-height="60"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="teal"
      app
    >
      <!-- template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template -->
      <!-- -->
      <v-btn icon @click="mainWindow()">
        <v-icon dark :size="icontabsize + 'px'">mdi-arrow-left-thick</v-icon>
      </v-btn>
      <v-toolbar-title style="width: 400px;font-size: 1.4em">
        <span>Admin Find Have Fun</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="companySettingsWindow()">
        <v-icon :size="icontabsize + 'px'">mdi-cog</v-icon>
      </v-btn>
      <!-- background-color="rgba(255,255,255,0.2)" -->
      <template v-slot:extension>
        <v-tabs
          fixed-tabs
          color="yellow darken-3"
          class="text-h1 font-weight-bold font-italic"
          v-model="admin_tabs"
          icons-and-text
        >
          <v-tab style="font-size: 10px" href="#tab-coach">Coach
            <v-icon size="icontabsize + 'px'"
              >mdi-emoticon-cool-outline</v-icon
            >
          </v-tab>
          <v-tab style="font-size: 10px" href="#tab-groups" @click="reloadGroups()">Group
            <v-icon :size="icontabsize + 'px'"
              >mdi-account-group-outline</v-icon
            >
          </v-tab>
          <v-tab style="font-size: 10px" href="#tab-members" @click="initTabMembers()">Member
            <v-icon :size="icontabsize + 'px'"
              >mdi-account-search-outline</v-icon
            >
          </v-tab>
          <v-tab style="font-size: 10px" href="#tab-calender">Calender
            <v-icon :size="icontabsize + 'px'">mdi-calendar-month</v-icon>
          </v-tab>
          <!-- v-tab style="font-size: 10px" href="#tab-facilities">Facility
            <v-icon :size="icontabsize + 'px'">mdi-domain</v-icon>
          </v-tab -->
        </v-tabs>
      </template>
    </v-app-bar>
    <!-- END V App Bar ---------------------------------------------------------------->
    <v-tabs-items :value="admin_tabs">
      <v-tab-item value="tab-coach">
        <v-card :dark="selectedTheme" flat>
          <v-row>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <!-- v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search Coaches"
              single-line
              dense
              hide-details
            ></v-text-field -->
              <!-- v-toolbar dense color="amber lighten-2" flat light>
                <v-row nogutters>
                  <v-col>
                    <v-btn text color="primary" class="mt-4 pa-0">
                      My Coaches
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      flat
                      v-model="autofillcoachesmessage"
                      :items="coacheslist"
                      item-text="name"
                      class="mt-4 pa-0"
                      dense
                      label="filter"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-toolbar -->
              <v-row dense>
                <v-col>
                  <v-btn text color="primary" class="ma-0 ml-2 mt-2 pa-0">
                    My Coaches
                  </v-btn>
                </v-col>
                <v-col>
                  <!-- v-autocomplete
                    flat
                    v-model="autofillcoachesmessage"
                    :items="coacheslist"
                    item-text="name"
                    class="mt-4 pa-0"
                    dense
                    label="filter"
                  ></v-autocomplete -->
                </v-col>
              </v-row>

              <v-container fluid class="ma-0 pa-0">
                <v-list dense>
                  <v-list-group
                    v-for="item in coacheslist"
                    :key="item.id"
                    v-model="item.dialog"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item-avatar>
                        <v-img class="ma-0 pa-0" :src="item.avatar"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name + ' - ' + item.username"
                        >
                        </v-list-item-title>
                        <!-- v-list-item-subtitle 
                        v-text=""></v-list-item-subtitle -->
                        <!-- v-spacer></v-spacer -->
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-icon class="ma-0 pa-0">
                          <v-btn
                            icon
                            small
                            class="ma-0 pa-0"
                            @click="coachSettings(item)"
                          >
                            <v-icon size="24px">mdi-cog-outline</v-icon>
                          </v-btn>
                        </v-list-item-icon>
                      </v-list-item-action>
                    </template>

                    <!-- v-list-item class="ml-n16">
                    <v-list-item-content>
                      <v-card flat
                        class="ma-0 mb-0 pa-0"
                        max-width="800">
                        <v-row no-gutters>
                        <v-textarea class="ma-0 pa-0"
                          dense
                          solo
                          auto-grow
                          rows="1"
                          name="input-7-1"
                          label="new message"
                          v-model="sendfriendusermessage">
                        </v-textarea>
                          <v-btn small class="ml-3 mt-2 mb-0 pa-0"
                            icon
                            @click="sendFriendMessage()"
                            color="deep-purple accent-4">
                            <v-icon size="32px">mdi-email-send-outline</v-icon>
                          </v-btn>
                        </v-row>
                      </v-card>
                      <v-card flat class="ma-0 mt-n5 pa-0"
                        max-width="800">
                        <v-card-text>
                          <v-row dense justify="start" class="mb-3 mx-0"
                            v-for="message in userhistmsg"
                            :key="message.time"
                            >
                            <v-col v-if="message.color === 'green'" cols="12"
                              sm="10" offset-sm="2">
                              <div class="font-weight-normal text-left teal--text">
                                <div>{{ message.message }}</div>
                                <div><strong>{{ message.from }}</strong>
                                  {{ message.time.substr(0, 10) + ' ' + message.time.substr(11, 5) }}
                                </div>
                              </div>
                            </v-col>
                            <v-col v-else cols="12" sm="10">
                              <div class="font-weight-normal text-left purple--text">
                                <div>{{ message.message }}</div>
                                <div><strong>{{ message.from }}</strong>
                                  {{ message.time.substr(0, 10) + ' ' + message.time.substr(11, 5) }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item -->
                  </v-list-group>
                </v-list>
              </v-container>
            </v-col>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <!-- TAB GROUPS ------------------------------------------------------>
      <v-tab-item value="tab-groups">
        <v-card :dark="selectedTheme" flat>
          <v-row>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <!-- v-row dense>
              <v-col cols="5">
                <v-select
                :items="searchmemgrp"
                label="Search combinations"
                dense
              ></v-select>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="search"
                  label="Search"
                  single-line
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn x-small class="mb-n2"
                  color="blue"
                  dark >
                  <v-icon left>
                    mdi-magnify
                  </v-icon>
                  Search
                </v-btn>
              </v-col>
              </v-row -->
              <!-- v-toolbar dense color="amber lighten-2" flat light>
                <v-btn text color="blue-grey lighten-1" class="ma-0 pa-0">
                  GROUPS
                </v-btn>
                <v-btn
                  icon
                  @click="createGroup()"
                  class="mt-1 pa-0"
                  color="blue-grey lighten-1"
                  dark
                >
                  <v-icon size="24px">mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-autocomplete
                  v-model="autofillgroupmessage"
                  :items="grouplist"
                  item-text="name"
                  class="mt-4 pa-0"
                  flat
                  dense
                  label="filter"
                ></v-autocomplete>
              </v-toolbar -->
              <v-row dense>
                <v-col cols="2">
                <v-btn text color="primary" class="ma-0 ml-2 mt-2 pa-0">
                  GROUPS
                </v-btn>
                </v-col>
                <v-col cols="2">
                <v-btn icon @click="createGroup()" class="mt-2 ml-2 pa-0">
                  <v-icon size="24px">mdi-plus-circle-outline</v-icon>
                </v-btn>
                </v-col>
                <v-col cols="8">
                </v-col>
                <!-- v-autocomplete
                  v-model="autofillgroupmessage"
                  :items="grouplist"
                  item-text="name"
                  class="mt-4 pa-0"
                  flat
                  dense
                  label="filter"
                ></v-autocomplete -->
              </v-row>
            </v-col>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
          </v-row>
          <!-- v-row dense>
            <v-col >
              <v-btn small
                color="primary"
                text
                @click="saveCompanyGroup()"
                >Save
              </v-btn>
              <v-btn small
                color="error"
                text
                @click="groupcompanycreatedialog = false"
              >Cancel
            </v-btn>
            </v-col>
          </v-row -->
          <v-row dense>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-list dense>
                <v-list-group
                  v-for="item in grouplist"
                  @click="createGroupInlMessage(item)"
                  v-model="item.dialog"
                  :key="item.id"
                  no-action
                  active-class="light-blue lighten-4"
                >
                  <template v-slot:activator>
                    <!-- v-list-item-icon>
                  <v-icon v-if="item.icon" color="pink">mdi-star</v-icon>
                </v-list-item-icon -->

                    <v-list-item-avatar>
                      <v-img class="ma-0 pa-0" :src="item.avatar"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.name + ' -- ' + item.description"
                      ></v-list-item-title>
                      <!-- v-list-item-subtitle
                        v-text="item.title"
                      ></v-list-item-subtitle -->
                      <v-list-item-subtitle
                        class="green--text"
                        v-text="
                          item.messageAt.substr(11, 5) + ' ' + item.message
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <!-- v-list-item-action>
                      <v-list-item-icon>
                        <v-btn
                          icon
                          small
                          class="ma-0 pa-0"
                          @click="opengroupBookings(item)"
                        >
                          <v-icon size="24px">mdi-calendar</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item-action -->

                    <v-list-item-action>
                      <v-list-item-icon class="ma-0 pa-0">
                        <v-btn
                          icon
                          small
                          class="ma-0 pa-0"
                          @click="opengroupUpdate(item)"
                        >
                          <v-icon size="24px"
                            >mdi-account-multiple-outline</v-icon
                          >
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item-action>
                  </template>

                  <!-- v-list-item class="ml-n16">
                    <v-list-item-content>
                      Text
                    </v-list-item-content>
                   </v-list-item -->

                  <v-list-item class="ml-n16">
                    <v-list-item-content>
                      <v-card
                        flat
                        class="mx-auto ma-0 mb-2 pa-0"
                        max-width="800"
                      >
                        <v-row no-gutters>
                          <v-textarea
                            class="ma-0 pa-0"
                            dense
                            solo
                            auto-grow
                            rows="1"
                            name="input-7-1"
                            label="new message"
                            v-model="sendgroupusermessage"
                          >
                          </v-textarea>
                          <v-btn
                            small
                            class="ml-3 mt-2 mb-0 pa-0"
                            icon
                            @click="sendGroupMessage()"
                            color="deep-purple accent-4"
                          >
                            <v-icon size="32px">mdi-email-send-outline</v-icon>
                          </v-btn>
                        </v-row>
                      </v-card>
                      <v-card flat class="ma-0 mt-n5 pa-0" max-width="800">
                        <v-card-text>
                          <v-row
                            dense
                            justify="start"
                            class="mb-3 mx-0"
                            v-for="message in grouphistmsg"
                            :key="message.time"
                          >
                            <v-col
                              v-if="message.color === 'green'"
                              cols="12"
                              sm="10"
                              offset-sm="2"
                            >
                              <div
                                class="font-weight-normal text-left teal--text"
                              >
                                <div>{{ message.message }}</div>
                                <div>
                                  <strong>{{ message.from }}</strong>
                                  {{
                                    message.time.substr(0, 10) +
                                      " " +
                                      message.time.substr(11, 5)
                                  }}
                                </div>
                              </div>
                            </v-col>
                            <v-col v-else cols="12" sm="10">
                              <div
                                class="font-weight-normal text-left purple--text"
                              >
                                <div>{{ message.message }}</div>
                                <div>
                                  <strong>{{ message.from }}</strong>
                                  {{
                                    message.time.substr(0, 10) +
                                      " " +
                                      message.time.substr(11, 5)
                                  }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-col>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <!----- TAB MEMBERS ------------------------------------------------------------------------------------->
      <v-tab-item value="tab-members">
        <AdminMembers :userid="userid" :idcompany="idcompany" 
          :ActiveActivityID="ActiveActivityID"
          :grouplistnomsg="grouplistnomsg"
          :theme="selectedTheme">
        </AdminMembers>
      </v-tab-item>

      <!-- v-tab-item value="tab-members">
        <v-card :dark="selectedTheme" flat>
          <v-row dense>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-row dense>
                <v-col>
                  <v-autocomplete
                    v-model="selectedMemberGroup"
                    :items="grouplistnomsg"
                    item-text="name"
                    item-value="id"
                    class="ml-5 mt-4"
                    dense
                    label="Groups"
                    small-chips
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col>
                </v-col>
              </v-row>
              <v-container color="black" fluid class="ma-0 pa-0">
                  <v-card flat :dark="selectedTheme"
                    class="ma-0 pa-0"
                    v-model="selectedgroupmembers"
                  >
                    <v-list dense>
                    <v-list-item
                      v-for="item in groupmemberusers"
                      :key="item.User.id"
                      active-class="teal lighten-4"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-avatar :input-value="active">
                          <v-img
                            class="ma-0 pa-0"
                            :src="item.User.avatar"
                          ></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.User.name + ' - ' + item.User.username"
                          >
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.User.skill + ' ' + item.User.skilltext"
                          ></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-icon class="ma-0 pa-0">
                            <v-btn
                              icon
                              small
                              class="ma-0 pa-0"
                              @click="createMemberMessage(item)"
                            >
                              <v-icon size="24px"
                                >mdi-message-text-outline</v-icon
                              >
                            </v-btn>
                          </v-list-item-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                    </v-list>
                  </v-card>
              </v-container>
            </v-col>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
          </v-row>
        </v-card>
      </v-tab-item -->

      <v-tab-item value="tab-calender">
        <v-card :dark="selectedTheme">
          <v-row dense no-gutters class="fill-height">
            <v-col no-gutters>
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small color="white" v-bind="attrs" v-on="on">
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="setToday">
                        <v-list-item-title>Today</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                  <v-toolbar-title
                    style="font-size: 1.0em"
                    class="ml-0 pl-0"
                    v-if="$refs.calendar"
                  >
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="calendertype === 'ordinary'"
                    class="mr-0 pr-0"
                    icon
                    color="green"
                    @click="updateCalenderTemplates()"
                  >
                    <v-icon :size="icontabsize + 'px'"
                      >mdi-text-box-multiple-outline</v-icon
                    >
                  </v-btn>
                  <v-menu
                    bottom
                    right
                    v-if="calendertype === 'calendertemplates'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        class="mr-2 pr-2"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon :size="icontabsize + 'px'"
                          >mdi-text-box-multiple-outline</v-icon
                        >
                        <v-icon right>
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openBT()">
                        <v-list-item-title>Bookingtypes</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openBooking">
                        <v-list-item-title>Booking</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Booking Items</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    class="mr-0 pr-0"
                    icon
                    v-if="calendertype === 'calendertemplates'"
                    color="grey"
                    @click="updateCalender()"
                  >
                    <v-icon :size="icontabsize + 'px'"
                      >mdi-arrow-right-thick</v-icon
                    >
                  </v-btn>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  locale="SE"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  :event-more="false"
                  @click:more="viewDay"
                  @click:event="showEvent"
                  @click:date="viewDay"
                  @change="updateRange"
                ></v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card color="grey lighten-4" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <v-btn icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-toolbar-title
                        v-html="selectedEvent.name"
                      ></v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon>
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <span v-html="selectedEvent.details"></span>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
          <!-- v-card-actions>
            <v-btn small color="primary" flat @click.stop="show=false">Close</v-btn>
          </v-card-actions -->
        </v-card>
        <!-- /v-container -->
      </v-tab-item>
      <v-tab-item value="tab-facilities">
        <v-card :dark="selectedTheme" flat>
          <v-row>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <!-- v-toolbar dense color="grey">
              <v-btn small color="primary">
                My Facilities
              </v-btn>
            </v-toolbar -->
              <v-container fluid class="ma-0 pa-0">
                <v-row dense>
                  <v-col> </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2" xs="0" sm="0" md="2"></v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!-- HELP BUTTON ------------------------------------------------------------>
    <v-btn
      small
      bottom
      color="yellow"
      light
      fab
      fixed
      right
      @click="helpdialog = !helpdialog"
    >
      <v-icon>mdi-help</v-icon>
    </v-btn>
    <!-- DIALOG GROUP CREATE ---------------------------------------------------------->
    <v-dialog persistent v-model="groupcompanycreatedialog" :fullscreen="$vuetify.breakpoint.xs" width="600px">
      <v-card :dark="selectedTheme">
        <v-container>
          <v-row dense>
            <v-col cols="6" md="6">
              <v-text-field
                dense
                v-model="creategroupname"
                filled
                color="blue-grey lighten-2"
                label="Name"
                class="ma-0 pa-0"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <!-- v-text-field
                dense
                v-model="creategrouptitle"
                filled
                color="blue-grey lighten-2"
                label="Title"
                class="ma-0 pa-0"
              ></v-text-field -->
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                dense
                v-model="creategroupdescription"
                filled
                color="blue-grey lighten-2"
                label="Description"
                class="ma-0 mt-n3 pa-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                dense
                v-model="groupavatar"
                filled
                color="blue-grey lighten-2"
                label="Avatar image"
                class="ma-0 mt-n3 pa-0"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                dense
                v-model="selectedgroupaccess"
                :items="groupaccess"
                label="Group Type"
                class="ma-0 pa-0"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="groupmaxmembers"
                label="Max Users (0 for unlimited)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                class="ma-0 mt-1 pa-0"
                v-model="selectedmembergroupuser"
                :items="memberslist"
                item-text="name"
                item-value="id"
                dense
                chips
                small-chips
                deletable-chips
                label="Add to group"
                multiple
              ></v-autocomplete>
              <!-- v-autocomplete
                dense
                v-model="selectedmembergroupuser"
                :items="memberslist"
                chips
                deletable-chips
                small-chips
                color="blue-grey lighten-2"
                label="Add to group"
                item-text="name"
                item-value="id"
                multiple
                class="ma-0 pa-0"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.avatar"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="data.item.avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.description"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete -->
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                class="ma-0 mt-1 pa-0"
                v-model="selectedcoach"
                :items="coacheslist"
                item-text="name"
                item-value="id"
                dense
                chips
                small-chips
                deletable-chips
                label="Responsible Coach"
                multiple
              ></v-autocomplete>
              <!-- v-autocomplete
                dense
                v-model="selectedcoach"
                :items="coacheslist"
                chips
                deletable-chips
                small-chips
                color="blue-grey lighten-2"
                label="Responsible Coach"
                item-text="name"
                item-value="id"
                class="ma-0 pa-0"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.avatar"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="data.item.avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.description"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete -->
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" text @click.native="saveCompanyGroup">
            Save
          </v-btn>
          <v-btn
            small
            color="error"
            text
            @click="groupcompanycreatedialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG GROUP UPDATE ---------------------------------------------------------->
    <v-dialog persistent v-model="groupcompanyupdatedialog" :fullscreen="$vuetify.breakpoint.xs" width="600px">
      <v-card :dark="selectedTheme">
        <v-container>
          <v-row dense>
            <v-col cols="6" md="6">
              <v-text-field
                dense
                v-model="updategroupname"
                filled
                color="blue-grey lighten-2"
                label="Name internal not showed"
                class="ma-0 mt-2 pa-0"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <!-- v-text-field
                dense
                v-model="updategrouptitle"
                filled
                color="blue-grey lighten-2"
                label="Title"
                class="ma-0 mt-2 pa-0"
              ></v-text-field -->
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                dense
                v-model="updategroupdescription"
                filled
                color="blue-grey lighten-2"
                label="Description"
                class="ma-0 mt-n3 pa-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                dense
                v-model="updategroupavatar"
                filled
                color="blue-grey lighten-2"
                label="File url avatar"
                class="ma-0 mt-n3 pa-0"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                dense
                v-model="selectedupdategroupaccess"
                :items="groupaccess"
                label="Group Type"
                class="ma-0 pa-0"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                v-model="updategroupmaxmembers"
                label="Max Users (0 for unlimited)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- v-autocomplete
                v-model="selectedupdategroupusers"
                :items="groupusers"
                item-text="User.name"
                item-value="name"
                outlined
                dense
                chips
                small-chips
                deletable-chips
                label="Bookings"
                multiple
            ></v-autocomplete -->
              <v-autocomplete
                dense
                chip
                deletable-chips
                small-chips
                v-model="selectedupdategroupusers"
                :items="groupusers"
                color="blue-grey lighten-2"
                label="Group members"
                item-text="User.name"
                item-value="name"
                multiple
                class="ma-0 mt-1 pa-0"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.User.avatar"></v-img>
                    </v-avatar>
                    {{ data.item.User.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="data.item.User.avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.User.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.User.description"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <!-- v-autocomplete
                v-model="addupdategroupusers"
                :items="memberslist"
                item-text="name"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                deletable-chips
                label="Add To Group"
                multiple
            ></v-autocomplete -->
              <v-autocomplete
                dense
                chips
                deletable-chips
                small-chips
                v-model="addupdategroupusers"
                :items="memberslist"
                color="blue-grey lighten-2"
                label="Add to group"
                item-text="name"
                item-value="id"
                multiple
                class="ma-0 pa-0"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.avatar"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="data.item.avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.description"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                dense
                chips
                deletable-chips
                small-chips
                v-model="selectedcoach"
                :items="coacheslist"
                color="blue-grey lighten-2"
                label="Responsible Coach"
                item-text="name"
                item-value="id"
                class="ma-0 mt-2 pa-0"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.avatar"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="data.item.avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.description"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" text @click="updateCompanyGroup">
            Save
          </v-btn>
          <v-btn
            small
            color="error"
            text
            @click="groupcompanyupdatedialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG GROUP BOOKING  ---------------------------------------------------------->
    <v-dialog persistent v-model="groupbookingdialog" :fullscreen="$vuetify.breakpoint.xs" width="500px">
      <v-card :dark="selectedTheme">
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="selectedbookingtype"
                :items="calenderheaderlist"
                item-text="name"
                item-value="id"
                dense
                chips
                small-chips
                deletable-chips
                label="Bookings"
                multiple
              ></v-autocomplete>
              <!-- v-list >
                <v-list-item-group
                  v-model="selectedgroupheaders"
                  active-class="blue--text"
                  multiple
                >
                  <template v-for="(item) in calenderheaderlist">
                  <v-list-item dense :key="item.id">
                      <v-list-item-content>
                      <v-list-item-title v-if="item.description === null" v-text="item.name + ' ' + item.description"></v-list-item-title>
                      <v-list-item-title v-else v-text="item.name + ' ' + item.description"></v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list -->
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" text @click="updateGroupBooking">
            Save
          </v-btn>
          <v-btn small color="error" text @click="groupbookingdialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG COACH SETUP ---------------------------------------------------------->
    <v-dialog v-model="coachsetupdialog" persistent max-width="400px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-title>Coach Setup</v-card-title>
          <v-row dense>
            <v-col cols="6">
              <v-select
                dense
                v-model="selectedaccessright"
                :items="adminaccess"
                label="Access Types"
                class="ma-0 pa-0"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" text @click="coachUpdateSettings()">
            Save
          </v-btn>
          <v-btn small color="error" text @click="coachsetupdialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG BOOKING TYPES ---------------------------------------------------------->
    <v-dialog v-model="bookingtypesdialog" :fullscreen="$vuetify.breakpoint.xs" persistent max-width="500px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-title class="ml-n4">Booking Types Setup</v-card-title>
          <v-row dense>
            <v-col cols="12">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  dense
                  v-model="bookingtype.name"
                  :counter="10"
                  :rules="nameRules"
                  label="Name"
                  required
                ></v-text-field>
                <v-text-field
                  dense
                  v-model="bookingtype.description"
                  label="Description"
                  required
                ></v-text-field>
                <v-autocomplete
                  dense
                  v-model="bookingtype.color"
                  :disabled="isUpdating"
                  :items="bookingtypecolors"
                  filled
                  label=""
                  item-text="text"
                  item-value="text"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :color="data.item.text"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      <!-- v-btn small
                        :color="data.item.text"
                        class="ma-0 pa-0"
                      >
                        {{ data.item.text }}
                      </v-btn -->
                      {{ data.item.text }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <!-- v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content -->
                    </template>
                    <template v-else>
                      <v-btn small :color="data.item.text" class="ma-2 pa-2">
                        {{ data.item.text }}
                      </v-btn>
                    </template>
                  </template>
                </v-autocomplete>
                <v-btn
                  small
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="saveBT"
                >
                  Save
                </v-btn>
                <v-btn small color="error" class="mr-4" @click="cancelBT">
                  Cancel
                </v-btn>
                <v-btn small color="primary" class="mr-4" @click="newBT">
                  New
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-divider></v-divider>
          <v-simple-table dense height="200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Color
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in bookingtypes"
                  :key="item.name"
                  @click="selectBookingType(item)"
                >
                  <td>{{ item.name }}</td>
                  <td>
                    <v-btn
                      small
                      :color="item.color"
                      class="mt-0, mb-0 ml-2 mr-2"
                    >
                      {{ item.color }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
        <v-card-actions>
          <v-btn small color="grey" @click="bookingtypesdialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG BOOKINGS ------------------------max-width="400px"---------------------------------->
    <v-dialog v-model="bookingdialog" :fullscreen="$vuetify.breakpoint.xs" persistent max-width="500px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-title class="ml-n4">Booking Setup</v-card-title>
          <v-row dense>
            <v-col cols="12">
              <v-select
                dense
                v-model="selectedBookingType"
                :items="bookingtypes"
                item-text="name"
                item-value="id"
                label="Booking Type"
              ></v-select>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  dense
                  v-model="booking.name"
                  :counter="10"
                  :rules="nameRules"
                  label="Name"
                  required
                ></v-text-field>
                <v-text-field
                  dense
                  v-model="booking.description"
                  label="Description"
                  required
                ></v-text-field>
                <v-select
                  dense
                  v-model="selectedOccurent"
                  :items="occurent"
                  item-text="text"
                  item-value="value"
                  label="Occurrency"
                ></v-select>
                <v-row>
                  <v-col cols="6" dense>
                    <v-menu
                      v-model="menudatefrommodel"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="100px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="booking.periodstart"
                          label="Date From"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="booking.periodstart"
                        @input="menudatefrommodel = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" dense>
                    <v-menu
                      v-model="menudatetomodel"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="100px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="booking.periodstop"
                          label="Date To"
                          readonly
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="booking.periodstop"
                        @input="menudatetomodel = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-card-text class="mt-n8"
                      >When booking is set to active and saved all booking items
                      will be created</v-card-text
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-switch
                      class="mt-n2"
                      v-model="activatebooking"
                      label="Activate Booking"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-btn
                  small
                  :disabled="!valid"
                  color="success"
                  class="mr-4 mt-2"
                  @click="saveBooking"
                >
                  Save
                </v-btn>
                <v-btn
                  small
                  color="error"
                  class="mr-4 mt-2"
                  @click="closeBooking()"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  color="primary"
                  class="mr-4 mt-2"
                  @click="newBooking()"
                >
                  New
                </v-btn>
              </v-form>
              <!-- booking item table ----------------------------->
              <v-data-table
                :headers="bookingitemheaders"
                :items="bookingitem"
                sort-by="calories"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>My CRUD</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          New Item
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.name"
                                  label="Dessert name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.calories"
                                  label="Calories"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.fat"
                                  label="Fat (g)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.carbs"
                                  label="Carbs (g)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="editedItem.protein"
                                  label="Protein (g)"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">
                            Cancel
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="save">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >Are you sure you want to delete this
                          item?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">
                    Reset
                  </v-btn>
                </template>
              </v-data-table>
              <!-- ------- end booking item table -->
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-divider></v-divider>
          <v-simple-table dense height="200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Occurence
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in bookings"
                  :key="item.name"
                  @click="selectBooking(item)"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.occurence }}</td>
                  <td>
                    <v-btn small color="grey" @click="itemBooking(item)">
                      Item
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
        <v-card-actions>
          <v-btn small color="grey" @click="closeBooking()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG BOOKINGS ITEMS ------------------------max-width="400px"---------------------------------->
    <v-dialog v-model="bookingitemsdialog" :fullscreen="$vuetify.breakpoint.xs" persistent max-width="500px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-title class="ml-n4">Booking Item Setup</v-card-title>
          <v-row dense>
            <v-col cols="12">
              <v-select
                dense
                v-model="selectedBooking"
                :items="bookings"
                item-text="name"
                item-value="id"
                label="Booking Item"
              ></v-select>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  dense
                  v-model="bookingitem.name"
                  :counter="10"
                  :rules="nameRules"
                  label="Name"
                  required
                ></v-text-field>
                <v-text-field
                  dense
                  v-model="bookingitem.description"
                  label="Description"
                  required
                ></v-text-field>
                <v-btn
                  small
                  :disabled="!valid"
                  color="success"
                  class="mr-4 mt-2"
                  @click="saveBookingItem"
                >
                  Save
                </v-btn>
                <v-btn
                  small
                  color="error"
                  class="mr-4 mt-2"
                  @click="closeBookingItem()"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  color="primary"
                  class="mr-4 mt-2"
                  @click="newBookingItem()"
                >
                  New
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-divider></v-divider>
          <v-simple-table dense height="200px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Occurence
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in bookings"
                  :key="item.name"
                  @click="selectBookingItem(item)"
                >
                  <td>{{ item.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
        <v-card-actions>
          <v-btn small color="grey" @click="closeItemBooking()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SET COMPANY ________________________________________________________________ -->
    <v-dialog v-model="setcompanydialog" persistent max-width="200px">
      <v-card :dark="selectedTheme">
        <v-container fluid>
          <v-card-text>Set Active Company</v-card-text>
          <v-divider></v-divider>
          <v-select
            dense
            v-model="selectedActiveCompany"
            :items="accessrights"
            color="blue-grey lighten-2"
            item-text="companyname"
            item-value="idcompany"
            class="ma-0 pa-0"
          >
          </v-select>
        </v-container>
        <v-card-actions class="bt-n10 ma-0 pa-0">
          <v-btn
            small
            class="ma-0 pa-0"
            color="blue"
            text
            @click="setActiveCompany()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG MEMBER MESSAGE ----------------------------------------->
    <v-dialog
      v-model="dialogmembermessage"
      scrollable
      persistent
      max-width="600px"
    >
      <v-row>
        <v-col cols="12">
          <v-card :dark="selectedTheme" class="ma-0 mb-n10 pa-0">
            <v-container>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-btn
                      small
                      icon
                      class="mb-2 ml-2"
                      color="black"
                      @click="dialogmembermessage = false"
                    >
                      <v-icon dark :size="icontabsize + 'px'">
                        mdi-arrow-left-thick
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="8"></v-col>
                  <v-col cols="2">
                    <v-btn
                      small
                      class="mb-2 mr-2"
                      icon
                      @click="sendMemberMessage()"
                      color="deep-purple accent-4"
                    >
                      <v-icon size="32px">mdi-email-send-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-textarea
                      dense
                      solo
                      auto-grow
                      rows="1"
                      name="input-7-1"
                      label="new message"
                      v-model="sendfriendmembermessage"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-container>
          </v-card>
          <v-card :dark="selectedTheme">
            <v-card-text>
              <v-row
                dense
                justify="start"
                class="mb-3 mx-0"
                v-for="message in memberuserhistmsg"
                :key="message.time"
              >
                <v-col
                  v-if="message.color === 'green'"
                  cols="12"
                  sm="10"
                  offset-sm="2"
                >
                  <div class="font-weight-normal text-left teal--text">
                    <!-- {{ message.time.toLocaleString() }} -->
                    <div>{{ message.message }}</div>
                    <div>
                      <strong>{{ message.from }}</strong>
                      {{
                        message.time.substr(0, 10) +
                          " " +
                          message.time.substr(11, 5)
                      }}
                    </div>
                  </div>
                </v-col>
                <v-col v-else cols="12" sm="10">
                  <div class="font-weight-normal text-left purple--text">
                    <!-- {{ message.time.toLocaleString() }} -->
                    <div>{{ message.message }}</div>
                    <div>
                      <strong>{{ message.from }}</strong>
                      {{
                        message.time.substr(0, 10) +
                          " " +
                          message.time.substr(11, 5)
                      }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- SNACKBAR ------------------------------------------------------------------------------------>
    <v-snackbar v-model="snackbar" :timeout="timeout" top color="blue-grey">
      {{ notificationtext }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red darken-2"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
  <!-- /v-app -->
</template>

<script>
// import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
// import AuthenticationService from "@/services/AuthenticationService"
import SearchesService from "@/services/SearchesService";
import UserService from "@/services/UserService";
// import io from 'socket.io-client'
// import networkConnection from '@/plugins/socketconf'
import * as socketio from "@/plugins/socketio";
// import MessageService from '@/chatservices/MessagesService'
import GroupServices from "@/services/GroupServices";
// import ActivityService from '@/services/ActivityService'
import SystemService from "@/services/SystemService";
// import MyImageService from '@/services/MyImageService'
// import MailService from '@/services/MailService'
// import Calender from '@/components/Calender.vue'
import CalenderService from "@/services/CalenderService";
// import VueCookies from 'vue-cookies'
// import UserGroupService from '@/chatservices/UserGroupService'
// import LoginChatService from '@/chatservices/LoginChatService'
// const FormData = require('form-data')
import AdminMembers from '@/components/AdminMembers'

export default {
  components: {
    AdminMembers
    // Calender
    // VuetifyAudio: () => import('vuetify-audio')
  },
  name: "AdminWindow",
  props: {
    source: String
  },
  data() {
    return {
      isUpdating: false,
      bookingitemsdialog: false,
      selectedBooking: [],
      valid: "",
      timeout: 10000,
      notificationtext: "",
      selectedTheme: false,
      banheader: 0,
      banschema: [
        {
          time: "06:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "07:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "08:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "09:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "10:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "11:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "12:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "13:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "14:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "15:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "16:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "17:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "18:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "19:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "20:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "21:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "22:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        },
        {
          time: "23:00",
          surface1: "bana 1",
          surface2: "bana 2",
          surface3: "bana 3",
          surface4: "bana 4",
          surface5: "bana 5",
          surface6: "bana 6"
        }
      ],
      snackbar: false,
      opacity: 1,
      memberuserhistmsg: [],
      textmembermessage: "",
      dialogmembermessage: false,
      manymembermessage: false,
      selectedgroupmembers: [],
      selectedMemberGroup: "",
      messagefrienddialog: false,
      sendfriendmembermessage: "",
      sendfriendmemberitem: null,
      membermessagelist: [],
      updateFriendList: [],
      updateFriendHistoryList: [],
      activatebooking: false,
      datefrom: "",
      dateto: "",
      menudatefrommodel: "",
      menudatetomodel: "",
      bookingdialog: false,
      selectedOccurent: "",
      selectedBookingType: "",
      token: "",
      accessrights: [],
      selectedActiveCompany: "",
      setcompanydialog: false,
      // Members
      autofillcoachesmessage: "",
      autofillmembersmessage: [],
      allmemberslist: [],
      // Coach
      itemcoach: null,
      coachsetupdialog: false,
      username: "",
      name: "",
      userid: 0,
      password: 0,
      idcompany: 0,
      idfacility: 0,
      coachAccessRight: 0,
      coachAdminSettings: null,
      groupbookingdialog: false,
      calenderheaderlist: [],
      selectedgroupheaders: [],
      // Groups
      autofillgroupmessage: "",
      grouplistnomsg: [],
      grouplist: [],
      groupusers: [],
      groupmemberusers: [],
      grouphistmsg: [],
      groupcompanycreatedialog: false,
      selectedmembergroupuser: [],
      selectedupdategroupusers: [],
      addupdategroupusers: [],
      groupcompanyupdatedialog: false,
      groupaccess: [
        { text: "normal", value: 0 },
        { text: "invite", value: 1 }
      ],
      adminaccess: [
        { text: "none", value: 0 },
        { text: "read", value: 1 },
        { text: "group", value: 2 },
        { text: "members", value: 3 },
        { text: "calender", value: 4 },
        { text: "all", value: 5 }
      ],
      selectedgroupaccess: "",
      creategroupname: "",
      creategrouptitle: "",
      creategroupdescription: "",
      groupmaxmembers: 0,
      selectedaccessright: "",
      updategroupname: "",
      updategrouptitle: "",
      updategroupdescription: "",
      updategroupmaxmembers: 0,
      updategroupavatar: "",
      selectedupdategroupaccess: 0,
      groupavatar:
        "https://findhavefun.com/fhfclient01/src/assets/grpavatars/017-network.png",
      // members items
      search: "",
      bookingitemheaders: [],
      headers: [
        {
          text: "Name",
          align: "start",
          filterable: false,
          value: "name"
        },
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Personnumber", value: "protein" }
      ],
      // calender items
      bookingtypecolors: [
        { text: "red", value: "#F4433" },
        { text: "pink", value: "#E91E6" },
        { text: "purple", value: "#9C27" },
        { text: "deep-purple", value: "#673A" },
        { text: "indigo", value: "#3F51B" },
        { text: "blue", value: "#2196F" },
        { text: "light-blue", value: "#03A9" },
        { text: "cyan", value: "#00BC" },
        { text: "teal", value: "#00968" },
        { text: "green", value: "#4CAF" },
        { text: "light-green", value: "#8BC3" },
        { text: "lime", value: "#CDDC" },
        { text: "yellow", value: "#FFEB3" },
        { text: "amber", value: "#FFC10" },
        { text: "orange", value: "#FF980" },
        { text: "deep-orange", value: "#FF572" },
        { text: "brown", value: "#79554" },
        { text: "blue-grey", value: "#607D" },
        { text: "grey", value: "#9E9E9" }
      ],
      occurent: [
        { text: "daily", value: "daily" },
        { text: "weekly", value: "weekly" },
        { text: "monthly", value: "monthly" },
        { text: "quarterly", value: "quarterly" }
      ],
      weekly: [
        { text: "mon", value: "daily" },
        { text: "tue", value: "weekly" },
        { text: "wed", value: "monthly" },
        { text: "thu", value: "quarterly" },
        { text: "fri", value: "daily" },
        { text: "sat", value: "weekly" },
        { text: "sun", value: "monthly" }
      ],
      month: [
        { text: "jan", value: "january" },
        { text: "feb", value: "february" },
        { text: "mar", value: "mars" },
        { text: "apr", value: "april" },
        { text: "may", value: "may" },
        { text: "jun", value: "june" },
        { text: "jul", value: "july" },
        { text: "aug", value: "august" },
        { text: "sep", value: "september" },
        { text: "oct", value: "october" },
        { text: "nov", value: "november" },
        { text: "dec", value: "december " }
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length >= 5 || "Name has to be 5 or more characters",
      ],
      bookings: [],
      bookingtypes: [],
      bookingtypesdialog: false,
      bookingtype: {
        id: 0,
        idactivity: 0,
        name: "",
        description: "",
        idcompany: "",
        iduser: "",
        color: ""
      },
      booking: {
        id: 0,
        idbookingtype: 0,
        iduser: 0,
        idactivity: 0,
        idfacility: 0,
        idcompany: 0,
        name: "",
        description: "",
        occurence: "",
        periodstart: "",
        periodstop: "",
        active: 0,
        template: 0,
        idtemplate: 0,
        dialog: 0
      },
      bookingitemweeklyheaders: [
        { text: "day", value: "day" },
        { text: "start", value: "start" },
        { text: "stop", value: "stop" }
      ],
      bookingtableitem: null,
      bookingitem: {
        id: 0,
        iduser: 0,
        idbooking: 0,
        idactivity: 0,
        name: "",
        description: "",
        datefrom: "",
        timefrom: "",
        dateto: "",
        timeto: ""
      },
      bookingtypeitem: null,
      calendertype: "ordinary",
      admin_tabs: "tab-coach",
      main_tab: "",
      listsheader: "",
      focus: "",
      focustempltes: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days"
      },
      coacheslist: [],
      selectedcoach: "",
      selectcoachold: "",
      calenderlist: [],
      calendertemplatelist: [],
      memberslist: [],
      searchmemgrp: ["Members / Groups", "Groups / Members"],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1"
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party"
      ],
      sendgroupusermessage: "",
      messageitem: null,
      messagehistmsg: [],
      FriendBadge: 0
    };
  },
  watch: {
    selectedBookingType() {
      console.log(this.selectedBookingType);
      this.getBookingHeaders();
    },
    selectedMemberGroup() {
      console.log(this.selectedMemberGroup);
      if (this.selectedMemberGroup) {
        this.getSelectedGroupMembers();
      } else {
        this.groupmemberusers = [];
      }
    }
  },
  computed: {
    icontabsize() {
      if (this.$vuetify.breakpoint.name === "xs") return 24;
      if (this.$vuetify.breakpoint.name === "sm") return 24;
      else return 32;
      // case "xs": return "24px"
      // case "sm": return "24px"
      // case "md": return "32px"
      // case "lg": return "32px"
      // case "xl": return "32px"
    }
  },
  created() {
    this.ActiveActivityID = this.$route.params.activeactivity;
    this.idcompany = this.$route.params.activecompany;
    this.token = this.$route.params.token;
    this.password = this.$route.params.password;
    this.username = this.$store.state.user.username;
    this.name = this.$store.state.user.name;
    this.userid = this.$store.state.user.id;
    this.main_tab = this.$route.params.main_tab;
    this.listsheader = this.$route.params.listheader;
    this.selectedTheme = this.$route.params.selectedTheme;
  },
  async mounted() {
    // this.getActivities ()
    if (!this.$route.params.activecompany) {
      this.$router.push({
        name: "chatwindow"
      });
    }
    socketio.keepAlive({
      type: "srvping",
      callback: message => {
        socketio.sendEvent({
          type: "clipong",
          data: message
        });
      }
    });
    socketio.addEventListener({
      type: "group_message",
      callback: message => {
        this.checknoduplicatesGroups(message);
      }
    });
    socketio.addEventListener({
      type: "new_message",
      callback: message => {
        this.checknoduplicates(message);
      }
    });
    this.admin_tabs = "tab-coach";
    await this.getAdminBookings();
    await this.getCoaches();
    await this.manageGroups();
  },
  methods: {
    initVars() {
      this.ActiveActivityID = this.$route.params.activeactivity;
      this.idcompany = this.$route.params.activecompany;
      this.token = this.$route.params.token;
      this.password = this.$route.params.password;
      this.username = this.$store.state.user.username;
      this.name = this.$store.state.user.name;
      this.userid = this.$store.state.user.id;
      this.main_tab = this.$route.params.main_tab;
      this.listsheader = this.$route.params.listheader;
      this.selectedTheme = this.$route.params.selectedTheme;
    },
    async reloadGroups() {
      await this.manageGroups();
    },
    updateGroupBooking() {
      console.log("updateGroupBooking");
    },
    async coachUpdateSettings() {
      console.log("coachUpdateSettings");
      const admin = this.selectedaccessright;
      const response = await SystemService.updateAccessRights({
        iduser: this.itemcoach.id,
        idactivity: this.ActiveActivityID,
        idcompany: this.idcompany,
        access: { admin: admin }
      });
      this.coachsetupdialog = false;
      console.log(response);
    },
    async coachSettings(item) {
      try {
        console.log("coachSettings");
        this.itemcoach = item;
        const userid = item.id;
        const response = await SystemService.getAccessRights(
          userid,
          this.ActiveActivityID,
          this.idcompany
        );
        this.coachAdminSettings = response.data;
        const admin = this.coachAdminSettings[0].admin;
        console.log(admin);
        this.coachAccessRight = this.coachAdminSettings[0].admin;
        this.selectedaccessright = this.coachAccessRight;
        const rights = this.coachAccessRight;
        console.log(rights);
        this.coachsetupdialog = true;
      } catch (error) {
        console.log(error);
      }
    },
    // Groups
    async createGroup() {
      this.groupcompanycreatedialog = true;
      try {
        this.memberslist = [];
        const response = await SearchesService.getCompanyMembers(
          this.idcompany,
          this.ActiveActivityID
        );
        this.memberslist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getMembers() {
      try {
        this.memberslist = [];
        const response = await SearchesService.getCompanyMembers(
          this.idcompany,
          this.ActiveActivityID
        );
        this.memberslist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getMembersSkills() {
      try {
        this.memberslist = [];
        const response = await SearchesService.getCompanyMembersSkills(
          this.idcompany,
          this.ActiveActivityID
        );
        this.memberslist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async manageGroups() {
      try {
        this.grouplist = [];
        //const Approved = approved
        // this.friendlist = []
        const response = await GroupServices.manageGroupMessages(
          this.idcompany,
          this.ActiveActivityID
        );
        this.grouplist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async initTabMembers() {
      try {
        this.grouplistnomsg = [];
        this.memberslist = [];
        //const Approved = approved
        // this.friendlist = []
        const response = await GroupServices.manageGroups(
          this.idcompany,
          this.ActiveActivityID
        );
        this.grouplistnomsg = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    // Coaches
    async getCoaches() {
      try {
        const response = await SystemService.getCoaches(
          this.ActiveActivityID,
          this.idcompany
        );
        this.coacheslist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    mainWindow() {
      this.$router.push({
        name: "mainwindow",
        params: {
          userid: this.userid,
          username: this.username,
          password: this.password,
          main_tab: this.main_tab,
          activity: this.ActiveActivityID
          // listsheader: this.listsheader
        }
      });
    },
    async updateCalenderTemplates() {
      // const active = 1;
      /*
      const calender = await CalenderService.getCalenderTemplates(
        this.userid,
        this.idcompany,
        active
      );
      this.calenderlist = calender.data;
      */
      this.calendertype = "calendertemplates";
    },
    async updateCalender() {
      this.calendertype = "ordinary";
      const active = 1;
      const calender = await CalenderService.getCalenderBookings(
        this.userid,
        this.idcompany,
        active
      );
      this.calenderlist = calender.data;
    },
    async getAdminBookings() {
      try {
        const active = 1;
        const template = 0;
        const calender = await CalenderService.getCalenderBookings(
          this.idcompany,
          template,
          active
        );
        this.calenderlist = calender.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCalenderTemplates() {
      try {
        const active = 1;
        const calender = await CalenderService.getCalenderTemplates(
          this.userid,
          active
        );
        this.calendertemplatelist = calender.data;
      } catch (error) {
        console.log(error);
      }
    },
    openCalender() {
      this.calenderdialog = true;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];
      console.log(start);
      console.log(end);
      /*
      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)
      let allDay
      let firstTimestamp
      let first
      let secondTimestamp
      let second
      */

      /*
      for (let i = 0; i < eventCount; i++) {
        allDay = this.rnd(0, 3) === 0
        firstTimestamp = this.rnd(min.getTime(), max.getTime())
        first = new Date(firstTimestamp - (firstTimestamp % 900000))
        secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        second = new Date(first.getTime() + secondTimestamp)
      }*/
      let index = 0;
      while (this.calenderlist[index]) {
        events.push({
          name: this.calenderlist[index].Booking.name,
          start:
            this.calenderlist[index].datefrom +
            " " +
            this.calenderlist[index].timefrom,
          end:
            this.calenderlist[index].dateto +
            " " +
            this.calenderlist[index].timeto,
          color: this.calenderlist[index].Booking.BookingType.color
          // timed: 2
        });
        index = index + 1;
      }
      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async opengroupUpdate(item) {
      try {
        console.log(item);
        var coachid = 0;
        // console.log(this.selectedupdgroupusers)
        await this.getMembers();
        this.groupid = item.id;
        this.selectedupdategroupusers = [];
        this.addupdategroupusers = [];
        this.updategroupname = item.name;
        this.updategrouptitle = item.title;
        this.updategroupdescription = item.description;
        this.updategroupmaxmembers = item.maxmembers;
        this.selectedupdategroupaccess = item.access;
        this.updategroupavatar = item.avatar;
        this.groupcompanyupdatedialog = true;
        const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupUsers(item.id, activityid);
        this.groupusers = response.data;
        var index = 0;
        while (this.groupusers[index]) {
          var name = this.groupusers[index].User.name;
          this.selectedupdategroupusers.push(name);
          if (this.groupusers[index].coach === 1) {
            coachid = this.groupusers[index].userid;
          }
          index = index + 1;
        }

        index = 0;
        while (this.coacheslist[index]) {
          // this.selectedupdategroupusers.push(name)
          if (this.coacheslist[index].id === coachid) {
            this.selectedcoach = this.coacheslist[index].id;
            this.selectedcoachold = this.coacheslist[index].id;
          }
          index = index + 1;
        }

        // remove group users from memberlist
        index = 0;
        while (this.groupusers[index]) {
          var userid = this.groupusers[index].userid;
          var pos = this.memberslist
            .map(function(e) {
              return e.id;
            })
            .indexOf(userid);
          if (pos >= 0) {
            // pos = pos + 1;
            this.memberslist.splice(pos, 1);
            console.log(this.memberslist);
          }
          index = index + 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async opengroupBookings(item) {
      try {
        console.log(item);
        const active = 1;
        const template = 0;
        const response = await CalenderService.getBookingHeaders(
          this.idcompany,
          template,
          active
        );
        this.calenderheaderlist = response.data;
        this.groupbookingdialog = true;
        console.log(this.calenderheaderlist);
      } catch (error) {
        console.log(error);
      }
    },
    async xxxopengroupUpdate(item) {
      try {
        console.log(item);
        // console.log(this.selectedupdgroupusers)
        await this.getMembers();
        this.groupid = item.id;
        this.selectedupdategroupusers = [];
        this.updategroupname = item.name;
        this.updategrouptitle = item.title;
        this.updategroupdescription = item.description;
        this.updategroupmaxmembers = item.maxmembers;
        this.selectedupdategroupaccess = item.access;
        this.groupcompanyupdatedialog = true;
        const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupUsers(item.id, activityid);
        const groupusers = response.data;
        var index = 0;
        const members = this.memberslist;
        console.log(members);
        while (groupusers[index]) {
          var userid = groupusers[index].userid;
          // var pos = this.memberslist.indexOf(userid)
          var pos = this.memberslist
            .map(function(e) {
              return e.id;
            })
            .indexOf(userid);
          pos = pos + 1;
          this.selectedupdategroupusers.push(this.memberslist[pos]);
          var lst = this.selectedupdategroupusers;
          console.log(lst);
          index = index + 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveCompanyGroup() {
      try {
        // const selectedgroups = this.selectedmembergroupuser
        // console.log(selectedgroups)
        // Create group
        // const selectedActivity = this.selectedGroupActivity
        const activityid = this.ActiveActivityID;
        const result = await GroupServices.createCompanyGroup({
          userid: this.userid,
          idactivity: activityid,
          idcompany: this.idcompany,
          name: this.creategroupname,
          title: this.creategrouptitle,
          description: this.creategroupdescription,
          access: this.selectedgroupaccess,
          maxmembers: this.groupmaxmembers,
          avatar: this.groupavatar,
          type: "company"
        });
        //   Get and save checked
        if (result.data.id) {
          var groupusers = [];
          var index = 0;
          while (this.selectedmembergroupuser[index]) {
            var usergroup = { userid: 0, groupid: 0, idactivity: 0, coach: 0 };
            usergroup.groupid = result.data.id;
            var userid = this.selectedmembergroupuser[index].id;
            usergroup.userid = userid;
            usergroup.idactivity = this.ActiveActivityID;
            groupusers.push(usergroup);
            index = index + 1;
          }
          // Add creator also to usergroup
          // activityid = this.ActiveActivityID
          var selcoach = this.selectedcoach;
          var coach = 0;
          if (selcoach > 0) {
            coach = 1;
          }
          if (coach === 0) {
            selcoach = this.userid;
          }
          groupusers.push({
            userid: selcoach,
            groupid: result.data.id,
            idactivity: activityid,
            coach: coach
          });
          // var credentials = { userlist: groupusers }
          const response = await GroupServices.addUsersToGroup({
            userlist: groupusers
          });
          console.log(response);
          await this.manageGroups();
          this.addupdategroupusers = [];
          this.selectedupdategroupusers = [];
          this.groupcompanycreatedialog = false;
        } else {
          console.log("Error: Group/UserGroup Wasnt Created !");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateCompanyGroup() {
      const activityid = this.ActiveActivityID;
      var name = "";
      try {
        const usersremoved = [];
        var newgroupusers = [];
        // add old and new values
        // const selectedgroups = this.selectedupdgroupuser
        // console.log(selectedgroups)
        // Update group properties
        const result = await GroupServices.updateCompanyGroup({
          id: this.groupid,
          name: this.updategroupname,
          title: this.updategrouptitle,
          description: this.updategroupdescription,
          access: this.selectedupdategroupaccess,
          maxmembers: this.updategroupmaxmembers,
          avatar: this.updategroupavatar
        });

        console.log(result);

        // go through updated selectedupdgroupuser and find those removed
        var index = 0;
        while (this.groupusers[index]) {
          name = this.groupusers[index].User.name;
          var pos = this.selectedupdategroupusers.indexOf(name);
          if (pos < 0) {
            // user removed add to remove list
            var usergroup = { id: 0 };
            usergroup.id = this.groupusers[index].id;
            usersremoved.push(usergroup);
          }
          index = index + 1;
        }
        if (usersremoved.length > 0) {
          console.log("users to remove");
          const res = await GroupServices.deleteUsersFromGroup({
            userlist: usersremoved
          });
          console.log(res);
        }
        // add all new users
        index = 0;
        while (this.addupdategroupusers[index]) {
          var newusergroup = { userid: 0, groupid: 0, idactivity: 0, coach: 0 };
          // usergroup.groupid = result.data.id
          var id = this.addupdategroupusers[index];
          pos = this.memberslist
            .map(function(e) {
              return e.id;
            })
            .indexOf(id);
          newusergroup.userid = this.memberslist[pos].id;
          newusergroup.groupid = this.groupid;
          newusergroup.idactivity = this.ActiveActivityID;
          newgroupusers.push(newusergroup);
          index = index + 1;
        }

        var selcoach = this.selectedcoach;
        var coach = 0;
        if (selcoach > 0) {
          coach = 1;
          index = 0;
          var coachexists = 0;
          while (this.groupusers[index]) {
            if (this.groupusers[index].userid === this.selectedcoach) {
              coachexists = 1;
            }
            index = index + 1;
          }
          if (coachexists === 0) {
            newgroupusers.push({
              userid: selcoach,
              groupid: this.groupid,
              idactivity: activityid,
              coach: coach
            });
          }
        }

        var response = null;
        if (newgroupusers.length > 0) {
          try {
            response = await GroupServices.addUsersToGroup({
              userlist: newgroupusers
            });
          } catch (error) {
            console.log(error);
          }
        }

        if (this.selectedcoach !== this.selectedcoachold) {
          // selcoach = this.selectedcoachold
          const updcoachold = await GroupServices.updateUserGroupCoach({
            userid: this.selectedcoachold,
            groupid: this.groupid,
            idactivity: activityid,
            coach: 0
          });
          console.log(updcoachold);
        }

        if (coach === 1 && coachexists) {
          // Make this to be sure if coach already added it needs to be updated
          const updcoach = await GroupServices.updateUserGroupCoach({
            userid: selcoach,
            groupid: this.groupid,
            idactivity: activityid,
            coach: coach
          });
          console.log(updcoach);
        }

        this.groupcompanyupdatedialog = false;
        this.selectedcoach = 0;
        this.selectedupdategroupusers = [];
        console.log(response);
        await this.manageGroups();
      } catch (error) {
        console.log(error);
      }
    },
    async xxxupdateCompanyGroup() {
      try {
        // const selectedgroups = this.selectedmembergroupuser
        // console.log(selectedgroups)
        // Create group
        // const selectedActivity = this.selectedGroupActivity
        const activityid = this.ActiveActivityID;
        /*
        const result = await GroupServices.createCompanyGroup({
          userid: this.userid,
          idactivity: activityid,
          idcompany: this.idcompany,
          name: this.updategroupname,
          title: this.updategrouptitle,
          description: this.updategroupdescription,
          access: this.selectedupdategroupaccess,
          maxmembers: this.updategroupmaxmembers,
          avatar: this.groupavatar,
          type: "company"
        });
        */
        //   Get and save checked
        var groupusers = [];
        var index = 0;
        while (this.selectedupdategroupusers[index]) {
          var usergroup = { userid: 0, groupid: 0, idactivity: 0, coach: 0 };
          usergroup.groupid = this.groupid;
          var userid = this.selectedupdategroupusers[index].id;
          usergroup.userid = userid;
          usergroup.idactivity = this.ActiveActivityID;
          groupusers.push(usergroup);
          index = index + 1;
        }
        // Add creator also to usergroup
        // activityid = this.ActiveActivityID
        var selcoach = this.selectedcoach;
        var coach = 0;
        if (selcoach > 0) {
          coach = 1;
        }
        if (coach === 0) {
          selcoach = this.userid;
        }
        groupusers.push({
          userid: selcoach,
          groupid: this.groupid,
          idactivity: activityid,
          coach: coach
        });
        // var credentials = { userlist: groupusers }
        const response = await GroupServices.addUsersToGroup({
          userlist: groupusers
        });
        this.groupcompanyupdatedialog = false;
        this.selectedcoach = null;
        this.selectedupdategroupusers = [];
        console.log(response);
        await this.manageGroups();
      } catch (error) {
        console.log(error);
      }
    },
    async createGroupInlMessage(item) {
      // this.messagegroupdialog = true
      // const pos = this.selectedmessagerows
      try {
        this.grouphistmsg = [];
        var groupid = item.id;
        const activityid = this.ActiveActivityID;
        const groupmsg = await SearchesService.getGroupMessagesHist(
          groupid,
          activityid
        );
        const tmp = groupmsg.data;
        var index = 0;
        if (tmp.length > 0) {
          while (tmp[index]) {
            var rec = {
              id: 0,
              idgroup: 0,
              idactivity: 0,
              idsender: 0,
              idreceiver: 0,
              from: "",
              message: "",
              time: "",
              color: ""
            };
            rec.idgroup = tmp[index].idgroup;
            rec.idactivity = tmp[index].idactivity;
            rec.from = tmp[index].sendername;
            rec.message = tmp[index].message;
            rec.time = tmp[index].createdAt;
            rec.id = tmp[index].id;
            rec.idsender = tmp[index].idsender;
            rec.idreceiver = tmp[index].idreceiver;
            if (
              rec.idsender === this.userid ||
              rec.idreceiver === this.userid
            ) {
              rec.color = "deep-purple lighten-1";
            } else {
              rec.color = "green";
            }
            this.grouphistmsg.push(rec);
            index = index + 1;
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.messageitem = item;
      // this.membermessagelist[this.selectedmessagerows]
      console.log(this.messageitem);
    },
    async sendGroupMessage() {
      if (this.sendgroupusermessage === "") {
        return;
      }
      console.log(this.messageitem);
      var message = {
        idgroup: 0,
        idactivity: 0,
        idsender: 0,
        idreceiver: 0,
        sendername: "",
        receivername: "",
        message: "",
        type: "group",
        groupavatar: ""
      };
      try {
        message.idgroup = this.messageitem.id;
        message.idactivity = this.ActiveActivityID;
        message.idreceiver = this.messageitem.id;
        message.idsender = this.$store.state.user.id;
        message.sendername = this.$store.state.user.name;
        message.receivername = this.messageitem.name;
        message.groupavatar = this.messageitem.avatar;
        message.message = this.sendgroupusermessage;
        message.type = "group";
        // this.socket.emit('new_message', message )
        const response = await UserService.sendMyGroupMessage(message);
        const newmessage = response.data.message;
        // this.membermessagelist.unshift(newmessage);
        this.messagegroupdialog = false;
        this.updateGroupHistoryList(newmessage);
        this.sendgroupusermessage = "";
        this.sendGPMessage(newmessage);
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.error
      }
      // try {
      //   this.sendGPMessage(newmessage)
      // } catch (error) {
      //   console.log(error)
      // }
    },
    async updateGroupHistoryList(message) {
      // this is called from Friend list when
      // user expends the list and send message
      try {
        // if (this.grouphistmsg.length > 0) {
        // check if userlist needs update idsender idreceiver
        // check if right user list
        /* if ((this.grouphistmsg[0].idsender === message.idsender) &&
              (this.grouphistmsg[0].idreceiver === message.idreceiver) || 
              (this.grouphistmsg[0].idreceiver === message.idsender) &&
              (this.grouphistmsg[0].idsender === message.idreceiver)) {*/

        var rec = {
          id: 0,
          idactivity: 0,
          idsender: 0,
          idreceiver: 0,
          from: "",
          message: "",
          time: "",
          color: ""
        };
        if (message.idsender === this.userid) {
          rec.color = "deep-purple lighten-1";
        } else {
          rec.color = "green";
        }
        rec.from = message.sendername;
        rec.message = message.message;
        rec.time = message.createdAt;
        rec.id = message.id;
        rec.idactivity = message.idactivity;
        rec.idsender = message.idsender;
        rec.idreceiver = message.idreceiver;
        this.grouphistmsg.unshift(rec);
      } catch (error) {
        console.log(error);
      }
    },
    sendGPMessage(message) {
      socketio.sendEvent({
        type: "group_message",
        data: message
      });
    },
    companySettingsWindow() {
      console.log("companySettingsWindow()");
    },
    async saveXCompanyGroup() {
      try {
        console.log("saveXComp  any");
      } catch (error) {
        console.log(error);
      }
    },
    checknoduplicates(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      try {
        var pos = this.membermessagelist
          .map(function(e) {
            return e.id;
          })
          .indexOf(message.id);
        if (pos < 0) {
          this.membermessagelist.unshift(message);
          if (this.userid === message.idreceiver) {
            if (this.membermessagelist[pos].User.msgbadge === 0) {
              this.membermessagelist[pos].User.msgbadge++;
            }
            this.friendmsgbadge++;
            this.FriendBadge++;
            // this.play();
            // this.playNative();
            this.notificationtext = "New message from " + message.sendername;
            this.snackbar = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    checknoduplicatesGroups(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      try {
        var index = this.grouplist
          .map(function(e) {
            return e.groupid;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          this.grouplist[index].message = message.message;
          this.grouplist[index].messageAt = message.createdAt;
          if (this.userid !== message.idsender) {
            this.grouplist[index].Group.msgbadge = 1;
            // this.notScheduleBasics(message);
            // this.friendmsgbadge++
            // this.play();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openBT() {
      try {
        const bttypes = await CalenderService.getBookingTypes(
          this.userid,
          this.idcompany
        );
        this.bookingtypes = bttypes.data;
        this.bookingtypesdialog = true;
      } catch (error) {
        console.log(error);
      }
    },
    async openBooking() {
      try {
        const bttypes = await CalenderService.getBookingTypes(
          this.userid,
          this.idcompany
        );
        this.bookingtypes = bttypes.data;

        this.bookingdialog = true;
        const idcompany = this.idcompany;
        const template = 1;
        const active = 0;
        const bookings = await CalenderService.getBookingHeaders(
          idcompany,
          template,
          active
        );
        this.bookings = bookings.data;

      } catch (error) {
        console.log(error);
      }
    },
    async getBookingHeaders() {
      const idbookingtype = this.selectedBookingType;
      const idcompany = this.idcompany;
      const template = true;
      const active = false;
      const bookings = await CalenderService.getBookingHeaders(
        idbookingtype,
        idcompany,
        template,
        active
      );
      this.bookings = bookings.data;
    },
    newBT() {
      // this.bookingtypeitem = null
      this.bookingtype.id = 0;
      this.bookingtype.idactivity = 0;
      this.bookingtype.idcompany = 0;
      this.bookingtype.iduser = 0;
      this.bookingtype.name = "";
      this.bookingtype.color = "";
      this.bookingtype.description = "";
    },
    async saveBT() {
      try {
        var response = null;
        if (this.idcompany === 0) {
          console.log("No companyid cant create bookingtype");
          return;
        }
        var post = this.bookingtype;
        console.log(post);
        if (this.bookingtype.id === 0) {
          this.bookingtype.idcompany = this.idcompany;
          this.bookingtype.iduser = this.userid;
          this.bookingtype.idactivity = this.ActiveActivityID;
          response = await CalenderService.createBookingType(this.bookingtype);
        } else {
          response = await CalenderService.updateBookingType(this.bookingtype);
        }
        const bttypes = await CalenderService.getBookingTypes(
          this.userid,
          this.idcompany
        );
        this.bookingtypes = bttypes.data;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    clearBooking() {
      this.selectedBookingType = "";
      this.selectedOccurent = "";
      this.booking.id = 0;
      this.booking.idbookingtype = 0;
      this.booking.iduser = 0;
      this.booking.idactivity = 0;
      this.booking.idfacility = 0;
      this.booking.idcompany = 0;
      this.booking.name = "";
      this.booking.description = "";
      this.booking.occurence = "";
      this.booking.periodstart = "";
      this.booking.periodstop = "";
      this.booking.active = 0;
      this.booking.template = 0;
      this.booking.idtemplate = 0;
      this.booking.dialog = 0;
      this.activatebooking = false;
    },
    newBooking() {
      this.clearBooking();
    },
    async saveBooking() {
      /*
        id: 0,
        idbookingtype: 0,
        iduser: 0,
        idactivity: 0,
        idfacility: 0,
        idcompany: 0,
        name: "",
        description: "",
        occurence: "",
        periodstart: "",
        periodstop: "",
        active: 0,
        template: 0,
        idtemplate: 0,
        dialog: 0
      */
      try {
        if (this.idcompany === 0) {
          console.log("No companyid cant create booking");
          return;
        }
        var response = null;
        var post = this.booking;
        console.log(post);
        if (this.booking.id === 0) {
          this.booking.idbookingtype = this.selectedBookingType;
          this.booking.idcompany = this.idcompany;
          this.booking.iduser = this.userid;
          this.booking.idactivity = this.ActiveActivityID;
          this.booking.occurence = this.selectedOccurent;
          if (this.activatebooking === true) {
            this.booking.active = 1;
          } else {
            this.booking.active = 0;
          }
          this.booking.template = 1;
          response = await CalenderService.createBooking(this.booking);
        } else {
          response = await CalenderService.updateBookingType(this.booking);
        }
        console.log(response);
        const booking = await CalenderService.getBookingHeaders(
          this.selectedBookingType,
          this.idcompany,
          this.booking.template,
          this.booking.active
        );
        this.bookings = booking.data;
        this.clearBooking();
        console.log(this.bookings);
      } catch (error) {
        console.log(error);
      }
    },
    cancelBT() {
      try {
        this.bookingtypesdialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    cancelBooking() {
      try {
        this.clearBooking();
        // this.bookingdialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    closeBooking() {
      this.clearBooking();
      this.bookingdialog = false;
    },

    selectBookingType(item) {
      try {
        this.bookingtypeitem = item;
        this.bookingtype.id = item.id;
        this.bookingtype.idactivity = item.idactivity;
        this.bookingtype.idcompany = item.idcompany;
        this.bookingtype.iduser = item.iduser;
        this.bookingtype.name = item.name;
        this.bookingtype.color = item.color;
        this.bookingtype.description = item.description;

        // bookingtype: {id: 0, idactivity: 0, name: "", description: "", idcompany: "", iduser: "", color: ""},
      } catch (error) {
        console.log(error);
      }
    },
    itemBooking(item) {
      console.log(item);
      this.bookingdialog = false;
    },
    selectBooking(item) {
      try {
        this.bookingtableitem = item;
        this.booking.id = item.id;
        this.booking.idactivity = item.idactivity;
        this.booking.idcompany = item.idcompany;
        this.booking.iduser = item.iduser;
        this.booking.name = item.name;
        this.booking.description = item.description;
        this.booking.occurence = item.occurence;
        this.selectedOccurent = item.occurence;
        this.booking.periodstart = item.periodstart;
        this.booking.periodstop = item.periodstop;
        this.booking.active = item.active;
        this.booking.template = item.template;
        this.booking.idtemplate = item.idtemplate;
        // bookingtype: {id: 0, idactivity: 0, name: "", description: "", idcompany: "", iduser: "", color: ""},
      } catch (error) {
        console.log(error);
      }
    },
    async getAccessRights(showSelectCompany) {
      try {
        const idcompany = 0;
        const response = await SystemService.getAccessRights(
          this.userid,
          this.ActiveActivityID,
          idcompany
        );
        this.accessrights = response.data;
        if (this.accessrights.length > 0) {
          if (showSelectCompany === true) {
            this.checkDefaultCompany();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    setActiveCompany() {
      var activeCompany = this.selectedActiveCompany;
      // this.$store.dispatch("activeCompany", activeCompany);
      this.idcompany = activeCompany;
      this.setcompanydialog = false;
    },
    checkDefaultCompany() {
      try {
        // check if more then one company
        // var accesslist = this.accessrights
        if (this.accessrights.length > 1) {
          this.setcompanydialog = true;
        } else {
          // Set default company
        }
      } catch (error) {
        console.log(error);
      }
    },
    getSelectedGroupId() {
      console.log("getSelectedGroupId");
      try {
        /*
        const name = this.selectedMemberGroup;
        var pos = this.grouplistnomsg
          .map(function(e) {
            return e.name;
          })
          .indexOf(name);
        return this.grouplistnomsg[pos].id;
        */
        return this.selectedMemberGroup;
      } catch (error) {
        console.log(error);
      }
    },
    async getSelectedGroupMembers() {
      console.log("getSelectedGroupMembers");
      try {
        // var tmp = this.grouplistnomsg;
        // console.log(tmp)
        /*
        const activityid = this.ActiveActivityID;
        const name = this.selectedMemberGroup;
        var pos = this.grouplistnomsg
          .map(function(e) {
            return e.name;
          })
          .indexOf(name);
        const id = this.grouplistnomsg[pos].id;
        */
        const id = this.selectedMemberGroup;
        const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupUserSkills(id, activityid);
        this.groupmemberusers = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async createMemberMessage(item) {
      try {
        console.log(item);
        if (this.selectedgroupmembers.length === 1) {
          await this.createFriendInlMessage(item);
          this.dialogmembermessage = true;
          console.log("this.selectedgroupmembers.length === 1");
        }
        if (this.selectedgroupmembers.length > 1) {
          console.log("this.selectedgroupmembers.length > 1");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createFriendInlMessage(item) {
      var friendid = 0;
      try {
        if (this.FriendBadge > 0) {
          this.FriendBadge--;
        }
        this.sendfriendmemberitem = item;
        item.User.msgbadge = 0;
        this.memberuserhistmsg = [];
        friendid = item.User.id;
        const activityid = this.ActiveActivityID;
        const mainid = this.idcompany;
        const usermsg = await SearchesService.getActivityMessagesHist(
          this.userid,
          friendid,
          activityid,
          mainid,
          true
        );
        const tmp = usermsg.data;
        var index = 0;
        if (tmp.length > 0) {
          while (tmp[index]) {
            var rec = {
              id: 0,
              idgroup: 0,
              idmain: 0,
              idactivity: 0,
              idsender: 0,
              idreceiver: 0,
              from: "",
              message: "",
              time: "",
              color: ""
            };
            if (tmp[index].idsender === this.userid) {
              rec.color = "blue";
            } else {
              rec.color = "green";
            }
            rec.idgroup = tmp[index].idgroup;
            rec.idmain = tmp[index].idmain;
            rec.from = tmp[index].sendername;
            rec.message = tmp[index].message;
            rec.time = tmp[index].createdAt;
            rec.id = tmp[index].id;
            rec.idsender = tmp[index].idsender;
            rec.idreceiver = tmp[index].idreceiver;
            rec.idactivity = tmp[index].idactivity;
            this.memberuserhistmsg.push(rec);
            index = index + 1;
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.messageitem = item;
      // this.membermessagelist[this.selectedmessagerows]
      console.log(this.messageitem);
    },
    async sendMemberMessage() {
      if (this.sendfriendmembermessage === "") {
        return;
      }
      console.log(this.sendfriendmemberitem);
      var message = {
        idgroup: 0,
        idcoach: 0,
        idmain: 0,
        idactivity: 0,
        idsender: 0,
        idreceiver: 0,
        sendername: "",
        receivername: "",
        message: "",
        type: "single"
      };
      // var response = null
      var newmessage = null;
      try {
        message.idgroup = this.getSelectedGroupId();
        message.idmain = message.idgroup;
        message.idreceiver = this.sendfriendmemberitem.User.id;
        message.idactivity = this.ActiveActivityID;
        message.idsender = this.userid;
        message.idcoach = this.userid;
        message.sendername = this.name;
        message.receivername = this.sendfriendmemberitem.User.name;
        message.type = "single";
        message.message = this.sendfriendmembermessage;
        // this.socket.emit('new_message', message )
        // this.sendIOMessage(message)
        const response = await UserService.sendMyMessage(message);
        newmessage = response.data.message;
        this.messagefrienddialog = false;
        this.sendfriendmembermessage = "";
        // this.membermessagelist.unshift(newmessage);
        // this.memberuserhistmsg.unshift(newmessage);
        // this.updateMemberList(newmessage);
        this.updateMemberHistoryList(newmessage);
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.error
      }
      try {
        this.sendIOMessage(newmessage);
      } catch (error) {
        console.log(error);
      }
    },
    sendIOMessage(message) {
      socketio.sendEvent({
        type: "new_message",
        data: message
      });
    },
    updateMemberHistoryList(message) {
      // this is called from Friend list when
      // user expends the list and send message
      try {
        // check if userlist needs update idsender idreceiver
        // check if right user list
        var rec = {
          id: 0,
          idactivity: 0,
          idsender: 0,
          idreceiver: 0,
          from: "",
          message: "",
          time: "",
          color: ""
        };
        if (message.idsender === this.userid) {
          rec.color = "deep-purple lighten-1";
        } else {
          rec.color = "green";
        }
        rec.from = message.sendername;
        rec.message = message.message;
        rec.time = message.createdAt;
        rec.id = message.id;
        rec.idsender = message.idsender;
        rec.idreceiver = message.idreceiver;
        rec.idactivity = message.idactivity;
        this.memberuserhistmsg.unshift(rec);
      } catch (error) {
        console.log(error);
      }
    },
    updateMemberList(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      try {
        // Add to friendlist message field also
        var index = this.friendlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          this.friendlist[index].message = message.message;
          this.friendlist[index].messageAt = message.createdAt;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style>
.scroll {
  height: 500px;
  overflow-y: scroll;
}
v-text-h3 input {
  font-size: 1.5em;
}
</style>
