import io from "socket.io-client";
// const io = require('socket.io-client')

let socket = undefined;
const localIP = "findhavefun.com";
// const localIP = "localhost";
// const networkIP = "http://192.168.0.8";
const networkIP = "https://207.180.251.109";
// const networkIP = "http://0.0.0.0";
// http://192.168.43.204
const port = 1341;
const networkConnection = false;

// `http://${networkIP}:${port}` :
function initialiseSocket() {
  const url = networkConnection
    ? `https://${networkIP}:${port}`
    : `https://${localIP}:${port}`;
  socket = io(url, {
    withCredentials: false,
    extraHeaders: {
      "my-custom-header": "Access-Control-Allow-Origin"
    },
    reconnect: true
  });
}

const url2 = networkConnection
    ? `http://${networkIP}:${port}`
    : `http://${localIP}:${port}`;

    export const iosocket = io(url2);

/*
var socket = io.connect('http://127.0.0.1:8080/', {
  reconnect: true
});
*/

export function keepAlive(event) {
  if (!socket) {
    initialiseSocket();
  }
  socket.on(event.type, event.callback);
}

export function addEventListener(event) {
  if (!socket) {
    initialiseSocket();
  }
  socket.on(event.type, event.callback);
}

export function initClient(event) {
  if (!socket) {
    initialiseSocket();
  }
  socket.on(event.type, event.callback);
}

export function socketOff() {
  socket.off("new_message");
  socket.off("group_message");
}

export function sendEvent(event) {
  socket.emit(event.type, event.data);
}

export function getSocket() {
  if (!socket) {
    initialiseSocket();
  }
  return socket;
}
