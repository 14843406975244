<template>
  <div>
    <!-- color="lime lighten-1" -->
    <v-toolbar :dark="theme"
      flat
      dense
    >
      <v-btn text color="primary" class="ma-3 pa-0">
        My Friends
      </v-btn>
      <!-- v-spacer></v-spacer -->
      <div class="black--text">
      <v-text-field class="ml-3 mt-4"
        ref="searchNameField"
        autocomplete="new-password"
        v-model="searchNameField"
        append-icon="mdi-magnify">
      </v-text-field>
      </div>
    </v-toolbar>
    <v-container dense>
      <FriendChat
        v-for="item in filteredList"
        :key="item.messageAt"
        :item = "item"
        :activityid = "activeactivity"
        :userid = "userid"
        :theme = "theme"
        :platform = "platform"
        @new-friend-msg="newMessage"
        @minus-friend-badge="countDownFriendBadge"
      ></FriendChat>
    </v-container>
  </div>
</template> 

<script>
import SearchesService from "@/services/SearchesService";
import FriendChat from "@/components/FriendChat.vue";
import * as socketio from "@/plugins/socketio";

export default {
  name: "friends",
  components: {
    FriendChat
  },
  emits: ["update-friend-badge"],
  props: ['userid', 'activeactivity', 'theme', 'platform', 'listsheader'],
  data() {
    return {
      searchNameField: "",
      friendlist: [],
      menuMessagesF: false,
      notregtxt: "",
      snackbarwarningmsg: false,
      showhistory: false,
      FriendBadge: 0
    }
  },
  watch: {
    async activeactivity() {
      await this.getFriends();
    }
  },
  computed: {
    filteredList() {
        return this.friendlist.filter(post => {
            return post.User.name.toLowerCase().includes(this.searchNameField.toLowerCase())
        })
    },
    ActiveActivity() {
      return this.$store.state.ActiveActivity
      // await this.getFriends();
    }
  },
  created() {

  },
  async mounted() {
    console.log(this.theme);
    if (this.friendlist.length === 0) {
      //const active = 1;
      await this.getFriends();
    }
    // this.initSocket();

    /*
    this.$socket.$subscribe('new_message', payload => {
      console.log(payload)
      this.checknoduplicates(payload);
    });
    */

    socketio.addEventListener({
      type: "new_message",
      callback: (message) => {
        this.checknoduplicates(message);
      },
    });
  },
  methods: {
    countDownFriendBadge() {
      this.FriendBadge--;
      this.updateFriendBadge();
      console.log('countDownFriendBadge');
    },
    updateFriendBadge() {
      this.$emit("update-friend-badge", this.FriendBadge);
    },
    checknoduplicates(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      // var messageBadge = 0;
      try {
        var index = this.friendlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          if (this.friendlist[index].Msg.id !== message.id ) {
            this.friendlist[index].message = message.message;
            this.friendlist[index].messageAt = message.createdAt;
            if (this.userid === message.idreceiver) {
              // if (this.friendlist[index].User.msgbadge === 0) {
              // }
              this.FriendBadge++;
              this.friendlist[index].Msg.msgbadge = 1;
              this.updateFriendBadge();
              // this.play();
              // this.playNative();
            }
          }
          // this.sound.play()
        }
      } catch (error) {
        console.log(error);
      }
    },
    newMessage(item) {
      console.log(item);
      // item.msgbadge++;
      // this.FriendBadge++;
      // this.updateFriendBadge();
    },
    async getFriends() {
      try {
        // const Approved = approved
        this.FriendBadge = 0;
        // console.log(approved);
        var menu = this.menuMessagesF;
        console.log(menu);
        // this.menuMessagesF = false
        const appr = 1;
        this.friendlist = [];
        const activityid = this.activeactivity;
        // const response = await SearchesService.getActivityFriends(this.userid, appr)
        const response = await SearchesService.getFriendsAndLatestMessage(
          this.userid,
          activityid,
          appr
        );
        // const friendlist = response.data
        /*
        var index = 0
        while (list[index]) {
          var post = {name: "", gender: "", skill: ""}
          post = list[index]
          post.name = list[index].User.name
          post.gender = list[index].User.gender
          // post.skill = list[index].User.skill
          this.friendlist.push(post)
          index++
        }
        */
        this.FriendBadge = 0;
        this.friendlist = response.data;
        var index = 0;
        while (this.friendlist[index]) {
          if (this.friendlist[index].Msg.receiverread === 0 &&
            this.friendlist[index].Msg.id > 0) 
          {
            this.FriendBadge++;
            this.friendlist[index].Msg.msgbadge = 1;
          }
          index++;
        }
        // let len = this.friendlist.length;
        // console.log(len);
        // console.log(this.friendlist);
        this.updateFriendBadge();
      } catch (error) {
        console.log(error);
      }
      // console.log(this.friendlist);
      // if (this.myfriendslistsheader !== "FRIENDS") {
      //  this.myfriendslistsheader = "FRIENDS";
        // this.setTBSelectedColor()
    },
    updateFriendList(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      try {
        // Add to friendlist message field also
        var index = this.friendlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          this.friendlist[index].message = message.message;
          this.friendlist[index].messageAt = message.createdAt;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style lang="sass" scoped>

</style>