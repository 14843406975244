<style scoped lang="scss">
  .ar {
    width: 340px;
    font-family: 'Roboto', sans-serif;
    border-radius: 30px;
    border-color: #AEAEAE;
    background-color: #b2c9a2;
    color: #201e1e;
    box-shadow: 0 4px 18px 0 rgba(0,0,0,0.17);
    // #FAFAFA
    position: left;
    box-sizing: content-box;

    &-content {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: left;
    } 

    &-records {
      height: 8px;
      padding-top: 1px;
      overflow-y: auto;
      margin-bottom: 0px;

      &__record {
        width: 280px;
        height: 45px;
        padding: 0 10px;
        margin: 0 auto;
        line-height: 45px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #000000;
        position: relative;

        &--selected {
          border: 1px solid #141313;
          border-radius: 24px;
          background-color: #1e9b56b3;
          margin-top: 5px;
          padding: 0 34px;
        }
      }
    }

    &-recorder {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      &__duration {
        color: #AEAEAE;
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px;
      }

      &__stop {
        position: relative;
        margin-top: -4px;
      }

      &__time-limit {
        position: relative;
        color:#AEAEAE;
        font-size: 12px;
        top: -25px;
      }

      &__records-limit {
        position: relative;
        color: #AEAEAE;
        font-size: 12px;
        top: 25px
      }
    }

    &-spinner {
      display: flex;
      height: 30px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 144px;
      z-index: 10;

      &__dot {
        display: block;
        margin: 0 8px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: #05CBCD;
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;

        &:nth-child(2) { animation-delay: .2s; }

        &:nth-child(3) { animation-delay: .4s; }

        @keyframes blink {
          0%    { opacity: .2; }
          20%   { opacity: 1;  }
          100%  { opacity: .2; }
        }
      }
    }

    &__text {
      color: rgba(7, 7, 7, 0.5);
      font-size: 12px;
    }

    &__blur {
      filter: blur(2px);
      opacity: 0.7;
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    &__upload-status {
      text-align: center;
      font-size: 10px;
      padding: 2px;
      letter-spacing: 1px;
      position: absolute;
      bottom: 0;

      &--success {
        color: green;
      }

      &--fail {
        color: red;
      }
    }

    &__rm {
      cursor: pointer;
      position: absolute;
      width: 6px;
      height: 6px;
      padding: 6px;
      line-height: 6px;
      margin: auto;
      left: 10px;
      bottom: 0;
      top: 0;
      color: rgb(244, 120, 90);
    }

    &__downloader,
    &__uploader {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &__downloader {
      right: 115px;
    }

    &__uploader {
      right: 85px;
    }
  }

  .ardark {
    width: 340px;
    font-family: 'Roboto', sans-serif;
    border-radius: 30px;
    background-color: #070707;
    color: #c7bcbc;
    box-shadow: 0 4px 18px 0 rgba(0,0,0,0.17); 
    // #FAFAFA
    position: left;
    box-sizing: content-box;

    &-content {
      padding: 14px;
      display: flex;
      flex-direction: column;
      align-items: left;
    } 

    &-records {
      height: 8px;
      padding-top: 1px;
      overflow-y: auto;
      margin-bottom: 0px;

      &__record {
        width: 280px;
        height: 45px;
        padding: 0 10px;
        margin: 0 auto;
        line-height: 45px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #000000;
        position: relative;

        &--selected {
          border: 1px solid #141313;
          border-radius: 24px;
          background-color: #1e9b56b3;
          margin-top: 5px;
          padding: 0 34px;
        }
      }
    }

    &-recorder {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: left;

      &__duration {
        color: #AEAEAE;
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      &__stop {
        position: relative;
        top: 1px;
      }

      &__time-limit {
        position: relative;
        color:#AEAEAE;
        font-size: 12px;
        top: -25px;
      }

      &__records-limit {
        position: relative;
        color: #AEAEAE;
        font-size: 12px;
        top: 25px
      }
    }

    &-spinner {
      display: flex;
      height: 30px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 144px;
      z-index: 10;

      &__dot {
        display: block;
        margin: 0 8px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: #05CBCD;
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;

        &:nth-child(2) { animation-delay: .2s; }

        &:nth-child(3) { animation-delay: .4s; }

        @keyframes blink {
          0%    { opacity: .2; }
          20%   { opacity: 1;  }
          100%  { opacity: .2; }
        }
      }
    }

    &__text {
      color: rgba(248, 244, 244, 0.5);
      font-size: 12px;
    }

    &__blur {
      filter: blur(2px);
      opacity: 0.7;
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    &__upload-status {
      text-align: center;
      font-size: 10px;
      padding: 2px;
      letter-spacing: 1px;
      position: absolute;
      bottom: 0;

      &--success {
        color: green;
      }

      &--fail {
        color: red;
      }
    }

    &__rm {
      cursor: pointer;
      position: absolute;
      width: 6px;
      height: 6px;
      padding: 6px;
      line-height: 6px;
      margin: auto;
      left: 10px;
      bottom: 0;
      top: 0;
      color: rgb(244, 120, 90);
    }

    &__downloader,
    &__uploader {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &__downloader {
      right: 115px;
    }

    &__uploader {
      right: 85px;
    }
  }

  @import '../scss/icons';
</style>

<template>
  <v-card :dark="theme" width="300px" class="ml-0 mt-n3">
     <v-card-text>
          <v-row dense>
            <v-col cols="2">
              <v-btn class="mr-8" @click.native="toggleRecorder" icon small>
                <v-icon :color="runcolor" size="24px">mdi-microphone</v-icon>
              </v-btn>
            </v-col>
            <!-- v-col>
              <v-btn @click="voiceStopRecord" icon small>
                <v-icon size="24px">mdi-pause</v-icon>
              </v-btn>
            </v-col -->
            <v-col cols="2">
              <v-btn @click.native="stopRecorder" icon small>
                <v-icon :color="stopcolor" size="24px">mdi-stop</v-icon>
              </v-btn>
            </v-col>
            <div class="mt-2 mr-6">
              Sec {{ recorder.duration }}
            </div>
            <div></div>
            <div class="mt-2">{{ voiceLen }}</div>
          </v-row>
          <v-row v-if="show === true" dense class="mb-0">
            <v-col>
              <audio v-if="soundMsg.url" controls><source :src="soundMsg.url"></audio>
            </v-col>
          </v-row>
        </v-card-text>
   
          <!-- div class="ar__overlay" v-if="isUploading"></div>
          <div class="ar-spinner" v-if="isUploading">
            <div class="ar-spinner__dot"></div>
            <div class="ar-spinner__dot"></div>
            <div class="ar-spinner__dot"></div>
          </div -->

              <!-- div class="{ar-recorder}">
                <table>
                  <tr>
                    <td>
                  <icon-button
                    class="ar-icon ar-icon__sm"
                    :name="iconButtonType"
                    :class="{
                      'ar-icon--rec': isRecording,
                      'ar-icon--pulse': isRecording && volume > 0.02
                    }"
                    @click.native="toggleRecorder"/>
                    </td>
                    <td> &nbsp; {{recordedTime}} &nbsp;
                    </td>
                    <td>
                      <icon-button
                        class="ar-icon ar-icon__sm ar-recorder__stop"
                        name="stop"
                        @click.native="stopRecorder"/>
                    </td>
                    <td>
                      &nbsp; &nbsp; Max length: {{timesec}}sec
                    </td>
                  </tr>
                </table>
              </div -->
  </v-card>
</template>

<script>
  // import AudioPlayer from './Player'
  // import Downloader  from './downloader'
  // import IconButton  from './icon-button'
  import Recorder    from '@/lib/recorder'
  // import Uploader    from './uploader'
  import UploaderPropsMixin from '@/mixins/uploader-props'
  import { convertTimeMMSS }  from '@/lib/utils'

  export default {
    mixins: [UploaderPropsMixin],
    props: {
      show : { type: Boolean },
      theme : { type: Boolean },
      attempts : { type: Number },
      time     : { type: Number },

      bitRate    : { type: Number, default: 128   },
      sampleRate : { type: Number, default: 44100 },

      showDownloadButton : { type: Boolean, default: true },
      showUploadButton   : { type: Boolean, default: true },

      micFailed        : { type: Function },
      beforeRecording  : { type: Function },
      pauseRecording   : { type: Function },
      afterRecording   : { type: Function },
      failedUpload     : { type: Function },
      beforeUpload     : { type: Function },
      successfulUpload : { type: Function },
      selectRecord     : { type: Function }
    },
    data () {
      return {
        runcolor: "",
        stopcolor: "",
        themeClass: 'ar',
        textThemeDark : 'white',
        textThemeLight : 'black',
        textColor : '',
        themeDark : true,
        space: '&nbsp;',
        spacestr: ' ',
        voiceLen: "Max 60 sec",
        timesec: 0,
        isUploading   : false,
        recorder      : this._initRecorder(),
        recordList    : [],
        selected      : {},
        uploadStatus  : null,
      }
    },
    components: {
      // AudioPlayer,
      // Downloader,
      // IconButton,
      // Uploader
    },
    created() {
      // this.theme = false
      if (this.theme === false) {
        this.themeClass = 'ar'
      } else {
        this.themeClass = 'ardark'
      }
    },
    mounted () {
      /*
      this.$eventBus.$on('start-upload', () => {
        this.isUploading = true
        this.beforeUpload && this.beforeUpload('before upload')
      })

      this.$eventBus.$on('end-upload', (msg) => {
        this.isUploading = false

        if (msg.status === 'success') {
          this.successfulUpload && this.successfulUpload(msg.response)
        } else {
          this.failedUpload && this.failedUpload(msg.response)
        }
      })
      */
      this.timesec = this.time * 60
    },
    beforeDestroy () {
      this.stopRecorder()
    },
    methods: {
      toggleRecorder () {
        if (this.attempts && this.recorder.records.length >= this.attempts) {
          return
        }

        if (!this.isRecording) {
          if (this.recordList.length > 0) {
            this.removeRecord (0)
          }
        }
        if (!this.isRecording || (this.isRecording && this.isPause)) {
          this.recorder.start()
          this.runcolor = "green"
        } else {
          this.runcolor = "orange"
          this.recorder.pause()
        }
      },
      stopRecorder () {
        if (!this.isRecording) {
          return
        }

        this.recorder.stop()
        this.runcolor = ""
        this.stopcolor = ""
        this.recordList = this.recorder.recordList()
      },
      removeRecord (idx) {
        this.recordList.splice(idx, 1)
        this.$set(this.selected, 'url', null)
        this.$eventBus.$emit('remove-record')
      },
      choiceRecord (record) {
        if (this.selected === record) {
          return
        }
        this.selected = record
        this.selectRecord && this.selectRecord(record)
      },
      _initRecorder () {
        return new Recorder({
          beforeRecording : this.beforeRecording,
          afterRecording  : this.afterRecording,
          pauseRecording  : this.pauseRecording,
          micFailed       : this.micFailed,
          bitRate         : this.bitRate,
          sampleRate      : this.sampleRate,
          format          : this.format
        })
      }
    },
    computed: {
      attemptsLeft () {
        return this.attempts - this.recordList.length
      },
      iconButtonType () {
        return this.isRecording && this.isPause ? 'mic' : this.isRecording ? 'pause' : 'mic'
      },
      isPause () {
        return this.recorder.isPause
      },
      isRecording () {
        return this.recorder.isRecording
      },
      recordedTime () {
        if (this.time && this.recorder.duration >= 60.00) {
          this.stopRecorder()
        }
        return convertTimeMMSS(this.recorder.duration)
      },
      volume () {
        return parseFloat(this.recorder.volume)
      }
    }
  }
</script>