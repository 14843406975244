import Api from "@/services/Api";

export default {
  getSysteminfo() {
    return Api().get("getsysteminfo");
  },
  getActivities() {
    return Api().get("getactivities");
  },
  getUserActivities(UserID) {
    return Api().get("getuseractivities", {
      params: { userid: UserID }
    });
  },
  getLanguages() {
    return Api().get("getlanguages", {});
  },
  // My search interests needs an searchID also as parameter
  getFacilities(Type) {
    return Api().get("getfacilities", {
      params: {
        Type: Type
      }
    });
  },
  existFacility(PlaceId) {
    return Api().get("existfacility", {
      params: {
        placeid: PlaceId
      }
    });
  },
  getActivityFacilities(ActivityId, Active, Place) {
    return Api().get("getactivityfacilities", {
      params: {
        idactivity: ActivityId,
        active: Active,
        place: Place
      }
    });
  },
  getUserFacilities(UserId, ActivityId) {
    return Api().get("getuserfacilities", {
      params: {
        iduser: UserId,
        idactivity: ActivityId
      }
    });
  },
  getCompanies(ActivityId) {
    return Api().get("getcompanies", {
      params: {
        idactivity: ActivityId
      }
    });
  },
  getUserCoach(UserId, ActivityId) {
    return Api().get("getusercoach", {
      params: {
        iduser: UserId,
        idactivity: ActivityId
      }
    });
  },
  saveUserCoach(credentials) {
    return Api().post("saveusercoach", credentials);
  },
  removeUserCoach(credentials) {
    console.log("removeUserCoach");
    return Api().post("removeusercoach", credentials);
  },
  getUserSkills(UserId) {
    return Api().get("getuserskills", {
      params: {
        userid: UserId
      }
    });
  },
  getActivitySkills(ActivityId) {
    return Api().get("getactivityskills", {
      params: {
        id: ActivityId
      }
    });
  },
  getAllActivitySkills(iduser) {
    return Api().get("getallactivityskills", {
      params: {
        iduser: iduser
      }
    });
  },
  deleteActivityUsers(credentials) {
    console.log(credentials);
    return Api().post("deleteactivityusers", credentials);
  },
  updateActivitySkill(credentials) {
    console.log(credentials);
    return Api().post("updateactivityskill", credentials);
  },
  createActivitySkill(credentials) {
    console.log(credentials);
    return Api().post("createactivityskill", credentials);
  },
  updateUserSkill(credentials) {
    console.log(credentials);
    return Api().post("updateuserskill", credentials);
  },
  createFeedback(credentials) {
    console.log(credentials);
    return Api().post("createfeedback", credentials);
  },
  createUserFacilities(credentials) {
    console.log("In Api create userfacilities");
    console.log(credentials);
    return Api().post("createuserfacilities", credentials);
  },
  createFacility(credentials) {
    console.log("In Api create facility");
    console.log(credentials);
    return Api().post("createfacility", credentials);
  },
  createUserMemberships(credentials) {
    console.log("In Api create userMemberships");
    return Api().post("createusermemberships", credentials);
  },
  removeUserMemberships(credentials) {
    console.log("In Api remove userMemberships");
    return Api().post("removeusermemberships", credentials);
  },
  getAccessRights(iduser, idactivity, idcompany) {
    return Api().get("getaccessrights", {
      params: {
        iduser: iduser,
        idactivity: idactivity,
        idcompany: idcompany
      }
    });
  },
  updateAccessRights(credentials) {
    console.log(credentials);
    return Api().post("updateAccessRights", credentials);
  },
  createCompany(credentials) {
    console.log(credentials);
    return Api().post("createcompany", credentials);
  },
  updateCompany(credentials) {
    console.log(credentials);
    return Api().post("updatecompany", credentials);
  },
  getCompany(companyId) {
    console.log(companyId);
    return Api().get("updatecompany", {
      params: {
        id: companyId
      }
    });
  },
  getCoaches(idactivity, idcompany) {
    return Api().get("getcoaches/", {
      params: {
        idactivity: idactivity,
        idcompany: idcompany
      }
    });
  },
  getUserMemberships(IdUser, IdActivity) {
    return Api().get("getusermemberships/", {
      params: {
        iduser: IdUser,
        idactivity: IdActivity
      }
    });
  }
};
