import Api from "@/services/Api";

export default {
  register(credentials) {
    console.log("Före Api call");
    return Api().post("register", credentials);
  },
  update(credentials) {
    console.log("Före Api call");
    return Api().post("update", credentials);
  },
  updateSettings(credentials) {
    console.log("Före Api call");
    return Api().post("updatesettings", credentials);
  },
  updateParentId(credentials) {
    console.log("Före Api call");
    return Api().post("updateparentid", credentials);
  },
  updateTheme(credentials) {
    console.log("Före Api call");
    return Api().post("updatetheme", credentials);
  },
  updateToken(credentials) {
    console.log("Före Api call");
    return Api().post("updatetoken", credentials);
  },
  addToken(credentials) {
    return Api().post("addtoken", credentials);
  },
  checkUsername(username) {
    return Api().get("checkusername", {
      params: {
        username: username
      }
    });
  },
  getUsername(userid) {
    return Api().get("getusername", {
      params: {
        id: userid
      }
    });
  },
  getTokens(UserId) {
    return Api().get("gettokens", {
      params: {
        userid: UserId
      }
    });
  },
  existToken(token) {
    return Api().get("existtoken", {
      params: {
        token: token
      }
    });
  },
  login(credentials) {
    console.log("Före Api call");
    return Api().post("login", credentials);
  },
  updatePassword(credentials) {
    console.log("Före Api call");
    return Api().post("updatepassword", credentials);
  },
  resetOldPassword(credentials) {
    console.log("Före Api call");
    return Api().post("resetoldpassword", credentials);
  }
};
