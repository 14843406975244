<template>
  <!-- class="grey darken-4" -->
  <v-app>
    <v-main dark style="margin-bottom: 50px">
      <!-- class="grey darken-4" -->
      <!-- v-container :class="background" fluid -->
        <router-view>
          <Login />
          <MainWindow></MainWindow>
        </router-view>
      <!-- /v-container -->
    </v-main>
  </v-app>
</template>

<script>
import Login from "./components/Login";
import MainWindow from "./components/MainWindow";
// import AdminWindow from "./components/AdminWindow";

export default {
  name: "App",

  components: {
    Login,
    MainWindow
  },

  data() {
    return {
      dummy: null
    }
  },
  methods: {

  }
};
</script>

<style scoped>
.v-input {
  font-size: 1.3em;
}

.bgr {
  color: #252424;
}
</style>
