<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="dialogwidth"
    >
      <v-card :dark="selectedTheme" :height="calenderHeight">
        <v-sheet height="64" class="d-flex">
          <v-toolbar
            flat
            src="https://findhavefun.com/images/erasmus-plus-sport.jpg"
          >
            <template v-slot:img="{ props }">
              <v-img
                v-bind="props"
                gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
              ></v-img>
            </template>
            <v-btn
              icon
              class="ml-0 mr-2"
              color="blue darken-4"
              @click.stop="show = false"
            >
              <v-icon>
                mdi-close-outline
              </v-icon>
            </v-btn>
            <v-btn
              small
              class="mr-4"
              color="blue darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn fab text color="blue darken-4" @click="prev">
              <v-icon size="32">
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn fab text color="blue darken-4" @click="next">
              <v-icon size="32">
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendarnew">
              {{ $refs.calendarnew.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  color="blue darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <!-- v-row dense class="fill-height" -->
        </v-sheet>
        <v-row dense class="fill-height">
          <v-col class="ma-0">
            <v-sheet :height="calwinHeight">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                show-week="true"
                :type="type"
                :events="events"
                :event-color="getEventColor"
                :event-ripple="false"
                :weekdays="weekdays"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="getEvents"
                @mousedown:event="startDrag"
                @mousedown:time="startTime"
                @mousemove:time="mouseMove"
                @mouseup:time="endDrag"
                @mouseleave.native="cancelDrag"
              >
                <template v-slot:event="{ event, timed, eventSummary }">
                  <div class="v-event-draggable" v-html="eventSummary()"></div>
                  <div
                    v-if="timed"
                    class="v-event-drag-bottom"
                    @mousedown.stop="extendBottom(event)"
                  ></div>
                </template>
              </v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-y
              >
                <!-- color="grey lighten-4" -->
                <v-card :width="eventPopupWidth" flat>
                  <v-card-title>
                    <v-toolbar dense dark>
                      <v-row dense v-if="displayColors === false" class="ma-0">
                        <v-col cols="2">
                          <v-btn small icon @click="editEvent">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="4">
                          <v-menu top :close-on-click="closeOnClick">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                :color="selectedColor"
                                v-bind="attrs"
                                v-on="on"
                                class="ml-1"
                              >
                                <!-- v-icon>mdi-palette</v-icon -->
                                Color
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item
                                v-for="(item, index) in bookingtypecolors"
                                :key="index"
                              >
                                <v-btn
                                  small
                                  :color="item.text"
                                  @click="selectColor(item)"
                                >
                                  {{ item.text }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                        <!-- v-toolbar-title
                      v-html="selectedEvent.name"
                    ></v-toolbar-title -->
                        <v-col cols="2"> </v-col>
                        <v-col cols="2">
                          <!-- v-spacer></v-spacer -->
                          <v-btn small icon @click="openUserLists" class="ml-3">
                            <v-icon>mdi-account-multiple-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      :disabled="editable"
                      class="mb-2"
                      dense
                      v-model="eventname"
                      hide-details="auto"
                    ></v-text-field>
                    <v-textarea
                      :disabled="editable"
                      class="ma-0 pa-0"
                      dense
                      auto-grow
                      rows="1"
                      name="input-7-1"
                      v-model="eventdetails"
                    >
                    </v-textarea>
                    <!-- span v-html="selectedEvent.details"></span -->
                  </v-card-text>
                  <v-card-actions>
                    <v-btn small text @click="saveEvent">
                      Save
                    </v-btn>
                    <v-btn small text @click="closeEvent">
                      Close
                    </v-btn>
                    <v-btn small text @click="deleteEvent">
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
        <!-- v-card-actions>
          <v-btn small color="primary" @click.stop="show = false">Close</v-btn>
        </v-card-actions -->
      </v-card>
    </v-dialog>
    <v-dialog v-model="colorsDlg" width="200px">
      <v-card flat>
        <v-row dense>
          <v-col>
            <v-autocomplete
              dense
              v-model="selectedColor"
              :disabled="isUpdating"
              :items="bookingtypecolors"
              filled
              label=""
              item-text="text"
              item-value="text"
            >
              <template v-slot:selection="data">
                <v-chip
                  :color="data.item.text"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  <!-- v-btn small
                        :color="data.item.text"
                        class="ma-0 pa-0"
                      >
                        {{ data.item.text }}
                      </v-btn -->
                  {{ data.item.text }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'"> </template>
                <template v-else>
                  <v-btn small :color="data.item.text">
                    {{ data.item.text }}
                  </v-btn>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="saveMoveEventDlg" width="200px">
      <v-card flat>
        <v-row dense>
          <v-col> </v-col>
        </v-row>
        <v-card-actions>
          <v-btn small text @click="saveMovedDlg">
            Save
          </v-btn>
          <v-btn small text @click="closeMovedDlg">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG USER ACTIVITY CREATE :fullscreen="$vuetify.breakpoint.xs" ------------------------------------------------->
    <v-dialog persistent v-model="userfriendsdialog" :max-width="600">
      <v-card :dark="selectedTheme">
        <v-card-text>
          <div><br /></div>
          <div class="my-4 subtitle-1">
            Select friends to invite to your event! Press the field below to get
            a list of friends. Close the list by pressing arrow up/down to the
            right of the list.
          </div>
          <!-- p class="text--primary" >
            Press the field below to get a list of friends. Close the list by
            pressing arrow up/down to the right of the list.
          </p -->
        </v-card-text>
        <v-form>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  chips
                  deletable-chips
                  small-chips
                  v-model="selectedfriends"
                  :items="friendList"
                  color="blue-grey lighten-2"
                  label="Add friend"
                  item-text="User.name"
                  item-value="User.id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.User.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.User.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.User.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  chips
                  deletable-chips
                  small-chips
                  v-model="selectedgroups"
                  :items="groupList"
                  color="blue-grey lighten-2"
                  label="Add groups"
                  item-text="Group.name"
                  item-value="Group.id"
                  multiple
                  class="ma-0 pa-0"
                >
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :src="data.item.Group.avatar" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.Group.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.Group.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small color="primary" @click="checkIfSaveUserGroups">
            Save
          </v-btn>
          <v-btn small color="error" @click="cancelUserGroupDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="nodeitwarningmsg" top color="warning">
      {{ noeditregtext }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="nodeitwarningmsg = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import CalenderService from "../services/CalenderService";
import SearchesService from "@/services/SearchesService";
import GroupServices from "@/services/GroupServices";

// import CalenderService from "@/services/CalenderService";

export default {
  props: ["visible", "iduser", "ActiveActivityID", "selectedTheme"],
  data() {
    return {
      // calwinHeight: window.innerHeight,
      noeditregtext: "Only Owner Can Update Event",
      nodeitwarningmsg: false,
      eventfriends: [],
      eventgroups: [],
      selectedfriends: [],
      selectedgroups: [],
      userfriendsdialog: false,
      friendList: [],
      groupList: [],
      selectedFriendList: [],
      closeOnFriend: true,
      closeOnClick: true,
      colorsDlg: false,
      saveMoveEventDlg: false,
      displayColors: false,
      isUpdating: false,
      selectedColor: "",
      selectedSaveRecord: null,
      dateItem: {
        event: null,
        data: null,
      },
      dateItemList: [],
      statusNew: true,
      id: 0,
      eventPos: 0,
      eventname: "name",
      eventdetails: "details ",
      editable: true,
      focus: "",
      type: "week",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      scheduledItem: {
        id: 0,
        iduser: 0,
        idbookingtype: 0,
        idbookingitem: 0,
        idactivity: 0,
        name: "",
        details: "",
        color: "",
        datetimecreated: null,
        start: null,
        end: null,
        timed: false,
        datefrom: "",
        timefrom: "",
        dateto: "",
        timeto: "",
      },
      sheduleditemlist: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      defaultColor: "#000000",
      bookingtypecolors: [
        { text: "red", value: "#F4433" },
        { text: "pink", value: "#E91E6" },
        { text: "purple", value: "#9C27" },
        { text: "deep-purple", value: "#673A" },
        { text: "indigo", value: "#3F51B" },
        { text: "blue", value: "#2196F" },
        { text: "light-blue", value: "#03A9" },
        { text: "cyan", value: "#00BC" },
        { text: "teal", value: "#00968" },
        { text: "green", value: "#4CAF" },
        { text: "light-green", value: "#8BC3" },
        { text: "lime", value: "#CDDC" },
        { text: "yellow", value: "#FFEB3" },
        { text: "amber", value: "#FFC10" },
        { text: "orange", value: "#FF980" },
        { text: "deep-orange", value: "#FF572" },
        { text: "brown", value: "#79554" },
        { text: "blue-grey", value: "#607D" },
        { text: "grey", value: "#9E9E9" },
        { text: "white", value: "#000000" },
        { text: "black", value: "#FFFFFF" },
      ],
      colors: [
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#2196F3",
        "#03A9F4",
        "#00BCD4",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FFEB3B",
        "#FFC107",
        "#FF9800",
        "#FF5722",
        "#795548",
        "#607D8B",
        "#9E9E9E",
        "#000000",
        "#FFFFFF",
      ],
      colors2: [
        "#2196F3",
        "#3F51B5",
        "#673AB7",
        "#00BCD4",
        "#4CAF50",
        "#FF9800",
        "#757575",
      ],
      names: [
        "Meeting",
        "Holiday",
        "Tennis",
        "Padel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      /*
      {
        iduser: 0,
        idbookingtype: 0,
        idbookingitem: 0,
        idactivity: 0,
        name: "",
        details: "",
        color: "",
        start: null,
        end: null,
        timed: false,
        datefrom: "",
        timefrom: "",
        dateto: "",
        timeto: "",
      },
      */
      saveEventRec: {
        id: 0,
        iduser: 0,
        idbookingtype: 0,
        idbookingitem: 0,
        idactivity: 0,
        name: "",
        details: "",
        color: "",
        datetimecreated: null,
        start: null,
        end: null,
        timed: false,
        datefrom: "",
        timefrom: "",
        dateto: "",
        timeto: "",
      },
      createStart: null,
      extendOriginal: null,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      workweek: [1, 2, 3, 4, 5],
      longweekend: [4, 5, 6, 0],
    };
  },
  watch: {
    selectedColor(value) {
      this.events[this.eventPos].color = value;
    },
  },
  computed: {
    activeEventColor() {
      // this.colorsDlg = false;
      return this.selectedColor;
    },
    calwinHeight() {
      if (this.$vuetify.breakpoint.name === "xs" && this.type === "month") {
        return "750px";
      }
      if (this.$vuetify.breakpoint.name === "xs") {
        return "750px";
      }
      if (this.$vuetify.breakpoint.name !== "xs" && this.type === "month") {
        return "650px";
      }
      return "650px";
    },
    calenderHeight() {
      if (this.$vuetify.breakpoint.name === "xs" && this.type === "month") {
        return "90%";
      }
      if (this.$vuetify.breakpoint.name === "xs") {
        return "70%";
      }
      if (this.$vuetify.breakpoint.name !== "xs" && this.type === "month") {
        return "100%";
      }
      return "100%";
    },
    show: {
      get() {
        return this.visible;
      },
      set(focus) {
        if (!focus) {
          this.$emit("close");
        }
      },
    },
    dialogwidth() {
      if (this.$vuetify.breakpoint.name === "xs") return "100%";
      if (this.$vuetify.breakpoint.name === "sm") return "80%";
      if (this.$vuetify.breakpoint.name === "md") return "70%";
      if (this.$vuetify.breakpoint.name === "lg") return "70%";
      if (this.$vuetify.breakpoint.name === "xl") return "70%";
    },
    eventPopupWidth() {
      if (this.$vuetify.breakpoint.name === "xs") return "100%";
      if (this.$vuetify.breakpoint.name === "sm") return "100%";
      if (this.$vuetify.breakpoint.name === "md") return "100%";
      if (this.$vuetify.breakpoint.name === "lg") return "100%";
      if (this.$vuetify.breakpoint.name === "xl") return "100%";
    },
  },
  async mounted() {
    // this.userid = this.$store.state.user.id;
    // window.addEventListener('resize', () => {
    //  this.calwinHeight = window.innerHeight
    // })
    try {
      console.log("mounted");
      console.log(this.iduser);
      console.log(this.$store.state.user.id);
      this.iduser = this.$store.state.user.id;
      const items = await CalenderService.getScheduledItems(this.iduser);
      this.sheduleditemlist = items.data;
    } catch (error) {
      console.log(error);
    }
    // this.$refs.calendar.checkChange();
    // const active = 1;
    // const response = await CalenderService.getBookings(this.userid, active);
    // this.calenderlist = response.data;
  },
  methods: {
    saveMovedDlg() {
      this.checkIfSaveUserGroups();
      this.saveMoveEventDlg = true;
    },
    closeMovedDlg() {
      this.saveMoveEventDlg = false;
    },
    cancelUserGroupDialog() {
      this.userfriendsdialog = false;
    },
    async checkIfSaveUserGroups() {
      // We only save groups if we have an event id
      if (
        this.selectedSaveRecord.id === "" ||
        this.selectedSaveRecord.id === 0
      ) {
        this.saveEvent();
        this.userfriendsdialog = true;
        return;
      } else {
        await this.saveUserGroups(this.selectedSaveRecord.id);
        this.userfriendsdialog = false;
      }
    },
    async saveEventUserToList(eventid, iduser) {
      try {
        var flist = [];
        var friendpost = {
          idscheduleditem: eventid,
          idscheduleditemfriend: iduser,
        };
        flist.push(friendpost);
        const res = await CalenderService.addScheduledUsers({
          id: eventid,
          selectedfriends: flist,
        });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    async updateSelectedUsers(eventid) {
      var index = 0;
      var list = [];
      var pos = 0;
      try {
        console.log(this.selectedfriends);
        console.log(this.eventfriends);
        if (this.selectedfriends.length > 0) {
          if (this.eventfriends.length > 0) {
            index = 0;
            // remove users not selected again
            while (this.eventfriends[index]) {
              var pos = this.selectedfriends.indexOf(
                this.eventfriends[index].idscheduleditemfriend
              );
              if (pos < 0) {
                var removepost = {
                  idscheduleditem: eventid,
                  idscheduleditemfriend: this.eventfriends[index]
                    .idscheduleditemfriend,
                };
                list.push(removepost);
              }
              index++;
            }
          }
          if (list.length > 0) {
            const res = await CalenderService.removeScheduledUsers({
              id: eventid,
              selectedfriends: list,
            });
            console.log(res);
          }
          list = [];
          index = 0;
          // add new users
          while (this.selectedfriends[index]) {
            // check if selected user is new then add
            if (this.eventfriends.length > 0) {
              var pos = this.eventfriends
                .map(function(e) {
                  return e.idscheduleditemfriend;
                })
                .indexOf(this.selectedfriends[index]);
              if (pos < 0) {
                var friendpost = {
                  idscheduleditem: eventid,
                  idscheduleditemfriend: this.selectedfriends[index],
                };
                if (this.selectedfriends[index] !== this.iduser) {
                  list.push(friendpost);
                }
              }
            } else {
              // New user
              var friendpost = {
                idscheduleditem: eventid,
                idscheduleditemfriend: this.selectedfriends[index],
              };
              list.push(friendpost);
            }
            index++;
          }
          if (list.length > 0) {
            const res = await CalenderService.addScheduledUsers({
              id: eventid,
              selectedfriends: list,
            });
            console.log(res);
          }
        }
      } catch (err) {}
    },
    async updateSelectedGroups(eventid) {
      var index = 0;
      var list = [];
      var pos = 0;
      try {
        console.log(this.selectedgroups);
        console.log(this.eventgroups);
        if (this.selectedgroups.length > 0) {
          if (this.eventgroups.length > 0) {
            index = 0;
            // remove groups not selected again
            while (this.eventgroups[index]) {
              var pos = this.selectedgroups.indexOf(
                this.eventgroups[index].idscheduleditemfriend
              );
              if (pos < 0) {
                var removepost = {
                  idscheduleditem: eventid,
                  idscheduleditemgroup: this.eventgroups[index]
                    .idscheduleditemgroup,
                };
                list.push(removepost);
              }
              index++;
            }
          }
          if (list.length > 0) {
            const res = await CalenderService.removeScheduledGroups({
              id: eventid,
              selectedgroups: list,
            });
            console.log(res);
          }
          list = [];
          index = 0;
          // add new groups
          while (this.selectedgroups[index]) {
            // check if selected group is new then add
            if (this.eventgroups.length > 0) {
              var pos = this.eventgroups
                .map(function(e) {
                  return e.idscheduleditemgroup;
                })
                .indexOf(this.selectedgroups[index].idscheduleditemgroup);
              if (pos < 0) {
                var grouppost = {
                  idscheduleditem: eventid,
                  idscheduleditemgroup: this.selectedgroups[index],
                };
                list.push(grouppost);
              }
            } else {
              // New group
              var grouppost = {
                idscheduleditem: eventid,
                idscheduleditemgroup: this.selectedgroups[index],
              };
              list.push(grouppost);
            }
            index++;
          }
          if (list.length > 0) {
            const res = await CalenderService.addScheduledGroups({
              id: eventid,
              selectedgroups: list,
            });
            console.log(res);
          }
        }
      } catch (err) {}
    },
    async xxxupdateSelectedGroups(eventid) {
      try {
        console.log(this.selectedgroups);
        if (this.selectedgroups.length > 0) {
          var index = 0;
          var list = [];
          while (this.selectedgroups[index]) {
            var grouppost = {
              idscheduleditem: eventid,
              idscheduleditemgroup: this.selectedgroups[index],
            };
            list.push(grouppost);
            index++;
          }
          if (list.length > 0) {
            const res = await CalenderService.addScheduledGroups({
              id: eventid,
              selectedgroups: list,
            });
            console.log(res);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async saveUserGroups(eventid) {
      try {
        console.log(this.selectedfriends);
        await this.updateSelectedUsers(eventid);
        await this.updateSelectedGroups(eventid);
      } catch (err) {
        console.log(err);
      }
    },
    async getFriends() {
      try {
        const Approved = 1;
        const response = await SearchesService.getActivityFriends(
          this.iduser,
          this.ActiveActivityID,
          Approved
        );
        this.friendList = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async getGroups() {
      try {
        const response = await GroupServices.getUserGroups(
          this.iduser,
          this.ActiveActivityID
        );
        this.groupList = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    selectColor(item) {
      this.selectedColor = item.text;
    },
    selectFriend(item) {
      this.selectedFriendList.push(item);
    },
    async openUserLists() {
      try {
        this.userfriendsdialog = true;
        await this.getFriends();
        await this.getGroups();

        this.eventfriends = [];
        this.eventgroups = [];
        if (this.selectedSaveRecord.id > 0) {
          this.selectedfriends = [];
          const eventfriendstmp = await CalenderService.getScheduledItemFriends(
            this.selectedSaveRecord.id
          );
          console.log(eventfriendstmp);
          this.eventfriends = eventfriendstmp.data;
          var index = 0;
          while (this.eventfriends[index]) {
            this.selectedfriends.push(
              this.eventfriends[index].idscheduleditemfriend
            );
            index++;
          }
          const eventgroupstmp = await CalenderService.getScheduledItemGroups(
            this.selectedSaveRecord.id
          );
          console.log(eventgroupstmp);
          this.eventgroups = eventgroupstmp.data;
          this.selectedgroups = [];
          index = 0;
          while (this.eventgroups[index]) {
            this.selectedgroups.push(
              this.eventgroups[index].idscheduleditemgroup
            );
            index++;
          }
        }
        console.log("openUserLists");
      } catch (error) {
        console.log(error);
      }
    },
    openColors() {
      if (this.colorsDlg === false) {
        this.colorsDlg = true;
      } else {
        this.colorsDlg = false;
      }
      /*
      if (this.displayColors === false) {
        this.displayColors = true;
      } else {
        this.displayColors = false;
      }
      */
    },
    openColorsInl() {
      if (this.displayColors === false) {
        this.displayColors = true;
      } else {
        this.displayColors = false;
      }
    },
    updateList() {},
    getFormattedDate(date) {
      const year = date.getFullYear(),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = date.getDate();

      return [year, month, day].join("-");
    },
    closeEvent() {
      this.selectedOpen = false;
      // this.eventname = "";
      // this.eventdetails = "";
      // this.selectedColor = "";
      this.selectedEvent.name = this.eventname;
      this.selectedEvent.details = this.eventdetails;
      this.selectedEvent.color = this.eventcolor;
      this.eventPost = -1;
    },
    async deleteEvent() {
      try {
        if (!this.checkOwnerForEdit()) return;
        this.selectedOpen = false;
        this.eventname = "";
        this.eventdetails = "";
        this.selectedColor = "";
        const res = await CalenderService.removeScheduledItem({
          id: this.selectedSaveRecord.id,
        });
        this.events.splice(this.eventPos, 1);
        console.log(res);
        this.eventPos = -1;
      } catch (error) {
        console.log(error);
      }
    },
    setPosInEvents() {
      let index = 0;
      this.eventPos = -1;
      while (this.events[index]) {
        if (
          this.events[index].start === this.selectedEvent.start &&
          this.events[index].end === this.selectedEvent.end &&
          this.events[index].name === this.selectedEvent.name
        ) {
          this.eventPos = index;
          this.selectedSaveRecord = this.dateItemList[index].data;
          console.log(this.dateItemList[index]);
          break;
        }
        index++;
      }
    },
    editEvent() {
      console.log("editEvent");
      console.log(this.saveEventRec);
      if (this.editable === true) {
        this.editable = false;
      } else {
        this.editable = true;
      }
    },
    checkOwnerForEdit() {
      console.log(this.selectedSaveRecord);
      if (this.selectedSaveRecord.id > 0) {
        if (this.iduser !== this.selectedSaveRecord.iduser) {
          this.nodeitwarningmsg = true;
          return false;
        } else return true;
      }
    },
    async saveEvent() {
      try {
        let res = null;
        /*
        const saverec = {
          iduser: this.$store.state.user.id,
          idbookingtype: 0,
          idbookingitem: 0,
          idactivity: this.ActiveActivityID,
          name: this.createEvent.name,
          details: this.createEvent.details,
          color: this.createEvent.color,
          // start: null,
          // end: null,
          datefrom: this.createEvent.datefrom,
          timefrom: this.createEvent.timefrom,
          dateto: this.createEvent.dateto,
          timeto: this.createEvent.timeto,
        };
        */
        // this.createEvent.iduser = this.userid;
        // this.createEvent.idactivity = this.ActiveActivityID;
        console.log(this.selectedSaveRecord);
        if (
          this.selectedSaveRecord.id === "" ||
          this.selectedSaveRecord.id === 0
        ) {
          // if (this.statusNew === true) {
          this.saveEventRec.name = this.eventname;
          // this.saveEventRec.color = this.selectedColor;
          this.saveEventRec.details = this.eventdetails;
          var date = new Date(this.saveEventRec.start);
          this.saveEventRec.timefrom = date.toLocaleTimeString();
          // let timestr =  this.this.saveEventRec.timefrom;
          // this.this.saveEventRec.timefrom = timestr + ":00";
          console.log(this.saveEventRec);
          date = new Date(this.saveEventRec.end);
          this.saveEventRec.timeto = date.toLocaleTimeString();
          console.log(this.saveEventRec);
          this.saveEventRec.dateto = date.toLocaleDateString();
          this.saveEventRec.id = "";
          this.saveEventRec.color = this.selectedColor;
          console.log(this.saveEventRec);
          res = await CalenderService.createScheduledItem(this.saveEventRec);
          console.log(res);
          let res1 = await this.saveEventUserToList(res.data.id, this.iduser);
          console.log(res1);
          let res2 = await this.saveUserGroups(res.data.id);
          console.log(res2);
        } else {
          if (!this.checkOwnerForEdit()) return;
          this.selectedSaveRecord.start = this.dateItemList[
            this.eventPos
          ].event.start;
          this.selectedSaveRecord.end = this.dateItemList[
            this.eventPos
          ].event.end;
          var newStartDate = new Date(this.selectedSaveRecord.start);
          // console.log(newStartDate.toLocaleDateString());
          this.selectedSaveRecord.datefrom = newStartDate.toLocaleDateString();
          this.selectedSaveRecord.timefrom = newStartDate.toLocaleTimeString();
          console.log(this.selectedSaveRecord.timefrom);
          var newEndDate = new Date(this.selectedSaveRecord.end);
          this.selectedSaveRecord.dateto = newEndDate.toLocaleDateString();
          this.selectedSaveRecord.timeto = newEndDate.toLocaleTimeString();
          console.log(this.selectedSaveRecord.timeto);
          // this.selectedSaveRecord.month = this.dateItemList[this.eventPos].event.month;
          // this.selectedSaveRecord.day = this.dateItemList[this.eventPos].event.day;
          this.selectedSaveRecord.timed = this.dateItemList[
            this.eventPos
          ].event.timed;
          // this.dateItemList[this.eventPos].event.color = this.selectedColor;

          // console.log(this.selectedSaveRecord);
          this.selectedSaveRecord.name = this.eventname;
          this.selectedSaveRecord.color = this.selectedColor;
          this.selectedSaveRecord.details = this.eventdetails;
          console.log(this.selectedSaveRecord);
          // this.selectedSaveRecord.id = this.selectedSaveRecord.id;
          res = await CalenderService.updateScheduledItem(
            this.selectedSaveRecord
          );
          this.statusNew = true;
          res = await this.saveUserGroups(this.selectedSaveRecord.id);
        }
        this.selectedOpen = false;
        this.selectedEvent.name = this.eventname;
        this.selectedEvent.details = this.eventdetails;
        this.selectedEvent.color = this.eventcolor;
        this.dateItemList[this.eventPos].event.color = this.selectedColor;
        console.log(this.selectedEvent);
        console.log(this.dateItemList[this.eventPos].event);
      } catch (err) {
        console.log(err);
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        console.log(event);
        this.eventname = event.name;
        this.eventdetails = event.details;
        this.eventcolor = event.color;
        this.selectedElement = nativeEvent.target;
        this.setPosInEvents();
        if (this.checkOwnerForEdit()) {
          this.editable = true;
        }
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        // setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.eventcolor = event.color;
        this.selectedColor = event.color;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      var str = tms.time.substring(0, 3) + "00";
      // console.log(str);
      tms.time = str;
      tms.minute = 0;
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
        this.statusNew = false;
      } else {
        this.statusNew = true;
        this.createStart = this.roundTime(mouse);
        this.id++;

        let endstamp = 60 * 60 * 1000;

        this.createEvent = {
          name: "Name New Event",
          details: "",
          color: this.defaultColor,
          start: this.createStart,
          end: this.createStart + endstamp,
          timed: true,
        };

        this.selectedfriends = [];
        this.selectedgroups = [];
        // this.saveEventRec.id = this.id;
        this.saveEventRec.name = "Event New Event";
        this.saveEventRec.details = "";
        this.saveEventRec.color = this.defaultColor; // this.rndElement(this.colors);
        this.saveEventRec.datetimecreated = new Date();
        this.saveEventRec.datefrom = tms.date;
        this.saveEventRec.timefrom = tms.time;
        this.saveEventRec.month = tms.month;
        this.saveEventRec.day = tms.day;
        this.saveEventRec.start = this.createEvent.start;
        // let strstart = this.saveEventRec.start.toString();
        // console.log(strstart);
        // let dt = new Date(strstart).getTime();
        // this.createEvent.start = timestamp(strstart);
        //  this.saveEventRec.start
        this.saveEventRec.end = this.createEvent.end;
        this.saveEventRec.timed = this.createEvent.timed;
        this.saveEventRec.iduser = this.iduser;
        this.saveEventRec.idactivity = this.ActiveActivityID;
        this.saveEventRec.idbookingtype = 0;
        this.saveEventRec.idbookingitem = 0;

        this.events.push(this.createEvent);
        this.sheduleditemlist.push(this.saveEventRec);
        let dateItem = { event: this.createEvent, data: this.saveEventRec };
        this.dateItemList.push(dateItem);
        // this.selectedOpen = true;
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      // this.saveMoveEventDlg = true;
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      // const roundTo = 15; // minutes
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    getEventColor(event) {
      const rgbstr = event.color.substring(1);
      const rgb = parseInt(rgbstr, 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color;
    },
    getEvents({ start, end }) {
      const events = [];
      this.dateItemList = [];

      const min = new Date(`${start.date}T00:00:00`).getTime();
      const max = new Date(`${end.date}T23:59:59`).getTime();
      const days = (max - min) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      var index = 0;
      while (this.sheduleditemlist[index]) {
        // for (let i = 0; i < eventCount; i++) {
        //  const timed = this.rnd(0, 3) !== 0;
        //  const firstTimestamp = this.rnd(min, max);
        //  const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
        //  const start = firstTimestamp - (firstTimestamp % 900000);
        //  const end = start + secondTimestamp;

        if (this.sheduleditemlist[index].UserScheduledItem) {
          var newEvent = {
            // name: this.rndElement(this.names),
            // color: this.rndElement(this.colors),
            name: this.sheduleditemlist[index].UserScheduledItem.name,
            details: this.sheduleditemlist[index].UserScheduledItem.details,
            color: this.sheduleditemlist[index].UserScheduledItem.color,
            start: this.sheduleditemlist[index].UserScheduledItem.start,
            end: this.sheduleditemlist[index].UserScheduledItem.end,
            timed: true,
          };
        } else {
          index++;
          continue;
        }

        events.push(newEvent);

        var dateItem = {
          event: newEvent,
          data: this.sheduleditemlist[index].UserScheduledItem,
        };
        this.dateItemList.push(dateItem);
        index++;
      }

      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
  },
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
