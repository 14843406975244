import Api from "@/services/Api";

export default {
  getGroups(UserID) {
    console.log(UserID);
    return Api().get("getgroups", {
      params: {
        userid: UserID
      }
    });
  },
  getUserGroups(UserID, ActivityID) {
    console.log(UserID);
    return Api().get("getusergroups", {
      params: {
        userid: UserID,
        activityid: ActivityID
      }
    });
  },
  getUserGroupRequests(UserID, ActivityID, Joined) {
    console.log(UserID);
    return Api().get("getusergrouprequests", {
      params: {
        userid: UserID,
        activityid: ActivityID,
        joined: Joined
      }
    });
  },
  getGroupsMessages(UserID, ActivityID) {
    console.log(UserID);
    return Api().get("getgroupsmessages", {
      params: {
        userid: UserID,
        activityid: ActivityID
      }
    });
  },
  getUserGroupsIds(UserID, ActivityID) {
    console.log(UserID);
    return Api().get("getusergroupsids", {
      params: {
        userid: UserID,
        activityid: ActivityID
      }
    });
  },
  createGroup(credentials) {
    console.log(credentials);
    return Api().post("creategroup", credentials);
  },
  updateUserGroup(credentials) {
    console.log(credentials);
    return Api().post("updateusergroup", credentials);
  },
  createCompanyGroup(credentials) {
    console.log(credentials);
    return Api().post("createcompanygroup", credentials);
  },
  updateCompanyGroup(credentials) {
    console.log(credentials);
    return Api().post("updatecompanygroup", credentials);
  },
  addUsersToGroup(credentials) {
    console.log(credentials);
    return Api().post("adduserstogroup", credentials);
  },
  updateUserGroupCoach(credentials) {
    console.log(credentials);
    return Api().post("updateusergroupcoach", credentials);
  },
  deleteUsersFromGroup(credentials) {
    console.log(credentials);
    return Api().post("deleteusersfromgroup", credentials);
  },
  deleteUserGroup(credentials) {
    console.log(credentials);
    return Api().post("deleteusergroup", credentials);
  },
  getGroupUsers(GroupID, ActivityID) {
    return Api().get("getgroupusers", {
      params: {
        groupid: GroupID,
        activityid: ActivityID
      }
    });
  },
  getGroupUserSkills(GroupID, ActivityID) {
    return Api().get("getgroupuserskills", {
      params: {
        groupid: GroupID,
        activityid: ActivityID
      }
    });
  },
  getGroupUserTokens(GroupID) {
    return Api().get("getgroupusertokens", {
      params: {
        groupid: GroupID
      }
    });
  },
  deleteGroup(credentials) {
    console.log(credentials);
    return Api().post("deletegroup", credentials);
  },
  getGroupOffers(UserId, ActivityId) {
    return Api().get("getgroupoffers", {
      params: {
        userid: UserId,
        idactivity: ActivityId
      }
    });
  },
  manageGroups(companyid, activityid) {
    return Api().get("managegroups", {
      params: {
        companyid: companyid,
        activityid: activityid
      }
    });
  },
  manageGroupMessages(companyid, activityid) {
    return Api().get("managegroupmessages", {
      params: {
        companyid: companyid,
        activityid: activityid
      }
    });
  },
  updateUserGroupMessageRead(credentials) {
    console.log(credentials);
    return Api().post("updateusergroupmessageread", credentials);
  },
  updateGroupData(credentials) {
    console.log(credentials);
    return Api().post("updategroupdata", credentials);
  }
};
