import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  deep: true,
  plugins: [createPersistedState()],
  state: {
    token: null,
    user: null,
    searchid: null,
    isUserLoggedIn: false,
    access: null,
    ActiveActivity: 0
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      if (token) {
        state.isUserLoggedIn = true;
      } else {
        state.isUserLoggedIn = false;
      }
    },
    setUser(state, user) {
      state.user = user;
    },
    setSearchID(state, searchid) {
      state.searchid = searchid;
    },
    setAccess(state, access) {
      state.access = access;
    },
    setActiveActivity(state, ActiveActivity) {
      state.ActiveActivity = ActiveActivity;
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setSearchID({ commit }, searchid) {
      commit("setSearchID", searchid);
    },
    setAccess({ commit }, access) {
      commit("setAccess", access);
    },
    setActiveActivity({ commit }, ActiveActivity ) {
      commit("setActiveActivity", ActiveActivity);
    }
  }
});
