<template>
  <v-container>
      <!-- v-model="signindialog" max-width="60%"> -->
      <v-row>
        <v-col xs="1" sm="1" md="2" lg="3"></v-col>
          <v-col sm="10" md="8" lg="6">
          <v-card width="400px">
            <v-card-title>Sign In</v-card-title>
              <v-list-item>
                <v-list-item-content>
                  <v-row class="mx-2" dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="username"
                        placeholder="username"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :append-icon="pswshow1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="errorloginmsg"
                        v-model="password"
                        :type="pswshow1 ? 'text' : 'password'"
                        @click:append="pswshow1 = !pswshow1"
                        placeholder="password"
                      >
                      </v-text-field>
                    </v-col>
                      <!-- v-btn justify="center" text color="blue" 
                          :href="'http://findhavefun.com/resetpswwindow'">
                          reset password ?
                        </v-btn -->
                      <!-- v-select
                            v-model="selectedLoginActivity"
                            :items="interestlist"
                            item-text="name"
                            item-value="id"
                            label="Activities"
                            :rules="activityRules"
                            :error-messages="errorsearchactivitymsg"
                          ></v-select -->
                      <v-col cols="12">
                      <v-checkbox
                        class="text--primary ma-1 pa-1"
                        v-model="cookie"
                        label="Remember my login"
                        color="indigo"
                        value="1"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            <!-- v-card-actions -->
              <v-list-item>
              <v-row dense>
                <v-col>
                  <v-btn small color="primary" @click="login">Sign In</v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    small
                    color="grey"
                    @click="resetpassword()"
                    >Reset Password</v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    small
                    color="error"
                    @click="cancel()"
                    >Cancel</v-btn
                  >
                    </v-col>
              </v-row>
              </v-list-item>
              <v-list-item>
              <v-row v-if="loginWithGoogle === true" dense>
                <v-col>
                  <v-btn small color="white" @click="loginGoogle">
                    <v-icon left>
                      mdi-google
                    </v-icon>Sign In With Google</v-btn>
                </v-col>
              </v-row>
              </v-list-item>
            <!-- /v-card-actions -->
          </v-card>
          </v-col>
          <v-col xs="1" sm="1" md="2" lg="3">
          </v-col>
      </v-row>
    </v-container>
</template>

<script>

import AuthenticationService from "@/services/AuthenticationService";
// import "firebase/auth";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
import 'capacitor-secure-storage-plugin';
import "@codetrix-studio/capacitor-google-auth";
import { Plugins } from "@capacitor/core";
// const { Storage } = Plugins;
// import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
// import "@codetrix-studio/capacitor-google-auth";
const { GoogleAuth } = Plugins;
// const { Storage } = Plugins;
// import { Storage } from '@capacitor/storage';
// import { saveUserDataIndexDB, getUserDataIndexDB } from '@/utils/mydbtools';
// import { setName, getKeyValue, removeKey } from '@/storage/storage';
const { SecureStoragePlugin } = Plugins;

export default {
  name: "Login",
  components: {
  },
  // props: ['username', 'password'],
  data() {
    return {
      loginWithGoogle: false,
      showLoginWindow: false,
      platform: "web",
      dummy: "",
      username: "",
      password: "",
      userid: 0,
      error: "",
      pswshow1: false,
      errorloginmsg: "",
      UserSignedIn: false,
      userinfo: "",
      cookie: "0"
    }
  },
  created() {

  },
  async mounted() {
    // await GoogleAuth.init(); 
    // if (this.$route.params.showlogin === true) {
    //  this.showLoginWindow = true;
    // }
    this.login();
  },
  methods: {
    resetpassword() {
      this.$router.push({
        name: "resetpassword"
      });
    },
    cancel() {
      this.$router.push({
          name: "mainwindow"
      })
    },
    async loginGoogle() {
      try {
        // await GoogleAuth.init(); // or await GoogleAuth.init()

        const googleUser = await GoogleAuth.signIn();
        console.log('my user: ', googleUser);
        this.userinfo = googleUser;
      } catch (error) {
        console.log(error.message)
      }
    },
    async loginGoogleWeb() {
      // const auth = getAuth(firebaseApp);
      // onAuthStateChanged(auth, user => {
        // Check for user status
      // });
    },
    async login() {
      let response = null;
      // let user = null;
      let saveLogin = "1"; //this.cookie;
      try {
        console.log("Before login call");
        var username = this.username;
        console.log(username + this.password);

        // if (this.cookie === "1") {

        if (!this.username) {
          var userkey = await this.getUserData();
          // userkey = window.$cookies.get("user");
          // console.log('cookies get user ' + userkey);
          // let userkey = await getUserDataIndexDB();
          if (userkey) {
            this.username = userkey.username;
            this.password = userkey.password;
          }
        }
        // }
        if (this.showLoginWindow === true) {
          this.showLoginWindow = false;
          return;
        }
        response = await AuthenticationService.login({
          username: this.username,
          password: this.password,
        });
        /* turn off for the moment , send email checks instead 
        if (response.data.user.verified === false || response.data.user.verified === 0) {
          this.errorloginmsg = "You have to confirm your email adress, check your emails"
          MailService.sendVerificationEmail({emailto: response.data.user.email})
          return
        } */
        // this.playMP3 ()
        console.log("After login call");
        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.user);
        this.userid = response.data.user.id;

/*
        this.userid = response.data.user.id;
        this.username = response.data.user.username;
        this.name = response.data.user.name;
        this.User = response.data.user;
        this.UserUpd = this.User;
        this.personnumber = response.data.user.personnumber;
        this.displayname = this.username;
        this.ActiveActivityID = response.data.user.defaultactivity;
        this.DefaultActivityID = response.data.user.defaultactivity;
        this.selectedDefaultActivity = response.data.user.defaultactivity;
        this.selectedActiveActivity = response.data.user.defaultactivity;
*/
        
        // Tokens.checkToken(this.token, this.userid);

        if (saveLogin === "1") {
          try {
            userkey = window.$cookies.get("user");
            console.log('cookie.get user ' + userkey);
            // let userkey = await getUserDataIndexDB();
            userkey = await this.getUserData();
            if (userkey.username === "") {
              /* user = {
                id: this.userid,
                username: this.username,
                password: this.password,
              }; */
              // await saveUserDataIndexDB(this.username, this.password);
              // let ret = window.$cookies.set('bearer', response.data.token);
              // console.log('this.$cookies.set response.data.token ' + response.data.token + ' ret === ' + ret)
              // const ret = window.$cookies.set("user", user);
              // console.log('this.$cookies.set ret ===' + ret);
              await this.setUserData(this.username, this.password);
            } else {
              /* user = {
                id: this.userid,
                username: this.username,
                password: this.password,
              }; */
              if (userkey.username === this.username) {
                if (userkey.password !== this.password) {
                  // this.$cookies.set('bearer', response.data.token);
                  // this.$cookies.set("user", user);
                  await this.setUserData(this.username, this.password);
                }
              }
              if (userkey.username !== this.username) {
                // this.$cookies.set('bearer', response.data.token);
                // this.$cookies.set("user", user);
                await this.setUserData(this.username, this.password);
              }
             
            }
          } catch (error) {
            console.log(error);
          }
        }

        this.$router.push({
        name: "infowindow",
          params: {
            userid: this.userid,
            username: this.username,
            password: this.password
            // listsheader: this.myfriendslistsheader
          },
        });

      } catch (error) {
        console.log(error);
        this.error = error;
        this.errorloginmsg = "Wrong Username or Password";
        // this.signindialog = true;
        this.UserSignedIn = false;
        return;
      }
    },
    async setUserData(username, password) {
      try {
      await SecureStoragePlugin.set({ key: 'user', value: username })
        .then(success => console.log(success))
        .catch(error => {console.log(error)})
      await SecureStoragePlugin.set({ key: 'password', value: password })
        .then(success => console.log(success))
        .catch(error => {console.log(error)})
      } catch (error) {
        console.log('error in setUserData')
      }
    },
    async getUserData() {
      let ret = {username: "", password: ""};
      await SecureStoragePlugin.get({ key: 'user' })
        .then(value => {
          console.log(value);
          ret.username = value.value;
        })
        .catch(error => {
          console.log('Item with specified key does not exist.' + error);
        });
      await SecureStoragePlugin.get({ key: 'password' })
        .then(value => {
          console.log(value);
          ret.password = value.value;
        })
        .catch(error => {
          console.log('Item with specified key does not exist.' + error);
        });
        return ret;
    },
    /*
    async xxgetUserData () {
      try {
        // const user = await getName('user');
        // const user = await getKeyValue('user');
        const { username } = await Storage.get({ key: 'user' });
        console.log(username);
        // const password = await getName('password');
        // const password = await getKeyValue('password');
        const { password } = await Storage.get({ key: 'password' });
        console.log(password);
       
       if (typeof username === 'undefined' || typeof password === 'undefined') {
          return null;
        }
        let userdata = { user: username.value, password: password.value };

        return userdata;
      } catch (err) {
        console.log(err);
        console.log('Error getUserData');
        return null;
      }
    },
    */
    /*
    async xgetUserData () {
      try {
        const user = await getKeyValue('user');
        console.log(user);
        const password = await getKeyValue('password');
        console.log(password);
       
       if (typeof user === 'undefined' || typeof password === 'undefined') {
          return null;
        }
        let userdata = { user: user, password: password };

        return userdata;
      } catch (err) {
        console.log(err);
        console.log('Error getUserData');
        return null;
      }
    },
    async removeUserData () {
      await removeKey('user');
      await removeKey('password');
    }
    */

  }
}

</script>

<style scoped>

</style>