<template>
  <v-card :dark="theme" flat>
    <v-row dense>
      <v-col cols="2" xs="0" sm="0" md="2"></v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
          <v-row dense>
            <v-col cols="7">
              <v-autocomplete
                v-model="selectedMemberGroup"
                :items="grouplistnomsg"
                item-text="name"
                item-value="id"
                class="ml-5 mt-8"
                dense
                label="Groups"
                small-chips
                clearable
              ></v-autocomplete>
              <!-- v-btn text color="primary" class="mt-4 pa-0">
                      Members
                    </v-btn -->
            </v-col>
            <v-col cols="4">
              <v-text-field class="ml-3 mt-4"
                ref="searchNameField"
                v-model="searchNameField" 
                label="Search on name"
                append-icon="mdi-magnify">
              </v-text-field>
            </v-col>
          </v-row>
        <v-container color="black" fluid class="ma-0 pa-0">
          <v-card
            flat
            :dark="theme"
            class="ma-0 pa-0"
            v-model="selectedgroupmembers"
          >
            <v-list dense>
              <v-list-item
                v-for="item in filteredList"
                :key="item.User.id"
                active-class="teal lighten-4"
              >
                <!-- v-list-group
                      v-for="item in groupmemberusers"
                      :key="item.User.id"
                      v-model="item.User.dialog"
                      no-action
                      multiple-->
                <template v-slot:default="{ active }">
                  <v-list-item-avatar :input-value="active">
                    <v-img class="ma-0 pa-0" :src="item.User.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.User.name + ' - ' + item.User.username"
                    >
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.User.skill + ' ' + item.User.skilltext"
                    ></v-list-item-subtitle>
                    <!-- v-spacer></v-spacer -->
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-icon class="ma-0 pa-0">
                      <v-btn
                        icon
                        small
                        class="ma-0 pa-0"
                        @click="createMemberMessage(item)"
                      >
                        <v-icon size="24px">mdi-message-text-outline</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item-action>
                </template>

                <!-- v-list-item class="ml-n16">
                    <v-list-item-content>
                      <v-card flat
                        class="ma-0 mb-0 pa-0"
                        max-width="800">
                        <v-row no-gutters>
                        <v-textarea class="ma-0 pa-0"
                          dense
                          solo
                          auto-grow
                          rows="1"
                          name="input-7-1"
                          label="new message"
                          v-model="sendfriendusermessage">
                        </v-textarea>
                          <v-btn small class="ml-3 mt-2 mb-0 pa-0"
                            icon
                            @click="sendFriendMessage()"
                            color="deep-purple accent-4">
                            <v-icon size="32px">mdi-email-send-outline</v-icon>
                          </v-btn>
                        </v-row>
                      </v-card>
                      <v-card flat class="ma-0 mt-n5 pa-0"
                        max-width="800">
                        <v-card-text>
                          <v-row dense justify="start" class="mb-3 mx-0"
                            v-for="message in userhistmsg"
                            :key="message.time"
                            >
                            <v-col v-if="message.color === 'green'" cols="12"
                              sm="10" offset-sm="2">
                              <div class="font-weight-normal text-left teal--text">
                                <div>{{ message.message }}</div>
                                <div><strong>{{ message.from }}</strong>
                                  {{ message.time.substr(0, 10) + ' ' + message.time.substr(11, 5) }}
                                </div>
                              </div>
                            </v-col>
                            <v-col v-else cols="12" sm="10">
                              <div class="font-weight-normal text-left purple--text">
                                <div>{{ message.message }}</div>
                                <div><strong>{{ message.from }}</strong>
                                  {{ message.time.substr(0, 10) + ' ' + message.time.substr(11, 5) }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item -->
              </v-list-item>
            </v-list>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="2" xs="0" sm="0" md="2"></v-col>
    </v-row>
  </v-card>
</template>

<script>
import SearchesService from "@/services/SearchesService";
import UserService from "@/services/UserService";
import GroupServices from "@/services/GroupServices";

export default {
  components: {
    // Calender
    // VuetifyAudio: () => import('vuetify-audio')
  },
  props: ['userid', 'idcompany', 'ActiveActivityID', 'grouplistnomsg', 'theme'],
  name: "AdminMembers",
  data() {
    return {
      searchNameField: "",
      // selectedTheme: false,
      snackbar: false,
      memberuserhistmsg: [],
      textmembermessage: "",
      dialogmembermessage: false,
      manymembermessage: false,
      selectedgroupmembers: [],
      selectedMemberGroup: "",
      messagefrienddialog: false,
      sendfriendmembermessage: "",
      sendfriendmemberitem: null,
      membermessagelist: [],
      updateFriendList: [],
      updateFriendHistoryList: [],
      // Coach
      username: "",
      name: "",
      password: 0,
      idfacility: 0,
      coachAccessRight: 0,
      coachAdminSettings: null,
      groupbookingdialog: false,
      calenderheaderlist: [],
      selectedgroupheaders: [],
      // Groups
      autofillgroupmessage: "",
      // grouplistnomsg: [],
      groupmemberusers: [],
      grouplist: [],
      memberslist: [],
      messageitem: null,
      FriendBadge: 0,
    };
  },
  watch: {
    // searchNameField(field) {
    //  console.log("searchNameField " + field);
    // },
    selectedMemberGroup() {
      console.log(this.selectedMemberGroup);
      if (this.selectedMemberGroup) {
        this.getSelectedGroupMembers();
      } else {
        this.groupmemberusers = [];
      }
    },
  },
  computed: {
    icontabsize() {
      if (this.$vuetify.breakpoint.name === "xs") return 24;
      if (this.$vuetify.breakpoint.name === "sm") return 24;
      else return 32;
      // case "xs": return "24px"
      // case "sm": return "24px"
      // case "md": return "32px"
      // case "lg": return "32px"
      // case "xl": return "32px"
    },
    filteredList() {
        return this.groupmemberusers.filter(post => {
            return post.User.name.toLowerCase().includes(this.searchNameField.toLowerCase())
        })
    }
  },
  created() {
    console.log("created adminmembers");
  },
  async mounted() {
    console.log("created adminmembers");
    // await this.initTabMembers();
    // await this.manageGroups();
  },
  methods: {
    async reloadGroups() {
      await this.manageGroups();
    },
    memberSearch(item) {
      console.log(item);
    },
    async manageGroups() {
      try {
        this.grouplist = [];
        //const Approved = approved
        // this.friendlist = []
        const response = await GroupServices.manageGroupMessages(
          this.idcompany,
          this.ActiveActivityID
        );
        this.grouplist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getMembers() {
      try {
        this.memberslist = [];
        const response = await SearchesService.getCompanyMembers(
          this.idcompany,
          this.ActiveActivityID
        );
        this.memberslist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getMembersSkills() {
      try {
        this.memberslist = [];
        const response = await SearchesService.getCompanyMembersSkills(
          this.idcompany,
          this.ActiveActivityID
        );
        this.memberslist = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async initTabMembers() {
      try {
        this.grouplistnomsg = [];
        this.memberslist = [];
        //const Approved = approved
        // this.friendlist = []
        const response = await GroupServices.manageGroups(
          this.idcompany,
          this.ActiveActivityID
        );
        this.grouplistnomsg = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getSelectedGroupMembers() {
      console.log("getSelectedGroupMembers");
      try {
        // var tmp = this.grouplistnomsg;
        // console.log(tmp)
        /*
        const activityid = this.ActiveActivityID;
        const name = this.selectedMemberGroup;
        var pos = this.grouplistnomsg
          .map(function(e) {
            return e.name;
          })
          .indexOf(name);
        const id = this.grouplistnomsg[pos].id;
        */
        const id = this.selectedMemberGroup;
        const activityid = this.ActiveActivityID;
        const response = await GroupServices.getGroupUserSkills(id, activityid);
        this.groupmemberusers = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async createMemberMessage(item) {
      try {
        console.log(item);
        if (this.selectedgroupmembers.length === 1) {
          await this.createFriendInlMessage(item);
          this.dialogmembermessage = true;
          console.log("this.selectedgroupmembers.length === 1");
        }
        if (this.selectedgroupmembers.length > 1) {
          console.log("this.selectedgroupmembers.length > 1");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createFriendInlMessage(item) {
      var friendid = 0;
      try {
        if (this.FriendBadge > 0) {
          this.FriendBadge--;
        }
        this.sendfriendmemberitem = item;
        item.User.msgbadge = 0;
        this.memberuserhistmsg = [];
        friendid = item.User.id;
        const activityid = this.ActiveActivityID;
        const mainid = this.idcompany;
        const usermsg = await SearchesService.getActivityMessagesHist(
          this.userid,
          friendid,
          activityid,
          mainid,
          true
        );
        const tmp = usermsg.data;
        var index = 0;
        if (tmp.length > 0) {
          while (tmp[index]) {
            var rec = {
              id: 0,
              idgroup: 0,
              idmain: 0,
              idactivity: 0,
              idsender: 0,
              idreceiver: 0,
              from: "",
              message: "",
              time: "",
              color: "",
            };
            if (tmp[index].idsender === this.userid) {
              rec.color = "blue";
            } else {
              rec.color = "green";
            }
            rec.idgroup = tmp[index].idgroup;
            rec.idmain = tmp[index].idmain;
            rec.from = tmp[index].sendername;
            rec.message = tmp[index].message;
            rec.time = tmp[index].createdAt;
            rec.id = tmp[index].id;
            rec.idsender = tmp[index].idsender;
            rec.idreceiver = tmp[index].idreceiver;
            rec.idactivity = tmp[index].idactivity;
            this.memberuserhistmsg.push(rec);
            index = index + 1;
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.messageitem = item;
      // this.membermessagelist[this.selectedmessagerows]
      console.log(this.messageitem);
    },
    async sendMemberMessage() {
      if (this.sendfriendmembermessage === "") {
        return;
      }
      console.log(this.sendfriendmemberitem);
      var message = {
        idgroup: 0,
        idcoach: 0,
        idmain: 0,
        idactivity: 0,
        idsender: 0,
        idreceiver: 0,
        sendername: "",
        receivername: "",
        message: "",
        type: "single",
      };
      // var response = null
      var newmessage = null;
      try {
        message.idgroup = this.getSelectedGroupId();
        message.idmain = message.idgroup;
        message.idreceiver = this.sendfriendmemberitem.User.id;
        message.idactivity = this.ActiveActivityID;
        message.idsender = this.userid;
        message.idcoach = this.userid;
        message.sendername = this.name;
        message.receivername = this.sendfriendmemberitem.User.name;
        message.type = "single";
        message.message = this.sendfriendmembermessage;
        // this.socket.emit('new_message', message )
        // this.sendIOMessage(message)
        const response = await UserService.sendMyMessage(message);
        newmessage = response.data.message;
        this.messagefrienddialog = false;
        this.sendfriendmembermessage = "";
        // this.membermessagelist.unshift(newmessage);
        // this.memberuserhistmsg.unshift(newmessage);
        // this.updateMemberList(newmessage);
        this.updateMemberHistoryList(newmessage);
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.error
      }
      try {
        this.sendIOMessage(newmessage);
      } catch (error) {
        console.log(error);
      }
    },
    updateMemberHistoryList(message) {
      // this is called from Friend list when
      // user expends the list and send message
      try {
        // check if userlist needs update idsender idreceiver
        // check if right user list
        var rec = {
          id: 0,
          idactivity: 0,
          idsender: 0,
          idreceiver: 0,
          from: "",
          message: "",
          time: "",
          color: "",
        };
        if (message.idsender === this.userid) {
          rec.color = "deep-purple lighten-1";
        } else {
          rec.color = "green";
        }
        rec.from = message.sendername;
        rec.message = message.message;
        rec.time = message.createdAt;
        rec.id = message.id;
        rec.idsender = message.idsender;
        rec.idreceiver = message.idreceiver;
        rec.idactivity = message.idactivity;
        this.memberuserhistmsg.unshift(rec);
      } catch (error) {
        console.log(error);
      }
    },
    updateMemberList(message) {
      // Check for duplicates
      // Update Friends/Group lists to latest message
      try {
        // Add to friendlist message field also
        var index = this.friendlist
          .map(function(e) {
            return e.id;
          })
          .indexOf(message.idgroup);
        if (index >= 0) {
          this.friendlist[index].message = message.message;
          this.friendlist[index].messageAt = message.createdAt;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.scroll {
  height: 500px;
  overflow-y: scroll;
}
v-text-h3 input {
  font-size: 1.5em;
}
</style>
