<template>
    <v-container>
        <v-row dense>
            <v-col xs="1" sm="1" md="2" lg="3"></v-col>
            <v-col sm="10" md="8" lg="6">
            <v-card width="400px">
                <v-card-title>Enter Your Email</v-card-title>
                <v-container>
                <v-row dense>
                    <v-col cols="12">
                    <v-col cols="10">
                        <v-text-field
                        dense
                        required
                        v-model="resetemail"
                        :rules="emailRules"
                        placeholder="Email"
                        >
                        </v-text-field>
                    </v-col>
                    </v-col>
                    <v-col cols="12">
                    <v-card-text v-if="resetsent === true">
                        <v-alert border="bottom" color="success" dark>
                        Check your inbox for password reset link
                        </v-alert>
                        <!-- div>
                        <h5>Check your inbox for password reset link</h5>
                        </div -->
                    </v-card-text>
                    </v-col>
                </v-row>
                </v-container>
                <v-card-actions>
                <v-btn small
                    v-if="resetsent === false"
                    color="primary"
                    @click="resetSendEmail()"
                    >Send</v-btn
                >
                <v-btn small color="grey" @click="closeresetemail()">Close</v-btn>
                </v-card-actions>
            </v-card>
            </v-col>
            <v-col xs="1" sm="1" md="2" lg="3">
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import MailService from "@/services/MailService";

export default {
    data () {
        return {
            resetsent: false
        }
    },
    methods: {
        async resetSendEmail() {
            try {
                const email = await MailService.resetPasswordEmail({
                emailto: this.resetemail,
                });
                console.log(email);
                this.resetsent = true;
            } catch (error) {
                console.log(error);
            }
        },
        closeresetemail() {
            this.$router.push({
                name: "mainwindow"
            })
        }
    }
}
</script>

<style scoped>

</style>